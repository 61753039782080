import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterOutputs } from "@/helpers/trpc";
import { generateUniqueColor } from "@/lib/utils";
import { Avatar, AvatarFallback } from "@heffl/ui/components/primitives/avatar";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import { cn, websiteFavicon } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Building2,
  CalendarDays,
  CircleDollarSign,
  Clock,
  FastForward,
  LucideIcon,
  MessageCircle,
  ScrollText,
  StickyNote,
  ThumbsDown,
  ThumbsUp,
  User,
} from "lucide-react";
import { useNavigate } from "react-router-dom";

dayjs.extend(relativeTime);

interface Props {
  deal: RouterOutputs["deals"]["list"][number];
  dragOverlay?: boolean;
  isListingCard?: boolean;
}

export const CompanyLogo = ({
  company,
  avatarClassName,
  className,
}: {
  company?: { website?: string | null; name: string } | null;
  avatarClassName?: string;
  className?: string;
}) => {
  return company && company.website ? (
    <img
      src={websiteFavicon(company.website, 64)}
      alt="company"
      className={cn("w-12 h-12 rounded-sm", className)}
    />
  ) : (
    <Avatar className={cn("w-12 h-12 uppercase rounded-sm", className)}>
      <AvatarFallback
        className={cn(
          "text-base text-gray-600 bg-gray-200 rounded-sm",
          avatarClassName
        )}
      >
        {company ? (
          `${company.name[0]}${company.name[1]}`
        ) : (
          <User className="text-gray-700" />
        )}
      </AvatarFallback>
    </Avatar>
  );
};

const InfoItem = ({
  icon: Icon,
  value,
  className,
}: {
  icon: LucideIcon;
  value: string | number;
  className?: string;
}) => {
  return (
    <div className="flex gap-2 items-center">
      <Icon className="w-4 h-4 text-gray-500" />
      <Tooltip content={value}>
        <p className={cn("text-xs line-clamp-1", className)}>{value}</p>
      </Tooltip>
    </div>
  );
};

const DealCard = ({ deal, dragOverlay, isListingCard = false }: Props) => {
  const nextActivity = deal.activities.find((activity) => !activity.completed);

  const navigate = useNavigate();

  const counts = [
    {
      icon: StickyNote,
      count: deal._count.notes,
      label: "Notes",
    },
    {
      icon: CalendarDays,
      count: deal.activities.length,
      label: "Activities",
    },
    {
      icon: ScrollText,
      count: deal._count.quotations,
      label: "Quotations",
    },
    {
      icon: MessageCircle,
      count: deal._count.comments,
      label: "Comments",
    },
  ];

  return (
    <div
      className={cn(
        "bg-white border border-gray-200 !rounded-sm font-normal",
        dragOverlay ? "cursor-grabbing" : "cursor-grab",
        isListingCard ? "w-full" : "max-width-[300px]"
      )}
      onClick={() => isListingCard && navigate(`/crm/deals/details/${deal.id}`)}
    >
      <div className="flex gap-3 items-center">
        <div className="flex flex-col w-full line-clamp-2">
          {/* <p className="text-sm font-normal text-gray-500">
            {deal.clients
              ? deal.clients.name
              : deal.dealContacts.length
              ? formatName(deal.dealContacts[0].contacts)
              : ""}
          </p> */}
          <div className="flex justify-between w-full border-b p-1.5 border-gray-200">
            <p className="font-medium line-clamp-1">{deal.title}</p>
            {deal.status === "WON" && (
              <Badge
                variant="success"
                className="flex gap-1 items-center w-fit"
                icon={ThumbsUp}
                small
              >
                Won
              </Badge>
            )}
            {deal.status === "LOST" && (
              <Badge
                variant="error"
                className="flex gap-1 items-center w-fit"
                icon={ThumbsDown}
                small
              >
                Lost
              </Badge>
            )}
          </div>

          <div className="flex flex-col gap-2 p-2 !font-normal">
            <InfoItem
              icon={CircleDollarSign}
              value={
                deal.price
                  ? heffl.format.currency(deal.price, deal.currencies.symbol)
                  : "No value"
              }
            />
            <InfoItem icon={Building2} value={deal.clients?.name || ""} />
            <InfoItem
              icon={FastForward}
              value={
                nextActivity
                  ? `${nextActivity.title} at ${heffl.format.dynamicDate(
                      nextActivity.startDate
                    )}`
                  : "No upcoming activities"
              }
              className={
                nextActivity
                  ? dayjs(nextActivity.startDate).isSame(dayjs(), "day")
                    ? "text-green-600"
                    : dayjs(nextActivity.startDate).isBefore(dayjs(), "day")
                    ? "text-red-600"
                    : ""
                  : ""
              }
            />

            {/* {isListingCard && (
              <p className="text-sm text-gray-500">
                {deal.dealPipelineStages.dealPipelines.name}
              </p>
            )}
            {isListingCard && (
              <p className="text-sm text-gray-500">
                {deal.dealPipelineStages.name}
              </p>
            )} */}

            <div className="flex gap-2 justify-between items-center pt-1">
              <div className="flex flex-row gap-2 items-center">
                {deal.ownedBy && (
                  <div className="flex flex-row gap-2 items-center">
                    <Avatar className="w-5 h-5">
                      <AvatarFallback
                        className="text-[8px] text-white uppercase"
                        style={{
                          backgroundColor: generateUniqueColor(
                            deal.ownedBy.firstName,
                            500
                          ),
                        }}
                      >
                        {`${deal.ownedBy.firstName[0]}${deal.ownedBy.firstName[1]}`}
                      </AvatarFallback>
                    </Avatar>
                  </div>
                )}

                {counts.map(
                  ({ icon: Icon, count, label }) =>
                    count > 0 && (
                      <Tooltip key={label} content={label}>
                        <div className="flex flex-row gap-1 items-center">
                          <Icon className="w-3.5 h-3.5 text-gray-500" />
                          <p className="text-xs">{count}</p>
                        </div>
                      </Tooltip>
                    )
                )}
              </div>

              <div className="flex flex-row gap-1 items-center">
                <Clock className="w-3.5 h-3.5 text-gray-500" />
                <p className="text-xs font-normal text-gray-600">
                  {dayjs(deal.createdAt).fromNow()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DealCard;
