import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import Pagination from "@/components/Pagination";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import useTeam from "@/lib/hooks/useTeam";
import enums from "@heffl/server/src/schemas/enums";
import DropMenu from "@heffl/ui/components/DropMenu";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { isMobile } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  Copy,
  MoreHorizontal,
  ReceiptText,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { downloadPdf, generateInvoiceName } from "../invoices/details";
import heffl from "@/helpers/hefflHelpers/heffl";
import { Badge } from "@heffl/ui/components/primitives/badge";

type PageFilters = {
  search: string;
  pageNo: number;
  status?: z.infer<typeof enums.invoiceStatus>;
};

const ProformaInvoices = () => {
  const navigate = useNavigate();
  const team = useTeam();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    status: undefined,
  });

  const { data: proformaInvoices, isLoading } =
    trpc.proformaInvoice.list.useQuery({
      ...filters,
      pageSize: 30,
    });

  if (!team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_PROFORMA_INVOICES.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page fullWidth title="Proforma Invoices" className="sm:p-0">
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
        />

        <ResponsiveActionButton
          onClick={() => navigate("/sales/proforma-invoices/add")}
          text="Proforma Invoice"
        />
      </div>

      <div className="sm:px-3">
        <FilterBar
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "status",
              type: "checkbox",
              label: "Status",
              value: filters.status ? [filters.status] : [],
              onChange: (value) =>
                setFilters({
                  status: value[0] as z.infer<typeof enums.invoiceStatus>,
                }),
              options: [
                { label: "Draft", value: "DRAFT" },
                { label: "Sent", value: "SENT" },
                { label: "Paid", value: "PAID" },
              ],
            },
          ]}
        />
      </div>

      {isMobile() ? (
        <div className="flex flex-col gap-2 mt-4 mb-[100px]">
          {proformaInvoices?.invoices.map((invoice) => (
            <Card
              key={invoice.id}
              onClick={() => navigate(`details/${invoice.id}`)}
              className="cursor-pointer"
            >
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <p className="text-sm font-medium">{invoice.clients?.name}</p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-xs">Invoice number</p>
                  <p className="text-sm font-medium text-gray-700">
                    #{invoice.number}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-xs">Created on</p>
                  <p className="text-sm text-gray-500">
                    {dayjs(invoice.date).format("DD/MM/YYYY")}
                  </p>
                </div>
              </div>
            </Card>
          ))}
          <Pagination
            pageNo={filters.pageNo}
            count={proformaInvoices?.meta.count}
            setPageNo={(pageNo) => setFilters({ pageNo })}
            className="justify-start"
          />
        </div>
      ) : (
        <DataGrid
          rowKey="id"
          name="proformaInvoicesListMain"
          className="h-[calc(100vh-117px-var(--header-height))]"
          label="Proforma Invoices"
          loading={isLoading}
          onCellClick={({ row }) => {
            navigate(`details/${row.id}`);
          }}
          empty={{
            icon: ReceiptText,
            title: "No proforma invoices added",
            description: "Please add a proforma invoice",
            actionText: "Add proforma invoice",
            onAction: () => navigate("/sales/proforma-invoices/add"),
            buttonSize: "sm",
          }}
          rows={proformaInvoices?.invoices || []}
          pagination={{
            pageNo: filters.pageNo,
            pageSize: 30,
            count: proformaInvoices?.meta.count || 0,
            setPageNo(pageNo) {
              setFilters({ pageNo });
            },
          }}
          columns={[
            {
              key: "number",
              name: "Number",
              width: 120,
              iconify: "tabler:hash",
              renderCell: ({ row }) => (
                <p className="text-sm font-medium text-gray-900 cursor-pointer hover:text-primary-600">
                  #{row.number}
                </p>
              ),
            },
            {
              key: "client",
              name: "Client",
              width: 300,
              iconify: "tabler:user",
              renderCell: ({ row }) => (
                <p className="text-sm text-gray-900">{row.clients?.name}</p>
              ),
            },
            {
              key: "status",
              name: "Status",
              width: 120,
              iconify: "tabler:circle-dot",
              renderCell: ({ row }) => (
                <Badge
                  variant={
                    row.status === "DRAFT"
                      ? "neutral"
                      : row.status === "SENT"
                      ? "default"
                      : row.status === "PAID"
                      ? "solidSuccess"
                      : row.status === "CANCELLED"
                      ? "error"
                      : "neutral"
                  }
                  small
                >
                  {row.status}
                </Badge>
              ),
            },
            {
              key: "date",
              name: "Date",
              width: 140,
              iconify: "tabler:calendar",
              renderCell: ({ row }) => (
                <p className="text-sm text-gray-600">
                  {heffl.format.date(row.date)}
                </p>
              ),
            },
            {
              key: "amount",
              name: "Amount",
              width: 140,
              iconify: "tabler:currency-dollar",
              renderCell: ({ row }) => (
                <p className="text-sm font-medium !text-right text-gray-900 w-full">
                  {heffl.format.currency(
                    heffl.calculate.lineItems({
                      lineItems: row.proformaInvoiceProducts,
                      discount: row.discount,
                    }).total,
                    "AED"
                  )}
                </p>
              ),
            },
            {
              key: "dueDate",
              name: "Due Date",
              width: 140,
              iconify: "tabler:calendar-due",
              renderCell: ({ row }) => (
                <p className="text-sm text-gray-600">
                  {heffl.format.date(row.dueDate)}
                </p>
              ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              iconify: "tabler:dots-vertical",
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () =>
                        navigate(
                          `/sales/proforma-invoices/add?invoiceId=${row.id}`
                        ),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        await downloadPdf({
                          name: generateInvoiceName(row),
                          url: `print?proforma-invoice=${row.uuid}`,
                        });
                      },
                    },
                  ]}
                >
                  <Button variant="ghost" className="p-0 w-8 h-8">
                    <MoreHorizontal className="w-4 h-4 text-gray-500" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
        />
      )}
    </Page>
  );
};

export default ProformaInvoices;
