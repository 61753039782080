import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { BarChart } from "@heffl/ui/components/charts/barchart";
import SimpleTable from "@heffl/ui/components/simple-table";
import { useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import EmptyScreen from "@heffl/ui/components/primitives/empty-screen";
import { useEffect } from "react";

const DealsByStage = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<
    RouterInputs["crm"]["reports"]["deals"]["dealsByStage"]
  >({
    status: [],
    dates: [heffl.date.monthStart(), heffl.date.monthEnd()],
    owners: [],
    pipelineId: [],
    quotationStatus: [],
  });

  const { data: users } = trpc.users.list.useQuery({
    type: ["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"],
  });

  const { data: pipelines } = trpc.deals.pipelines.list.useQuery();

  const { data: dealsByStage, isLoading } =
    trpc.crm.reports.deals.dealsByStage.useQuery(filters, {
      enabled: filters.pipelineId.length > 0,
    });

  useEffect(() => {
    if (pipelines && filters.pipelineId.length === 0 && pipelines.length > 0) {
      setFilters({
        pipelineId: [pipelines[0].id],
      });
    }
  }, [pipelines]);

  return (
    <div>
      <FilterBar
        defaultFilters={["pipeline"]}
        suffix={
          <div className="p-1 px-2 rounded-lg border">
            Count: {dealsByStage?.meta.count}
          </div>
        }
        onChange={() => {}}
        className="pb-3"
        filters={[
          {
            key: "pipeline",
            type: "checkbox",
            label: "Pipeline",
            value: filters.pipelineId,
            multiple: false,
            onChange: (value) =>
              setFilters({
                pipelineId: value as number[],
              }),
            options:
              pipelines?.map((pipeline) => ({
                label: pipeline.name,
                value: pipeline.id,
              })) || [],
          },
          {
            key: "date",
            type: "date-range",
            label: "Created at",
            value: filters.dates,
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
          {
            key: "owners",
            type: "checkbox",
            label: "Owners",
            value: filters.owners,
            onChange: (value) =>
              setFilters({
                owners: value as number[],
              }),
            options:
              users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })) || [],
          },
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.status,
            onChange: (value) =>
              setFilters({
                status: value as ("ACTIVE" | "WON" | "LOST")[],
              }),
            options: [
              {
                label: "Active",
                value: "ACTIVE",
              },
              {
                label: "Won",
                value: "WON",
              },
              {
                label: "Lost",
                value: "LOST",
              },
            ],
          },
        ]}
      />

      {match({
        isLoading,
        dealsByStage,
        pipelineId: filters.pipelineId,
      })
        .with({ pipelineId: [] }, () => (
          <EmptyScreen title="Please select a pipeline" icon />
        ))
        .with({ isLoading: false, dealsByStage: { data: [] } }, () => (
          <EmptyScreen title="No deals found" />
        ))
        .with({ isLoading: false }, () => (
          <div>
            <BarChart
              data={dealsByStage?.data || []}
              type="stacked"
              index="name"
              categories={["dealsCount"]}
              categoryLabels={{
                dealsCount: "Deals",
              }}
              colors={["blue"]}
              showLegend={false}
              yAxisWidth={48}
              className="h-64"
              yAxisLabel="Number of Deals"
              xAxisLabel="Stage"
              onValueChange={(value) => {
                if (value) {
                  const params = heffl.url.objToParams({
                    pipelineId: filters.pipelineId,
                    stageIds: [Number(value.id)],
                    dates: filters.dates,
                    owners: filters.owners,
                    status: filters.status,
                  });
                  navigate(`/crm/deals/table?${params}`);
                }
              }}
            />
            <SimpleTable
              borderless
              size="small"
              className="mt-4"
              onRowClick={(row) => {
                const params = heffl.url.objToParams({
                  pipelineId: filters.pipelineId,
                  stageIds: [Number(row.id)],
                  dates: filters.dates,
                  owners: filters.owners,
                  status: filters.status,
                });
                navigate(`/crm/deals/table?${params}`);
              }}
              columns={[
                {
                  label: "Stage",
                  key: "name",
                  width: 200,
                },
                {
                  label: "Count",
                  width: 100,
                  render: (value) => value.dealsCount,
                },
              ]}
              rows={dealsByStage?.data || []}
              idKey="id"
            />
          </div>
        ))
        .otherwise(() => (
          <FullScreenSpinner />
        ))}
    </div>
  );
};

export default DealsByStage;
