import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { Badge } from "@heffl/ui/components/primitives/badge";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Building, DollarSign, Eye, Timer, User } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { rrulestr } from "rrule";

const RecurringInvoices = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [filters, setFilters] = useParamsState<
    RouterInputs["sales"]["recurringInvoices"]["list"]
  >({
    search: "",
    pageNo: 1,
    clients: [],
    projects: [],
    isActive: undefined,
    salesPersons: [],
    pageSize: 30,
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data: recurringInvoices, isLoading } =
    trpc.sales.recurringInvoices.list.useQuery({
      ...filters,
    });

  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  if (!permissions || !recurringInvoices) return <FullScreenSpinner />;

  return (
    <Page fullWidth title="Recurring Invoices" className="!p-0">
      <div className="flex flex-col gap-2 justify-between p-3 border-b sm:flex-row">
        <SearchInput
          placeholder="Search recurring invoices..."
          value={filters.search || ""}
          onChange={(e) => setFilters({ search: e })}
        />
        <div className="flex gap-2 items-center">
          <ResponsiveActionButton
            onClick={() => navigate("add")}
            text="Recurring Invoice"
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-2 p-3 mt-0 mb-0">
        <FilterBar
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          filters={[
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              value: filters.clients,
              showSearch: true,
              onChange: (value) => setFilters({ clients: value as number[] }),
              onSearch: (value) => setClientSearch(value),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
            },
            {
              key: "salesPersons",
              label: "Sales Persons",
              type: "checkbox",
              value: filters.salesPersons,
              options: users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })),
              onChange: (e) => {
                setFilters({ salesPersons: e as number[] });
              },
            },
          ]}
        />
      </div>

      {isLoading || !recurringInvoices ? (
        <FullScreenSpinner />
      ) : (
        <DataGrid
          rowKey="id"
          onCellClick={(invoice) => {
            navigate(`/sales/recurring-invoices/edit/${invoice.row.id}`);
          }}
          className="h-[calc(100vh-170px)]"
          loading={isLoading}
          name="recurringInvoicesList"
          label="Recurring Invoices"
          empty={{
            icon: DollarSign,
            title: "No recurring invoices found",
            description: "No recurring invoices available to display.",
          }}
          pagination={{
            pageNo: filters.pageNo || 1,
            count: recurringInvoices?.meta.count,
            pageSize: filters.pageSize || 30,
            setPageNo: (pageNo) => setFilters({ pageNo }),
            setPageSize: (pageSize) => setFilters({ pageSize }),
          }}
          rows={recurringInvoices?.recurringInvoices || []}
          columns={[
            {
              key: "number",
              name: "#",
              width: 100,
              renderCell: ({ row }) => row.number,
            },
            {
              key: "name",
              name: "Name",
              width: 300,
              renderCell: ({ row }) => (
                <p className="cursor-pointer">{row.name}</p>
              ),
            },
            {
              key: "client",
              name: "Client",
              width: 200,
              icon: Building,
              renderCell: ({ row }) => <p>{row.clients.name}</p>,
            },
            {
              key: "isActive",
              name: "Status",
              width: 100,
              icon: Eye,
              renderCell: ({ row }) => (
                <Badge variant={row.isActive ? "success" : "neutral"}>
                  {row.isActive ? "Active" : "Inactive"}
                </Badge>
              ),
            },
            {
              key: "frequency",
              name: "Frequency",
              width: 160,
              icon: Timer,
              renderCell: ({ row }) =>
                heffl.format.capitalize(rrulestr(row.frequencyRule).toText()),
            },
            {
              key: "amount",
              name: "Amount",
              width: 160,
              icon: DollarSign,
              renderCell: ({ row }) =>
                heffl.format.currency(
                  calculateLineItems({
                    lineItems: row.recurringInvoiceProducts,
                    discount: row.discount,
                  }).total,
                  "AED",
                  true
                ),
            },
            {
              key: "salesPerson",
              name: "Sales Person",
              width: 160,
              icon: User,
              renderCell: ({ row }) => (
                <p>
                  {row.salesPerson ? heffl.format.name(row.salesPerson) : "-"}
                </p>
              ),
            },
          ]}
        />
      )}
    </Page>
  );
};

export default RecurringInvoices;
