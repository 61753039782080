import { z } from "zod";
import enums from "./enums";

export const paymentMethod = z.object({
  name: z.string(),
  isDefault: z.boolean().default(false),
});

export const lineItemProduct = z.object({
  name: z.string(),
  productId: z.coerce.number().nullish(),
  quantity: z.coerce
    .number()
    .nonnegative("Quantity cannot be negative")
    .default(0),
  price: z.coerce.number().nonnegative("Price cannot be negative").default(0),
  description: z.string().nullish(),
  tax: z.coerce.number().nonnegative("Tax cannot be negative").default(0),
  viewType: enums.lineItemViewType.default("LINE_ITEM"),
});

export const quotation = z.object({
  clientId: z.number(),
  contactId: z.number().nullish(),
  templateId: z.number(),
  date: z.date(),
  quotationProducts: lineItemProduct
    .extend({
      customFields: z.any().default({}),
      scope: z.string().default(""),
    })
    .array(),
  title: z.string().default(""),
  subject: z.string().nullish(),
  status: enums.quotationStatus.default("DRAFT"),
  contentHtml: z.string(),
  markedSentOn: z.date().nullish(),
  internalNotes: z.string().nullish().optional(),
  dealId: z.number().nullish(),
  discount: z.coerce.number().default(0),
  currencyId: z.number().default(1),
  quotationTags: z.coerce.number().array().default([]),
  expiryDate: z.date().nullish(),
  salesPersonId: z.number().nullish(),
  lostReasonId: z.number().nullish(),
  lostReasonDescription: z.string().nullish(),
  customFields: z.any().default({}),
});

export const commission = z.object({
  date: z.date(),
  status: enums.commissionStatus.default("DRAFT"),
  salesOrderId: z.number(),
  salesOrderItemId: z.number().nullish(),
  baseAmount: z.coerce.number().default(0),
  amount: z.coerce.number().min(0.01, "Amount is required"),
  paidTo: enums.commissionPaidTo,
  payableToVendorId: z.number().nullish(),
  payableToUserId: z.number().nullish(),
  notes: z.string().default(""),
  cancelReason: z.string().nullish(),
  createInstantBill: z.boolean().default(false),
});

export const invoiceProduct = lineItemProduct.extend({
  buyPrice: z.coerce.number().default(0),
});

export const invoice = z.object({
  recurringInvoiceId: z.number().nullish(),
  salesOrderInvoices: z
    .object({
      salesOrderId: z.number().nullish(),
    })
    .nullish(),
  projectInvoices: z
    .object({
      projectId: z.number(),
      projectPeriodId: z.number().nullish(),
    })
    .array()
    .nullish(),
  fsJobInvoices: z
    .object({
      fsJobId: z.number().nullish(),
      fsScheduleId: z.number().nullish(),
    })
    .nullish(),
  pmLeaseInvoices: z
    .object({
      pmLeaseId: z.number().nullish(),
    })
    .nullish(),
  externalId: z.string().nullish(),
  importOperationId: z.number().nullish(),
  clientId: z.number(),
  contactId: z.number().nullish(),
  date: z.date(),
  dueDate: z.date(),
  invoiceProducts: invoiceProduct.array(),
  status: enums.invoiceStatus.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish().optional(),
  discount: z.coerce
    .number()
    .nonnegative("Discount cannot be negative")
    .default(0),
  currencyId: z.number().default(1),
  templateId: z.number(),
  invoiceTags: z.coerce.number().array().default([]),
  cancelReason: z.string().nullish(),
  salesPersonId: z.number().nullish(),
  paymentReceived: z
    .object({
      paymentMethodId: z.number(),
      depositToAccountId: z.number(),
      amount: z.coerce.number().min(0.01, "Amount is required"),
    })
    .nullish(),
});

export const recurringInvoice = z.object({
  name: z.string().min(1, "Name is required"),
  clientId: z.number(),
  contactId: z.number().nullish(),
  startDate: z.date(),
  endDate: z.date().nullish(),
  recurringInvoiceProducts: invoiceProduct.array(),
  notes: z.string().nullish().optional(),
  discount: z.coerce
    .number()
    .nonnegative("Discount cannot be negative")
    .default(0),
  currencyId: z.number().default(1),
  templateId: z.number(),
  salesPersonId: z.number().nullish(),
  projectId: z.number().nullish(),
  frequencyRule: z.string().min(1, "Frequency rule is required"),
  isActive: z.boolean().default(true),
  dueDays: z.coerce.number().nonnegative("Due days cannot be negative"),
});

export const proformaInvoice = z.object({
  externalId: z.string().nullish(),
  importOperationId: z.number().nullish(),
  clientId: z.number(),
  contactId: z.number().nullish(),
  date: z.date(),
  dueDate: z.date(),
  proformaInvoiceProducts: lineItemProduct.array(),
  status: enums.invoiceStatus.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().nullish().optional(),
  discount: z.coerce.number().default(0),
  currencyId: z.number().default(1),
  templateId: z.number(),
});

export const invoicePayment = z.object({
  clientId: z.number(),
  date: z.date(),
  paymentMethodId: z.number(),
  collectedByUserId: z.number().nullish(),
  amount: z.coerce.number().min(0.01, "Amount is required"),
  refNo: z.string().nullish(),
  notes: z.string().nullish(),
  depositToAccountId: z.number(),
  paymentReceivedAllocations: z
    .object({
      invoiceId: z.number().nullish(),
      amount: z.coerce.number(),
      type: enums.paymentAllocationType,
    })
    .array()
    .default([]),
});

export const paymentLink = z.object({
  paymentGateway: enums.paymentGateway,
  amount: z.coerce.number(),
  paymentStatus: enums.paymentStatus.default("PENDING"),
  active: z.boolean().default(false),
  invoiceId: z.number().nullish(),
  clientId: z.number(),
  metadata: z.record(z.any()).default({}),
  currencyId: z.number().default(1),
  description: z.string().default(""),
});

export const salesOrder = z.object({
  clientId: z.number(),
  contactId: z.number().nullish(),
  date: z.date(),
  salesOrderItems: lineItemProduct
    .extend({
      id: z.number().optional(),
      buyPrice: z.coerce.number().default(0),
      position: z.number().default(1),
    })
    .array(),
  status: enums.salesOrderStatus.default("DRAFT"),
  contentHtml: z.string(),
  notes: z.string().default(""),
  discount: z.coerce.number().default(0),
  templateId: z.number(),
  salesPersonId: z.number().nullish(),
  cancelReason: z.string().nullish(),
  createInstantInvoice: z.boolean().optional(),
  customFields: z.any().nullish().default({}),
});
