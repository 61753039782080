import DetailsPage from "@/components/details-page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { InvoiceStatusBadge } from "@/pages/sales/invoices/list";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import StatsBar from "@heffl/ui/components/stats-cards";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import dayjs from "dayjs";
import {
  Calendar,
  FileText,
  Home,
  MoreHorizontal,
  Pencil,
  Trash,
  DollarSign,
  Clock,
  Plus,
} from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const LeaseDetails: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const leaseId = Number(params.id);

  const { data: lease, isLoading } =
    trpc.propertyManagement.leases.details.useQuery(leaseId);

  const deleteLeaseMutation = trpc.propertyManagement.leases.delete.useMutation(
    {
      onSuccess() {
        toast.success("Lease deleted successfully");
        navigate("/propertyManagement/leases");
      },
      onError(error) {
        toast.error(error.message);
      },
    }
  );

  if (isLoading || !lease) return <FullScreenSpinner />;

  // Calculate total rent from lease items
  const totalRent = lease.pmLeaseItems.reduce(
    (sum, item) => sum + Number(item.price) * Number(item.quantity),
    0
  );

  // Calculate lease duration in months
  const leaseDuration = dayjs(lease.endDate).diff(
    dayjs(lease.startDate),
    "month"
  );

  return (
    <DetailsPage
      icon={FileText}
      title={`Lease #${lease.number}`}
      description={`${lease.pmPropertyUnits.name} - ${lease.clients.name}`}
      widgets={[
        <Badge key="duration" variant="neutral" small icon={Calendar}>
          {leaseDuration} Months
        </Badge>,
        <Badge key="unit" variant="neutral" small icon={Home}>
          {lease.pmPropertyUnits.name}
        </Badge>,
      ]}
      attributes={[
        {
          section: "Lease Details",
          items: [
            {
              label: "Tenant",
              value: lease.clients.name,
            },
            {
              label: "Unit",
              value: lease.pmPropertyUnits.name,
            },
            {
              label: "Start Date",
              value: heffl.format.date(lease.startDate),
            },
            {
              label: "End Date",
              value: heffl.format.date(lease.endDate),
            },
            {
              label: "First Rent Date",
              value: heffl.format.date(lease.firstRentDate),
            },
            {
              label: "Monthly Rent",
              value: heffl.format.currency(totalRent, "AED"),
            },
          ],
        },
      ]}
      tabs={{
        items: [
          {
            key: "overview",
            label: "Overview",
            iconify: "tabler:home",
            children: (
              <div className="grid gap-6 p-4">
                <StatsBar
                  items={[
                    {
                      title: "Monthly Rent",
                      value: heffl.format.currency(totalRent, "AED"),
                      icon: DollarSign,
                    },
                    {
                      title: "Duration",
                      value: `${leaseDuration} Months`,
                      icon: Calendar,
                    },
                    {
                      title: "Start Date",
                      value: heffl.format.date(lease.startDate),
                      icon: Clock,
                    },
                    {
                      title: "End Date",
                      value: heffl.format.date(lease.endDate),
                      icon: Clock,
                    },
                  ]}
                  className="mb-6"
                />

                <Card title="Lease Items">
                  <SimpleTable
                    idKey="id"
                    rows={lease.pmLeaseItems}
                    columns={[
                      {
                        label: "Description",
                        key: "name",
                      },
                      {
                        label: "Type",
                        key: "paymentType",
                      },
                      {
                        label: "Amount",
                        render: (row) =>
                          heffl.format.currency(
                            Number(row.price) * Number(row.quantity),
                            "AED"
                          ),
                      },
                    ]}
                  />
                </Card>
              </div>
            ),
          },
          {
            key: "invoices",
            label: "Invoices",
            icon: heffl.icons.sales.invoices.icon,
            title: "Invoices",
            count: lease.pmLeaseInvoices.length,
            actions: [
              {
                label: "Invoice",
                icon: Plus,
                buttonVariant: "primary",
                onClick: () =>
                  navigate(`/sales/invoices/add?pmLeaseId=${lease.id}`),
              },
            ],
            children: (
              <SimpleTable
                borderless
                fixedHeader={false}
                className="mt-4"
                rows={
                  lease.pmLeaseInvoices.flatMap(
                    (invoice) => invoice.invoices
                  ) || []
                }
                onRowClick={(row) =>
                  navigate(`/sales/invoices/details/${row.id}`)
                }
                columns={[
                  {
                    label: "Number",
                    key: "number",
                  },

                  {
                    label: "Client",
                    render: (row) => row.clients.name,
                  },
                  {
                    label: "Status",
                    key: "status",
                    render: (row) => <InvoiceStatusBadge invoice={row} />,
                  },
                  {
                    label: "Date",
                    render: (row) => dayjs(row.date).format("DD/MM/YYYY"),
                  },
                  {
                    label: "Amount",
                    render: (row) =>
                      heffl.format.currency(
                        heffl.calculate.invoice({
                          invoiceProducts: row.invoiceProducts,
                          discount: row.discount,
                          paymentReceivedAllocations:
                            row.paymentReceivedAllocations,
                        }).invoiceTotal,
                        "AED",
                        true
                      ),
                  },
                ]}
                idKey="id"
              />
            ),
          },
          {
            key: "payments",
            label: "Payments",
            iconify: "tabler:cash",
            count: lease.pmLeaseInvoices.flatMap(
              (invoice) => invoice.invoices.paymentReceivedAllocations || []
            ).length,
            children: (
              <SimpleTable
                borderless
                fixedHeader={false}
                className="mt-4"
                rows={lease.pmLeaseInvoices.flatMap(
                  (invoice) => invoice.invoices.paymentReceivedAllocations || []
                )}
                columns={[
                  {
                    label: "Date",
                    render: (row) =>
                      heffl.format.date(
                        dayjs(row.paymentsRecieved.date).toDate()
                      ),
                  },
                  {
                    label: "Amount",
                    render: (row) =>
                      heffl.format.currency(row.amount, "AED", true),
                  },
                  {
                    label: "Payment Method",
                    render: (row) => row.paymentsRecieved.paymentMethods.name,
                  },
                  {
                    label: "Collected By",
                    render: (row) =>
                      row.paymentsRecieved.collectedBy
                        ? heffl.format.name(row.paymentsRecieved.collectedBy)
                        : "N/A",
                  },
                ]}
                idKey="id"
              />
            ),
          },
          {
            key: "tenants",
            label: "Tenants",
            iconify: "tabler:users",
            children: (
              <SimpleTable
                idKey="id"
                rows={[lease.clients]}
                columns={[
                  {
                    label: "Name",
                    render: (row) => row.name,
                  },
                  {
                    label: "Tax Number",
                    key: "taxNumber",
                  },
                  {
                    label: "Website",
                    key: "website",
                  },
                ]}
                onRowClick={(row) => navigate(`/crm/contacts/${row.id}`)}
              />
            ),
          },
          {
            key: "complaints",
            label: "Complaints",
            iconify: "tabler:alert-triangle",
            children: (
              <div className="p-4">
                <p className="text-gray-500">No complaints available</p>
              </div>
            ),
          },
          {
            key: "tasks",
            label: "Tasks",
            iconify: "tabler:checklist",
            children: (
              <div className="p-4">
                <p className="text-gray-500">No tasks available</p>
              </div>
            ),
          },
          {
            key: "notes",
            label: "Notes",
            iconify: "tabler:note",
            children: (
              <div className="p-4">
                <p className="text-gray-500">No notes available</p>
              </div>
            ),
          },
          {
            key: "files",
            label: "Files",
            iconify: "tabler:files",
            children: (
              <div className="p-4">
                <p className="text-gray-500">No files attached</p>
              </div>
            ),
          },
        ],
      }}
      actions={[
        {
          icon: Pencil,
          label: "Edit",
          buttonVariant: "outline",
          onClick: () =>
            navigate(`/propertyManagement/leases/edit/${lease.id}`),
        },
        {
          icon: MoreHorizontal,
          label: "More",
          onClick: () => {},
          dropdown: (
            <>
              <Button
                variant="ghost"
                className="flex justify-start text-red-500"
                onClick={async () => {
                  const confirmed = await confirm(
                    "Are you sure you want to delete this lease?"
                  );
                  if (confirmed) {
                    deleteLeaseMutation.mutate({ id: leaseId });
                  }
                }}
              >
                <Trash className="mr-2 w-4 h-4" />
                Delete
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};

export default LeaseDetails;
