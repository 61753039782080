import DataGrid from "@/components/dataGrid/DataGrid";
import Empty from "@/components/Empty";
import FilterBar, { CheckboxFilterType } from "@/components/filter-bar";
import { SearchInput } from "@/components/FormComponents";
import KanbanBoard, { KanbanColumn } from "@/components/kanbanBoard/kanban";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import useTeam from "@/lib/hooks/useTeam";
import {
  formatCustomFields,
  renderCustomFieldValue,
} from "@heffl/server/src/helpers/customFields";
import enums from "@heffl/server/src/schemas/enums";
import appIcons from "@heffl/ui/components/appIcons";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { cn, formatCurrency, formatName, isMobile } from "@heffl/ui/lib/utils";
import {
  Calendar,
  CheckCircle,
  CircleDollarSignIcon,
  DollarSign,
  Goal,
  Handshake,
  KanbanSquare,
  List,
  Pencil,
  Plus,
  ThumbsDown,
  ThumbsUp,
  User2,
  UserCircle,
  Zap,
} from "lucide-react";
import { sum } from "radash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import { z } from "zod";
import AddDealDrawer from "./components/add-deal-modal";
import DealCard from "./components/dealCard";

const dealStatuses: {
  value: z.infer<typeof enums.dealStageType>;
  label: string;
  icon: React.ReactNode;
}[] = [
  {
    value: "ACTIVE",
    label: "Active",
    icon: <Zap className="h-4" />,
  },
  {
    value: "WON",
    label: "Won",
    icon: <ThumbsUp className="h-4 text-green-500" />,
  },
  {
    value: "LOST",
    label: "Lost",
    icon: <ThumbsDown className="h-4 text-red-500" />,
  },
];

type DealFilters = {
  status: z.infer<typeof enums.dealStageType>[];
  dates: [Date, Date] | undefined;
  closedAt: [Date, Date] | undefined;
  assignedTo?: number[];
  owners?: number[];
  clients?: number[];
  pipelineId?: number[];
  stageIds?: number[];
  search: string;
  sourceIds?: number[];
  lostReasons?: number[];
  lineItems?: number[];
  customFields: {
    [key: string]: (string | number)[];
  };
};

export default function DealsList() {
  const trpcUtils = trpc.useUtils();
  const navigate = useNavigate();

  const filterBarRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  const view = params.view;

  const team = useTeam();

  const [filters, setFilters] = useParamsState<DealFilters>({
    status: [],
    closedAt: undefined,
    assignedTo: [],
    dates: undefined,
    owners: [],
    clients: [],
    lineItems: [],
    pipelineId: [],
    stageIds: [],
    search: "",
    customFields: {},
    sourceIds: [],
    lostReasons: [],
  });
  const [clientSearch, setClientSearch] = useState("");
  const [lineItemSearch, setLineItemSearch] = useState("");
  const [addDealDrawerVisible, setAddDealDrawerVisible] = useState(false);

  const { data: lineItems } = trpc.products.list.useQuery({
    search: lineItemSearch,
    pageSize: 10,
  });

  const { data: lostReasons } = trpc.lostReasons.list.useQuery({
    type: "DEAL",
  });
  const { data: sources } = trpc.sources.list.useQuery();

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: dealsCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "DEAL",
    });

  const { data: pipelineDetails } = trpc.deals.pipelines.details.useQuery(
    {
      id: filters.pipelineId?.[0] || 0,
    },
    {
      enabled: !!filters.pipelineId?.[0],
    }
  );
  const { data: customFields } = trpc.customizations.customFields.list.useQuery(
    {
      section: "DEAL",
      dealPipelineId: filters.pipelineId?.[0],
    },
    {
      enabled: !!filters.pipelineId?.length,
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customFieldFilters: CheckboxFilterType<any>[] = filters.pipelineId
    ?.length
    ? customFields
        ?.filter((field) => field.dataType === "SINGLE_OPTION")
        .map((field) => ({
          multiple: false,
          type: "checkbox",
          key: field.name,
          label: field.label,
          value: filters.customFields[field.name] || [],
          onChange: (value) =>
            setFilters({
              customFields: {
                ...filters.customFields,
                [field.name]: value,
              },
            }),
          options: field.values.map((o) => ({
            label: o,
            value: o,
          })),
        })) || []
    : [];

  const dealFilters = useMemo(() => {
    return {
      closedAt: filters.closedAt,
      pipelineId: filters.pipelineId?.[0],
      stageIds: filters.stageIds,
      startDate: filters.dates?.[0],
      endDate: filters.dates?.[1],
      status: filters.status,
      lostReasons: filters.lostReasons,
      assignedTo: filters.assignedTo,
      owners: filters.owners,
      clients: filters.clients,
      search: filters.search,
      lineItems: filters.lineItems,
      sourceIds: filters.sourceIds,
      orderBy:
        view === "board" ? ("position" as const) : ("createdAt" as const),
      customFields:
        filters.pipelineId?.length && customFields ? filters.customFields : {},
    };
  }, [filters, view]);

  const { data: deals } = trpc.deals.list.useQuery(dealFilters);

  const { data: users } = trpc.users.list.useQuery({
    type: ["ADMIN", "OWNER", "SUPER_ADMIN", "STAFF"],
  });
  const { data: pipelinesList } = trpc.deals.pipelines.list.useQuery();
  const dealsBulkUpdate = trpc.deals.bulkPositionUpdate.useMutation();

  useEffect(() => {
    if (pipelinesList?.length === 1 && !filters.pipelineId?.length) {
      setFilters({
        pipelineId: [pipelinesList[0].id],
      });
    }
  }, [pipelinesList]);

  const getDealColumns = (): KanbanColumn[] => {
    if (filters.pipelineId?.length) {
      return (
        pipelineDetails?.dealPipelineStages.map((stage) => ({
          color:
            stage.stageType === "WON"
              ? "#22C55E"
              : stage.stageType === "LOST"
              ? "#EF4444"
              : "#3B82F6",
          label: stage.name,
          value: stage.id.toString(),
          id: stage.id.toString(),
          summary: `${formatCurrency(
            deals
              ?.filter((deal) => deal.dealStageId === stage.id)
              .reduce((acc, deal) => acc + (deal.price || 0), 0) || 0,
            "AED"
          )}`,
        })) || []
      );
    } else {
      return [
        {
          id: "ACTIVE",
          label: "Active",
          value: "ACTIVE",
          color: "#3B82F6",
          summary: `${formatCurrency(
            deals
              ?.filter((deal) => deal.dealPipelineStages.stageType === "ACTIVE")
              .reduce((acc, deal) => acc + (deal.price || 0), 0) || 0,
            "AED"
          )}`,
        },
        {
          id: "WON",
          label: "Won",
          value: "WON",
          color: "#22C55E",
          summary: `${formatCurrency(
            deals
              ?.filter((deal) => deal.dealPipelineStages.stageType === "WON")
              .reduce((acc, deal) => acc + (deal.price || 0), 0) || 0,
            "AED"
          )}`,
        },
        {
          id: "LOST",
          label: "Lost",
          value: "LOST",
          color: "#EF4444",
          summary: `${formatCurrency(
            deals
              ?.filter((deal) => deal.dealPipelineStages.stageType === "LOST")
              .reduce((acc, deal) => acc + (deal.price || 0), 0) || 0,
            "AED"
          )}`,
        },
      ];
    }
  };

  const getStageColor = (stageName: string): string => {
    switch (stageName.toLowerCase()) {
      case "qualified":
        return "bg-blue-500";
      case "won":
        return "bg-emerald-500";
      case "deal lost":
        return "bg-red-500";
      case "site surveyed":
      case "quotation prepared":
      case "negotiations started":
        return "bg-amber-500";
      default:
        return "bg-gray-500";
    }
  };

  const getStatusConfig = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return {
          bg: "bg-blue-50",
          text: "text-blue-700",
          icon: Zap,
          iconClassName: "!text-blue-500",
        };
      case "WON":
        return {
          bg: "bg-emerald-50",
          text: "text-emerald-700",
          icon: ThumbsUp,
          iconClassName: "!text-emerald-500",
        };
      case "LOST":
        return {
          bg: "bg-red-50",
          text: "text-red-700",
          icon: ThumbsDown,
          iconClassName: "!text-red-500",
        };
      default:
        return {
          bg: "bg-gray-50",
          text: "text-gray-700",
          icon: CheckCircle,
          iconClassName: "!text-gray-500",
        };
    }
  };

  if (!team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_DEALS.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page title="Deals" fullWidth className="!p-0">
      <AddDealDrawer
        open={addDealDrawerVisible}
        onClose={() => {
          setAddDealDrawerVisible(false);
        }}
        defaultValues={{
          pipelineId: filters.pipelineId?.[0],
        }}
      />
      {/* Header actions */}
      <div className="flex flex-col gap-3">
        <div className="flex justify-between items-center p-2 border-b border-gray-200">
          <SearchInput
            value={filters.search}
            onChange={(e) => setFilters({ search: e })}
          />
          {team.user.permissions.CREATE_DEALS.allowed && (
            <ResponsiveActionButton
              onClick={() => setAddDealDrawerVisible(true)}
              text="Deal"
            />
          )}
        </div>

        <StripeTabs
          tabParentClassName="pl-3"
          items={[
            {
              label: "Board",
              key: "board",
              icon: KanbanSquare,
            },
            {
              label: "Table",
              key: "table",
              icon: List,
            },
          ]}
          value={view}
          onChange={(value) => navigate(`/crm/deals/${value}`)}
          suffix={
            <div className="flex gap-2 items-center">
              {!!filters.pipelineId?.length && (
                <Button
                  variant="primaryOutline"
                  size="sm"
                  icon={Pencil}
                  onClick={() =>
                    navigate(
                      `/crm/deals/pipelines/edit/${filters.pipelineId?.[0]}`
                    )
                  }
                >
                  Edit pipeline
                </Button>
              )}
              <Button
                onClick={() => navigate("/crm/deals/pipelines/add")}
                variant="primaryOutline"
                size="sm"
                icon={Plus}
              >
                Add pipeline
              </Button>
            </div>
          }
        />

        <div className="flex gap-2 items-start" ref={filterBarRef}>
          <FilterBar
            onChange={() => {}}
            className="px-3 sm:pr-60"
            defaultFilters={["dates", "pipelineId"]}
            filters={[
              {
                key: "date",
                type: "date-range",
                label: "Created at",
                value: filters.dates,
                onChange: (value) =>
                  setFilters({
                    dates: value,
                  }),
              },
              {
                key: "closedAt",
                type: "date-range",
                label: "Actual close date",
                value: filters.closedAt,
                onChange: (value) =>
                  setFilters({
                    closedAt: value,
                  }),
              },
              {
                key: "pipelineId",
                type: "checkbox",
                label: "Pipeline",
                multiple: false,
                value: filters?.pipelineId || [],
                onChange: (value) =>
                  setFilters({
                    pipelineId: value as unknown as number[],
                    stageIds: [],
                  }),
                options: pipelinesList?.map((pipeline) => ({
                  label: pipeline.name,
                  value: pipeline.id,
                })),
              },
              {
                key: "sourceIds",
                type: "checkbox",
                label: "Source",
                value: filters?.sourceIds || [],
                onChange: (value) =>
                  setFilters({
                    sourceIds: value as unknown as number[],
                  }),
                options: sources?.map((source) => ({
                  label: source.name,
                  value: source.id,
                })),
              },
              {
                key: "stageIds",
                type: "checkbox",
                label: "Stage",
                value: filters?.stageIds || [],
                onChange: (value) =>
                  setFilters({
                    stageIds: value as unknown as number[],
                  }),
                options: pipelineDetails?.dealPipelineStages.map((stage) => ({
                  label: stage.name,
                  value: stage.id,
                })),
              },
              {
                key: "status",
                type: "checkbox",
                label: "Status",
                value: filters.status,
                onChange: (value) =>
                  setFilters({
                    status: value as z.infer<typeof enums.dealStageType>[],
                  }),
                options: dealStatuses,
              },
              {
                key: "lostReasons",
                type: "checkbox",
                label: "Lost reasons",
                value: filters.lostReasons,
                onChange: (value) =>
                  setFilters({
                    lostReasons: value,
                  }),
                options: [
                  { label: "No reason", value: 0 },
                  ...(lostReasons?.map((reason) => ({
                    label: reason.reason,
                    value: reason.id,
                  })) || []),
                ],
              },
              {
                key: "services",
                type: "checkbox",
                label: "Line items",
                showSearch: true,
                onSearch: (e) => setLineItemSearch(e),
                value: filters.lineItems || [],
                onChange: (value) =>
                  setFilters({
                    lineItems: value as number[],
                  }),
                options:
                  lineItems?.products.map((lineItem) => ({
                    label: lineItem.name,
                    value: lineItem.id,
                  })) || [],
              },
              {
                key: "assignedTo",
                type: "checkbox",
                label: "Assigned to",
                value: filters?.assignedTo || [],
                onChange: (value) =>
                  setFilters({
                    assignedTo: value as number[],
                  }),
                options: [
                  { label: "Unassigned", value: 0 },
                  ...(users?.map((user) => ({
                    label: user.firstName,
                    value: user.id,
                  })) || []),
                ],
              },
              {
                key: "owners",
                type: "checkbox",
                label: "Owners",
                value: filters?.owners || [],
                onChange: (value) =>
                  setFilters({
                    owners: value as number[],
                  }),
                options:
                  users?.map((user) => ({
                    label: user.firstName,
                    value: user.id,
                  })) || [],
              },
              {
                key: "clients",
                type: "checkbox",
                label: "Clients",
                value: filters?.clients || [],
                showSearch: true,
                onChange: (value) =>
                  setFilters({
                    clients: value as number[],
                  }),
                options: clients?.clients.map((client) => ({
                  label: client.name,
                  value: client.id,
                })),
                onSearch: (e) => setClientSearch(e),
              },
              ...customFieldFilters,
            ]}
            suffix={
              <Card parentClassName="p-1 hidden sm:block">
                <div className="flex gap-2 justify-between">
                  <div className="text-gray-500 flex items-center gap-0.5">
                    <CircleDollarSignIcon className="w-4 h-4" />
                    Total:{" "}
                  </div>
                  <div className="font-medium text-gray-700">
                    {formatCurrency(
                      sum(deals || [], (d) => d.price || 0),
                      "AED"
                    )}
                  </div>
                </div>
              </Card>
            }
          />
        </div>
      </div>
      {/* Header actions ends here */}

      {match({
        view,
        deals,
        pipelineDetails,
        pipelinesList,
        isMobile: isMobile(),
      })
        .with({ deals: undefined, pipelineDetails: undefined }, () => (
          <FullScreenSpinner />
        ))
        .with({ pipelinesList: [] }, () => (
          <Empty
            className="mt-8"
            icon={Handshake}
            title="No sales pipelines"
            description="Please add some sales pipelines to get started."
            buttonSize="sm"
            onAction={() => navigate("/crm/deals/pipelines/add")}
            actionText="Add sales pipeline"
          />
        ))
        // .with({ isMobile: true }, () => (
        //   <div className="flex flex-col gap-3 px-3 mt-3">
        //     {deals?.map((deal) => (
        //       <DealCard key={deal.id} deal={deal} isListingCard />
        //     ))}
        //   </div>
        // ))
        .with({ view: "board" }, ({ deals }) => (
          <KanbanBoard
            disableDrag={!filters.pipelineId?.length}
            items={deals?.map((deal) => ({
              ...deal,
              dealStageId: filters.pipelineId?.length
                ? deal.dealStageId.toString()
                : deal.dealPipelineStages.stageType,
            }))}
            statusKey="dealStageId"
            columns={getDealColumns()}
            renderItem={(item) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/crm/deals/details/${item.id}`);
                }}
              >
                <DealCard
                  deal={{ ...item, dealStageId: Number(item.dealStageId) }}
                />
              </div>
            )}
            onDragging={(updated) => {
              trpcUtils.deals.list.setData(dealFilters, () =>
                updated.map((item) => ({
                  ...item,
                  dealStageId: Number(item.dealStageId),
                }))
              );
            }}
            onChange={(updated) => {
              dealsBulkUpdate.mutate(
                updated.map((item) => ({
                  id: Number(item.id),
                  position: item.position,
                  dealStageId: Number(item.status),
                }))
              );
            }}
            className={cn(
              "px-2 mt-3 sm:h-[calc(100vh-208px)] h-[calc(100vh-285px)]"
            )}
          />
        ))
        .with({ view: "table" }, () => (
          <DataGrid
            name="dealsListMain"
            className="sm:h-[calc(100vh-250px)] mt-3"
            label="Deals"
            allowExport={team.user.permissions.EXPORT_DEALS.allowed}
            allowSelect
            empty={{
              icon: Handshake,
              title: "No deals",
              description: "Please add some deals to get started.",
              buttonSize: "sm",
            }}
            rows={deals || []}
            rowKey="id"
            columns={[
              {
                key: "title",
                name: "Title",
                width: 200,
                renderCell: ({ row }) => (
                  <div
                    onClick={() => navigate(`/crm/deals/details/${row.id}`)}
                    className="truncate rounded-md cursor-pointer hover:text-primary-600"
                  >
                    {row.title}
                  </div>
                ),
              },
              {
                key: "pipeline",
                name: "Pipeline",
                icon: KanbanSquare,
                width: 150,
                renderCell: ({ row }) =>
                  row.dealPipelineStages.dealPipelines?.name,
              },
              {
                key: "value",
                name: "Value",
                icon: DollarSign,
                width: 120,
                renderCell: ({ row }) =>
                  formatCurrency(row.price ? row.price : 0, "AED"),
              },
              {
                key: "client",
                name: "Client",
                icon: UserCircle,
                width: 150,
                renderCell: ({ row }) => (
                  <Badge avatar variant="outline">
                    {row.clients?.name}
                  </Badge>
                ),
              },
              {
                key: "assignedTo",
                name: "Assigned to",
                icon: User2,
                width: 140,
                renderCell: ({ row }) => (
                  <div className="flex gap-1">
                    {row.dealAssignees?.map((assignee) => (
                      <Badge
                        key={assignee.users.id}
                        variant="outline"
                        icon={appIcons.common.user.icon}
                      >
                        {heffl.format.name(assignee.users)}
                      </Badge>
                    ))}
                    {row.dealAssignees?.length === 0 && (
                      <p className="text-gray-500">No assignee</p>
                    )}
                  </div>
                ),
              },
              {
                key: "closingDate",
                name: "Expected close date",
                icon: Calendar,
                width: 170,
                renderCell: ({ row }) =>
                  row.expectedCloseDate
                    ? heffl.format.date(row.expectedCloseDate)
                    : "No expected close date",
              },
              {
                key: "closedAt",
                name: "Actual close date",
                icon: Calendar,
                width: 170,
                renderCell: ({ row }) =>
                  row.closedAt && row.status !== "ACTIVE"
                    ? heffl.format.date(row.closedAt)
                    : "Not closed yet",
              },
              {
                key: "owner",
                name: "Owner",
                icon: User2,
                width: 120,
                renderCell: ({ row }) =>
                  row.ownedBy ? (
                    <Badge variant="outline" icon={appIcons.common.user.icon}>
                      {formatName(row.ownedBy)}
                    </Badge>
                  ) : (
                    "No owner"
                  ),
              },
              {
                key: "stage",
                name: "Stage",
                icon: Goal,
                width: 130,
                renderCell: ({ row }) => (
                  <div className="flex gap-1.5 items-center">
                    <div
                      className={`h-2 w-2 rounded-full ${getStageColor(
                        row.dealPipelineStages?.name
                      )}`}
                    />
                    <span>{row.dealPipelineStages?.name}</span>
                  </div>
                ),
              },
              {
                key: "status",
                name: "Status",
                icon: CheckCircle,
                width: 120,
                renderCell: ({ row }) => {
                  const config = getStatusConfig(row.status);
                  return (
                    <Badge
                      variant="outline"
                      icon={config.icon}
                      iconClassName={config.iconClassName}
                    >
                      {row.status.charAt(0) + row.status.slice(1).toLowerCase()}
                    </Badge>
                  );
                },
              },
              {
                key: "source",
                name: "Source",
                icon: Zap,
                width: 120,
                renderCell: ({ row }) =>
                  row.crmSources ? (
                    <Badge variant="outline" icon={Zap}>
                      {row.crmSources.name}
                    </Badge>
                  ) : (
                    "No source"
                  ),
              },
              ...(dealsCustomFields ?? []).map((field) => ({
                key: field.name,
                name: field.label,
                // @ts-ignore
                renderCell: ({ row }) =>
                  renderCustomFieldValue(
                    field,
                    formatCustomFields({
                      customFields: row.customFields,
                      customFieldFields: dealsCustomFields || [],
                    })[field.name]
                  ),
              })),
            ]}
          />
        ))
        .otherwise(() => null)}
    </Page>
  );
}
