import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { customFieldIcons } from "@/pages/settings/custom-fields.settings";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import {
  Form,
  FormField,
  FormLabel,
} from "@heffl/ui/components/primitives/form";
import FormList from "@heffl/ui/components/primitives/form-list";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import { Switch } from "@heffl/ui/components/primitives/switch";
import {
  Sortable,
  SortableDragHandle,
  SortableItem,
} from "@heffl/ui/components/sortable";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { cn } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { GripVertical, Plus, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

export const ObjectTemplateForm = ({
  form,
  entitySlug,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.customizations.objectTemplate>>;
  entitySlug: string;
}) => {
  const [dragging, setDragging] = useState(false);

  const templateFields = form.watch("objectTemplateFields") || [];

  const { data: customObject } =
    trpc.customizations.objects.details.useQuery(entitySlug);

  if (!customObject) {
    return <FullScreenSpinner />;
  }

  const fieldsToAdd = customObject.attributes
    .filter((attribute) => attribute.isActive)
    .filter(
      (attribute) =>
        !templateFields.find((field) => field.name === attribute.name)
    )
    .sort((a, b) => {
      if (a.required === b.required) return 0;
      return a.required ? -1 : 1;
    });

  return (
    <>
      <FormField name="name" label="Name">
        <Input placeholder="Template Name" />
      </FormField>
      <FormLabel className="mt-2">Fields</FormLabel>
      <Sortable
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
        value={templateFields.map((field) => ({
          id: field.name,
        }))}
        onValueChange={(order) => {
          console.log(order, "order");
          form.setValue(
            "objectTemplateFields",
            // @ts-ignore
            order.map((o, index) => ({
              ...templateFields.find((f) => f.name === o.id),
              position: index + 1,
            }))
          );
        }}
      >
        <FormList name="objectTemplateFields">
          {({ append, remove }) => {
            return (
              <div
                className={cn(
                  "grid grid-cols-2 gap-2",
                  dragging && "flex flex-col gap-2"
                )}
              >
                {templateFields.map((field, index) => (
                  <SortableItem
                    key={field.name}
                    value={field.name}
                    className={cn(
                      "flex justify-between items-center p-1 px-4 rounded-lg border",
                      field.width === 100 && "col-span-2"
                    )}
                  >
                    <div className="flex gap-2 items-center">
                      <SortableDragHandle
                        variant="ghost"
                        size="sm"
                        iconClassName="text-gray-400"
                        className="px-0"
                      >
                        <GripVertical className="w-4 h-4 text-gray-400" />
                      </SortableDragHandle>
                      <p>{field.label}</p>
                      {customObject.attributes.find(
                        (attr) => attr.name === field.name
                      )?.required && <span className="text-red-500">*</span>}
                    </div>
                    <div className="flex gap-1 items-center">
                      <Switch
                        size="small"
                        checkedChildren="Half"
                        unCheckedChildren="Half"
                        value={field.width === 50}
                        onChange={(v) => {
                          form.setValue(
                            `objectTemplateFields.${index}.width`,
                            v ? 50 : 100
                          );
                        }}
                      />
                      <Button
                        onClick={() => {
                          remove(index);
                        }}
                        icon={Trash2}
                        variant="ghost"
                      />
                    </div>
                  </SortableItem>
                ))}
                <div className="col-span-2">
                  <Select
                    previewParentClassName="w-full mt-6"
                    options={fieldsToAdd.map((field) => ({
                      label: (
                        <div>
                          {field.label}
                          {field.required && (
                            <span className="text-red-500">*</span>
                          )}
                        </div>
                      ),
                      value: field.name,
                      fieldValue: field,
                      icon: customFieldIcons[field.dataType],
                    }))}
                    placeholder="Add field"
                    onSelect={(option) => {
                      append({
                        name: option.value,
                        label: option.fieldValue.label,
                        position: templateFields.length + 1,
                        width: 100,
                      });
                    }}
                    previewRender={() => {
                      return (
                        <Button
                          icon={Plus}
                          variant="ghost"
                          className="w-full border border-dashed"
                        >
                          Add Field
                        </Button>
                      );
                    }}
                  />
                </div>
              </div>
            );
          }}
        </FormList>
      </Sortable>
    </>
  );
};

export const AddObjectTemplateModal = ({
  open,
  onClose,
  entitySlug,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  entitySlug: string;
  defaultValues?: Partial<
    z.infer<typeof Schemas.customizations.objectTemplate>
  >;
}) => {
  const { data: customObject } =
    trpc.customizations.objects.details.useQuery(entitySlug);

  const form = useForm<z.infer<typeof Schemas.customizations.objectTemplate>>({
    resolver: zodResolver(Schemas.customizations.objectTemplate),
    defaultValues: {
      objectTemplateFields: [],
    },
  });

  const onModalClose = () => {
    form.reset();
    onClose();
  };

  const templateAddMutation =
    trpc.customizations.objects.templates.add.useMutation({
      onSuccess: () => {
        toast.success("Template added successfully");
        onModalClose();
      },
    });

  const onSubmit = (
    values: z.infer<typeof Schemas.customizations.objectTemplate>
  ) => {
    if (values.objectTemplateFields.length === 0) {
      return heffl.toast.error("Please add at least one field");
    }
    if (!customObject) return;

    const requiredFields = customObject.attributes.filter(
      (attr) => attr.required && attr.isActive
    );

    const missingRequiredFields = requiredFields.filter(
      (field) =>
        !values.objectTemplateFields.some(
          (templateField) => templateField.name === field.name
        )
    );

    if (missingRequiredFields.length > 0) {
      return heffl.toast.error(
        `Please add the following required fields: ${missingRequiredFields
          .map((f) => f.label)
          .join(", ")}`
      );
    }

    templateAddMutation.mutate(values);
  };

  useEffect(() => {
    if (defaultValues) {
      form.reset({ ...form.getValues(), ...defaultValues });
    }
  }, [defaultValues]);

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Create object template"
      footer={
        <Button
          loading={templateAddMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="w-full"
          size="md"
        >
          Add Template
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ObjectTemplateForm form={form} entitySlug={entitySlug} />
      </Form>
    </ModalDrawer>
  );
};

export const EditObjectTemplateModal = ({
  open,
  onClose,
  objectTemplateId,
  entitySlug,
}: {
  open: boolean;
  onClose: ({
    deleted,
    updated,
  }: {
    deleted: boolean;
    updated: boolean;
  }) => void;
  objectTemplateId: number;
  entitySlug: string;
}) => {
  const confirm = useConfirm();
  const form = useForm<z.infer<typeof Schemas.customizations.objectTemplate>>({
    resolver: zodResolver(Schemas.customizations.objectTemplate),
  });

  const { data: template } =
    trpc.customizations.objects.templates.details.useQuery(objectTemplateId);
  const { data: customObject } =
    trpc.customizations.objects.details.useQuery(entitySlug);

  const onModalClose = () => {
    form.reset();
    onClose({ deleted: false, updated: false });
  };

  const templateUpdateMutation =
    trpc.customizations.objects.templates.update.useMutation({
      onSuccess: () => {
        toast.success("Template updated successfully");
        onClose({ deleted: false, updated: true });
      },
    });

  const templateDeleteMutation =
    trpc.customizations.objects.templates.delete.useMutation({
      onSuccess: () => {
        toast.success("Template deleted successfully");
        onClose({ deleted: true, updated: false });
      },
    });

  const onSubmit = (
    values: z.infer<typeof Schemas.customizations.objectTemplate>
  ) => {
    if (values.objectTemplateFields.length === 0) {
      return heffl.toast.error("Please add at least one field");
    }
    if (!customObject) return;

    const requiredFields = customObject.attributes.filter(
      (attr) => attr.required && attr.isActive
    );

    const missingRequiredFields = requiredFields.filter(
      (field) =>
        !values.objectTemplateFields.some(
          (templateField) => templateField.name === field.name
        )
    );

    if (missingRequiredFields.length > 0) {
      return heffl.toast.error(
        `Please add the following required fields: ${missingRequiredFields
          .map((f) => f.label)
          .join(", ")}`
      );
    }

    templateUpdateMutation.mutate({
      id: objectTemplateId,
      objectTemplate: values,
    });
  };

  useEffect(() => {
    if (template) {
      form.reset({
        ...template,
        objectTemplateFields: template.objectTemplateFields.map((field) => ({
          label: field.label,
          name: field.name,
          width: field.width,
          position: field.position,
        })),
      });
    }
  }, [template]);

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Edit Template"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            icon={Trash2}
            size="md"
            variant="destructiveOutline"
            loading={templateDeleteMutation.isLoading}
            onClick={async () => {
              const confirmed = await confirm({
                title: "Are you sure you want to delete this template?",
              });
              if (confirmed) {
                templateDeleteMutation.mutate(objectTemplateId);
              }
            }}
          />
          <Button
            loading={templateUpdateMutation.isLoading}
            onClick={() => form.handleSubmit(onSubmit)()}
            variant="primary"
            className="w-full"
            size="md"
          >
            Update Template
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ObjectTemplateForm form={form} entitySlug={entitySlug} />
      </Form>
    </ModalDrawer>
  );
};
