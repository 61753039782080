import { RouterInputs, RouterOutputs, trpc } from "@/helpers/trpc";
import { DashboardChartItem } from "../charts-item-helpers";
import heffl from "@/helpers/hefflHelpers/heffl";
import { taskStatuses } from "../../details/components/kanban";
import enums, { enumsToOptions } from "@heffl/server/src/schemas/enums";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Link } from "react-router-dom";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";

const TaskCount: React.FC<{
  filterValues: RouterInputs["projects"]["reports"]["taskCount"];
}> = ({ filterValues }) => {
  const { data: taskCount } = trpc.projects.reports.taskCount.useQuery({
    ...filterValues,
  });

  if (taskCount === undefined) return <FullScreenSpinner />;

  return (
    <div className="text-8xl text-center">
      <Link
        to={`/projects/tasks/table?${objectToParamsJSON({
          ...filterValues,
          type:
            filterValues && filterValues?.type?.includes("OVERDUE")
              ? "OVERDUE"
              : "ALL",
        })}`}
      >
        {taskCount?.total}
      </Link>
    </div>
  );
};

export const taskCountChart: DashboardChartItem = {
  name: "task-count",
  title: "Task Count",
  description: "Count of tasks",
  entity: "PROJECT",
  filters: [
    {
      label: "Date",
      type: "date-range",
      key: "dates",
    },
    {
      label: "Pipeline",
      type: "checkbox",
      key: "pipelineIds",
      optionsMeta: {
        hasAsyncSearch: true,
        query: "projects.pipelines.list",
        queryInput: {},
        parseResult: (data: RouterOutputs["projects"]["pipelines"]["list"]) =>
          data.map((pipeline) => ({
            label: pipeline.name,
            value: pipeline.id,
          })),
      },
    },
    {
      label: "Assignee",
      type: "checkbox",
      key: "assignees",
      optionsMeta: {
        hasAsyncSearch: false,
        query: "users.list",
        queryInput: {},
        parseResult: (data: RouterOutputs["users"]["list"]) =>
          data.map((user) => ({
            label: heffl.format.name(user),
            value: user.id,
          })),
      },
    },
    {
      label: "Assigned By",
      type: "checkbox",
      key: "assignedBy",
      optionsMeta: {
        hasAsyncSearch: false,
        query: "users.list",
        queryInput: {},
        parseResult: (data: RouterOutputs["users"]["list"]) =>
          data.map((user) => ({
            label: heffl.format.name(user),
            value: user.id,
          })),
      },
    },
    {
      label: "Client",
      type: "checkbox",
      key: "clients",
      optionsMeta: {
        hasAsyncSearch: true,
        query: "clients.list",
        queryInput: {
          pageSize: 20,
        } as RouterInputs["clients"]["list"],
        parseResult: (data: RouterOutputs["clients"]["list"]) =>
          data.clients.map((client) => ({
            label: client.name,
            value: client.id,
          })),
      },
    },
    {
      label: "Status",
      type: "checkbox" as const,
      key: "statuses",
      options: taskStatuses.map((status) => ({
        label: status.label,
        value: status.value,
      })),
    },
    {
      label: "Type",
      type: "checkbox" as const,
      key: "type",
      options: [
        { label: "All", value: "ALL" },
        { label: "Overdue", value: "OVERDUE" },
      ],
      multiple: false,
    },
    {
      label: "Tags",
      type: "checkbox",
      key: "tags",
    },
    {
      label: "Priority",
      type: "checkbox",
      key: "priority",
      options: enumsToOptions(enums.taskPriority).map((priority) => ({
        label: priority.label,
        value: priority.value,
      })),
    },
  ],
  defaultWidth: 25,
  mainChartType: "number",
  components: {
    stat: TaskCount,
    chart: null,
    table: null,
  },
};
