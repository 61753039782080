import DropMenu from "@/components/DropMenu";
import { SearchInput } from "@/components/FormComponents";
import Pagination from "@/components/Pagination";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import { calculateQuotationLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import TagsInput from "@heffl/ui/components/TagInput";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatCurrency, formatName, isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  Building,
  Calendar,
  CalendarDays,
  Copy,
  DollarSign,
  Eye,
  MoreHorizontal,
  Package,
  Pencil,
  ScrollText,
  Tags,
  Trash,
  UserCircle,
  Zap,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { downloadPdf } from "../invoices/details";
import QuotationCard from "./QuotationCard";
import { generateQuotationName } from "./details";
import heffl from "@/helpers/hefflHelpers/heffl";

type QuotationStatus = z.infer<typeof enums.quotationStatus>;

export const QuotationStatusBadge: React.FC<{
  status: QuotationStatus;
  small?: boolean;
}> = ({ status, small }) => {
  const badgeProps = {
    small,
    variant: {
      DRAFT: "neutral",
      SENT: "default",
      ACCEPTED: "success",
      REJECTED: "error",
    }[status] as "neutral" | "default" | "success" | "error",
  };

  return (
    <Badge {...badgeProps}>
      {status.charAt(0) + status.slice(1).toLowerCase()}
    </Badge>
  );
};

type Quote = RouterOutputs["quotations"]["list"]["quotations"][number];

const getQuoteExpiryHTML = (quote: Quote): React.ReactNode => {
  const today = dayjs();
  const quoteExpiry = quote.expiryDate ? dayjs(quote.expiryDate) : null;
  const daysRemaining = quoteExpiry ? quoteExpiry.diff(today, "day") : null;

  if (daysRemaining !== null) {
    const statusClass = daysRemaining >= 0 ? "text-green-500" : "text-red-500";
    const expiryText =
      daysRemaining >= 0
        ? `Expires in ${daysRemaining} day${daysRemaining !== 1 ? "s" : ""}`
        : `Expired on ${dayjs(quoteExpiry).format("MMM, DD")}`;
    return <span className={`font-normal ${statusClass}`}>{expiryText}</span>;
  }

  return (
    <span className="font-normal text-gray-500">
      {quote.expiryDate
        ? dayjs(quote.expiryDate).format("DD-MM-YYYY")
        : "No Expiry Date"}
    </span>
  );
};

type PageFilters = {
  statuses: QuotationStatus[];
  pageSize: number;
  dates: [Date, Date] | undefined;
  markedAcceptedOn: [Date, Date] | undefined;
  search: string;
  pageNo: number;
  clients: number[];
  tags: number[];
  salesPersons: number[];
};

type QuotationsListProps = {
  filters: Partial<PageFilters>;
  pagination?: {
    setPageNo: (pageNo: number) => void;
    setPageSize: (pageSize: number) => void;
  };
  classes?: {
    dataGrid?: string;
  };
};

export const QuotationList = ({
  filters,
  pagination,
  classes,
}: QuotationsListProps) => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const { data: tags } = trpc.tags.list.useQuery({
    type: "QUOTATION",
  });
  const { data: quotations, isLoading: loading } =
    trpc.quotations.list.useQuery({
      ...filters,
      statuses: filters.statuses,
      startDate: filters.dates?.[0],
      endDate: filters.dates?.[1],
      markedAcceptedOn: filters.markedAcceptedOn,
      pageNo: filters.pageNo,
      pageSize: filters.pageSize,
      clients: filters.clients,
      tags: filters.tags,
      salesPersons: filters.salesPersons,
    });

  const updateQuotationMutation = trpc.quotations.update.useMutation({
    onError(error) {
      toast.error(error.message);
    },
  });

  const deleteQuotationMutation = trpc.quotations.delete.useMutation({
    onSuccess() {
      toast.success("Quotation deleted successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <>
      {loading || !quotations ? (
        <FullScreenSpinner />
      ) : isMobile() ? (
        <div className="flex flex-col gap-2 px-4 mt-4">
          {quotations?.quotations.map((quotation) => (
            <QuotationCard key={quotation.id} data={quotation} />
          ))}
          <Pagination
            pageNo={filters.pageNo || 1}
            count={quotations?.meta?.count}
            pageSize={filters?.pageSize || 30}
            setPageNo={(pageNo) => pagination?.setPageNo(pageNo)}
            className="justify-start"
          />
        </div>
      ) : (
        <DataGrid
          rowKey="id"
          className={classes?.dataGrid}
          onCellClick={(quotation) =>
            navigate(`/sales/quotations/details/${quotation.row.id}`)
          }
          loading={loading}
          allowExport={permissions?.EXPORT_QUOTATIONS.allowed}
          name="quotationsListMain"
          label="Quotations"
          empty={{
            icon: ScrollText,
            title: "No quotations found",
            description: "No quotations available to display.",
          }}
          pagination={{
            pageNo: filters.pageNo || 1,
            count: quotations?.meta.count,
            pageSize: filters.pageSize || 30,
            setPageNo: (pageNo) => pagination?.setPageNo(pageNo),
            setPageSize: (pageSize) => pagination?.setPageSize(pageSize),
          }}
          rows={quotations?.quotations || []}
          columns={[
            {
              key: "number",
              name: "#",
              width: 60,
              renderCell: ({ row }) => row.number,
            },
            {
              key: "client",
              name: "Client",
              width: 300,
              icon: Building,
              renderCell: ({ row }) => (
                <p className="cursor-pointer hover:underline hover:text-primary-800">
                  {row.clients.name}
                </p>
              ),
            },
            {
              key: "status",
              name: "Status",
              width: 100,
              icon: Eye,
              renderCell: ({ row }) => (
                <QuotationStatusBadge status={row.status} />
              ),
            },
            {
              key: "amount",
              name: "Amount",
              width: 160,
              icon: DollarSign,
              renderCell: ({ row }) =>
                formatCurrency(
                  calculateQuotationLineItems({
                    lineItems: row.quotationProducts,
                    discount: row.discount,
                    customFields: row.documentTemplates.customFields,
                  }).total,
                  row.currencies.symbol
                ),
            },
            {
              key: "date",
              name: "Date",
              width: 160,
              icon: Calendar,
              renderCell: ({ row }) => format(row.date, "dd/MM/yy"),
            },
            {
              key: "tags",
              name: "Tags",
              width: 200,
              icon: Tags,
              renderCell: ({ row }) => (
                <TagsInput
                  value={row.quotationTags.map((tag) => tag.tagId)}
                  onChange={(tags) => {
                    updateQuotationMutation.mutate({
                      id: row.id,
                      quotation: { quotationTags: tags },
                    });
                  }}
                  tags={tags}
                />
              ),
            },
            {
              key: "services",
              name: "Services",
              icon: Package,
              width: 200,
              renderCell: ({ row }) => (
                <span className="text-sm text-gray-600">
                  {row.quotationProducts.map((p) => p.name).join(", ")}
                </span>
              ),
            },

            {
              key: "expiry",
              name: "Expiry",
              width: 180,
              icon: CalendarDays,
              renderCell: ({ row }) =>
                row.status === "SENT" ? getQuoteExpiryHTML(row) : null,
            },
            {
              key: "acceptedAt",
              name: "Accepted At",
              width: 180,
              icon: CalendarDays,
              renderCell: ({ row }) =>
                row.status === "ACCEPTED" && row.markedAcceptedOn
                  ? heffl.format.dateTime(row.markedAcceptedOn)
                  : null,
            },
            {
              key: "salesPerson",
              name: "Sales Person",
              width: 200,
              icon: UserCircle,
              renderCell: ({ row }) => (
                <div className="flex gap-2 items-center">
                  {row.salesPerson ? (
                    <span className="text-sm">
                      {formatName(row.salesPerson)}
                    </span>
                  ) : (
                    <span className="text-sm text-gray-500">Not assigned</span>
                  )}
                </div>
              ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              icon: Zap,
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      label: "Edit",
                      icon: Pencil,
                      onClick: () => navigate(`edit/${row.id}`),
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () => navigate(`add?quotationId=${row.id}`),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        await downloadPdf({
                          name: generateQuotationName(row),
                          url: `print?quotation=${row.uuid}`,
                        });
                      },
                    },
                    {
                      icon: Trash,
                      className: "text-red-500",
                      label: "Delete",
                      onClick: async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this quotation?"
                        );
                        if (confirmed) {
                          deleteQuotationMutation.mutate(row.id);
                        }
                      },
                    },
                  ]}
                >
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    variant={"ghost"}
                  >
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
        />
      )}
    </>
  );
};

const Quotations = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    dates: undefined,
    clients: [],
    tags: [],
    salesPersons: [],
    pageSize: 30,
    markedAcceptedOn: undefined,
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data: tags } = trpc.tags.list.useQuery({
    type: "QUOTATION",
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  const { data: salesPersons } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const permissions = usePermissions();

  if (!tags || !permissions) return <FullScreenSpinner />;
  if (!permissions.VIEW_QUOTATIONS.allowed) {
    return (
      <Page fullWidth title="Quotations">
        <div className="flex flex-col justify-center items-center h-screen">
          <div className="flex flex-col gap-2 justify-center items-center p-3">
            <h1 className="text-3xl font-bold">
              You don&apos;t have permission to view quotations
            </h1>
            <p className="text-base font-medium">
              Please contact the admin to request access.
            </p>
          </div>
        </div>
      </Page>
    );
  }
  return (
    <Page fullWidth title="Quotations" className="!p-0">
      <div className="flex flex-col gap-2 justify-between p-3 border-b sm:flex-row">
        <SearchInput
          placeholder="Search quotations..."
          value={filters.search}
          onChange={(e) => {
            setFilters({ search: e, pageNo: 1 });
          }}
        />
        {permissions.CREATE_QUOTATIONS.allowed && (
          <ResponsiveActionButton
            onClick={() => navigate("add")}
            text="Quotation"
          />
        )}
      </div>
      <FilterBar
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        className="p-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Date",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
          {
            key: "markedAcceptedOn",
            type: "date-range",
            label: "Accepted At",
            value: filters.markedAcceptedOn,
            onChange: (value) => setFilters({ markedAcceptedOn: value }),
          },
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.statuses,
            onChange: (value) =>
              setFilters({ statuses: value as QuotationStatus[] }),
            options: [
              { label: "Draft", value: "DRAFT" },
              { label: "Sent", value: "SENT" },
              { label: "Accepted", value: "ACCEPTED" },
              { label: "Rejected", value: "REJECTED" },
            ],
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients",
            showSearch: true,
            value: filters.clients,
            onChange: (value) => {
              setFilters({ clients: value as number[] });
            },
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
            onSearch: (e) => setClientSearch(e),
          },
          {
            key: "salesPersons",
            label: "Sales Person",
            type: "checkbox",
            value: filters.salesPersons,
            options: salesPersons?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
            onChange: (value) => {
              setFilters({ salesPersons: value as number[] });
            },
          },
          {
            key: "tags",
            label: "Tags",
            type: "checkbox",
            value: filters.tags,
            options:
              tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })) ?? [],
            onChange: (e) => {
              setFilters({ tags: e as number[] });
            },
          },
        ]}
      />
      <QuotationList
        pagination={{
          setPageNo: (pageNo) => setFilters({ pageNo }),
          setPageSize: (pageSize) => setFilters({ pageSize }),
        }}
        filters={filters}
        classes={{
          dataGrid: "h-[calc(100vh-var(--header-height)-117px)]",
        }}
      />
    </Page>
  );
};

export default Quotations;
