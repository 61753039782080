import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { cn } from "@heffl/ui/lib/utils";
import { Progress, Upload, Select } from "antd";
import imageCompression from "browser-image-compression";
import { Icon } from "@iconify/react";
import { nanoid } from "nanoid";
import { map } from "radash";
import React, { useEffect, useState } from "react";
import { Document, Page as PDFPage, pdfjs } from "react-pdf";
import { useImmer } from "use-immer";
import axios from "axios";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Schedule =
  RouterOutputs["fieldService"]["schedules"]["list"]["schedules"][number];

const BulkUploadServiceReports = () => {
  const selectRef = React.useRef<HTMLSelectElement>(null);

  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [date, setDate] = useState(new Date());
  const [file, setFile] = useState<File | null>(null);
  const [uploading, setUploading] = useState(false);
  const [mappedReports, setMappedReports] = useImmer<
    {
      pageNumber: number;
      schedule_id: number;
      schedule: Schedule;
      name: string;
    }[]
  >([]);

  const { data: schedules } = trpc.fieldService.schedules.list.useQuery({
    dates: [date, date],
    status: ["ARRIVED", "IN_PROGRESS", "COMPLETED", "CONFIRMED", "ON_MY_WAY"],
  });

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        heffl.toast.error(error.message);
      },
    });

  const scheduleUpdateMutation =
    trpc.fieldService.schedules.update.useMutation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight" && pageNumber < numPages) {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
      } else if (event.key === "ArrowLeft" && pageNumber > 1) {
        setPageNumber((prevPageNumber) => prevPageNumber - 1);
      }
      setTimeout(() => {
        selectRef.current?.focus();
      }, 100);
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [pageNumber, numPages]);

  const selectedReport = mappedReports.find(
    (report) => report.pageNumber === pageNumber
  );

  const scheduleOptions = schedules?.schedules.map((schedule) => ({
    value: schedule.id,
    label: `${
      schedule.fsJobs.fsProperties?.name
        ? schedule.fsJobs.fsProperties?.name
        : schedule.fsJobs.fsProperties?.clients.name
    } -  ${schedule.fsJobs.fsProperties?.clients.name.substring(0, 20)} - ${
      schedule.fsJobs.fsJobServices[0].products?.name
    }`,
  }));

  const compressImage = async (canvasPageNo: number, scheduleId: number) => {
    const canvas = document.getElementsByClassName(
      `pdf-page-${canvasPageNo}__canvas`
    )[0] as HTMLCanvasElement;
    const imageName = `report-${scheduleId}-${nanoid()}.jpeg`;
    const dataUrl = canvas.toDataURL("image/jpeg");
    const blob = await (await fetch(dataUrl)).blob();
    const imageFile = new File([blob], imageName, { type: "image/jpeg" });
    const compressedImage = await imageCompression(imageFile, {
      useWebWorker: true,
      maxSizeMB: 0.3,
      initialQuality: 3,
      maxWidthOrHeight: 2080,
      fileType: "image/webp",
    });
    return compressedImage;
  };

  const uploadReports = async () => {
    setUploading(true);
    const mappedReportsWithFiles = await map(mappedReports, async (report) => {
      const compressedImage = await compressImage(
        report.pageNumber,
        report.schedule_id
      );
      return { ...report, file: compressedImage };
    });

    const presignedUrls = await getPresignedUrls({
      files: mappedReportsWithFiles.map((report) => ({
        name: report.name,
        format: "image/webp",
        size: report.file.size,
      })),
      section: "field-service/schedules",
    });

    console.log("presignedUrls", presignedUrls);
    await map(presignedUrls, async (presignedUrl) => {
      const report = mappedReportsWithFiles.find(
        (report) => report.name === presignedUrl.name
      );
      console.log("report", report);
      if (report) {
        await axios.put(presignedUrl.presignedUrl, report.file, {
          headers: {
            "Content-Type": "image/webp",
          },
        });
        await scheduleUpdateMutation.mutateAsync({
          id: report.schedule_id,
          skipJobStatusCheck: true,
          schedule: {
            files: {
              fs_schedule_service_report: {
                new: [presignedUrl],
                deleted: [],
              },
            },
          },
        });
      }
      setUploading(false);
      setFile(null);
      setMappedReports([]);
      setPageNumber(1);
      setNumPages(0);
    });
  };

  return (
    <Page
      className="!relative bg-gray-100 min-h-screen"
      title="Bulk upload service reports"
    >
      <ModalDrawer open={uploading} onClose={() => {}}>
        <div className="flex flex-col gap-2 justify-center items-center h-72">
          <Icon
            icon="ph:spinner-bold"
            className="text-4xl animate-spinner-linear-spin text-primary-500"
          />
          <p className="text-lg">Uploading reports</p>
        </div>
      </ModalDrawer>

      <div
        className={cn(
          "flex fixed bottom-10 left-[60%] gap-4 items-center p-3.5 border border-gray-200 bg-white rounded-2xl shadow-lg -translate-x-1/2 w-fit !z-50",
          file ? "flex-row" : "hidden"
        )}
      >
        {selectedReport ? (
          <div className="flex items-center p-1 justify-between rounded-lg border border-green-600 min-w-[400px]">
            <div className="flex items-center">
              <Badge variant="success">
                {selectedReport.schedule.fsJobs.fsProperties.name ||
                  selectedReport.schedule.fsJobs.fsProperties.clients.name}
              </Badge>
              <span className="mx-2">
                {selectedReport.schedule.fsJobs.fsProperties.clients.name.slice(
                  0,
                  20
                )}{" "}
                -{" "}
                {selectedReport.schedule.fsJobs.fsJobServices[0].products?.name}
              </span>
            </div>
            <Button
              variant="ghost"
              size="sm"
              iconify="tabler:circle-x"
              onClick={() =>
                setMappedReports((draft) => {
                  const filtered = draft.filter(
                    (r) => r.pageNumber !== selectedReport.pageNumber
                  );
                  return filtered;
                })
              }
              iconClassName="text-red-500"
            />
          </div>
        ) : (
          <Select
            value={mappedReports[pageNumber - 1]?.schedule_id || undefined}
            onChange={(schedule_id) => {
              const schedule = schedules?.schedules?.find(
                (schedule) => schedule.id === schedule_id
              );
              if (!schedule) {
                return;
              }
              setMappedReports((draft) => {
                draft.push({
                  pageNumber,
                  schedule_id,
                  schedule,
                  name: `service_report_${schedule_id}_${pageNumber}.webp`,
                });
              });
            }}
            // @ts-ignore
            ref={selectRef}
            showSearch
            optionFilterProp="label"
            className="min-w-[400px]"
            options={scheduleOptions}
            loading={!schedules}
            optionRender={({ label }) =>
              label ? (
                <div>
                  <Badge variant="warning">
                    {String(label)?.split("-")[0]}
                  </Badge>
                  {String(label).split("-")[1]} {" - "}
                  {String(label).split("-")[2]}
                </div>
              ) : (
                ""
              )
            }
          />
        )}
        <Button
          onClick={uploadReports}
          disabled={!mappedReports.length}
          variant="primary"
          size="md"
          iconify="ph:upload-simple"
        >
          Upload reports
        </Button>
      </div>

      <div className="mb-8">
        <p className="mb-2 font-medium">Select a date</p>
        <DatePicker
          value={date}
          onChange={(newDate) => {
            if (newDate) {
              setDate(newDate);
            }
          }}
          className="w-fit"
        />
      </div>

      {(!date || !file) && (
        <div className="h-96">
          <Upload.Dragger
            name="file"
            accept=".pdf"
            beforeUpload={(newFile) => {
              setFile(newFile);
              return false;
            }}
            onChange={() => {
              // @ts-ignore
              selectRef.current?.focus();
            }}
          >
            <p className="mt-2 text-xl font-medium text-center text-gray-600">
              Click or drag file to this area to upload
            </p>
            <Button
              className="mt-4"
              variant="primary"
              size="md"
              iconify="ph:paperclip"
            >
              Choose service report
            </Button>
          </Upload.Dragger>
        </div>
      )}

      {file && (
        <div className="space-y-4">
          <div className="flex gap-4 items-center">
            <Progress
              className="flex-1"
              percent={Math.floor((pageNumber / numPages) * 100)}
            />
            <Button
              variant="destructive"
              onClick={() => {
                setFile(null);
                setMappedReports([]);
                setPageNumber(1);
                setNumPages(0);
              }}
              iconify="tabler:circle-x"
            >
              Remove file
            </Button>
          </div>

          <div className="flex justify-center">
            <Document
              file={file}
              onLoadSuccess={(props: { numPages: number }) =>
                setNumPages(props.numPages)
              }
            >
              {[...Array(numPages)].map((_, index) => (
                <div
                  key={nanoid()}
                  className={`${pageNumber === index + 1 ? "block" : "hidden"}`}
                >
                  <PDFPage
                    scale={1.3}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    pageNumber={index + 1}
                    _className={`pdf-page-${index + 1}`}
                  />
                </div>
              ))}
            </Document>
          </div>
        </div>
      )}
    </Page>
  );
};

export default BulkUploadServiceReports;
