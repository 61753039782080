import { ClientInput, MultipleUserInput } from "@/components/FormComponents";
import LineItemSelectorNew from "@/components/line-item-selector-new";
import CustomFieldsInputForm from "@/helpers/customFields/custom-fields-input-form";
import validateCustomFields from "@/helpers/customFields/validateCustomFields";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import FrequencySelector from "@/pages/field-service/jobs/components/job-frequency-selector";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import appIcons from "@heffl/ui/components/appIcons";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import Select from "@heffl/ui/components/primitives/select";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { Plus, Waypoints } from "lucide-react";
import { omit } from "radash";
import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const ProjectProfileForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.projectProfile.projectProfile>>;
}) => {
  const projectProfileItems = form.watch("projectProfileItems");
  const recurringStartDate = form.watch("recurringStartDate");
  const pipelineId = form.watch("projectPipelineId");
  const clientId = form.watch("clientId");

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery();
  const { data: quotations } = trpc.quotations.list.useQuery(
    {
      clients: clientId ? [clientId] : [],
      statuses: ["ACCEPTED"],
    },
    {
      enabled: !!clientId,
    }
  );

  const { data: projectTemplates } = trpc.projects.templates.list.useQuery();

  const lineItemsValues = calculateLineItems({
    lineItems: projectProfileItems || [],
    discount: form.watch("discount") || 0,
  });

  return (
    <div className="space-y-4">
      {/* Basic Info Section */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-gray-900">
          Basic Information
        </h3>
        <div className="grid grid-cols-2 gap-6">
          <FormField name="name" label="Profile Name">
            <Input
              placeholder="Enter profile name"
              className="w-full"
              autoFocus
            />
          </FormField>
          <FormField name="projectTitle" label="Project Title">
            <Input placeholder="Enter project title" className="w-full" />
          </FormField>
        </div>

        <FormField name="description" label="Project Description">
          <Textarea
            placeholder="Describe project details, notes etc.."
            className="w-full h-24"
          />
        </FormField>
      </div>

      {/* Project Settings Section */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-gray-900">
          Project Settings
        </h3>
        <div className="grid grid-cols-3 gap-2">
          <FormField name="projectPipelineId" label="Pipeline">
            <Select
              placeholder="Select pipeline"
              icon={Waypoints}
              options={pipelines?.map((p) => ({
                label: p.name,
                value: p.id,
              }))}
              className="w-full"
            />
          </FormField>
          <ClientInput
            name="clientId"
            label="Client"
            allowClear
            onAddModalClose={(id) => id && form.setValue("clientId", id)}
          />
          <FormField name="projectDurationDays" label="Project Duration (Days)">
            <Input
              type="number"
              min={1}
              placeholder="Enter duration"
              className="w-full"
            />
          </FormField>
        </div>

        <div className="flex gap-2">
          <FormField name="projectTemplateId" label="Project Template">
            <Select
              allowClear
              placeholder="Select template"
              options={projectTemplates?.map((t) => ({
                label: t.name,
                value: t.id,
              }))}
              className="w-full"
            />
          </FormField>
          <MultipleUserInput
            name="projectProfileAssignees"
            label="Project Assignees"
          />
          <FormField name="projectProfileQuotations" label="Quotations">
            <MultiSelect
              icon={appIcons.sales.quotes.icon}
              placeholder="Linked quotations"
              options={quotations?.quotations?.map((q) => ({
                label: `#${q.number} - ${q.subject || "No subject"}`,
                value: q.id,
              }))}
            />
          </FormField>
        </div>
      </div>

      {/* Recurring Schedule Section */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-gray-900">
          Recurring Schedule
        </h3>
        <div className="grid grid-cols-3 gap-2">
          <FormField name="recurringRule" label="Frequency">
            <FrequencySelector />
          </FormField>
          <FormField name="recurringStartDate" label="Start Date">
            <DatePicker className="w-full" />
          </FormField>
          <FormField name="recurringEndDate" label="End Date">
            <DatePicker
              className="w-full"
              allowClear
              disabled={!recurringStartDate}
              fromDate={recurringStartDate}
              presets={[
                {
                  label: "1 year",
                  value: dayjs(recurringStartDate).add(364, "day").toDate(),
                },
                {
                  label: "6 months",
                  value: dayjs(recurringStartDate).add(6, "months").toDate(),
                },
                {
                  label: "3 months",
                  value: dayjs(recurringStartDate).add(3, "months").toDate(),
                },
              ]}
            />
          </FormField>
        </div>
      </div>

      {/* Line Items Section */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-gray-900">Line Items</h3>
        <FormField name="projectProfileItems" label="Project Line Items">
          <LineItemSelectorNew
            type="PROJECT"
            required={false}
            addDefaultLineItem={false}
          />
        </FormField>

        {!!projectProfileItems?.length && (
          <div className="flex justify-end mt-6 w-full">
            <table className="gap-8 text-base border-separate border-spacing-y-3">
              <tr>
                <td className="w-52 text-gray-600">Subtotal</td>
                <td className="font-medium text-right">
                  {heffl.format.currency(lineItemsValues.subTotal, "AED")}
                </td>
              </tr>
              <tr>
                <td className="w-52 text-gray-600">Discount</td>
                <td className="text-right">
                  <FormField name="discount">
                    <Input suffix="AED" className="w-[120px]" />
                  </FormField>
                </td>
              </tr>
              <tr>
                <td className="flex gap-2 items-center w-52 text-gray-600">
                  VAT
                </td>
                <td className="font-medium text-right">
                  {heffl.format.currency(lineItemsValues.totalTax, "AED")}
                </td>
              </tr>
              <tr>
                <td className="w-52 font-semibold text-gray-900">Total</td>
                <td className="font-semibold text-right text-gray-900">
                  {heffl.format.currency(lineItemsValues.total, "AED")}
                </td>
              </tr>
            </table>
          </div>
        )}
      </div>

      {/* Custom Fields Section */}
      <div className="space-y-2">
        <h3 className="text-lg font-semibold text-gray-900">
          Additional Fields
        </h3>
        <CustomFieldsInputForm section="PROJECT" name="projectCustomFields" />
        {pipelineId && (
          <CustomFieldsInputForm
            section="PROJECT"
            projectPipelineId={pipelineId}
            name="projectCustomFields"
          />
        )}
      </div>
    </div>
  );
};

export const AddProjectProfileDrawer = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const convertQuotationIdParam = queryParams.get("convertQuotationId");

  const convertProjectId = queryParams.get("convertProjectId");

  const form = useForm<z.infer<typeof Schemas.projectProfile.projectProfile>>({
    resolver: zodResolver(Schemas.projectProfile.projectProfile),
  });

  const projectProfileQuotations = form.watch("projectProfileQuotations");
  const pipelineId = form.watch("projectPipelineId");
  const lineItems = form.watch("projectProfileItems");

  const { data: convertProject } = trpc.projects.details.useQuery(
    Number(convertProjectId),
    {
      enabled: !!convertProjectId,
    }
  );

  const { data: convertQuotation } = trpc.quotations.details.useQuery(
    Number(projectProfileQuotations?.[0]),
    {
      enabled: projectProfileQuotations?.length === 1,
    }
  );

  const { data: projectCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "PROJECT",
      projectPipelineId: null,
    });

  const { data: pipelineCustomFields } =
    trpc.customizations.customFields.list.useQuery(
      {
        section: "PROJECT",
        projectPipelineId: pipelineId,
      },
      {
        enabled: !!pipelineId,
      }
    );

  const projectProfileAddMutation = trpc.projects.profiles.add.useMutation({
    onSuccess(profile) {
      toast.success("Added project profile");
      navigate(`/projects/recurring-projects/details/${profile.id}`);
      onClose();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const onSubmit = (
    values: z.infer<typeof Schemas.projectProfile.projectProfile>
  ) => {
    const { isValid: isValidPipeline } = validateCustomFields({
      customFields: pipelineCustomFields,
      customFieldsValues: values.projectCustomFields,
      name: "projectCustomFields",
      form,
    });

    const { isValid: isValidProject } = validateCustomFields({
      customFields: projectCustomFields,
      customFieldsValues: values.projectCustomFields,
      name: "projectCustomFields",
      form,
    });
    if (!isValidProject || !isValidPipeline) return;

    projectProfileAddMutation.mutate({
      ...values,
    });
  };

  useEffect(() => {
    if (convertQuotationIdParam && !projectProfileQuotations?.length) {
      form.setValue("projectProfileQuotations", [
        Number(convertQuotationIdParam),
      ]);
    }
  }, [convertQuotationIdParam]);

  useEffect(() => {
    if (convertProject) {
      form.reset({
        ...form.getValues(),
        clientId: convertProject.clientId,
        projectTitle: convertProject.title,
        description: convertProject.description,
        projectDurationDays: dayjs(convertProject.endDate).diff(
          dayjs(convertProject.startDate),
          "days"
        ),
        projectProfileAssignees: convertProject.projectAssignees.map(
          (assignee) => assignee.users.id
        ),
        projectPipelineId: convertProject.pipelineId,
        discount: convertProject.discount,
        convertProjectId: convertProject.id,
        projectProfileQuotations: convertProject.projectQuotations.map(
          (quotation) => quotation.quotations.id
        ),
        projectCustomFields: convertProject.customFields,
        projectProfileItems: convertProject.projectItems.map((item) => ({
          ...omit(item, ["id", "createdAt", "projectId"]),
        })),
      });
    }
  }, [convertProject]);

  useEffect(() => {
    if (convertQuotation && !lineItems?.length) {
      form.reset({
        ...form.getValues(),
        clientId: convertQuotation.clientId,
        projectTitle: convertQuotation.subject || "",
        projectProfileItems: convertQuotation.quotationProducts,
        discount: convertQuotation.discount,
        salesPersonId: convertQuotation.salesPersonId,
        projectProfileQuotations: [convertQuotation.id],
      });
    }
  }, [convertQuotation]);

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="Add Recurring Project"
      type="sheet"
      footer={
        <Button
          loading={projectProfileAddMutation.isLoading}
          type="submit"
          variant="primary"
          className="w-full"
          onClick={() => {
            form.handleSubmit(onSubmit)();
          }}
          icon={Plus}
        >
          Add Recurring Project
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectProfileForm form={form} />
      </Form>
    </ModalDrawer>
  );
};
