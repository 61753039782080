import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";

import { useParamsState } from "@/lib/hooks/useParamsState";
import appIcons from "@heffl/ui/components/appIcons";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Timer } from "lucide-react";
import { useNavigate } from "react-router-dom";

const TimesheetUserReport: React.FC = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<
    RouterInputs["projects"]["reports"]["timesheetsByUser"]
  >({
    dates: [dayjs().subtract(30, "days").toDate(), dayjs().toDate()],
  });

  const { data: timesheets } = trpc.projects.reports.timesheetsByUser.useQuery({
    dates: filters.dates,
  });

  return (
    <div>
      <div className="flex flex-wrap items-center py-3 w-full">
        <FilterBar
          className="px-3"
          filters={[
            {
              key: "dates",
              type: "date-range",
              label: "Date Range",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value as [Date, Date] }),
            },
          ]}
        />
      </div>
      <DataGrid
        rowKey="user.id"
        name="timesheetUserReport"
        className="h-[calc(100vh-162px-var(--header-height))]"
        label="Timesheet User Report"
        loading={!timesheets}
        empty={{
          icon: Timer,
          title: "No timesheets",
          description: "There are no timesheets to display in the report.",
        }}
        rows={timesheets}
        columns={[
          {
            key: "user",
            name: "User",
            width: 200,
            renderCell: ({ row }) => (
              <Badge
                icon={appIcons.common.user.icon}
                variant="outline"
                className="cursor-pointer"
                onClick={() => {
                  const params = objectToParamsJSON({
                    users: [row.user.id],
                    dates: filters.dates,
                  });
                  navigate(`/projects/timesheets?${params}`);
                }}
              >
                {heffl.format.name(row.user)}
              </Badge>
            ),
          },
          {
            key: "totalMinutes",
            name: "Total",
            width: 150,
            renderCell: ({ row }) => heffl.format.duration(row.totalMinutes),
          },
          {
            key: "billableMinutes",
            name: "Billable",
            width: 150,
            renderCell: ({ row }) => heffl.format.duration(row.billableMinutes),
          },
          {
            key: "totalCost",
            name: "Total Cost",
            width: 150,
            renderCell: ({ row }) =>
              heffl.format.currency(row.totalCost, "AED", true),
          },
          {
            key: "approvedPercentage",
            name: "Approved",
            width: 150,
            renderCell: ({ row }) =>
              `${((row.approvedMinutes / row.totalMinutes) * 100).toFixed(2)}%`,
          },
          {
            key: "rejectedPercentage",
            name: "Rejected",
            width: 150,
            renderCell: ({ row }) =>
              `${((row.rejectedMinutes / row.totalMinutes) * 100).toFixed(2)}%`,
          },
        ]}
      />
    </div>
  );
};

export default TimesheetUserReport;
