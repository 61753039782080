import DetailsPage from "@/components/details-page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import StatsBar from "@heffl/ui/components/stats-cards";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import dayjs from "dayjs";
import {
  Building2,
  Calendar,
  FileText,
  Home,
  MoreHorizontal,
  Pencil,
  Trash,
  DollarSign,
  Users,
  Clock,
} from "lucide-react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = ["#F59E0B", "#60A5FA", "#F97316", "#34D399"];

const PropertyDetails: React.FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();

  const propertyId = Number(params.id);

  const { data: property, isLoading } =
    trpc.propertyManagement.properties.details.useQuery(propertyId);

  const deletePropertyMutation =
    trpc.propertyManagement.properties.delete.useMutation({
      onSuccess() {
        toast.success("Property deleted successfully");
        navigate("/propertyManagement/properties");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  if (isLoading || !property) return <FullScreenSpinner />;

  const activeLeases = property.pmPropertyUnits
    .flatMap((unit) => unit.pmLeases)
    .filter((lease) => dayjs(lease.endDate).isAfter(dayjs()));

  const allTenants = activeLeases.map((lease) => lease.clients);

  // Calculate stats
  const totalUnits = property.pmPropertyUnits.length;
  const occupiedUnits = property.pmPropertyUnits.filter((unit) =>
    unit.pmLeases.some((lease) => dayjs(lease.endDate).isAfter(dayjs()))
  ).length;
  const vacancyRate = (
    ((totalUnits - occupiedUnits) / totalUnits) *
    100
  ).toFixed(1);

  const totalRent = property.pmPropertyUnits.reduce(
    (sum, unit) => sum + Number(unit.marketRent),
    0
  );

  // Prepare pie chart data
  const unitStatusData = [
    { name: "Occupied", value: occupiedUnits, color: COLORS[0] },
    { name: "Vacant", value: totalUnits - occupiedUnits, color: COLORS[1] },
  ];

  // Get upcoming lease renewals
  const upcomingRenewals = activeLeases
    .filter((lease) => dayjs(lease.endDate).isBefore(dayjs().add(3, "months")))
    .sort((a, b) => dayjs(a.endDate).diff(dayjs(b.endDate)));

  return (
    <DetailsPage
      icon={Building2}
      title={property.name || ""}
      description={property.address}
      widgets={[
        <Badge key="units" variant="neutral" small icon={Home}>
          {property.pmPropertyUnits.length} Units
        </Badge>,
        <Badge key="active-leases" variant="neutral" small icon={FileText}>
          {activeLeases.length} Active Leases
        </Badge>,
      ]}
      attributes={[
        {
          section: "Property Details",
          items: [
            {
              label: "Address",
              value: property.address,
            },
            {
              label: "City",
              value: property.city,
            },
            {
              label: "State",
              value: property.countryStates?.name || "N/A",
            },
            {
              label: "Created on",
              value: heffl.format.date(property.createdAt),
            },
            {
              label: "Created by",
              value: heffl.format.name(property.users),
            },
          ],
        },
      ]}
      tabs={{
        items: [
          {
            key: "overview",
            label: "Overview",
            iconify: "tabler:building",
            children: (
              <div className="grid gap-6 p-4">
                <StatsBar
                  items={[
                    {
                      title: "Total Units",
                      value: totalUnits.toString(),
                      icon: Home,
                    },
                    {
                      title: "Occupancy Rate",
                      value: `${((occupiedUnits / totalUnits) * 100).toFixed(
                        1
                      )}%`,
                      icon: Users,
                    },
                    {
                      title: "Vacancy Rate",
                      value: `${vacancyRate}%`,
                      icon: Building2,
                    },
                    {
                      title: "Total Monthly Rent",
                      value: heffl.format.currency(totalRent, "AED"),
                      icon: DollarSign,
                    },
                  ]}
                  className="mb-6"
                />

                <div className="grid grid-cols-2 gap-6">
                  <Card title="Unit Status">
                    <div className="h-[300px]">
                      <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                          <Pie
                            data={unitStatusData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            label={({ name, percent }) =>
                              `${name} ${(percent * 100).toFixed(0)}%`
                            }
                          >
                            {unitStatusData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </Card>

                  <Card title="Upcoming Lease Renewals">
                    <SimpleTable
                      idKey="id"
                      rows={upcomingRenewals}
                      columns={[
                        {
                          label: "Unit",
                          render: (row) => row.pmPropertyUnits.name,
                        },
                        {
                          label: "Tenant",
                          render: (row) =>
                            heffl.format.name({
                              firstName: row.clients.name || "",
                            }),
                        },
                        {
                          label: "End Date",
                          render: (row) => heffl.format.date(row.endDate),
                          icon: Clock,
                        },
                      ]}
                      onRowClick={(row) =>
                        navigate(`/propertyManagement/leases/${row.id}`)
                      }
                    />
                  </Card>
                </div>
              </div>
            ),
          },
          // ... rest of the tabs remain unchanged ...
          {
            key: "units",
            label: "Units",
            iconify: "tabler:key",
            children: (
              <SimpleTable
                idKey="id"
                rows={property.pmPropertyUnits}
                columns={[
                  {
                    label: "Unit Name",
                    key: "name",
                  },
                  {
                    label: "Size (sqft)",
                    key: "sizeSqft",
                  },
                  {
                    label: "Market Rent",
                    render: (row) =>
                      heffl.format.currency(Number(row.marketRent), "AED"),
                  },
                  {
                    label: "Status",
                    render: (row) => {
                      const hasActiveLease = row.pmLeases.some((lease) =>
                        dayjs(lease.endDate).isAfter(dayjs())
                      );
                      return (
                        <Badge variant={hasActiveLease ? "success" : "neutral"}>
                          {hasActiveLease ? "Occupied" : "Vacant"}
                        </Badge>
                      );
                    },
                  },
                ]}
                onRowClick={(row) =>
                  navigate(`/propertyManagement/units/${row.id}`)
                }
              />
            ),
          },
          {
            key: "leases",
            label: "Leases",
            iconify: "tabler:contract",
            children: (
              <SimpleTable
                idKey="id"
                rows={activeLeases}
                columns={[
                  {
                    label: "No",
                    key: "id",
                  },
                  {
                    label: "Unit",
                    render: (row) => row.pmPropertyUnits.name,
                  },
                  {
                    label: "Tenant",
                    render: (row) =>
                      heffl.format.name({
                        firstName: row.clients.name || "",
                      }),
                  },
                  {
                    label: "Start Date",
                    render: (row) => heffl.format.date(row.startDate),
                    icon: Calendar,
                  },
                  {
                    label: "End Date",
                    render: (row) => heffl.format.date(row.endDate),
                    icon: Calendar,
                  },
                ]}
                onRowClick={(row) =>
                  navigate(`/propertyManagement/leases/${row.id}`)
                }
              />
            ),
          },
          {
            key: "tenants",
            label: "Tenants",
            iconify: "tabler:users",
            children: (
              <SimpleTable
                idKey="id"
                rows={allTenants}
                columns={[
                  {
                    label: "Name",
                    render: (row) =>
                      heffl.format.name({
                        firstName: row.name || "",
                      }),
                  },
                  {
                    label: "Tax Number",
                    key: "taxNumber",
                  },
                  {
                    label: "Website",
                    key: "website",
                  },
                ]}
                onRowClick={(row) => navigate(`/crm/contacts/${row.id}`)}
              />
            ),
          },
          {
            key: "notes",
            label: "Notes",
            iconify: "tabler:note",
            children: (
              <div className="p-4">
                <p className="text-gray-500">No notes available</p>
              </div>
            ),
          },
          {
            key: "files",
            label: "Files",
            iconify: "tabler:files",
            children: (
              <div className="p-4">
                <p className="text-gray-500">No files attached</p>
              </div>
            ),
          },
        ],
      }}
      actions={[
        {
          icon: Pencil,
          label: "Edit",
          buttonVariant: "outline",
          onClick: () =>
            navigate(`/propertyManagement/properties/edit/${property.id}`),
        },
        {
          icon: MoreHorizontal,
          label: "More",
          onClick: () => {},
          dropdown: (
            <>
              <Button
                variant="ghost"
                className="flex justify-start text-red-500"
                onClick={async () => {
                  const confirmed = await confirm(
                    "Are you sure you want to delete this property?"
                  );
                  if (confirmed) {
                    deletePropertyMutation.mutate(property.id);
                  }
                }}
              >
                <Trash className="mr-2 w-4 h-4" />
                Delete
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};

export default PropertyDetails;
