import DropMenu from "@/components/DropMenu";
import Empty from "@/components/Empty";
import { MultipleFieldStaffInput } from "@/components/FormComponents";
import FilesList from "@/components/file-list";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import useTeam from "@/lib/hooks/useTeam";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import { MobileLineItem } from "@/pages/sales/quotations/clientShare/MobileQuote";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import TagsInput from "@heffl/ui/components/TagInput";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Timeline } from "@heffl/ui/components/primitives/Timeline";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@heffl/ui/components/primitives/accordion";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import Select from "@heffl/ui/components/primitives/select";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { Switch } from "@heffl/ui/components/primitives/switch";
import TimePicker from "@heffl/ui/components/primitives/time-picker";
import RenderHtml from "@heffl/ui/components/render-html";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  cn,
  dynamicDateFormatting,
  formatCurrency,
  formatName,
  formatValue,
} from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { debounce } from "lodash";
import {
  Ban,
  Box,
  Building2,
  Check,
  CheckCircle,
  CircleDollarSign,
  Clock,
  Copy,
  Hammer,
  Home,
  Info,
  Link,
  LucideIcon,
  MapPin,
  MapPinIcon,
  MessageCircle,
  MoreVertical,
  Navigation,
  NotebookText,
  Pencil,
  Phone,
  Play,
  Plus,
  StickyNote,
  Tag,
  Timer,
  Trash2,
  Truck,
  UserRoundPlus,
  Wrench,
} from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import {
  generateGoogleMapsLink,
  openGoogleMaps,
} from "../../components/property-selector";
import { ScheduleStatusBadge, getDateDuration } from "../../jobs/details";
import { ScheduleInvoiceBadge, getJobId } from "../../jobs/list";
import { calculateDuration } from "../../staff-app/dashboard";
import { callMobile } from "../../staff-app/schedules/details";
import { getJobName, getScheduleMessage } from "../calendar";

export const joinDateAndTime = (date: Date, time: Date) => {
  const mergedDate = new Date(date);
  mergedDate.setHours(time.getHours());
  mergedDate.setMinutes(time.getMinutes());
  mergedDate.setSeconds(time.getSeconds());
  mergedDate.setMilliseconds(time.getMilliseconds());
  return mergedDate;
};

export const scheduleStatusOptions: {
  value: z.infer<typeof enums.scheduleStatus>;
  label: string;
  icon: LucideIcon;
  color: string;
  hex: string;
}[] = [
  {
    value: "SCHEDULED",
    label: "Scheduled",
    icon: Clock,
    color: "text-yellow-500",
    hex: "#EAB308",
  },
  {
    value: "CONFIRMED",
    label: "Confirmed",
    icon: Check,
    color: "text-orange-500",
    hex: "#F97316",
  },
  {
    value: "ON_MY_WAY",
    label: "On My Way",
    icon: Truck,
    color: "text-yellow-500",
    hex: "#EAB308",
  },
  {
    value: "ARRIVED",
    label: "Arrived",
    icon: Home,
    color: "text-green-500",
    hex: "#22C55E",
  },
  {
    value: "IN_PROGRESS",
    label: "In Progress",
    icon: Play,
    color: "text-blue-500",
    hex: "#3B82F6",
  },
  {
    value: "COMPLETED",
    label: "Completed",
    icon: CheckCircle,
    color: "text-green-500",
    hex: "#22C55E",
  },
  {
    value: "CANCELLED",
    label: "Cancelled",
    icon: Ban,
    color: "text-red-500",
    hex: "#EF4444",
  },
];

type DateTimePickerProps = {
  disabled?: boolean;
  value?: Date | undefined;
  onChange?: (value: Date | undefined) => void;
  className?: string;
  placeholder?: string;
  timePresets?: {
    label: string;
    value: Dayjs;
  }[];
  timeDisabled?: boolean;
  triggerOnBlur?: boolean;
  fromDate?: Date;
  toDate?: Date;
};
export const DateTimePickerNew = ({
  disabled,
  value,
  onChange,
  timeDisabled = false,
  triggerOnBlur = false,
  fromDate,
  toDate,
}: DateTimePickerProps) => {
  return (
    <div className="grid grid-cols-2 gap-2 items-center">
      <DatePicker
        disabled={disabled}
        fromDate={fromDate}
        toDate={toDate}
        value={value}
        onChange={(newDate) => {
          if (!onChange) return;
          if (newDate) {
            if (value) {
              const time = dayjs(value).toDate();
              return onChange(joinDateAndTime(newDate, time));
            }
            return onChange(
              dayjs(newDate).hour(0).minute(0).second(0).toDate()
            );
          }
          onChange(undefined);
        }}
      />
      <TimePicker
        triggerOnBlur={triggerOnBlur}
        className="h-12 sm:h-8"
        value={timeDisabled ? undefined : value}
        onChange={(newTime) => {
          if (!onChange || !dayjs(newTime).isValid()) return;
          if (newTime && value) {
            return onChange(joinDateAndTime(value, newTime));
          }
          return onChange(dayjs(value).hour(0).minute(0).second(0).toDate());
        }}
        disabled={timeDisabled || disabled}
      />
    </div>
  );
};

type syncProps = {
  startDate: Date;
  endDate: Date;
  changeBy: "startDate" | "endDate";
};

const syncStartEndDates = ({ startDate, endDate, changeBy }: syncProps) => {
  let start = dayjs(startDate);
  let end = dayjs(endDate);

  if (changeBy === "endDate" && end.isBefore(start)) {
    start = end.subtract(1, "hour");
  } else if (changeBy === "startDate" && start.isAfter(end)) {
    end = start.add(1, "hour");
  }

  return { startDate: start.toDate(), endDate: end.toDate() };
};

const ScheduleForm = ({
  form,
  scheduleId,
  jobItems,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.fieldService.fsSchedule>>;
  scheduleId?: number;
  jobItems:
    | RouterOutputs["fieldService"]["schedules"]["details"]["fsJobs"]["fsJobServices"]
    | undefined;
}) => {
  const timeDisabled = form.watch("timeDisabled");

  const scheduleStartDate = form.watch("startDate");
  const scheduleEndDate = form.watch("endDate");
  const scheduleServices = form.watch("fsScheduleServices");

  const { data: tags } = trpc.tags.list.useQuery({
    type: "SCHEDULE",
  });

  return (
    <>
      <div className="flex gap-3 w-full">
        <FormField
          name="startDate"
          labelClassName="w-full"
          labelParentClassName="w-full"
          label={
            <div className="flex gap-4 justify-between items-center -mb-2 w-full">
              <p className="font-medium">Starts</p>
              <div className="flex gap-2 items-center">
                <p>Any time</p>
                <FormField name="timeDisabled" className="w-fit">
                  <Checkbox />
                </FormField>
              </div>
            </div>
          }
        >
          <DateTimePickerNew
            onChange={(value) => {
              if (!value) return;
              const syncedDates = syncStartEndDates({
                startDate: value,
                endDate:
                  form.getValues("endDate") ||
                  dayjs(value).add(1, "hour").toDate(),
                changeBy: "startDate",
              });
              form.setValue("startDate", syncedDates.startDate);
              form.setValue("endDate", syncedDates.endDate);
            }}
            timeDisabled={timeDisabled}
          />
        </FormField>
        <FormField name="endDate" label="Ends">
          <DateTimePickerNew
            fromDate={scheduleStartDate}
            onChange={(value) => {
              if (!value) return;
              const syncedDates = syncStartEndDates({
                startDate: form.getValues("startDate") || value,
                endDate: value,
                changeBy: "endDate",
              });
              form.setValue("startDate", syncedDates.startDate);
              form.setValue("endDate", syncedDates.endDate);
            }}
            timeDisabled={timeDisabled}
          />
        </FormField>
      </div>

      <div className="grid grid-cols-4 gap-2">
        <FormField name="fsScheduleTags" label="Tags" className="col-span-2">
          <MultiSelect
            icon={Tag}
            placeholder="Select Tags"
            options={
              tags?.map((tag) => ({
                value: tag.id,
                label: tag.name,
                color: tag.color,
              })) || []
            }
          />
        </FormField>
        <FormField name="invoiceRequired" label="Invoice Required">
          <Switch />
        </FormField>
      </div>
      <MultipleFieldStaffInput
        name="fsScheduleAssignees"
        startTime={scheduleStartDate}
        endTime={scheduleEndDate}
        excludeScheduleId={scheduleId}
      />
      <div className="overflow-x-auto mt-2 w-full">
        <p className="text-sm font-semibold">Schedule Line items</p>
        <table className="mt-2 w-full table-auto">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-right">Quantity</th>
              <th className="px-4 py-2 text-right">Price</th>
              <th className="px-4 py-2 text-right">Tax</th>
              <th className="px-4 py-2 text-right">Total</th>
              <th className="px-4 py-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobItems?.map((jobService) => {
              const scheduleService = scheduleServices?.find(
                (service) => service.fsJobServiceId === jobService.id
              );
              const scheduleServiceIndex = scheduleServices?.findIndex(
                (service) => service.fsJobServiceId === jobService.id
              );
              return (
                <tr key={jobService.id} className="border-b">
                  <td className="px-4 py-2">{jobService.name}</td>
                  <td className="px-4 py-2 text-right">
                    <Input
                      type="number"
                      disabled={!scheduleService}
                      placeholder="NIL"
                      min={0}
                      className="ml-auto w-16"
                      value={scheduleService?.quantity}
                      onChange={(e) => {
                        form.setValue(
                          `fsScheduleServices.${scheduleServiceIndex}.quantity`,
                          Number(e.target.value)
                        );
                      }}
                    />
                  </td>
                  <td className="px-4 py-2 text-right">{jobService.price}</td>
                  <td className="px-4 py-2 text-right">
                    {jobService.tax ? `${jobService.tax}%` : "NIL"}
                  </td>
                  <td className="px-4 py-2 text-right">
                    {(
                      jobService.price *
                      (scheduleService?.quantity || 0) *
                      (1 + (jobService.tax || 0) / 100)
                    ).toFixed(2)}
                  </td>
                  <td className="px-4 py-2 text-center">
                    <Button
                      onClick={() => {
                        if (scheduleService) {
                          form.setValue(
                            "fsScheduleServices",
                            form
                              .getValues("fsScheduleServices")
                              .filter(
                                (service) =>
                                  service.fsJobServiceId !== jobService.id
                              )
                          );
                        } else if (jobService) {
                          const prev =
                            form.getValues("fsScheduleServices") || [];

                          form.setValue("fsScheduleServices", [
                            ...prev,
                            {
                              ...jobService,
                              fsJobServiceId: jobService.id,
                              quantity: 1,
                            },
                          ]);
                        }
                      }}
                      variant={scheduleService ? "destructive" : "primary"}
                      icon={scheduleService ? Trash2 : Plus}
                      size="sm"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <FormField name="notes" label="Notes">
        <MiniRichTextEditor height={130} />
      </FormField>
    </>
  );
};

export const AddScheduleDrawer = ({
  open,
  onClose,
  fsJobId,
  jobItems,
}: {
  open: boolean;
  onClose: () => void;
  fsJobId: number;
  jobItems:
    | RouterOutputs["fieldService"]["schedules"]["details"]["fsJobs"]["fsJobServices"]
    | undefined;
}) => {
  const form = useForm<z.infer<typeof Schemas.fieldService.fsSchedule>>({
    resolver: zodResolver(Schemas.fieldService.fsSchedule),
    defaultValues: {
      status: "SCHEDULED",
      fsJobId,
    },
  });

  const onModalClose = () => {
    onClose();
    form.reset();
  };

  const scheduleAddMutation = trpc.fieldService.schedules.add.useMutation({
    onSuccess: () => {
      toast.success("Schedule added successfully.");
      onModalClose();
    },
  });

  const onSubmit = (
    values: z.infer<typeof Schemas.fieldService.fsSchedule>
  ) => {
    scheduleAddMutation.mutate({ ...values });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onModalClose}
      title="Add Schedule"
      modalClassName="!max-w-[700px]"
      footer={
        <Button
          loading={scheduleAddMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="w-full"
          size="md"
        >
          Add Schedule
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ScheduleForm form={form} jobItems={jobItems} />
      </Form>
    </ModalDrawer>
  );
};

export const calculateWorkerTime = (
  timeLine?: {
    oldStatus: z.infer<typeof enums.scheduleStatus>;
    newStatus: z.infer<typeof enums.scheduleStatus>;
    date: Date;
  }[]
):
  | {
      startTime: Date;
      endTime: Date;
      workDuration: string;
      travelDuration: string;
      waitingDuration: string;
    }
  | undefined => {
  if (!timeLine?.length) {
    return undefined;
  }

  const values: {
    startTime?: Date;
    endTime?: Date;
    workDuration?: string;
    travelDuration?: string;
    waitingDuration?: string;
  } = {};

  const workStartTime = timeLine.find(
    (t) => t.oldStatus === "ARRIVED" && t.newStatus === "IN_PROGRESS"
  )?.date;
  const workEndTime = timeLine.find(
    (t) => t.oldStatus === "IN_PROGRESS" && t.newStatus === "COMPLETED"
  )?.date;
  if (workStartTime && workEndTime) {
    values.startTime = workStartTime;
    values.endTime = workEndTime;
    values.workDuration = getDateDuration(workStartTime, workEndTime);
  }

  const travelStartTime = timeLine.find(
    (t) => t.oldStatus === "CONFIRMED" && t.newStatus === "ON_MY_WAY"
  )?.date;
  const travelEndTime = timeLine.find(
    (t) => t.oldStatus === "ON_MY_WAY" && t.newStatus === "ARRIVED"
  )?.date;
  if (travelStartTime && travelEndTime) {
    values.travelDuration = getDateDuration(travelStartTime, travelEndTime);
  }

  const waitingStartTime = timeLine.find(
    (t) => t.oldStatus === "ON_MY_WAY" && t.newStatus === "ARRIVED"
  )?.date;
  const waitingEndTime = timeLine.find(
    (t) => t.oldStatus === "ARRIVED" && t.newStatus === "IN_PROGRESS"
  )?.date;
  if (waitingStartTime && waitingEndTime) {
    values.waitingDuration = getDateDuration(waitingStartTime, waitingEndTime);
  }

  return Object.keys(values).length === 5
    ? (values as {
        startTime: Date;
        endTime: Date;
        workDuration: string;
        travelDuration: string;
        waitingDuration: string;
      })
    : undefined;
};

export const EditScheduleModal = ({
  open,
  onClose,
  scheduleId,
}: {
  scheduleId: number;
  open: boolean;
  onClose: ({
    deleted,
    updated,
  }: {
    deleted: boolean;
    updated: boolean;
  }) => void;
}) => {
  const confirmDelete = useConfirm();

  const { data: scheduleDetails } =
    trpc.fieldService.schedules.details.useQuery({ id: scheduleId });

  const deleteScheduleMutation = trpc.fieldService.schedules.delete.useMutation(
    {
      onSuccess: () => {
        toast.success("Schedule deleted successfully.");
        onClose({ deleted: true, updated: false });
      },
    }
  );

  const scheduleUpdateMutation = trpc.fieldService.schedules.update.useMutation(
    {
      onSuccess() {
        toast.success("Schedule updated successfully.");
        onClose({ deleted: false, updated: true });
      },
      onError(error) {
        toast.error(error.message);
      },
    }
  );

  const form = useForm<z.infer<typeof Schemas.fieldService.fsSchedule>>({
    resolver: zodResolver(Schemas.fieldService.fsSchedule),
  });

  useEffect(() => {
    if (scheduleDetails) {
      form.reset({
        ...scheduleDetails,
        fsScheduleAssignees: scheduleDetails.fsScheduleAssignees.map(
          (assignee) => assignee.userId
        ),
        fsScheduleTags: scheduleDetails.fsScheduleTags.map((tag) => tag.tagId),
        files: undefined,
      });
    }
  }, [scheduleDetails, form]);

  const onSubmit = (
    values: z.infer<typeof Schemas.fieldService.fsSchedule>
  ) => {
    scheduleUpdateMutation.mutate({ id: scheduleId, schedule: values });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose({ deleted: false, updated: false });
        form.reset();
      }}
      modalClassName="!max-w-[700px]"
      title="Edit Schedule"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            onClick={async () => {
              const confirmed = await confirmDelete({
                title: "Are you sure you want to delete this schedule?",
                body: "This will delete all schedule data and cannot be undone.",
              });
              if (confirmed) {
                deleteScheduleMutation.mutate(scheduleId);
              }
            }}
            variant="destructiveOutline"
            icon={Trash2}
          >
            Delete
          </Button>
          <Button
            loading={scheduleUpdateMutation.isLoading}
            type="submit"
            variant="primary"
            className="w-full"
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update Schedule
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ScheduleForm
          form={form}
          scheduleId={scheduleId}
          jobItems={scheduleDetails?.fsJobs.fsJobServices}
        />
      </Form>
    </ModalDrawer>
  );
};

type LatLongToPlaceProps = {
  latitude: number;
  longitude: number;
  className?: string;
};

const LatLongToPlace: React.FC<LatLongToPlaceProps> = ({
  latitude,
  longitude,
  className,
}) => {
  const [placeName, setPlaceName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const fetchPlaceName = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
      );
      setPlaceName(response.data.display_name || "Unknown location");
    } catch (error) {
      console.error("Error fetching place name:", error);
      setPlaceName("Unknown location");
    } finally {
      setLoading(false);
      setHasFetched(true);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasFetched) {
          fetchPlaceName();
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [latitude, longitude, hasFetched]);

  return (
    <div ref={ref}>
      {loading ? (
        <p className={cn(className)}>Loading...</p>
      ) : (
        <p className={cn(className)}>{placeName}</p>
      )}
    </div>
  );
};

const ScheduleDetailsModal = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  onClose: () => void;
  id: number;
}) => {
  const navigate = useNavigate();

  const team = useTeam();

  const [showEdit, setShowEdit] = useState(false);
  const [includeUnavailable, setIncludeUnavailable] = useState(false);
  const [tab, setTab] = useState<
    | "info"
    | "finances"
    | "status-history"
    | "messages"
    | "items"
    | "assignees"
    | "files"
  >("info");

  const updateScheduleMutation = trpc.fieldService.schedules.update.useMutation(
    {
      onSuccess: () => {
        toast.success("Schedule updated successfully");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const scheduleAssigneeUpdateMutation =
    trpc.fieldService.schedules.updateAssignee.useMutation({
      onSuccess: () => {
        toast.success("Schedule assignees updated successfully.");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const { data: schedule } = trpc.fieldService.schedules.details.useQuery({
    id,
  });

  const { data: messages, isLoading: isMessagesLoading } =
    trpc.fieldService.schedules.messages.useQuery(
      {
        scheduleId: id,
      },
      {
        enabled: tab === "messages",
      }
    );
  const { data: fieldStaffs } = trpc.users.listFieldStaff.useQuery({
    startTime: schedule?.startDate,
    endTime: schedule?.endDate,
    includeUnavailable,
    scheduleId: schedule?.id,
  });

  const { data: tags } = trpc.tags.list.useQuery({
    type: "SCHEDULE",
  });

  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const workerTime = calculateWorkerTime(schedule?.fsScheduleStatusTimeline);

  const debouncedScheduleMutation = debounce(
    updateScheduleMutation.mutate,
    500
  );

  const debouncedScheduleAssigneeMutation = debounce(
    scheduleAssigneeUpdateMutation.mutate,
    500
  );

  const disableInvoiceAdd =
    schedule?.status !== "COMPLETED" ||
    schedule?.fsJobInvoices.length > 0 ||
    !schedule?.invoiceRequired;

  const notAssigned =
    !teamDetails?.teamSettings.fsJobs.allowScheduleCompletionWithoutAssignees &&
    schedule?.fsScheduleAssignees?.length === 0;

  const isScheduledForFuture = dayjs(schedule?.startDate).isAfter(
    dayjs(),
    "day"
  );

  const availableStatuses = () => {
    if (notAssigned) {
      return scheduleStatusOptions.filter((option) =>
        ["SCHEDULED", "CANCELLED"].includes(option.value)
      );
    }
    if (isScheduledForFuture) {
      return scheduleStatusOptions.filter((option) =>
        ["SCHEDULED", "CONFIRMED", "CANCELLED"].includes(option.value)
      );
    }
    return scheduleStatusOptions;
  };

  const statusAndMenu = (
    <div className="flex gap-2 justify-end mt-2">
      <Select
        hideSearch
        isLoading={updateScheduleMutation.isLoading}
        value={schedule?.status}
        className={cn(
          "w-full",
          schedule &&
            scheduleStatusOptions.find((s) => s.value === schedule.status)
              ?.color
        )}
        options={availableStatuses()}
        render={({ label, icon: Icon, color }) => (
          <div className="flex gap-2 items-center">
            <Icon className={cn("w-4 h-4", color)} />
            <p className="text-sm">{label}</p>
          </div>
        )}
        onChange={(value) => {
          let cancelReason = "";
          if (value === "CANCELLED") {
            cancelReason =
              window.prompt("Please enter the reason for cancellation") || "";
            if (!cancelReason.length) return;
          }
          updateScheduleMutation.mutate({
            id,
            schedule: {
              status: value as z.infer<typeof enums.scheduleStatus>,
              cancelReason,
            },
          });
        }}
      />
      <DropMenu
        items={[
          {
            hidden: disableInvoiceAdd,
            label: "Create invoice",
            onClick: () => {
              schedule &&
                navigate(
                  `/sales/invoices/add?fsJobId=${schedule.fsJobs.id}&fsScheduleId=${schedule.id}`
                );
            },
            icon: CircleDollarSign,
            className: "text-green-500",
          },
          {
            label: "Edit",
            onClick: () => setShowEdit(true),
            icon: Pencil,
          },
        ]}
      >
        <Button variant="outline">
          <MoreVertical className="w-4 h-4" />
        </Button>
      </DropMenu>
    </div>
  );

  return (
    <>
      <EditScheduleModal
        open={showEdit}
        onClose={({ deleted }) => {
          setShowEdit(false);
          deleted && onClose();
        }}
        scheduleId={id}
      />
      <ModalDrawer
        open={open}
        onClose={onClose}
        className="overflow-hidden overflow-y-auto p-0 mx-auto w-full h-screen bg-white rounded-lg sm:h-auto"
        title="Schedule Details"
        modalClassName="!min-w-[700px]"
        closeOnOverlayClick
      >
        {!schedule || (!team && <FullScreenSpinner />)}
        {schedule && team && (
          <div>
            <div className="flex flex-col gap-3 sm:flex-row sm:justify-between">
              <div className="flex flex-col gap-2 items-start sm:w-2/3 sm:gap-1">
                <div className="flex gap-2 items-center">
                  <h3
                    onClick={() => {
                      navigate(
                        `/field-service/jobs/details/${schedule.fsJobs.id}`
                      );
                    }}
                    className="flex gap-1 items-center text-lg font-semibold cursor-pointer hover:underline hover:text-primary-500"
                  >
                    {getJobId(schedule.fsJobs)} -{" "}
                    {getJobName(schedule.fsJobs.fsJobServices)}{" "}
                    <Link className="h-5 text-primary-600" />
                  </h3>
                  <Button
                    variant="primaryOutline"
                    size="xs"
                    icon={Copy}
                    onClick={() => {
                      const message = getScheduleMessage(schedule, {
                        title: schedule.fsScheduleAssignees
                          .map((assignee) => heffl.format.name(assignee.users))
                          .join(", "),
                        showJobTagsInSchedules:
                          team.settings.fsJobs.showJobTagsInSchedules,
                      });
                      copyToClipboard(message);
                    }}
                  >
                    Copy
                  </Button>
                </div>
                <span className="flex gap-1 items-center cursor-pointer">
                  <Tag className="h-4 text-green-500" />
                  {team.settings.fsJobs.showJobTagsInSchedules &&
                    schedule.fsJobs.fsJobTags.map((jobTag) => (
                      <Badge
                        key={jobTag.tags.name}
                        background={jobTag.tags.color}
                        icon={Hammer}
                      >
                        {jobTag.tags.name}
                      </Badge>
                    ))}
                  <TagsInput
                    tags={tags}
                    value={schedule.fsScheduleTags.map((tag) => tag.tagId)}
                    onChange={(value) => {
                      updateScheduleMutation.mutate({
                        id,
                        schedule: {
                          fsScheduleTags: value,
                        },
                      });
                    }}
                  />
                </span>
                {schedule.invoiceRequired &&
                  schedule.status !== "CANCELLED" && (
                    <span className="flex items-center cursor-pointer">
                      <CircleDollarSign className="h-4 text-green-500" />
                      <ScheduleInvoiceBadge schedule={schedule} />
                    </span>
                  )}

                <span
                  onClick={() => {
                    navigate(
                      `/sales/clients/details/${schedule.fsJobs.fsProperties.clients.id}`
                    );
                  }}
                  className="flex items-center font-medium cursor-pointer hover:underline hover:text-primary-500"
                >
                  <Building2 className="h-4 text-red-500" />
                  <p className="text-sm text-gray-700">
                    {schedule.fsJobs.fsProperties.clients.name}
                  </p>
                </span>
                <div className="flex gap-1 sm:flex-col">
                  <span className="flex items-center font-medium">
                    <MapPin className="h-4 text-blue-500" />
                    <p className="text-sm text-gray-700">
                      {formatValue.propertyName(schedule.fsJobs.fsProperties)}
                    </p>
                  </span>
                  <div className="flex gap-1 items-center mt-2">
                    <Button
                      onClick={() => {
                        const link = generateGoogleMapsLink(
                          schedule.fsJobs.fsProperties
                        );
                        copyToClipboard(link);
                      }}
                      variant="primaryOutline"
                      size="xs"
                      icon={Copy}
                    >
                      Copy link
                    </Button>
                    <Button
                      onClick={() =>
                        openGoogleMaps(schedule.fsJobs.fsProperties)
                      }
                      variant="primaryOutline"
                      size="xs"
                      icon={Navigation}
                    >
                      Open map
                    </Button>
                  </div>
                </div>
                {!!schedule.fsJobs.siteContactPerson?.mobile && (
                  <span
                    onClick={() => {
                      if (schedule.fsJobs.siteContactPerson?.mobile) {
                        callMobile(schedule.fsJobs.siteContactPerson.mobile);
                      }
                    }}
                    className="flex items-center mt-2 font-medium cursor-pointer hover:underline hover:text-primary-500 w-fit"
                  >
                    <Phone className="h-4 text-yellow-600" />
                    <p className="text-sm text-gray-700">
                      {schedule.fsJobs.siteContactPerson.mobile}
                    </p>
                  </span>
                )}
                <div className="flex gap-3 items-center mt-3">
                  <TagsInput
                    emptyLabel="Not assigned"
                    small={false}
                    className="flex-wrap"
                    value={schedule.fsScheduleAssignees.map(
                      (user) => user.userId
                    )}
                    uniqueColor={true}
                    tags={fieldStaffs?.map((tag) => ({
                      id: tag.id,
                      name: tag.firstName,
                    }))}
                    onChange={(value) => {
                      updateScheduleMutation.mutate({
                        id,
                        schedule: {
                          fsScheduleAssignees: value,
                        },
                      });
                    }}
                  />
                  <div className="flex gap-2 items-center">
                    <Checkbox
                      id="include-unavailable"
                      value={includeUnavailable}
                      onChange={(v) => setIncludeUnavailable(!!v)}
                    />
                    <label
                      htmlFor="include-unavailable"
                      className="font-normal cursor-pointer"
                    >
                      Show all
                    </label>
                  </div>
                </div>
              </div>
              {schedule.status !== "COMPLETED" && (
                <div className="flex flex-col gap-2 text-right sm:w-3/5">
                  {statusAndMenu}
                  {schedule.status === "CANCELLED" && (
                    <div className="text-start">
                      <p className="text-xs text-gray-500">Cancel reason</p>
                      <p className="text-sm text-red-500">
                        {schedule?.cancelReason}
                      </p>
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-4 w-full text-left">
                    <p className="font-medium">Starts</p>
                    <div className="flex gap-2 items-center">
                      <p>Any time</p>
                      <Checkbox
                        value={schedule.timeDisabled}
                        onChange={(value) => {
                          updateScheduleMutation.mutate({
                            id,
                            schedule: {
                              timeDisabled: !!value,
                              endDate: value
                                ? dayjs(schedule.startDate).isSame(
                                    schedule.endDate
                                  )
                                  ? dayjs(schedule.startDate)
                                      .add(1, "hour")
                                      .toDate()
                                  : schedule.endDate
                                : undefined,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                  <DateTimePickerNew
                    value={schedule.startDate}
                    timeDisabled={schedule.timeDisabled}
                    onChange={(value) => {
                      if (!value) return;
                      const syncedDates = syncStartEndDates({
                        startDate: value,
                        endDate: schedule.endDate,
                        changeBy: "startDate",
                      });
                      debouncedScheduleMutation({
                        id,
                        schedule: {
                          startDate: syncedDates.startDate,
                          endDate: syncedDates.endDate,
                        },
                      });
                    }}
                  />
                  <DateTimePickerNew
                    fromDate={schedule.startDate}
                    timePresets={[
                      {
                        label: "30m",
                        value: dayjs(schedule.startDate).add(30, "minute"),
                      },
                      {
                        label: "1h",
                        value: dayjs(schedule.startDate).add(60, "minute"),
                      },
                      {
                        label: "1h 30m",
                        value: dayjs(schedule.startDate).add(90, "minute"),
                      },
                      {
                        label: "2h",
                        value: dayjs(schedule.startDate).add(120, "minute"),
                      },
                    ]}
                    value={schedule.endDate}
                    timeDisabled={schedule.timeDisabled}
                    onChange={(value) => {
                      if (!value) return;
                      const syncedDates = syncStartEndDates({
                        startDate: schedule.startDate,
                        endDate: value,
                        changeBy: "endDate",
                      });
                      debouncedScheduleMutation({
                        id,
                        schedule: {
                          startDate: syncedDates.startDate,
                          endDate: syncedDates.endDate,
                        },
                      });
                    }}
                  />
                  <div
                    className={cn(
                      "flex gap-1 items-center font-semibold justify-end",
                      dayjs(schedule.endDate).diff(schedule.startDate, "hour") >
                        8
                        ? "text-red-500"
                        : "text-green-500"
                    )}
                  >
                    <Timer className="w-4 h-4" />
                    <p className="text-sm">
                      {!schedule.timeDisabled &&
                        heffl.format.duration(
                          dayjs(schedule.endDate).diff(
                            schedule.startDate,
                            "minute"
                          )
                        )}
                    </p>
                  </div>
                </div>
              )}
              {schedule.status === "COMPLETED" && (
                <div className="flex flex-col gap-2 text-right sm:w-3/5">
                  <div className="self-end w-60">{statusAndMenu}</div>
                  {workerTime && (
                    <div className="flex flex-col items-end">
                      <p className="text-[16px] mt-1">
                        {dynamicDateFormatting(
                          workerTime.startTime,
                          false,
                          ", "
                        )}{" "}
                        -{" "}
                        {!dayjs(workerTime.startTime).isSame(
                          workerTime.endTime,
                          "day"
                        )
                          ? dynamicDateFormatting(
                              workerTime.endTime,
                              false,
                              ", "
                            )
                          : dayjs(workerTime.endTime).format("hh:mm A")}
                      </p>
                      <div className="flex gap-2 items-center mt-2">
                        <Badge icon={Hammer} variant="success">
                          Work
                        </Badge>
                        <p className="text-base font-medium">
                          {workerTime.workDuration}
                        </p>
                      </div>
                      <div className="flex gap-2 items-center mt-2">
                        <Badge icon={Truck} variant="warning" small>
                          Travel
                        </Badge>
                        <p className="text-xs font-medium">
                          {workerTime.travelDuration}
                        </p>
                      </div>
                      {workerTime.waitingDuration !== "0m" && (
                        <div className="flex gap-2 items-center mt-2">
                          <Badge icon={Clock} variant="warning" small>
                            Waiting
                          </Badge>
                          <p className="text-xs font-medium">
                            {workerTime.waitingDuration}
                          </p>
                        </div>
                      )}
                      <div className="flex gap-1 items-center mt-1 text-xs text-gray-500">
                        Added by
                        <span className="flex items-center">
                          <UserRoundPlus className="h-3.5" />
                          {formatName(
                            schedule.fsScheduleStatusTimeline[0].users
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {!workerTime && (
                    <div className="flex flex-col items-end">
                      <div className="flex gap-1 items-center">
                        <span className="w-1.5 h-1.5 bg-red-500 rounded-full" />
                        <p className="text-xs text-gray-500">
                          No time added from workers
                        </p>
                      </div>
                      <p className="text-[16px] mt-1">
                        {dynamicDateFormatting(schedule.startDate, false, ", ")}{" "}
                        -{" "}
                        {!dayjs(schedule.startDate).isSame(
                          schedule.endDate,
                          "day"
                        )
                          ? dynamicDateFormatting(schedule.endDate, false, ", ")
                          : dayjs(schedule.endDate).format("hh:mm A")}
                      </p>
                      <div className="flex gap-2 items-center mt-2">
                        <Badge icon={Hammer} variant="success">
                          Work
                        </Badge>
                        <p className="text-base font-medium">
                          {getDateDuration(
                            schedule.startDate,
                            schedule.endDate
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <StripeTabs
              value={tab}
              onChange={setTab}
              className="-mx-2 mt-3"
              contentClassName="h-52 overflow-y-auto"
              items={[
                {
                  icon: Info,
                  key: "info",
                  label: "Info",
                  children: (
                    <div className="flex flex-col gap-3 mt-1">
                      <span className="flex gap-1 items-center font-semibold text-md">
                        <StickyNote className="w-4 h-4 text-yellow-500" />
                        Schedule Notes
                      </span>
                      <RenderHtml>{schedule.notes || "No notes"}</RenderHtml>
                      <span className="flex gap-1 items-center font-semibold text-md">
                        <NotebookText className="w-4 h-4 text-purple-500" />
                        Job Instructions
                      </span>
                      <RenderHtml>
                        {schedule.fsJobs.instructions || "No instructions"}
                      </RenderHtml>
                    </div>
                  ),
                },
                {
                  key: "assignees",
                  label: "Assignees",
                  icon: UserRoundPlus,
                  children: (
                    <div>
                      <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                          <thead>
                            <tr className="bg-gray-100">
                              <th className="p-2 font-semibold text-left">
                                Name
                              </th>
                              <th className="p-2 !w-80 font-semibold">Time</th>
                              <th className="p-2 font-semibold text-left">
                                Duration
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {schedule.fsScheduleAssignees.map((assignee) => (
                              <tr
                                key={assignee.id}
                                className="border-b hover:bg-gray-50"
                              >
                                <td className="p-2">
                                  {assignee.users.firstName}
                                </td>
                                <td className="flex flex-col gap-2 p-2 w-80">
                                  <DateTimePickerNew
                                    className="!w-full"
                                    fromDate={schedule.startDate}
                                    value={assignee.startDate}
                                    toDate={schedule.endDate}
                                    onChange={(value) => {
                                      if (!value) return;
                                      debouncedScheduleAssigneeMutation({
                                        id: assignee.id,
                                        assignee: {
                                          startDate: value,
                                        },
                                      });
                                    }}
                                  />
                                  <DateTimePickerNew
                                    fromDate={schedule.startDate}
                                    toDate={schedule.endDate}
                                    value={assignee.endDate}
                                    onChange={(value) => {
                                      if (!value) return;
                                      debouncedScheduleAssigneeMutation({
                                        id: assignee.id,
                                        assignee: {
                                          endDate: value,
                                        },
                                      });
                                    }}
                                  />
                                </td>
                                <td className="p-2">
                                  {calculateDuration(
                                    assignee.startDate,
                                    assignee.endDate
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ),
                },
                {
                  icon: Box,
                  label: "Line Items",
                  key: "items",
                  count: schedule._count.fsScheduleParts,
                  children: (
                    <Accordion type="single" collapsible className="w-full">
                      <AccordionItem value="parts">
                        <AccordionTrigger>Parts</AccordionTrigger>
                        <AccordionContent>
                          {schedule.fsScheduleParts.map((product, index) => (
                            <MobileLineItem key={index} product={product} />
                          ))}
                          {schedule.fsScheduleParts.length === 0 && (
                            <Empty
                              title=""
                              description="No parts added to schedule"
                              icon={Box}
                              iconSize={20}
                            />
                          )}
                        </AccordionContent>
                      </AccordionItem>
                      <AccordionItem value="schedule-services">
                        <AccordionTrigger>Schedule Services</AccordionTrigger>
                        <AccordionContent>
                          {schedule.fsScheduleServices.map((service, index) => (
                            <MobileLineItem key={index} product={service} />
                          ))}
                          {schedule.fsScheduleServices.length === 0 && (
                            <Empty
                              title=""
                              description="No services added to schedule"
                              icon={Wrench}
                              iconSize={20}
                            />
                          )}
                        </AccordionContent>
                      </AccordionItem>
                      <AccordionItem value="job-services">
                        <AccordionTrigger>Job Services</AccordionTrigger>
                        <AccordionContent className="pr-4 space-y-2">
                          {schedule.fsJobs.fsJobServices.map(
                            (service, index) => (
                              <MobileLineItem key={index} product={service} />
                            )
                          )}
                          <div className="flex flex-col gap-2 pb-4 mt-1 float-end">
                            <p className="text-base">
                              Total:{" "}
                              <span className="font-bold">
                                {formatCurrency(
                                  calculateLineItems({
                                    lineItems: schedule.fsJobs.fsJobServices,
                                    discount: schedule.fsJobs.discount,
                                  }).total,
                                  "AED"
                                )}
                              </span>
                            </p>
                          </div>
                          {schedule.fsJobs.fsJobServices.length === 0 && (
                            <Empty
                              title="No services added to job"
                              description=""
                              icon={Wrench}
                            />
                          )}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  ),
                },
                // {
                //   key: "finances",
                //   label: "Finances",
                //   icon: CircleDollarSign,
                //   count: schedule._count.fsJobInvoices,
                //   children: (
                //     <>
                //       {!!schedule.fsJobInvoices?.length && (
                //         <div>
                //           <p className="flex items-center text-sm font-semibold">
                //             <CircleDollarSign className="h-4 text-green-500" />{" "}
                //             Invoices
                //           </p>
                //           <div className="mt-1 space-y-2">
                //             {schedule.fsJobInvoices.map((invoice) => (
                //               <InvoiceCard
                //                 key={invoice.id}
                //                 data={invoice.invoices}
                //               />
                //             ))}
                //             {!schedule.fsJobInvoices && (
                //               <Empty
                //                 title="No invoices"
                //                 description="Try changing your search term or filter"
                //                 icon={CircleDollarSign}
                //               />
                //             )}
                //           </div>
                //         </div>
                //       )}
                //       {/* <div className="pt-4 mt-4 border-t">
                //         <p className="text-sm font-semibold">Line items</p>
                //         <div className="mt-1 space-y-2">
                //           {schedule.fsJobs.fsJobServices.map((lineItem) => (
                //             <MobileLineItem
                //               key={lineItem.id}
                //               product={lineItem}
                //             />
                //           ))}
                //         </div>
                //       </div> */}
                //     </>
                //   ),
                // },

                {
                  label: "History",
                  key: "status-history",
                  count: schedule._count.fsScheduleStatusTimeline,
                  children: (
                    <div>
                      {!schedule.fsScheduleStatusTimeline.length && (
                        <Empty
                          title="No Status History"
                          description="There is no status history available for this schedule."
                          icon={Clock}
                        />
                      )}
                      <Timeline
                        items={schedule.fsScheduleStatusTimeline.map(
                          (item) => ({
                            title: (
                              <div className="flex gap-2 items-center">
                                <ScheduleStatusBadge
                                  schedule={{ status: item.oldStatus }}
                                />
                                <p>&rarr;</p>
                                <ScheduleStatusBadge
                                  schedule={{ status: item.newStatus }}
                                />
                              </div>
                            ),
                            description: (
                              <div className="flex flex-col">
                                <p className="text-sm text-gray-700">
                                  At{" "}
                                  {dayjs(item.date).format(
                                    "DD MMM YYYY, hh:mm a"
                                  )}
                                  {!!item.latitude && !!item.longitude && (
                                    <span className="flex gap-1 items-center">
                                      <MapPinIcon className="h-4 text-yellow-500" />
                                      <LatLongToPlace
                                        className="text-xs text-gray-400"
                                        latitude={item.latitude}
                                        longitude={item.longitude}
                                      />
                                    </span>
                                  )}
                                </p>
                                <span className="flex items-center mt-1 text-xs">
                                  <UserRoundPlus className="h-3.5" />
                                  {formatName(item.users)}
                                </span>
                              </div>
                            ),
                          })
                        )}
                        activeItem={-1}
                      />
                    </div>
                  ),
                  icon: Clock,
                },
                {
                  label: "Messages",
                  key: "messages",
                  count: schedule._count.comments,
                  icon: MessageCircle,
                  children: (
                    <div className="">
                      {!messages?.length && !isMessagesLoading && (
                        <Empty
                          title="No messages"
                          description="Add a message to get started"
                          icon={MessageCircle}
                        />
                      )}
                      {isMessagesLoading && <FullScreenSpinner />}
                      {messages && <ActivityTimeline activities={messages} />}
                    </div>
                  ),
                },
                {
                  label: "Files",
                  key: "files",
                  count:
                    schedule.files?.fields.fs_schedule_service_report.length,
                  iconify: "tabler:paperclip",
                  children: (
                    <FilesList
                      className="grid grid-cols-2 gap-3"
                      fileClassName="border rounded-md p-2"
                      files={[
                        ...(schedule.files?.fields.fs_schedule_service_report ??
                          []),
                      ]}
                    />
                  ),
                },
              ]}
            />
          </div>
        )}
      </ModalDrawer>
    </>
  );
};

export default ScheduleDetailsModal;
