import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import StatsBar from "@heffl/ui/components/stats-cards";
import dayjs from "dayjs";
import { CheckCircle, Clock } from "lucide-react";
import { useEffect } from "react";
import { PieChart, Pie, Cell } from "recharts";

const EmployeePerformance = () => {
  const [filters, setFilters] = useParamsState<
    RouterInputs["projects"]["reports"]["taskEmployeePerformance"]
  >({
    userId: 0,
    dates: [dayjs().subtract(6, "month").toDate(), dayjs().toDate()],
  });

  const { data: users } = trpc.users.list.useQuery();
  const { data: performanceData } =
    trpc.projects.reports.taskEmployeePerformance.useQuery(filters, {
      enabled: filters.userId !== 0,
    });

  useEffect(() => {
    if (users?.length) {
      setFilters({ userId: users[0].id });
    }
  }, [users]);

  if (!performanceData || !users) return <FullScreenSpinner />;

  return (
    <div className="space-y-6">
      <FilterBar
        onChange={() => {}}
        filters={[
          {
            label: "Employee",
            type: "checkbox",
            key: "userId",
            multiple: false,
            showSearch: true,
            value: filters.userId ? [filters.userId] : [],
            onChange: (value) =>
              value.length && setFilters({ userId: Number(value[0]) }),
            options:
              users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })) || [],
          },
          {
            label: "Date Range",
            type: "date-range",
            key: "dates",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value }),
          },
        ]}
      />
      <StatsBar
        items={[
          {
            icon: CheckCircle,
            title: "Completion Rate",
            value: `${Math.round(performanceData.completionRate * 100)}%`,
          },
          {
            icon: Clock,
            title: "Average Delay",
            value: `${Math.round(performanceData.averageDelay)} days`,
          },
          {
            icon: CheckCircle,
            title: "Tasks Completed",
            value: performanceData.totalTasksCompleted.toString(),
          },
        ]}
        className="bg-white"
      />
      <div>
        <div className="relative w-[320px] h-[320px]">
          <PieChart width={320} height={320}>
            <Pie
              data={[
                {
                  name: "On Time",
                  value: performanceData.onTimeCompletedTasksCount,
                },
                {
                  name: "Delayed",
                  value:
                    performanceData.totalTasksCompleted -
                    performanceData.onTimeCompletedTasksCount,
                },
              ]}
              cx={150}
              cy={150}
              startAngle={180}
              endAngle={0}
              innerRadius={80}
              outerRadius={150}
              paddingAngle={0}
              dataKey="value"
            >
              <Cell fill="#22C55E" /> {/* Green for on time */}
              <Cell fill="#EF4444" /> {/* Red for delayed */}
            </Pie>
          </PieChart>
          <div className="absolute top-2/4 left-1/2 text-center -translate-x-1/2 -translate-y-1/2">
            <div className="text-2xl font-bold text-gray-900">
              {performanceData.onTimeCompletedTasksCount}/
              {performanceData.totalTasksCompleted}
            </div>
            <div className="text-sm text-gray-500">Tasks On Time</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeePerformance;
