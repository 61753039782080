import { trpc } from "@/helpers/trpc";
import { AddClientDrawer, EditClientDrawer } from "@/pages/crm/clients/list";
import { AddContactDrawer, EditContactDrawer } from "@/pages/crm/contacts/list";
import {
  AddVendorDrawer,
  EditVendorDrawer,
} from "@/pages/purchases/vendors/list";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import { Button } from "@heffl/ui/components/primitives/button";
import { Checkbox } from "@heffl/ui/components/primitives/checkbox";
import { FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { MultiSelect } from "@heffl/ui/components/primitives/multi-select";
import Select from "@heffl/ui/components/primitives/select";
import { cn, formatName, generateUniqueColor } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  Building2,
  CircleUserRound,
  Phone,
  SearchIcon,
  UserCircle2,
  Users,
} from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { z } from "zod";

export const UserInput = ({
  label,
  name,
  placeholder = "Select user",
  allowClear = false,
  defaultCurrentUser = false,
  className,
  type = undefined,
  filter = [],
  disabled = false,
}: {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  allowClear?: boolean;
  defaultCurrentUser?: boolean;
  type?: z.infer<typeof enums.userTypes>[];
  filter?: number[];
  disabled?: boolean;
}) => {
  const { setValue, getValues } = useFormContext();

  const { data: currentUser } = trpc.users.currentUser.useQuery(undefined, {
    enabled: defaultCurrentUser,
  });
  const { data: data, isLoading } = trpc.users.list.useQuery({
    type: type ? type : undefined,
  });

  useEffect(() => {
    if (defaultCurrentUser && currentUser && !getValues(name)) {
      setValue(name, currentUser.id);
    }
  }, [currentUser]);

  return (
    <FormField label={label} name={name} className={cn(className)}>
      <Select
        icon={CircleUserRound}
        allowClear={allowClear}
        placeholder={placeholder}
        isLoading={isLoading}
        disabled={disabled}
        options={data
          ?.filter((user) => (filter.length ? filter.includes(user.id) : true))
          ?.map((user) => ({
            label: user.firstName,
            value: user.id,
          }))}
      />
    </FormField>
  );
};

export const MultipleUserInput = ({
  label,
  name,
  placeholder = "Select users",
  className,
  filter = [],
  defaultCurrentUser = false,
  disabled = false,
  excludeType,
}: {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  filter?: number[];
  defaultCurrentUser?: boolean;
  excludeType?: z.infer<typeof enums.userTypes>[];
  disabled?: boolean;
}) => {
  const { setValue, getValues } = useFormContext();

  const { data: currentUser } = trpc.users.currentUser.useQuery(undefined, {
    enabled: defaultCurrentUser,
  });

  const { data } = trpc.users.list.useQuery({
    excludeType: excludeType ? excludeType : ["FIELD_STAFF"],
  });

  useEffect(() => {
    if (defaultCurrentUser && currentUser && !getValues(name)?.length) {
      setValue(name, [currentUser.id]);
    }
  }, [currentUser]);

  return (
    <FormField label={label} name={name} className={cn(className)}>
      <MultiSelect
        icon={UserCircle2}
        disabled={disabled}
        placeholder={placeholder}
        options={data
          ?.filter((user) => (filter.length ? filter.includes(user.id) : true))
          ?.map((user) => ({
            label: formatName(user),
            value: user.id,
          }))}
      />
    </FormField>
  );
};

export const MultipleFieldStaffInput = ({
  label = "Team",
  name,
  placeholder = "Select field staff",
  className,
  startTime,
  endTime,
  excludeScheduleId,
}: {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  startTime?: Date;
  endTime?: Date;
  excludeScheduleId?: number;
}) => {
  const [includeUnavailable, setIncludeUnavailable] = useState(false);

  const { data } = trpc.users.listFieldStaff.useQuery({
    startTime,
    endTime,
    includeUnavailable,
    scheduleId: excludeScheduleId,
  });

  return (
    <FormField
      labelParentClassName="w-full"
      labelClassName="w-full"
      label={
        <div className="flex justify-between items-center !w-full">
          {label}
          <div className="flex gap-2 items-center">
            <Checkbox
              id="include-unavailable"
              value={includeUnavailable}
              onChange={(v) => setIncludeUnavailable(!!v)}
            />
            <label
              htmlFor="include-unavailable"
              className="font-normal cursor-pointer"
            >
              Show all
            </label>
          </div>
        </div>
      }
      name={name}
      className={cn(className)}
    >
      <MultiSelect
        icon={UserCircle2}
        placeholder={placeholder}
        options={
          data?.map((user) => ({
            label: user.firstName,
            value: user.id,
            color: generateUniqueColor(user.firstName, 500),
          })) || []
        }
      />
    </FormField>
  );
};

export const ClientInput = ({
  label,
  name,
  onAddModalClose,
  placeholder = "",
  allowClear = false,
  className,
  addDefaultValues,
  disabled = false,
  onChange,
}: {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  allowClear?: boolean;
  onAddModalClose?: (id?: number) => void;
  addDefaultValues?: Partial<z.infer<typeof Schemas.crm.client>>;
  disabled?: boolean;
  onChange?: (id?: number) => void;
}) => {
  const { watch } = useFormContext();
  const clientId = watch(name);

  const [addClient, setAddClient] = useState(false);
  const [editClient, setEditClient] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState("");

  const { data: clients, isLoading } = trpc.clients.list.useQuery({
    search,
    pageNo: 1,
    pageSize: 20,
    include: clientId ? [clientId] : [],
  });

  useEffect(() => {
    if (addDefaultValues) {
      // waiting for add deal drawer to open
      setTimeout(() => {
        setAddClient(true);
      }, 500);
    }
  }, []);

  return (
    <>
      <AddClientDrawer
        open={addClient}
        onClose={(id) => {
          setAddClient(false);
          if (onAddModalClose) onAddModalClose(id);
        }}
        defaultValues={addDefaultValues}
      />
      {!!editClient && (
        <EditClientDrawer
          id={editClient}
          onClose={() => {
            setEditClient(undefined);
          }}
        />
      )}
      <FormField
        label={label}
        name={name}
        className={cn(className)}
        suffix={
          <div>
            {!!clientId && (
              <Button
                size="xs"
                variant="primaryOutline"
                onClick={() => {
                  setEditClient(clientId);
                }}
              >
                Edit
              </Button>
            )}
          </div>
        }
      >
        <Select
          disabled={disabled}
          shouldFilter={false}
          onSearch={setSearch}
          allowClear={allowClear}
          createButton={{
            label: "Add client",
            onClick: () => {
              setAddClient(true);
            },
          }}
          onChange={(value) => {
            if (onChange) onChange(value);
          }}
          isLoading={isLoading}
          placeholder={placeholder || "Select client"}
          iconify="tabler:building"
          options={
            clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
              phone: client.contacts?.find((contact) => contact.isPrimary)
                ?.mobile,
            })) || []
          }
          render={(client) => (
            <div className="flex flex-col gap-1 pb-1 w-full">
              <p className="text-sm font-semibold">{client.label}</p>
              <div className="flex gap-2">
                {!!client?.phone && (
                  <div className="flex flex-row gap-1 items-center text-xs">
                    <Phone className="w-3.5 h-3.5 text-gray-500" />
                    {client.phone}
                  </div>
                )}
              </div>
            </div>
          )}
        />
      </FormField>
    </>
  );
};

export const VendorInput = ({
  label,
  name,
  placeholder = "Select vendor",
  allowClear = false,
  className,
  disabled = false,
}: {
  className?: string;
  label?: string;
  name: string;
  placeholder?: string;
  allowClear?: boolean;
  disabled?: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const vendorId = watch(name);

  const [addVendor, setAddVendor] = useState(false);
  const [editVendor, setEditVendor] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState("");

  const { data: vendors, isLoading } = trpc.purchases.vendors.list.useQuery({
    search,
  });

  return (
    <>
      {addVendor && (
        <AddVendorDrawer
          open={addVendor}
          onClose={(id?: number) => {
            setAddVendor(false);
            if (id) {
              setValue(name, id);
            }
          }}
        />
      )}
      {!!editVendor && (
        <EditVendorDrawer
          onClose={() => setEditVendor(undefined)}
          id={editVendor}
        />
      )}
      <FormField
        label={label}
        name={name}
        className={className}
        suffix={
          <div className="flex gap-2">
            {vendorId && (
              <Button
                variant="primaryOutline"
                size="xs"
                onClick={() => setEditVendor(vendorId)}
              >
                Edit
              </Button>
            )}
          </div>
        }
      >
        <Select
          disabled={disabled}
          shouldFilter={false}
          onSearch={setSearch}
          allowClear={allowClear}
          createButton={{
            label: "Add vendor",
            onClick: () => {
              setAddVendor(true);
            },
          }}
          isLoading={isLoading}
          placeholder={placeholder}
          icon={Building2}
          options={
            vendors?.vendors.map((vendor) => ({
              label: vendor.name,
              value: vendor.id,
              phone: vendor.contacts?.find((contact) => contact.isPrimary)
                ?.mobile,
            })) || []
          }
          render={(vendor) => (
            <div className="flex flex-col gap-1 pb-1 w-full border-b border-gray-200">
              <p className="text-sm font-semibold">{vendor.label}</p>
              <div className="flex gap-2">
                {!!vendor?.phone && (
                  <div className="flex flex-row gap-1 items-center text-xs">
                    <Phone className="w-3.5 h-3.5 text-gray-500" />
                    {vendor.phone}
                  </div>
                )}
              </div>
            </div>
          )}
        />
      </FormField>
    </>
  );
};

export const ContactInput = ({
  label,
  name,
  onAddModalClose,
  placeholder = "Select client contact",
  clientId,
  vendorId,
  allowClear = false,
  className,
  propertyId,
  disabled = false,
  fetchEnabled = true,
  setDefault = false,
}: {
  disabled?: boolean;
  propertyId?: number;
  className?: string;
  allowClear?: boolean;
  clientId?: number;
  vendorId?: number;
  label?: string;
  name: string;
  placeholder?: string;
  onAddModalClose?: (id?: number) => void;
  fetchEnabled?: boolean;
  setDefault?: boolean;
}) => {
  const { setValue, watch } = useFormContext();
  const contactId = watch(name);
  const isDefaultSet = useRef(false);

  const [addContact, setAddContact] = useState(false);
  const [editContact, setEditContact] = useState<number | undefined>(undefined);
  const [search, setSearch] = useState("");

  const { data, isFetching } = trpc.contacts.list.useQuery(
    {
      search,
      pageNo: 1,
      pageSize: 20,
      clientId,
      propertyId,
      vendorId,
      include: contactId ? [contactId] : undefined,
    },
    {
      enabled: fetchEnabled,
    }
  );

  useEffect(() => {
    if (
      setDefault &&
      data?.contacts &&
      data.contacts.length > 0 &&
      !isDefaultSet.current
    ) {
      isDefaultSet.current = true;
      const primaryContact = data.contacts.find((contact) => contact.isPrimary);
      setValue(name, primaryContact?.id || data.contacts[0].id);
    }
  }, [data]);

  return (
    <>
      <AddContactDrawer
        open={addContact}
        onClose={(id) => {
          setAddContact(false);
          if (onAddModalClose) onAddModalClose(id);
        }}
        defaultValues={{
          clientId,
          propertyId,
          salutation: "Mr",
          vendorId,
        }}
      />
      {!!editContact && (
        <EditContactDrawer
          id={editContact}
          onClose={() => {
            setEditContact(undefined);
          }}
        />
      )}
      <FormField
        label={label}
        name={name}
        className={cn(className)}
        suffix={
          <div>
            {!!contactId && (
              <Button
                size="xs"
                variant="primaryOutline"
                onClick={() => {
                  setEditContact(contactId);
                }}
              >
                Edit
              </Button>
            )}
          </div>
        }
      >
        <Select
          disabled={disabled}
          options={data?.contacts.map((contact) => ({
            label: formatName(contact),
            value: contact.id,
            phone: contact.mobile,
          }))}
          render={(contact) => (
            <div className="flex flex-col gap-1 pb-1 w-full border-b border-gray-200">
              <p className="text-sm font-semibold">{contact.label}</p>
              <div className="flex gap-2">
                {!!contact?.phone && (
                  <div className="flex flex-row gap-1 items-center text-xs">
                    <Phone className="w-3.5 h-3.5 text-gray-500" />
                    {contact.phone}
                  </div>
                )}
              </div>
            </div>
          )}
          iconify="tabler:user"
          onSearch={setSearch}
          shouldFilter={false}
          allowClear={allowClear}
          isLoading={isFetching}
          placeholder={placeholder}
          createButton={{
            label: "Add new contact",
            onClick: () => {
              setAddContact(true);
            },
          }}
        />
      </FormField>
    </>
  );
};

export const MultipleContactsInput = ({
  label,
  name,
  onAddModalClose,
  placeholder = "",
  clientId,
  className,
}: {
  className?: string;
  clientId?: number;
  label?: string;
  name: string;
  placeholder?: string;
  onAddModalClose?: (id?: number) => void;
}) => {
  const { watch } = useFormContext();
  const contactIds: number[] = watch(name);

  const [addContact, setAddContact] = useState(false);
  const [search, setSearch] = useState("");

  const { data } = trpc.contacts.list.useQuery({
    pageNo: 1,
    pageSize: 20,
    search,
    clientId,
    include: contactIds?.length ? contactIds : undefined,
  });

  return (
    <>
      <AddContactDrawer
        open={addContact}
        onClose={(id) => {
          setAddContact(false);
          if (onAddModalClose) onAddModalClose(id);
        }}
      />
      <FormField label={label} name={name} className={cn(className)}>
        <MultiSelect
          onSearch={setSearch}
          icon={Users}
          placeholder={placeholder}
          options={
            data?.contacts?.map((contact) => ({
              label: formatName(contact),
              value: contact.id,
            })) || []
          }
          createButton={{
            label: "Add new contact",
            onClick: () => {
              setAddContact(true);
            },
          }}
        />
      </FormField>
    </>
  );
};

export const SearchInput = ({
  value,
  onChange,
  placeholder,
  className,
  autoFocus,
}: {
  className?: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
}) => {
  const [inputValue, setInputValue] = useState(value);

  const debouncedSearch = useMemo(() => {
    return debounce((searchValue: string) => {
      onChange(searchValue);
    }, 300);
  }, [onChange]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    debouncedSearch(newValue);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      debouncedSearch.cancel();
      onChange(inputValue);
    }
  };

  return (
    <div className={cn("relative w-full sm:max-w-sm", className)}>
      <Input
        className="pl-10 rounded-lg"
        placeholder={placeholder || "Search..."}
        type="search"
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
      />
      <SearchIcon className="absolute left-3 top-1/2 w-5 h-5 transform -translate-y-1/2" />
    </div>
  );
};

interface DropDownTimePickerProps {
  value?: Date | undefined;
  onChange?: (value: Date | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
}

export const DropDownTimePicker: React.FC<DropDownTimePickerProps> = ({
  value,
  onChange,
  placeholder = "Select time",
  disabled,
}) => {
  const formattedValue = value
    ? value.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    : null;
  return (
    <Select
      disabled={disabled}
      placeholder={placeholder}
      value={formattedValue}
      onChange={(updatedValue) => {
        const updatedDate = dayjs(updatedValue, "HH:mm A").toDate();
        if (onChange) onChange(updatedDate);
      }}
      options={Array.from({ length: 96 }, (_, i) => {
        const hour = Math.floor(i / 4);
        const minute = (i % 4) * 15;
        const time = new Date();
        time.setHours(hour);
        time.setMinutes(minute);
        const formattedTime = time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        });
        return {
          label: formattedTime,
          value: formattedTime,
        };
      })}
    />
  );
};
