import dayjs from "dayjs";

const date = {
  start: (date: Date) => {
    return dayjs(date).startOf("day").toDate();
  },
  end: (date: Date) => {
    return dayjs(date).endOf("day").toDate();
  },
  now: () => {
    return dayjs().toDate();
  },
  nowEnd: () => {
    return dayjs().endOf("day").toDate();
  },
  nowStart: () => {
    return dayjs().startOf("day").toDate();
  },
  monthStart: () => {
    return dayjs().startOf("month").toDate();
  },
  monthEnd: () => {
    return dayjs().endOf("month").toDate();
  },
  isDue: (date: Date) => {
    return dayjs().startOf("day").isAfter(dayjs(date).startOf("day"));
  },
  isToday: (date: Date) => {
    return dayjs().isSame(date, "day");
  },
  isThisWeek: (date: Date) => {
    return dayjs().isSame(date, "week");
  },
};

export default date;
