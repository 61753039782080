import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { AddProductDrawer } from "../products/components/addProductDrawer";
import InvoiceForm from "./components/invoice-form";

const InvoiceEdit = () => {
  const params = useParams();
  const invoiceId = Number(params.id);
  const navigate = useNavigate();

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const form = useForm<z.infer<typeof Schemas.sales.invoice>, unknown>({
    resolver: zodResolver(Schemas.sales.invoice),
  });

  const { data: invoiceDetails } = trpc.invoices.details.useQuery(invoiceId, {
    refetchOnWindowFocus: false,
  });
  const { data: invoiceTags } = trpc.tags.list.useQuery({
    type: "INVOICE",
  });

  const invoiceUpdateMutation = trpc.invoices.update.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Invoice updated successfully");
      navigate(-1);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    // check if default values is already set
    if (!form.getValues("clientId") && invoiceDetails) {
      form.reset({
        ...invoiceDetails,
        invoiceTags: invoiceDetails.invoiceTags.map((tag) => tag.tagId),
        projectInvoices: invoiceDetails.projectInvoices,
        salesOrderInvoices: {
          salesOrderId: invoiceDetails.salesOrderInvoices?.salesOrders.id,
        },
        pmLeaseInvoices: {
          pmLeaseId: invoiceDetails.pmLeaseInvoices?.pmLeases.id,
        },
      });
    }
  }, [invoiceDetails, form]);

  if (!invoiceDetails || !invoiceTags) return <FullScreenSpinner />;

  return (
    <Page
      showBack
      className="max-w-screen-lg"
      title="Edit Invoice"
      description="Update invoice details"
      suffix={
        <>
          <ResponsivePrimaryButton
            onClick={() =>
              form.handleSubmit((values) =>
                invoiceUpdateMutation.mutate({
                  id: invoiceId,
                  invoice: values,
                })
              )()
            }
            loading={invoiceUpdateMutation.isLoading}
            variant="primary"
            icon={Save}
          >
            Update Invoice
          </ResponsivePrimaryButton>
        </>
      }
    >
      {showAddProductDialog && (
        <AddProductDrawer
          open={showAddProductDialog}
          onClose={() => setShowAddProductDialog(false)}
        />
      )}
      <Form
        {...form}
        onSubmit={(values) => {
          invoiceUpdateMutation.mutate({
            id: invoiceId,
            invoice: values,
          });
        }}
      >
        <div>
          <InvoiceForm form={form} tags={invoiceTags} edit />
        </div>
      </Form>
    </Page>
  );
};

export default InvoiceEdit;
