import {
  activityStatuses,
  ActivitySummaryModal,
  EditActivityModal,
} from "@/components/actionsModals/activities-modals";
import { EditLogModal } from "@/components/actionsModals/log-modals";
import { EditNotesModal } from "@/components/actionsModals/notes-modals";
import { EditTaskModal } from "@/components/actionsModals/tasks-modals";
import FileIcon from "@/components/file-icon";
import { UserAvatar } from "@/components/UserAvatar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { getS3URLLegacy } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import {
  renderUpdateDescription,
  renderUpdateTitle,
} from "@/helpers/userActivity/render-timeline";
import CircleTick from "@/pages/crm/deals/details/components/circle-tick";
import { historyIcons } from "@/pages/crm/deals/details/history";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { THistory } from "@heffl/server/src/helpers/userActivities/types";
import enums from "@heffl/server/src/schemas/enums";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import RenderHtml from "@heffl/ui/components/render-html";
import {
  cn,
  convertFileSize,
  downloadFile,
  dynamicDateFormatting,
  formatName,
} from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import dayjs from "dayjs";
import { Clock, Pencil, Timer, UserRoundPlus, Users } from "lucide-react";
import mime from "mime-types";
import { capitalize } from "radash";
import { match, P } from "ts-pattern";
import { useImmer } from "use-immer";
import { z } from "zod";
import CommentInput from "./comments/comment-input";

type THistoryType = THistory[];

const ActivityTimelineNew = ({
  activities,
  className,
  entity,
  entityId,
}: {
  activities: THistoryType;
  className?: string;
  entity: z.infer<typeof enums.entities>;
  entityId: number;
}) => {
  const { data: users } = trpc.users.list.useQuery();
  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const [animateParent] = useAutoAnimate();

  const [edit, setEdit] = useImmer<{
    taskId: number | undefined;
    noteId: number | undefined;
    activityId: number | undefined;
    logId: number | undefined;
  }>({
    taskId: undefined,
    noteId: undefined,
    activityId: undefined,
    logId: undefined,
  });
  const [summaryModalOpen, setSummaryModalOpen] = useImmer<
    | {
        open: boolean;
        activity: {
          id: number;
          type: "call" | "meeting" | "todo";
          description: string;
          durationMinutes: number;
        };
      }
    | undefined
  >(undefined);

  const deleteCommentMutation = trpc.comments.delete.useMutation();
  const addCommentMutation = trpc.comments.add.useMutation({
    onError: (error) => {
      heffl.toast.error(error.message);
    },
  });
  const activityUpdateMutation = trpc.activities.update.useMutation();
  const taskUpdateMutation = trpc.tasks.update.useMutation();

  return (
    <>
      {!!edit.noteId && (
        <EditNotesModal
          open={!!edit.noteId}
          onClose={() =>
            setEdit((draft) => {
              draft.noteId = undefined;
            })
          }
          noteId={edit.noteId}
        />
      )}
      {!!edit.activityId && (
        <EditActivityModal
          open={!!edit.activityId}
          onClose={() =>
            setEdit((draft) => {
              draft.activityId = undefined;
            })
          }
          activityId={edit.activityId}
        />
      )}
      {!!edit.taskId && (
        <EditTaskModal
          open={!!edit.taskId}
          onClose={() =>
            setEdit((draft) => {
              draft.taskId = undefined;
            })
          }
          taskId={edit.taskId}
        />
      )}
      {!!edit.logId && (
        <EditLogModal
          open={!!edit.logId}
          onClose={() =>
            setEdit((draft) => {
              draft.logId = undefined;
            })
          }
          activityId={edit.logId}
        />
      )}
      {!!summaryModalOpen && (
        <ActivitySummaryModal
          open={true}
          activity={summaryModalOpen.activity}
          onClose={() => setSummaryModalOpen(undefined)}
        />
      )}

      {currentUser && users && (
        <div className="flex gap-1.5 items-start mt-2 !w-full">
          <UserAvatar user={currentUser} />
          <CommentInput
            className="w-full"
            onSubmit={(value) => {
              addCommentMutation.mutate({
                comment: value,
                type: "TEXT",
                entity: entity,
                entityId: entityId,
              });
            }}
            mentions={users
              .filter((user) => user.id !== currentUser?.id)
              ?.map((user) => ({
                label: heffl.format.name(user),
                value: `USER-${user.id}`,
              }))}
          />
        </div>
      )}
      <div className={cn("flex flex-col", className)} ref={animateParent}>
        {activities.map((history, index) => {
          const user = (
            <div className="flex items-center capitalize">
              <UserRoundPlus className="h-3.5" />
              {history.value.createdBy.firstName +
                " " +
                history.value.createdBy.lastName}
            </div>
          );
          return (
            <div key={index} className="flex flex-row gap-1">
              <div className="flex flex-col items-center">
                <div className="my-0">{historyIcons[history.type]}</div>
                {!(activities.length - 1 === index) && (
                  <div className="h-full min-h-[15px] border-l border-gray-200 border-dashed my-1"></div>
                )}
              </div>
              <div className="w-full">
                {match(history)
                  .with({ type: "task" }, ({ value: task }) => (
                    <div className="relative p-3 bg-gray-100 rounded-md shadow-sm sm:bg-white h-fit">
                      <div className="flex gap-1 items-center">
                        <CircleTick
                          checked={task.status === "COMPLETED"}
                          loading={
                            taskUpdateMutation.isLoading &&
                            taskUpdateMutation.variables?.id === task.id
                          }
                          onClick={() => {
                            taskUpdateMutation.mutate({
                              id: task.id,
                              task: {
                                status:
                                  task.status === "COMPLETED"
                                    ? "OPEN"
                                    : "COMPLETED",
                              },
                            });
                          }}
                        />

                        <p className="text-base font-medium">{task.title}</p>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: task.description || "",
                        }}
                      />
                      <div className="flex flex-col gap-1 mt-1 text-xs text-gray-600 sm:flex-row">
                        {format(task.date, "yyyy-MM-dd") <
                          format(new Date(), "yyyy-MM-dd") &&
                          task.status !== "COMPLETED" && (
                            <Badge className="w-fit" variant="error">
                              Due
                            </Badge>
                          )}
                        {task.taskAssignees.length ? (
                          <div className="flex items-center">
                            <Users className="h-3.5" />
                            {task.taskAssignees
                              .map((user) => formatName(user.users))
                              .join(", ")}
                          </div>
                        ) : null}
                        {user}
                        {task.status === "COMPLETED" && task.completedOn
                          ? `| Completed on ${dynamicDateFormatting(
                              task.completedOn
                            )}`
                          : null}
                      </div>
                      <Button
                        onClick={() => {
                          setEdit((draft) => {
                            draft.taskId = task.id;
                          });
                        }}
                        size="xs"
                        variant="outline"
                        className="absolute top-3 right-3"
                      >
                        Edit <Pencil className="h-3.5" />
                      </Button>
                    </div>
                  ))
                  .with({ type: "note" }, ({ value: note }) => (
                    <div className="relative p-3 bg-yellow-100 rounded-md shadow-sm h-fit">
                      {note.note}
                      <div className="flex gap-0.5 text-xs text-gray-600 mt-1">
                        <p className="flex items-center">
                          <Clock className="h-3.5" />
                          {format(note.createdAt, "hh:mm a")}
                        </p>
                        {user}
                      </div>
                      <Button
                        size="xs"
                        variant="outline"
                        className="absolute top-3 right-3"
                        onClick={() =>
                          setEdit((draft) => {
                            draft.noteId = note.id;
                          })
                        }
                      >
                        Edit <Pencil className="h-3.5" />
                      </Button>
                    </div>
                  ))
                  .with({ type: "event" }, ({ value: event }) => (
                    <div className="flex flex-col">
                      <p className="text-sm">{event.title}</p>
                      <p className="text-sm text-gray-600">{}</p>
                      <div className="flex gap-1 text-xs text-gray-600">
                        <p className="flex gap-1 items-center">
                          <Clock className="h-3.5 w-3.5" />
                          {format(event.date, "hh:mm a")}
                        </p>
                        {user}
                      </div>
                    </div>
                  ))
                  .with(
                    {
                      type: P.union(
                        "email",
                        "meeting",
                        "call",
                        "todo",
                        "whatsapp_message",
                        "sms"
                      ),
                    },
                    ({ value: activity }) => (
                      <div className="relative p-3 bg-gray-100 rounded-md shadow-sm sm:bg-white h-fit">
                        <div className="flex gap-1 items-center">
                          {!activity.isLogged && (
                            <CircleTick
                              checked={activity.completed}
                              loading={
                                activityUpdateMutation.isLoading &&
                                activityUpdateMutation.variables?.id ===
                                  activity.id
                              }
                              onClick={() => {
                                activityUpdateMutation.mutate({
                                  id: activity.id,
                                  activity: {
                                    completed: !activity.completed,
                                    completedOn: null,
                                  },
                                });

                                if (
                                  ["call", "meeting"].includes(activity.type) &&
                                  !activity.completed
                                ) {
                                  setSummaryModalOpen({
                                    open: true,
                                    activity: {
                                      id: activity.id,
                                      type: activity.type as
                                        | "call"
                                        | "todo"
                                        | "meeting",
                                      description: activity.description || "",
                                      durationMinutes: activity.durationMinutes,
                                    },
                                  });
                                }
                              }}
                            />
                          )}
                          <div className="flex gap-2 items-center">
                            <p className="text-base font-medium">
                              {activity.title}
                            </p>
                            {activity.status !== "DONE" &&
                              activity.status !== "TODO" &&
                              (activity.type === "call" ||
                                activity.type === "meeting") && (
                                <Badge
                                  small
                                  className="w-fit"
                                  variant={
                                    activityStatuses.find(
                                      (status) =>
                                        status.value === activity.status
                                    )?.variant
                                  }
                                  icon={
                                    activityStatuses.find(
                                      (status) =>
                                        status.value === activity.status
                                    )?.icon
                                  }
                                >
                                  {capitalize(
                                    activity.status.split("_").join(" ")
                                  )}
                                </Badge>
                              )}
                          </div>
                        </div>
                        <RenderHtml className="text-sm text-gray-600">
                          {activity.description}
                        </RenderHtml>

                        <div className="flex flex-col gap-1 mt-1 text-xs text-gray-600 sm:flex-row">
                          {format(activity.date, "yyyy-MM-dd") <
                            format(new Date(), "yyyy-MM-dd") &&
                            !activity.completed && (
                              <Badge className="w-fit" variant="error">
                                Due
                              </Badge>
                            )}
                          <Tooltip content="Start time">
                            <p className="flex gap-1 items-center">
                              <Clock className="h-3.5 w-3.5" />
                              {format(activity.startDate, "hh:mm a")}
                            </p>
                          </Tooltip>
                          {activity.durationMinutes > 0 && (
                            <Tooltip content="Duration minutes">
                              <p className="flex gap-1 items-center">
                                <Timer className="h-3.5 w-3.5" />
                                {activity.durationMinutes} mins
                              </p>
                            </Tooltip>
                          )}
                          {activity.activityAttendees.length ? (
                            <Tooltip content="Contacts associated">
                              <div className="flex gap-1 items-center">
                                <Users className="h-3.5 w-3.5 text-green-500" />
                                {activity.activityAttendees
                                  .map((user) => formatName(user.contacts))
                                  .join(", ")}
                              </div>
                            </Tooltip>
                          ) : null}
                          {activity.activityAssignees.length ? (
                            <Tooltip content="Assigned to">
                              <div className="flex gap-1 items-center">
                                <Users className="h-3.5 w-3.5" />
                                {activity.activityAssignees
                                  .map((user) => formatName(user.users))
                                  .join(", ")}
                              </div>
                            </Tooltip>
                          ) : null}
                          <Tooltip content="Created by">{user}</Tooltip>
                          {activity.completed && activity.completedOn
                            ? `| Completed on ${dynamicDateFormatting(
                                activity.completedOn
                              )}`
                            : null}
                        </div>
                        <Button
                          onClick={() => {
                            setEdit((draft) => {
                              if (activity.isLogged) {
                                draft.logId = activity.id;
                              } else {
                                draft.activityId = activity.id;
                              }
                            });
                          }}
                          size="xs"
                          variant="outline"
                          className="absolute top-3 right-3"
                        >
                          Edit <Pencil className="h-3.5" />
                        </Button>
                      </div>
                    )
                  )
                  .with({ type: "comment" }, ({ value: comment }) => (
                    <div className="flex relative flex-col gap-1 p-2 mb-2 bg-gray-100 rounded-md border shadow-sm sm:bg-white h-fit">
                      {comment.createdByUserId === currentUser?.id && (
                        <Button
                          onClick={() =>
                            heffl.modal.confirm({
                              title:
                                "Are you sure you want to delete this comment?",
                              mainIconify: "tabler:trash",
                              description: "",
                              variant: "destructive",
                              onConfirm() {
                                deleteCommentMutation.mutate(comment.id);
                              },
                            })
                          }
                          loading={
                            deleteCommentMutation.isLoading &&
                            deleteCommentMutation.variables === comment.id
                          }
                          iconify="tabler:trash"
                          size="sm"
                          variant="ghost"
                          className="absolute top-2 right-2"
                        />
                      )}
                      {!!comment.commentFiles.length &&
                        match(comment.type)
                          .with("IMAGE", () => (
                            <img
                              src={getS3URLLegacy(
                                "leads/comments",
                                comment.commentFiles[0].link
                              )}
                              alt="Comment Image"
                              className="max-w-full h-auto sm:w-96"
                            />
                          ))
                          .with("FILE", () => (
                            <div className="flex gap-4 items-center p-2 rounded-md border w-fit">
                              <FileIcon
                                ext={
                                  mime.extension(
                                    comment.commentFiles[0].format || ""
                                  ) || ""
                                }
                                className="w-12 h-12 border shadow-sm"
                              />
                              <div className="flex flex-col justify-between h-11">
                                <p>{comment.commentFiles[0].name}</p>
                                <div className="flex gap-2 items-center">
                                  <p className="text-xs text-gray-500">
                                    {convertFileSize(
                                      comment.commentFiles[0].size
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                          .otherwise(() => null)}
                      {!!comment.commentFiles.length && (
                        <div className="flex gap-2 items-center">
                          <Button
                            onClick={() => {
                              downloadFile(
                                comment.commentFiles[0].name,
                                getS3URLLegacy(
                                  "leads/comments",
                                  comment.commentFiles[0].link
                                )
                              );
                            }}
                            variant="link"
                            size="xs"
                          >
                            Download
                          </Button>
                          <Button
                            onClick={() => {
                              window.open(
                                getS3URLLegacy(
                                  "leads/comments",
                                  comment.commentFiles[0].link
                                ),
                                "_blank"
                              );
                            }}
                            variant="link"
                            size="xs"
                          >
                            Open
                          </Button>
                        </div>
                      )}
                      <RenderHtml
                        className="pr-8"
                        cssStyles={`  .mention {
    background-color: #fdf4ff;
    border-radius: 0.25rem;
    padding: 0.125rem 0.25rem;
    font-weight: 500;
    color: #9333ea;
    &:hover {
      background-color: #cbd5e1;
    }
      margin-right: 1px;
  }`}
                      >
                        {comment.comment}
                      </RenderHtml>
                      <div className="flex gap-1 items-center mt-1">
                        <UserAvatar user={comment.createdBy} size="xxs" />
                        <p className="!text-xs">
                          {heffl.format.name(comment.createdBy)}
                        </p>
                        <p className="!text-xs text-gray-600">
                          · {dayjs(comment.createdAt).fromNow()}
                        </p>
                      </div>
                    </div>
                  ))
                  .with({ type: "entityActivity" }, ({ value: update }) => (
                    <div className="flex flex-col pl-2">
                      <p className="text-sm">{renderUpdateTitle(update)}</p>
                      {update.updates.length > 1 && (
                        <p className="pl-4 text-sm text-gray-600">
                          {renderUpdateDescription(update)}
                        </p>
                      )}
                    </div>
                  ))
                  .exhaustive()}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ActivityTimelineNew;
