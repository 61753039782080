import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Badge } from "@heffl/ui/components/primitives/badge";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import dayjs from "dayjs";
import { Building2, MapPin } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddPropertyModal } from "./property-modal";

const ListProperties = () => {
  const navigate = useNavigate();

  const [addPropertyModal, setAddPropertyModal] = useState(false);
  const [filters, setFilters] = useParamsState<
    RouterInputs["propertyManagement"]["properties"]["list"]
  >({
    search: "",
  });

  const { data, isLoading } = trpc.propertyManagement.properties.list.useQuery(
    filters,
    {
      keepPreviousData: true,
    }
  );

  if (!data) return <FullScreenSpinner />;

  return (
    <Page title="Properties" fullWidth className="sm:!p-0">
      <AddPropertyModal
        open={addPropertyModal}
        onClose={() => setAddPropertyModal(false)}
      />

      <div className="flex flex-row justify-between border-gray-200 sm:p-3 sm:border-b">
        <SearchInput
          value={filters.search || ""}
          onChange={(v: string) =>
            setFilters({
              search: v,
            })
          }
          placeholder="Search properties..."
        />
        <ResponsiveActionButton
          onClick={() => setAddPropertyModal(true)}
          text="Property"
        />
      </div>

      <DataGrid
        name="propertyManagementProperties"
        label="Properties"
        loading={isLoading}
        className="h-[calc(100vh-190px)] mt-12"
        rows={data || []}
        rowKey="id"
        onCellClick={({ row }) =>
          navigate(`/property-management/properties/details/${row.id}`)
        }
        columns={[
          {
            key: "name",
            name: "Name",
            width: 200,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <Building2 className="w-4 h-4" />
                <span>{row.name}</span>
              </div>
            ),
          },
          {
            key: "address",
            name: "Address",
            width: 250,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <MapPin className="w-4 h-4" />
                <span>{row.address}</span>
              </div>
            ),
          },
          {
            key: "city",
            name: "City",
            width: 150,
            renderCell: ({ row }) => <div>{row.city}</div>,
          },
          {
            key: "state",
            name: "State",
            width: 150,
            renderCell: ({ row }) => <div>{row.countryStates.name}</div>,
          },
          {
            key: "units",
            name: "Units",
            width: 100,
            renderCell: ({ row }) => (
              <Badge variant="default" iconify="tabler:key">
                {row.pmPropertyUnits.length} Units
              </Badge>
            ),
          },
          {
            key: "leases",
            name: "Active Leases",
            width: 120,
            renderCell: ({ row }) => (
              <Badge variant="success" iconify="tabler:contract">
                {row.pmPropertyUnits.reduce(
                  (acc: number, unit) => acc + unit._count.pmLeases,
                  0
                )}{" "}
                Leases
              </Badge>
            ),
          },
          {
            key: "createdAt",
            name: "Created At",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.date(dayjs(row.createdAt).toDate())}</div>
            ),
          },
        ]}
        empty={{
          title: "No properties found",
          icon: Building2,
          description: "Add your first property to get started",
          actionText: "Add property",
          onAction: () => navigate("/property-management/properties/add"),
        }}
      />
    </Page>
  );
};

export default ListProperties;
