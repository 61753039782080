import DocumentEditor from "@/components/document-editor";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import EditableInput from "@heffl/ui/components/primitives/editable-input";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronLeft, Pencil, Save } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useBeforeUnload, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

const EditDocument = () => {
  const navigate = useNavigate();
  const params = useParams();
  const documentId = Number(params.id);

  const form = useForm<z.infer<typeof Schemas.documents.document>>({
    resolver: zodResolver(Schemas.documents.document),
  });

  const { data: document } = trpc.documents.details.useQuery(documentId);

  const name = form.watch("name");

  useBeforeUnload((event) => {
    event.preventDefault();
    return (event.returnValue =
      "You have unsaved changes. Are you sure you want to leave?");
  });

  const handleNavigation = (to: number | string) => {
    const confirmed = window.confirm(
      "You have unsaved changes. Are you sure you want to leave?"
    );
    if (confirmed) {
      typeof to === "number" ? navigate(to) : navigate(to);
    }
  };

  useEffect(() => {
    const handlePopState = (e: PopStateEvent) => {
      e.preventDefault();
      handleNavigation(-1);
    };

    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  const updateDocumentMutation = trpc.documents.update.useMutation({
    onSuccess: () => {
      if (window.history.length > 1) {
        toast.success("Document updated successfully");
        navigate(-1);
      } else {
        navigate("/documents");
      }
    },
  });

  useEffect(() => {
    if (document) {
      form.reset({ ...document });
    }
  }, [document]);

  if (!document) {
    return <FullScreenSpinner />;
  }

  return (
    <Form
      {...form}
      onSubmit={(values) => {
        updateDocumentMutation.mutate({
          id: documentId,
          document: values,
        });
      }}
      className="gap-0"
    >
      <div className="flex justify-between p-3 px-5">
        <div className="flex gap-3 items-center text-lg font-medium">
          <Button
            className="p-0 !w-8 !h-8 rounded-full shrink-0"
            variant="outline"
            onClick={() => {
              if (window.history.length > 1) {
                handleNavigation(-1);
              } else {
                handleNavigation("/documents");
              }
            }}
          >
            <ChevronLeft className="h-5" />
          </Button>
          <FormField name="name">
            <EditableInput
              className="w-96"
              display={({ doEdit }) => (
                <div className="flex gap-2 items-center">
                  <p className="text-lg font-medium">{name}</p>
                  <Button
                    onClick={() => doEdit(true)}
                    variant="ghost"
                    icon={Pencil}
                  />
                </div>
              )}
            />
          </FormField>
          <Badge>{document.documentTemplates.name}</Badge>
        </div>
        <Button
          type="submit"
          icon={Save}
          variant="primary"
          loading={updateDocumentMutation.isLoading}
        >
          Save document
        </Button>
      </div>
      <FormField name="contentHtml">
        <DocumentEditor
          height={window.innerHeight - 73}
          documentId={documentId}
          fsJobId={document.fsJobId}
        />
      </FormField>
    </Form>
  );
};

export default EditDocument;
