import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import { Form } from "@heffl/ui/components/primitives/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import useQueryParams from "@/helpers/useQuery";
import useTeam from "@/lib/hooks/useTeam";
import { calculateMultiplierTotal } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import { Badge } from "@heffl/ui/components/primitives/badge";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import Modal from "@heffl/ui/components/primitives/modal";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { formatValue } from "@heffl/ui/lib/utils";
import axios from "axios";
import dayjs from "dayjs";
import {
  Building2,
  CalendarDays,
  Clock,
  Hammer,
  MapPin,
  Plus,
  UserCircle,
} from "lucide-react";
import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RRule } from "rrule";
import { z } from "zod";
import { getJobName } from "../schedules/calendar";
import { joinDateAndTime } from "../schedules/components/schduleDetailsModal";
import JobForm, { jobSchema } from "./components/job-form";

export const confirmScheduleProps = (values: {
  startDate: Date;
  startTime: Date;
  endDate: Date;
  endTime: Date;
  schedulesOverlapping: RouterOutputs["fieldService"]["schedules"]["checkUserSchedules"];
}) => {
  return {
    actionVariant: "primary" as const,
    title: "Are you sure you want to add this job?",
    body: (
      <div className="flex flex-col">
        <p>
          This job at{" "}
          {dayjs(joinDateAndTime(values.startDate, values.startTime)).format(
            "DD/MM/YY hh:mm A"
          )}{" "}
          -{" "}
          {dayjs(
            joinDateAndTime(values.endDate || values.startDate, values.endTime)
          ).format("DD/MM/YY hh:mm A")}{" "}
          overlaps with the following schedules:
        </p>
        <div className="flex flex-col gap-2 mt-4">
          {values.schedulesOverlapping.map((schedule) => (
            <div key={schedule.id} className="p-3 space-y-1 rounded-lg border">
              <p className="text-lg font-medium text-black">
                {getJobName(schedule.fsJobs.fsJobServices)}
              </p>
              <div className="flex gap-1 items-center text-red-600">
                <Clock className="h-4" />
                <p className="text-sm">
                  {dayjs(schedule.startDate).format("DD/MM/YY hh:mm A")}
                  {schedule.endDate &&
                  !dayjs(schedule.startDate).isSame(schedule.endDate, "day")
                    ? ` - ${dayjs(schedule.endDate).format("DD/MM/YY hh:mm A")}`
                    : ` - ${dayjs(schedule.endDate).format("hh:mm A")}`}
                </p>
              </div>
              <div className="flex gap-2">
                <span className="flex items-center font-medium cursor-pointer hover:underline hover:text-primary-500">
                  <Building2 className="h-4 text-red-500" />
                  <p className="text-sm text-gray-700">
                    {schedule.fsJobs.fsProperties.clients.name}
                  </p>
                </span>

                <span className="flex items-center font-medium">
                  <MapPin className="h-4 text-blue-500" />
                  <p className="text-sm text-gray-700">
                    {formatValue.propertyName(schedule.fsJobs.fsProperties)}
                  </p>
                </span>
              </div>
              <div className="flex gap-2">
                {schedule.fsScheduleAssignees.map((assignee) => (
                  <Badge key={assignee.users.id} icon={UserCircle}>
                    {assignee.users.firstName} {assignee.users.lastName}
                  </Badge>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
  };
};

const AddJob = ({
  modal = false,
  onSuccess,
  defaultValues,
}: {
  modal?: boolean;
  onSuccess?: (id: number) => void;
  defaultValues?: Partial<z.infer<typeof jobSchema>>;
}) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const quotationId = queryParams.get("quotationId");
  const duplicateJobId = queryParams.get("duplicate");

  const [showJobTypeModal, setShowJobTypeModal] = useState(false);

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess: (data) => {
        console.log("data", data);
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const form = useForm<z.infer<typeof jobSchema>>({
    resolver: zodResolver(jobSchema),
    defaultValues: {
      startDate: new Date(),
      contractDate: new Date(),
      fsSchedulePricingType: "SCHEDULE",
      type: "ONEOFF",
      fsSchedules: [],
      frequencyRule: new RRule({
        freq: RRule.DAILY,
        bymonthday: [],
        tzid: "Asia/Dubai",
      }).toString(),
      files: {
        fs_job_lpo: {
          new: [],
          deleted: [],
          existing: [],
        },
      },
      ...defaultValues,
    },
  });

  const { data: convertQuotation } = trpc.quotations.details.useQuery(
    Number(quotationId),
    {
      enabled: !!quotationId,
    }
  );

  const { data: duplicateJob } = trpc.fieldService.jobs.details.useQuery(
    Number(duplicateJobId),
    {
      enabled: !!duplicateJobId,
    }
  );

  const team = useTeam();
  const jobSettings = team?.settings.fsJobs;

  const jobAddMutation = trpc.fieldService.jobs.add.useMutation({
    onSuccess: ({ id }) => {
      form.reset();
      toast.success("Succesfully added job.");
      !modal && navigate(`/field-service/jobs/details/${id}`);
      onSuccess && onSuccess(id);
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = async (values: z.infer<typeof jobSchema>) => {
    // extra validations
    if (
      jobSettings?.requiredFields.paymentMethodId &&
      !values.paymentMethodId
    ) {
      form.setError("paymentMethodId", {
        message: "Payment method is required",
      });
      return;
    }
    if (jobSettings?.requiredFields.lpoNumber && !values.lpoNumber) {
      form.setError("lpoNumber", {
        message: "LPO number is required",
      });
      return;
    }

    let frequencyRule = values.frequencyRule;
    if (
      values.type === "ONEOFF" &&
      (!values.endDate || dayjs(values.startDate).isSame(values.endDate))
    ) {
      frequencyRule = "RRULE:FREQ=DAILY";
    }

    const presignedUrls =
      values.files && values.files.fs_job_lpo.new.length
        ? await getPresignedUrls({
            files: values.files.fs_job_lpo.new,
            section: "field-service/jobs",
          })
        : [];

    jobAddMutation.mutate({
      ...values,
      frequencyRule,
      files: {
        fs_job_lpo: {
          new: presignedUrls,
          deleted: [],
        },
      },
    });
    await Promise.all(
      presignedUrls.map(async (file, index) => {
        const fileToUpload = values?.files?.fs_job_lpo.new[index].file;
        await axios.put(file.presignedUrl, fileToUpload, {
          headers: {
            "Content-Type": file.format,
          },
        });
      })
    );
  };

  useEffect(() => {
    if (convertQuotation) {
      form.setValue(
        "fsJobServices",
        convertQuotation.quotationProducts.map((product) => ({
          ...product,
          quantity:
            calculateMultiplierTotal({
              customFieldFields:
                convertQuotation.documentTemplates.customFields,
              customFieldValues: product.customFields,
            }) * product.quantity,
          uuid: nanoid(),
        }))
      );
      form.setValue("salesPersonId", convertQuotation.salesPersonId);
      form.setValue("fsJobQuotations", [convertQuotation.id]);
      form.setValue("discount", convertQuotation.discount);
      form.setValue("internalNotes", convertQuotation.internalNotes);
      if (convertQuotation.deals?.vendorId) {
        form.setValue("vendorId", convertQuotation.deals.vendorId);
      }
    }
  }, [convertQuotation]);

  useEffect(() => {
    if (duplicateJob) {
      form.reset({
        ...form.getValues(),
        contactPersonId: duplicateJob.contactPersonId,
        fsJobServices: duplicateJob.fsJobServices,
        discount: duplicateJob.discount,
        salesPersonId: duplicateJob.salesPersonId,
        type: duplicateJob.type,
        status: duplicateJob.status,
        startDate: duplicateJob.startDate,
        endDate: duplicateJob.endDate,
        frequencyRule: duplicateJob.frequencyRule,
        startTime: duplicateJob.startTime,
        endTime: duplicateJob.endTime,
        fsJobTags: duplicateJob.fsJobTags.map((tag) => tag.tagId),
        internalNotes: duplicateJob.internalNotes,
        fsPropertyId: duplicateJob.fsPropertyId,
        instructions: duplicateJob.instructions,
        fsSchedulePricingType: duplicateJob.fsSchedulePricingType,
        paymentMethodId: duplicateJob.paymentMethodId,
        paymentsCount: duplicateJob.paymentsCount,
        siteContactPersonId: duplicateJob.siteContactPersonId,
        vendorCharge: duplicateJob.vendorCharge,
        vendorId: duplicateJob.vendorId,
      });
      console.log("duplicated Job");
    }
  }, [duplicateJob]);

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...form.getValues(),
        ...defaultValues,
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    if (jobSettings) {
      setShowJobTypeModal(jobSettings.showJobTypeModalOnAdd);
    }
  }, [jobSettings]);

  if (!team) {
    return <FullScreenSpinner />;
  }

  return (
    <Page
      showBack={!modal}
      showHome={!modal}
      showAccount={!modal}
      title="Add job"
      description="Add jobs to your client."
      className="bg-gray-100"
      suffix={
        <div className="flex gap-2">
          <ResponsivePrimaryButton
            onClick={() => form.handleSubmit(onSubmit)()}
            variant="primary"
            loading={jobAddMutation.isLoading}
            icon={Plus}
          >
            Add Job
          </ResponsivePrimaryButton>
        </div>
      }
      pageTitleParentClassName={!modal ? undefined : "mt-2"}
    >
      <Modal
        open={showJobTypeModal}
        onClose={() => setShowJobTypeModal(false)}
        title="Select job type"
      >
        <div className="grid grid-cols-2 gap-4 p-4">
          <button
            onClick={() => {
              form.setValue("type", "ONEOFF");
              setShowJobTypeModal(false);
            }}
            className="flex flex-col justify-center items-center p-6 space-y-3 text-gray-600 bg-white rounded-lg border-2 border-gray-200 transition-colors hover:border-primary-500 hover:text-primary-600"
          >
            <Hammer className="w-12 h-12" />
            <span className="font-medium">One-off Job</span>
            <p className="text-sm text-gray-500">Single occurrence job</p>
          </button>

          <button
            onClick={() => {
              form.setValue("type", "CONTRACT");
              setShowJobTypeModal(false);
            }}
            className="flex flex-col justify-center items-center p-6 space-y-3 text-gray-600 bg-white rounded-lg border-2 border-gray-200 transition-colors hover:border-primary-500 hover:text-primary-600"
          >
            <CalendarDays className="w-12 h-12" />
            <span className="font-medium">Contract Job</span>
            <p className="text-sm text-gray-500">Repeating schedule job</p>
          </button>
        </div>
      </Modal>
      <Form {...form} onSubmit={onSubmit}>
        <JobForm
          form={form}
          isDefaultValuesSet={true}
          defaultClientId={convertQuotation?.clientId}
          completedSchedulesCount={0}
        />
      </Form>
    </Page>
  );
};

export default AddJob;
