import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { Badge } from "@heffl/ui/components/primitives/badge";
import EmptyScreen from "@heffl/ui/components/primitives/empty-screen";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";
import { cn } from "@heffl/ui/lib/utils";
import { Building2 } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { rrulestr } from "rrule";
import { match } from "ts-pattern";

const RecurringProjectReport = () => {
  const [filters, setFilters] = useParamsState<
    RouterInputs["projects"]["reports"]["recurringProjectReport"]
  >({
    startDate: undefined,
    clients: undefined,
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data, isLoading } =
    trpc.projects.reports.recurringProjectReport.useQuery(filters);

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageSize: 30,
  });

  return (
    <Page title="Recurring Project Report">
      <FilterBar
        className="mb-4"
        filters={[
          {
            key: "startDate",
            type: "date-range",
            label: "Start Date",
            value: filters.startDate,
            onChange: (value) => setFilters({ ...filters, startDate: value }),
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients",
            value: filters.clients,
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
            onSearch: (value) => setClientSearch(value),
            onChange: (value) => setFilters({ ...filters, clients: value }),
          },
        ]}
      />

      {match({
        isLoading,
        data,
      })
        .with({ isLoading: false, data: { recurringProjects: [] } }, () => (
          <EmptyScreen title="No recurring projects found" />
        ))
        .with({ isLoading: false }, () => (
          <SimpleTable
            idKey="id"
            height={635}
            rows={data?.recurringProjects || []}
            expandable={{
              expandedRowRender: (row) => {
                return (
                  <div className="w-fit">
                    <SimpleTable
                      className="!w-[1100px]"
                      rows={row.projects || []}
                      idKey="title"
                      height={300}
                      columns={[
                        {
                          label: "Project",
                          width: 300,
                          render: (row) => {
                            const total =
                              row.stats.todo +
                              row.stats.inProgress +
                              row.stats.onHold +
                              row.stats.completed;
                            return (
                              <div className="flex flex-col gap-2">
                                <div className="flex-1">
                                  <h4 className="text-sm font-semibold text-gray-900 truncate">
                                    <Link
                                      to={`/projects/details/${row.id}`}
                                      className="hover:text-primary"
                                    >
                                      {row.title}
                                    </Link>
                                  </h4>
                                  <p className="text-xs text-gray-500">
                                    {heffl.format.dynamicDate(row.startDate)}
                                    {" - "}
                                    {row.endDate
                                      ? heffl.format.dynamicDate(row.endDate)
                                      : "No end date"}
                                  </p>
                                  <div className="flex gap-1 items-center text-xs text-gray-500">
                                    <Building2 className="w-3.5 h-3.5" />
                                    {row.clients ? (
                                      <Link
                                        to={`/sales/clients/details/${row.clients.id}`}
                                        className="hover:text-primary"
                                      >
                                        {row.clients.name}
                                      </Link>
                                    ) : (
                                      <span>No client assigned</span>
                                    )}
                                  </div>
                                </div>
                                <div className="w-24">
                                  <div className="flex h-1.5 rounded-full overflow-hidden bg-gray-100">
                                    {[
                                      {
                                        value: row.stats.todo,
                                        color: "bg-yellow-500",
                                      },
                                      {
                                        value: row.stats.inProgress,
                                        color: "bg-blue-500",
                                      },
                                      {
                                        value: row.stats.onHold,
                                        color: "bg-orange-500",
                                      },
                                      {
                                        value: row.stats.completed,
                                        color: "bg-emerald-500",
                                      },
                                    ].map(
                                      (stat, i) =>
                                        stat.value > 0 && (
                                          <div
                                            key={i}
                                            className={`h-full ${stat.color}`}
                                            style={{
                                              width: `${
                                                (stat.value / total) * 100
                                              }%`,
                                            }}
                                          />
                                        )
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        },
                        {
                          label: "Open",
                          render: (row) => (
                            <span className="text-gray-500">
                              {row.stats.todo}
                            </span>
                          ),
                          width: 50,
                        },
                        {
                          label: "In Progress",
                          render: (row) => (
                            <span className="text-blue-500">
                              {row.stats.inProgress}
                            </span>
                          ),
                          width: 50,
                        },
                        {
                          label: "On Hold",
                          render: (row) => (
                            <span className="text-orange-500">
                              {row.stats.onHold}
                            </span>
                          ),
                          width: 50,
                        },
                        {
                          label: "Completed",
                          render: (row) => (
                            <span className="text-emerald-500">
                              {row.stats.completed}
                            </span>
                          ),
                          width: 50,
                        },
                        {
                          label: "Total",
                          render: (row) => {
                            const total =
                              row.stats.todo +
                              row.stats.inProgress +
                              row.stats.onHold +
                              row.stats.completed;
                            return <span className="font-medium">{total}</span>;
                          },
                          width: 50,
                        },
                        {
                          label: "Completion",
                          render: (row) => {
                            const total =
                              row.stats.todo +
                              row.stats.inProgress +
                              row.stats.onHold +
                              row.stats.completed;
                            const completionRate =
                              total > 0
                                ? Math.round(
                                    (row.stats.completed / total) * 100
                                  )
                                : 0;
                            return (
                              <span
                                className={cn(
                                  "font-medium",
                                  completionRate >= 75
                                    ? "text-emerald-500"
                                    : completionRate >= 50
                                    ? "text-blue-500"
                                    : completionRate >= 25
                                    ? "text-orange-500"
                                    : "text-gray-500"
                                )}
                              >
                                {completionRate}%
                              </span>
                            );
                          },
                          width: 50,
                        },
                      ]}
                    />
                  </div>
                );
              },
            }}
            columns={[
              {
                label: "Title",
                key: "projectTitle",
                width: 300,
                render: (row) => (
                  <Link
                    className="text-sm font-medium cursor-pointer hover:underline hover:text-primary"
                    to={`/projects/recurring-projects/details/${row.id}`}
                  >
                    {row.projectTitle}
                  </Link>
                ),
              },
              {
                label: "Frequency",
                key: "recurringRule",
                render: (row) => {
                  return heffl.format.capitalize(
                    rrulestr(row.recurringRule).toText()
                  );
                },
              },
              {
                label: "Client",
                key: "clients",
                render: (row) => {
                  if (!row.clients) return "-";
                  return (
                    <Badge variant="outline" iconify="tabler:building">
                      {row.clients.name}
                    </Badge>
                  );
                },
              },
              {
                label: "Start Date",
                key: "recurringStartDate",
                render: (row) => {
                  return heffl.format.date(row.recurringStartDate);
                },
              },
              {
                label: "End Date",
                key: "recurringEndDate",
                render: (row) => {
                  return row.recurringEndDate
                    ? heffl.format.date(row.recurringEndDate)
                    : "-";
                },
              },
              {
                label: "Created At",
                key: "createdAt",
                render: (row) => {
                  return heffl.format.dynamicDate(row.createdAt, true);
                },
              },
            ]}
          />
        ))
        .otherwise(() => (
          <FullScreenSpinner />
        ))}
    </Page>
  );
};

export default RecurringProjectReport;
