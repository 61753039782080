import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { styled } from "goober";
import { Crop, Trash2, Upload } from "lucide-react";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { Editor as TinyMCEEditor } from "tinymce";
import { tinyMCEkeys } from "../../../../packages/ui/components/primitives/RichTextEditor";
import { getS3URL } from "../helpers/s3Helpers";
import { trpc } from "../helpers/trpc";
import FilePicker from "./FilePicker";
import ImageCropper from "./ImageCropper";
import { nanoid } from "nanoid";
import heffl from "@/helpers/hefflHelpers/heffl";
import enums from "@heffl/server/src/schemas/enums";
import { z } from "zod";

interface Props {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
  height?: number;
  dynamicTags?: {
    title: string;
    menu: {
      value: string;
      title: string;
    }[];
  }[];
  documentId: number;
  fsJobId: number | null;
}

const EdittorStyled = styled("div")`
  .tox-editor-header {
    background-color: #f9fafb !important;
  }
  .tox-toolbar__primary {
    background-color: #f9fafb !important;
  }
`;

const tinyConfig = {
  menubar: false,
  branding: false,
  toolbar_sticky: true,
  content_style: `
    body { background: #fff; }
    .mce-toolbar-grp {
      background-color: #000 !important;
      background-image: none !important;
    }
    @media (min-width: 840px) {
      html {
        background: #eceef4;
        min-height: 100%;
        padding: 0 0.5rem;
      }
      body {
        background-color: #fff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        margin: 1rem auto 0;
        max-width: 820px;
        padding: 1rem;
      }
    }
  `,
  formats: {
    h1: { block: "h1", styles: { fontSize: "32px" } },
    h2: { block: "h2", styles: { fontSize: "24px" } },
    h3: { block: "h3", styles: { fontSize: "18.72px" } },
    p: { block: "p" },
  },
  line_height_formats: "0.5 0.75 1 1.2 1.4 1.6 2",
  style_formats: [
    { title: "Heading 1", format: "h1" },
    { title: "Heading 2", format: "h2" },
    { title: "Paragraph", format: "p" },
  ],
  plugins:
    "anchor autolink image lists media searchreplace table visualblocks wordcount code pagebreak hr",
  toolbar:
    "undo redo | fontfamily | styles fontsizeinput forecolor lineheight | bold italic underline strikethrough | gallery table | align | numlist bullist code pagebreak hr",

  pagebreak_separator:
    "<div style='page-break-before: always; clear:both'></div>",
  automatic_uploads: true,
  images_reuse_filename: false,
};

interface ImageType {
  id: number;
  name: string;
  section: z.infer<typeof enums.S3Folders>;
  link: string;
  entity: string;
}

const DocumentEditor = ({
  onChange,
  value,
  disabled = false,
  height = 800,
  documentId,
  fsJobId,
}: Props) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const [showGallery, setShowGallery] = useState(false);
  const [editorContent, setEditorContent] = useState(value || "");
  const [cropImageData, setCropImageData] = useState<{
    file?: File;
    previewUrl: string;
    name?: string;
  } | null>(null);

  const { data: images } = trpc.documents.listImages.useQuery({
    documentId,
    fsJobId: fsJobId,
  });

  const uploadImageMutation = trpc.documents.uploadImage.useMutation();
  const deleteImageMutation = trpc.files.delete.useMutation();

  const insertImage = (url: string) => {
    const imageHtml = `<img src="${url}" alt="" width="50%" />`;

    if (editorRef.current) {
      const editor = editorRef.current;
      const cursorPosition = editor.selection.getNode();

      if (cursorPosition) {
        editor.execCommand("mceInsertContent", false, imageHtml);
        editor.selection.select(cursorPosition);
        editor.selection.collapse(false);
      } else {
        editor.setContent(editor.getContent() + imageHtml);
      }

      const newContent = editor.getContent();
      setEditorContent(newContent);
      if (onChange) {
        onChange(newContent);
      }
    }
  };

  const handleImageUpload = async (files: FileList) => {
    if (files[0]) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setCropImageData({
          file,
          previewUrl: reader.result as string,
          name: file.name,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleExistingImageCrop = (imageUrl: string, name: string) => {
    setCropImageData({
      previewUrl: imageUrl,
      name: `cropped-${name}`,
    });
  };

  const handleCroppedImage = async (croppedImageBase64: string) => {
    try {
      // Convert base64 to blob
      const response = await fetch(croppedImageBase64);
      const blob = await response.blob();
      const file = new File(
        [blob],
        cropImageData?.name ? cropImageData.name : `cropped-${nanoid()}.jpg`,
        { type: "image/jpeg" }
      );

      const { presignedUrl, s3FileName } =
        await uploadImageMutation.mutateAsync({
          file: {
            name: file.name,
            format: file.type,
            size: file.size,
          },
          documentId,
        });

      // Modified axios PUT request
      await axios.put(presignedUrl, blob, {
        headers: {
          "Content-Type": file.type,
          "Access-Control-Allow-Origin": "*", // Add CORS header
        },
        // Add timeout and maxBodyLength settings
        timeout: 30000,
        maxBodyLength: Infinity,
      });

      const imageUrl = getS3URL({
        section: "documents/images",
        link: s3FileName,
      });

      setShowGallery(false);
      insertImage(imageUrl);
      setCropImageData(null);
      toast.success("Image uploaded successfully");
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Failed to upload image");
      setCropImageData(null);
    }
  };

  const renderImageItem = (image: ImageType) => {
    const imageUrl = getS3URL({
      section: image.section,
      link: image.link,
    });
    return (
      <div
        key={image.id}
        className="relative rounded border border-gray-100 cursor-pointer group aspect-video hover:border-blue-500"
      >
        <img
          src={imageUrl}
          alt={image.name}
          className="object-cover w-full h-full rounded transition-opacity group-hover:opacity-90"
        />
        <div className="flex absolute inset-0 gap-2 justify-center items-center rounded opacity-0 transition-opacity bg-black/40 group-hover:opacity-100">
          <Button
            variant="outline"
            size="sm"
            className="bg-white"
            onClick={() => {
              editorRef.current?.insertContent(
                `<img src="${imageUrl}" alt="${image.name}" />`
              );
              setShowGallery(false);
            }}
          >
            Insert
          </Button>
          <Button
            variant="outline"
            size="sm"
            className="bg-white"
            onClick={() => handleExistingImageCrop(imageUrl, image.name)}
          >
            <Crop className="w-4 h-4" />
          </Button>
          <Button
            variant="outline"
            size="sm"
            className="bg-white"
            iconClassName="text-red-500"
            onClick={() => {
              heffl.modal.confirm({
                title: "Delete Image",
                description: "Are you sure you want to delete this image?",
                onConfirm: () => {
                  deleteImageMutation.mutate({
                    id: image.id,
                    section: image.section,
                  });
                },
              });
            }}
            icon={Trash2}
          />
        </div>
      </div>
    );
  };

  return (
    <EdittorStyled>
      <Editor
        disabled={disabled}
        onInit={(_, editor) => {
          editorRef.current = editor;
          if (value) {
            editor.setContent(value);
          }
        }}
        onEditorChange={(content) => {
          setEditorContent(content);
          if (onChange) onChange(content);
        }}
        value={editorContent}
        apiKey={tinyMCEkeys[Math.floor(Math.random() * tinyMCEkeys.length)]}
        init={{
          ...tinyConfig,
          setup: (editor: TinyMCEEditor) => {
            editor.ui.registry.addButton("gallery", {
              icon: "gallery",
              tooltip: "Image Gallery",
              onAction: () => setShowGallery(true),
            });
          },
          height: `${height}px`,
        }}
      />

      <ModalDrawer
        open={showGallery}
        onClose={() => setShowGallery(false)}
        title="Image Gallery"
        modalClassName="min-w-[800px]"
      >
        <div className="flex flex-col gap-4 p-3">
          <div className="flex justify-end">
            <FilePicker onPick={handleImageUpload} accept="image/*">
              <Button className="w-48" variant="primary" icon={Upload}>
                Upload image
              </Button>
            </FilePicker>
          </div>

          <div className="flex flex-col gap-4">
            <div className="p-4 bg-white rounded-lg border border-gray-200">
              <div className="flex gap-2 items-center mb-4">
                <h3 className="text-base font-medium text-gray-900">
                  Job Images (
                  {images?.filter((img) => img.entity === "FS_JOB").length})
                </h3>
              </div>

              <div className="grid grid-cols-4 gap-2">
                {images
                  ?.filter((img) => img.entity === "FS_JOB")
                  .map((image) => renderImageItem(image as ImageType))}
              </div>
            </div>

            <div className="p-4 bg-white rounded-lg border border-gray-200">
              <div className="flex gap-2 items-center mb-4">
                <h3 className="text-base font-medium text-gray-900">
                  Document Images (
                  {images?.filter((img) => img.entity === "DOCUMENT").length})
                </h3>
              </div>

              <div className="grid grid-cols-4 gap-2">
                {images
                  ?.filter((img) => img.entity === "DOCUMENT")
                  .map((image) => renderImageItem(image as ImageType))}
              </div>
            </div>
          </div>
        </div>
      </ModalDrawer>

      {cropImageData && (
        <ImageCropper
          imageUrl={cropImageData.previewUrl}
          onCrop={handleCroppedImage}
          onClose={() => setCropImageData(null)}
          open={!!cropImageData}
        />
      )}
    </EdittorStyled>
  );
};

export default DocumentEditor;
