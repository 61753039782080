import RegistrationBg from "@/assets/images/registration-bg.jpg";
import { doLogin } from "@/helpers/auth";
import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Label } from "@heffl/ui/components/primitives/label";
import { cn } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRightIcon } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { messageOnWhatsApp } from "../field-service/staff-app/schedules/details";
import { apps } from "../home/apps-list";
import { motion, AnimatePresence } from "framer-motion";

const registerSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email"),
  teamName: z.string().min(1, "Organization name is required"),
  apps: z.array(z.string()),
  password: z.string().optional(),
  confirmPassword: z.string().optional(),
});

const RegisterNew = () => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const form = useForm<z.infer<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      apps: ["CRM", "SALES"],
    },
  });

  const registerTeamMutation = trpc.teams.register.useMutation({
    onSuccess: (data) => {
      doLogin(data);
      toast.success("Logged in successfully, redirecting...");
      navigate("/");
    },
    onError: (error) => {
      toast.error("Something went wrong, please try again");
      console.log(error);
    },
  });

  const appsSelected = form.watch("apps") || [];

  const onSubmit = (values: z.infer<typeof registerSchema>) => {
    if (step === 1) {
      setStep(2);
      return setTimeout(() => form.setFocus("password"), 100);
    } else {
      const password = values.password || "";
      if (password && password.length < 6) {
        return toast.error("Password must be at least 6 characters");
      }
      if (password !== values.confirmPassword) {
        return toast.error("Passwords do not match");
      }
      registerTeamMutation.mutate({
        ...values,
        password,
      });
    }
  };

  return (
    <div className="overflow-hidden relative min-h-screen">
      <motion.div
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{
          duration: 0.8,
          ease: "easeOut",
        }}
        className="absolute inset-0 z-10 bg-[radial-gradient(circle_at_50%_50%,_rgba(255,255,255,0.9),_rgba(255,255,255,0.6))] pointer-events-none"
      />
      <div
        className="absolute inset-0 bg-center bg-cover brightness-95 saturate-150"
        style={{ backgroundImage: `url(${RegistrationBg})` }}
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="absolute inset-0 bg-gradient-to-b from-white/30 to-transparent backdrop-blur-[1px] pointer-events-none"
      />

      <div className="flex relative justify-center items-center px-4 py-8 min-h-screen">
        <motion.div
          initial={{ opacity: 0, scale: 0.98 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.4,
            delay: 0.6,
            ease: "easeOut",
          }}
          className="w-full max-w-[440px] rounded-[16px] transition-all duration-300 bg-gray-100/50 backdrop-blur-sm p-4"
        >
          <motion.div
            layout="position"
            transition={{
              layout: {
                type: "spring",
                stiffness: 150,
                damping: 25,
                mass: 0.8,
                restDelta: 0.01,
              },
            }}
            className="flex flex-col bg-white overflow-hidden shadow-[0_0_0_1px_rgba(0,0,0,0.08)] rounded-[12px] p-4 md:p-6"
          >
            <Form {...form} onSubmit={onSubmit}>
              <AnimatePresence mode="sync">
                {step === 1 ? (
                  <motion.div
                    key="step1"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      opacity: { duration: 0.2 },
                      height: { duration: 0.3 },
                    }}
                    className="flex flex-col gap-2"
                  >
                    <h1 className="text-2xl font-medium text-gray-800 md:text-3xl">
                      Welcome to Heffl
                    </h1>
                    <p className="mb-3 text-sm text-gray-500">
                      Get started with automated workflows and explore the
                      simplicity.
                    </p>
                    <FormField name="name" label="Your Name">
                      <Input placeholder="Enter your name" autoFocus />
                    </FormField>
                    <FormField name="email" label="Email Address">
                      <Input type="email" placeholder="Enter your email" />
                    </FormField>
                    <FormField name="teamName" label="Company Name">
                      <Input placeholder="Enter company name" />
                    </FormField>
                    <Label>Select Apps</Label>
                    <div className="grid grid-cols-3 gap-3 mt-2 sm:grid-cols-4 md:grid-cols-5">
                      {apps
                        .filter(
                          (app) =>
                            ![
                              "EMPLOYEES",
                              "SETTINGS",
                              "TEMPLATES",
                              "FILES",
                            ].includes(app.id)
                        )
                        .map((app) => (
                          <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="flex flex-col gap-1 justify-center items-center font-medium text-gray-700 cursor-pointer"
                            key={app.name}
                            onClick={() => {
                              if (appsSelected.includes(app.id)) {
                                form.setValue(
                                  "apps",
                                  appsSelected.filter(
                                    (appId) => appId !== app.id
                                  )
                                );
                              } else {
                                form.setValue("apps", [
                                  ...appsSelected,
                                  app.id,
                                ]);
                              }
                            }}
                          >
                            <div
                              className={cn(
                                "p-2 text-white rounded-md transition-all duration-200",
                                !appsSelected.includes(app.id) &&
                                  "grayscale opacity-50"
                              )}
                              style={{
                                backgroundColor: app.color,
                              }}
                            >
                              <app.icon className="w-6 h-6 md:w-8 md:h-8" />
                            </div>
                            <p className="text-xs">{app.name}</p>
                          </motion.div>
                        ))}
                    </div>

                    <Button
                      type="submit"
                      variant="primary"
                      className="mt-4 w-full h-11"
                      suffix={<ArrowRightIcon className="ml-2 w-4 h-4" />}
                    >
                      Last step: set a password
                    </Button>
                    <div className="flex flex-wrap gap-2 justify-center items-center mt-3 text-sm text-center text-gray-500">
                      <span>Need help? Contact</span>
                      <a
                        href="mailto:support@heffl.com"
                        className="text-blue-600 hover:underline"
                      >
                        hadi@heffl.com
                      </a>
                      <Button
                        onClick={() => {
                          messageOnWhatsApp(
                            "+971507622104",
                            "Hi, i want to know more about heffl"
                          );
                        }}
                        iconify="logos:whatsapp-icon"
                        variant="ghost"
                        size="md"
                      />
                    </div>
                  </motion.div>
                ) : (
                  <motion.div
                    key="step2"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{
                      opacity: { duration: 0.2 },
                      height: { duration: 0.3 },
                    }}
                    className="flex flex-col gap-2"
                  >
                    <h1 className="text-2xl font-medium text-gray-800 md:text-3xl">
                      Set Your Password
                    </h1>
                    <p className="mb-4 text-sm text-gray-600">
                      Choose a secure password to protect your account
                    </p>
                    <FormField name="password" label="Password">
                      <Input
                        type="password"
                        placeholder="Enter your password"
                        autoFocus
                      />
                    </FormField>
                    <FormField name="confirmPassword" label="Confirm Password">
                      <Input
                        type="password"
                        placeholder="Confirm your password"
                      />
                    </FormField>
                    <Button
                      loading={registerTeamMutation.isLoading}
                      type="submit"
                      variant="primary"
                      className="mt-4 w-full h-11"
                    >
                      Complete Setup
                    </Button>
                    <p className="mt-4 text-sm text-center text-gray-500">
                      By creating an account, you agree to our Terms of Service
                    </p>
                  </motion.div>
                )}
              </AnimatePresence>
            </Form>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default RegisterNew;
