import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@heffl/ui/components/primitives/tabs";
import { cn } from "@heffl/ui/lib/utils";
import { VariantProps } from "@nextui-org/theme";
import { LucideIcon } from "lucide-react";
import ResponsiveActions from "../responsive-actions";
import { buttonVariants } from "./button";
import { Icon as Iconify } from "@iconify/react";

type ResponsiveAction = {
  dropdown?: React.ReactNode;
  hide?: boolean;
  label: string;
  hideLabelOnDesktop?: boolean;
  onClick?: () => void;
  iconClassName?: string;
  buttonClassName?: string;
  icon?: LucideIcon;
  buttonVariant?: VariantProps<typeof buttonVariants>["variant"];
  loading?: boolean;
};

export type TabsProps<T extends string> = {
  value?: T;
  defaultValue?: T;
  onChange?: (value: T) => void;
  contentClassName?: string;
  tabClassName?: string;
  tabParentClassName?: string;
  items: {
    key: T;
    label: string;
    children?: React.ReactNode;
    icon?: LucideIcon;
    iconify?: string;
    count?: number;
    title?: string;
    actions?: ResponsiveAction[];
    disabled?: boolean;
    contentClassName?: string;
  }[];
  className?: string;
  suffix?: React.ReactNode;
};

const StripeTabs = <T extends string>({
  items,
  className,
  value,
  defaultValue,
  onChange,
  contentClassName,
  tabClassName,
  tabParentClassName,
  suffix,
}: TabsProps<T>) => {
  return (
    <Tabs
      value={value}
      onValueChange={(value) => onChange?.(value as T)}
      defaultValue={defaultValue || (items.length ? items[0].key : undefined)}
      className={cn(
        "overflow-x-auto overflow-y-hidden relative mr-auto w-full",
        className
      )}
    >
      <TabsList
        className={cn(
          "justify-between w-full bg-transparent rounded-none border-b",
          tabParentClassName
        )}
      >
        <div className="flex overflow-x-auto gap-1">
          {items.map((item) => (
            <TabsTrigger
              key={item.key}
              value={item.key}
              disabled={item.disabled}
              className={cn(
                "relative group px-1 pb-1.5 text-sm rounded-none border-b-1.5 border-b-transparent bg-transparent  pt-2 text-muted-foreground shadow-none transition-none focus-visible:ring-0 data-[state=active]:border-b-primary data-[state=active]:text-foreground data-[state=active]:shadow-none",
                tabClassName
              )}
            >
              <div
                className={cn(
                  "flex items-center px-1 py-[1.5px] font-normal rounded-md border border-transparent",
                  "group-hover:border-gray-200",
                  "group-data-[state=active]:border-gray-200"
                )}
              >
                {!!item.icon && <item.icon className="mr-1 w-4 h-4" />}
                {!!item.iconify && (
                  <Iconify icon={item.iconify} className="mr-1 w-4 h-4" />
                )}
                {item.label}
                {!!item.count && (
                  <div className="px-1.5 h-4 ml-1 text-primary-800 bg-primary rounded-sm text-white text-[10px] flex items-center justify-center">
                    {item.count}
                  </div>
                )}
              </div>
            </TabsTrigger>
          ))}
        </div>
        {!!suffix && <div className="pr-5 mb-1.5">{suffix}</div>}
      </TabsList>
      {items
        .filter((item) => item.children)
        .map((item) => (
          <TabsContent
            key={item.key}
            value={item.key}
            className={cn(
              "px-2 py-2",
              cn(contentClassName, item.contentClassName)
            )}
          >
            {(!!item.title || !!item.actions?.length) && (
              <div className="flex justify-between pt-2 w-full">
                {!!item.title && (
                  <h3 className="text-lg font-medium">{item.title}</h3>
                )}
                <ResponsiveActions actions={item.actions || []} />
              </div>
            )}
            {item.children}
          </TabsContent>
        ))}
    </Tabs>
  );
};

export default StripeTabs;
