import LogoSmall from "@/assets/images/logo_small.png";
import { trpc } from "@/helpers/trpc";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@heffl/ui/components/primitives/breadcrumb";
import { Button } from "@heffl/ui/components/primitives/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@heffl/ui/components/primitives/popover";
import { cn, isMobile } from "@heffl/ui/lib/utils";
import {
  Bell,
  CheckCheck,
  ChevronLeft,
  CreditCard,
  Home,
  LayoutGrid,
  LogOut,
  Pin,
  ShieldAlert,
  User,
  UserCircleIcon,
} from "lucide-react";
import React, { useCallback, useEffect, useState } from "react";
// @ts-ignore
import notificationSound from "@/assets/notification.mp3";
import { doLogout } from "@/helpers/auth";
import { NotificationData } from "@heffl/server/src/helpers/agenda/notifications";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
// @ts-ignore
import { useRealtime } from "@/lib/hooks/useSocket";
import { getTeamApps } from "@/pages/personal-dashboard";
import useNav from "@/stores/useNav";
import RenderHtml from "@heffl/ui/components/render-html";
import { convert } from "html-to-text";
// @ts-ignore
import useSound from "use-sound";
import DropMenu from "../DropMenu";
import Empty from "../Empty";
import { UserAvatar } from "../UserAvatar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { Badge } from "@heffl/ui/components/primitives/badge";

export interface PageTitleProps {
  title?: string;
  suffix?: React.ReactNode;
  breadcrumbs?: { label?: string; path?: string }[];
  showBack?: boolean;
  showHome?: boolean;
  showAccount?: boolean;
  tags?: React.ReactNode;
  loading?: boolean;
  description?: string;
}

const SHOPDOC_TEAM_ID = 30;

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  suffix,
  showBack = false,
  showHome = true,
  showAccount = true,
  breadcrumbs = [],
  tags,
  description,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { socket } = useRealtime();
  const [isTabVisible, setIsTabVisible] = useState(!document.hidden);

  const [playNotificationSound] = useSound(notificationSound);

  const [notificationType, setNotificationType] = useState("unread");
  const [paymentNotificationType, setPaymentNotificationType] =
    useState("unread");
  const [showNotifications, setShowNotifications] = useState<
    "payment" | "notification" | undefined
  >(undefined);
  const [showAllApps, setShowAllApps] = useState(false);
  const [markLoading, setMarkLoading] = useState<"ALL" | number | undefined>(
    undefined
  );

  const { setSelectedPage } = useNav();

  const { data: currentUser } = trpc.users.currentUser.useQuery();
  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();
  const { data: notifications, refetch: refetchNotifications } =
    trpc.users.notifications.list.useQuery({
      excludeType: ["PAYMENT_RECEIVED"],
      isRead: notificationType === "read",
      take: notificationType === "read" ? 20 : undefined,
    });

  const { data: paymentNotifications, refetch: refetchPaymentNotifications } =
    trpc.users.notifications.list.useQuery({
      type: ["PAYMENT_RECEIVED"],
      isRead: paymentNotificationType === "read",
      take: paymentNotificationType === "read" ? 20 : undefined,
    });

  const markAsReadMutation = trpc.users.notifications.markAsRead.useMutation({
    onSuccess: () => {
      setMarkLoading(undefined);
    },
  });

  // Handle tab visibility changes
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabVisible(!document.hidden);
      if (!document.hidden) {
        // Refetch notifications when tab becomes visible again
        refetchNotifications();
        refetchPaymentNotifications();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [refetchNotifications, refetchPaymentNotifications]);

  const handleNotification = useCallback(
    (data: NotificationData) => {
      if (currentUser && data.recipientIds.includes(currentUser.id)) {
        // Only play sound if tab is visible and not on mobile
        if (!isMobile() && isTabVisible) {
          playNotificationSound();
        }

        // Show toast only if tab is visible
        if (isTabVisible) {
          toast.custom(
            (t) => (
              <div
                className={`${
                  t.visible ? "animate-enter" : "animate-leave"
                } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
              >
                <div
                  className="flex-1 p-4 w-0 cursor-pointer"
                  onClick={() => {
                    data.notification.url &&
                      window.open(data.notification.url, "_blank");
                  }}
                >
                  <div className="flex items-start">
                    <div className="flex-shrink-0 pt-0.5">
                      <div className="p-2 rounded-full">
                        <Bell className="w-6 h-6 text-green-500" />
                      </div>
                    </div>

                    <div className="flex-1 ml-3">
                      <p className="text-sm font-medium text-gray-900">
                        {data.notification.title}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {convert(data.notification.body || "")}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex border-l border-gray-200">
                  <button
                    onClick={() => toast.dismiss(t.id)}
                    className="flex justify-center items-center p-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Close
                  </button>
                  <button
                    onClick={() => {
                      data.notification.url &&
                        window.open(data.notification.url, "_blank");
                    }}
                    className="flex justify-center items-center p-4 text-sm font-medium text-green-600 hover:text-green-500 focus:outline-none focus:ring-2 focus:ring-green-500"
                  >
                    Open
                  </button>
                </div>
              </div>
            ),
            {
              position: "top-right",
            }
          );
        }
        refetchNotifications();
        refetchPaymentNotifications();
      }
    },
    [
      currentUser,
      playNotificationSound,
      refetchNotifications,
      refetchPaymentNotifications,
      isTabVisible,
    ]
  );

  useEffect(() => {
    if (socket && currentUser) {
      socket.on(currentUser.teamId.toString(), handleNotification);
    }

    return () => {
      if (socket && currentUser) {
        socket.off(currentUser.teamId.toString(), handleNotification);
      }
    };
  }, [currentUser, handleNotification, socket]);

  const isAppsList = location.pathname === "/";

  // Update document title when notification counts change
  useEffect(() => {
    const unreadNotifications = notifications?.length || 0;
    const unreadPayments = paymentNotifications?.length || 0;
    const totalUnread = unreadNotifications + unreadPayments;

    const baseTitle = title || "Heffl";
    document.title =
      totalUnread > 0 ? `(${totalUnread}) ${baseTitle}` : baseTitle;

    return () => {
      document.title = baseTitle;
    };
  }, [notifications?.length, paymentNotifications?.length, title]);

  return (
    <div className=" flex gap-y-3 justify-between p-2 w-full border-b -z-20 h-[49px]">
      <div className="flex gap-4">
        <div className="flex gap-3 items-center text-sm">
          {isAppsList ? (
            <div className="flex gap-2 items-center pl-4 cursor-pointer">
              <img src={LogoSmall} alt="Heffl" className="w-4 sm:w-5" />
              <p className="text-lg font-semibold text-gray-800 sm:text-xl sm:font-semibold sm:block">
                Heffl
              </p>
            </div>
          ) : showBack ? (
            <Button
              className="p-0 w-8 h-8 rounded-full"
              variant="outline"
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeft className="h-5" />
            </Button>
          ) : showHome ? (
            <Button
              variant="ghost"
              onClick={() => {
                navigate("/");
              }}
              className="p-0"
            >
              <Home className="h-5 text-gray-500" />
            </Button>
          ) : null}
          <div className="flex flex-col">
            <span className="flex items-center">
              <div>
                <div className="flex gap-1 items-center">
                  <p className="text-sm font-semibold text-gray-800 sm:text-lg line-clamp-1">
                    {title}
                  </p>
                  {tags}
                </div>
                {!!description?.length && (
                  <p className="font-normal text-gray-500 sm:text-xs text-[11px] -mt-1 line-clamp-1">
                    {description}
                  </p>
                )}
              </div>
            </span>
            <Breadcrumb className="-mt-1">
              <BreadcrumbList>
                {breadcrumbs.map((crumb, index) => {
                  const isLast = index === breadcrumbs.length - 1;
                  return (
                    <div
                      className="flex items-center text-[11px]"
                      key={crumb.path}
                    >
                      <BreadcrumbItem
                        onClick={() => crumb?.path && navigate(crumb.path)}
                        className={cn(
                          isLast && "text-gray-800",
                          "cursor-pointer"
                        )}
                      >
                        {crumb.label}
                      </BreadcrumbItem>
                      {!isLast ? <BreadcrumbSeparator /> : null}
                    </div>
                  );
                })}
              </BreadcrumbList>
            </Breadcrumb>
          </div>
        </div>
      </div>

      <div className="flex gap-2 !justify-end sm:pr-3 items-center">
        {/* // asly team id */}
        {teamDetails?.id === 21 && (
          <Badge icon={ShieldAlert} variant="error">
            Your trial ended!
          </Badge>
        )}
        {suffix}
        {currentUser && currentUser.type !== "FIELD_STAFF" && (
          <Popover
            open={showAllApps}
            onOpenChange={() => setShowAllApps(!showAllApps)}
          >
            <PopoverTrigger>
              <Button className="p-0 w-8 h-8 rounded-full">
                <LayoutGrid className="w-5 h-5" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[350px] p-2 bg-gray-100">
              <ScrollArea className="flex flex-col p-1 pt-6 h-96 bg-white rounded-lg">
                <div className="grid grid-cols-3 gap-2">
                  {getTeamApps(
                    teamDetails?.installedApps || [],
                    currentUser.type
                  )
                    .sort((a, b) => {
                      const pinnedApps = currentUser?.pinnedApps || [];
                      const aPinnedIndex = pinnedApps.indexOf(a.id);
                      const bPinnedIndex = pinnedApps.indexOf(b.id);
                      if (aPinnedIndex !== -1 && bPinnedIndex === -1) return -1;
                      if (aPinnedIndex === -1 && bPinnedIndex !== -1) return 1;
                      if (aPinnedIndex !== -1 && bPinnedIndex !== -1) {
                        return aPinnedIndex - bPinnedIndex;
                      }
                      return 0;
                    })
                    .map((app) => (
                      <div
                        key={app.id}
                        className="flex relative flex-col justify-center items-center font-medium text-gray-700 cursor-pointer sm:gap-3"
                        onClick={() => {
                          setSelectedPage(app.path.split("/").pop() || "");
                          navigate(app.path);
                        }}
                      >
                        {currentUser?.pinnedApps?.includes(app.id) && (
                          <Pin className="absolute top-0 left-3 p-0.5 text-white w-4 h-4 rounded-full bg-primary-800" />
                        )}
                        <div
                          className={cn(
                            "p-2.5 w-12 h-12 bg-white rounded-full shadow-md transition-all duration-100 sm:w-12 sm:h-12 hover:scale-110 hover:shadow-lg sm:rounded-2xl"
                          )}
                          style={{
                            backgroundColor: app.color,
                          }}
                        >
                          <app.icon
                            className={cn(
                              "w-full h-full text-white transition-all duration-75 hover:rotate-12"
                            )}
                          />
                        </div>
                        <p className="mt-2 text-xs sm:mt-0">{app.name}</p>
                      </div>
                    ))}
                </div>
              </ScrollArea>
            </PopoverContent>
          </Popover>
        )}

        {!showBack && showAccount && (
          <div className="flex gap-2 items-center">
            <Popover
              onOpenChange={() => {
                setShowNotifications(
                  showNotifications === "payment" ? undefined : "payment"
                );
                setNotificationType("unread");
              }}
              open={showNotifications === "payment"}
            >
              <PopoverTrigger asChild>
                <div className="relative mt-0.5">
                  <Button size="icon" className="w-8 h-8 rounded-full">
                    {paymentNotifications &&
                      paymentNotificationType === "unread" &&
                      paymentNotifications?.length > 0 && (
                        <span className="flex absolute -top-1 -right-1 justify-center items-center p-2 w-3 h-3 text-xs text-white rounded-full bg-primary">
                          {paymentNotifications?.length}
                        </span>
                      )}
                    <CreditCard className="w-5 h-5" />
                  </Button>
                </div>
              </PopoverTrigger>
              <PopoverContent className="sm:w-[400px] w-screen p-0 ">
                <ScrollArea className="flex relative flex-col p-1 pt-3 h-96">
                  <div className="flex sticky top-0 justify-between px-2 pb-2 bg-white">
                    <TabsInput
                      value={paymentNotificationType}
                      onChange={(value) => setPaymentNotificationType(value)}
                      options={[
                        {
                          label: "Unread",
                          value: "unread",
                        },
                        {
                          label: "Read",
                          value: "read",
                        },
                      ]}
                    />
                    {paymentNotificationType === "unread" && (
                      <Button
                        loading={markLoading === "ALL"}
                        variant="link"
                        onClick={() => {
                          setMarkLoading("ALL");
                          markAsReadMutation.mutate({
                            id: paymentNotifications?.map((n) => n.id) || [],
                          });
                        }}
                      >
                        Mark all as read
                      </Button>
                    )}
                  </div>
                  {paymentNotifications?.length === 0 && (
                    <Empty
                      icon={CreditCard}
                      title="No payments"
                      description="You don't have any new payments"
                    />
                  )}
                  {paymentNotifications?.map((notification) => (
                    <div
                      key={notification.id}
                      className="flex gap-4 p-3 pb-2 w-full rounded border-b cursor-pointer hover:bg-gray-100"
                    >
                      <div className="flex justify-center items-center p-2 w-10 h-10">
                        <Bell className="w-5 h-5" />
                      </div>
                      <div className="w-full">
                        <p
                          className="font-medium hover:text-primary hover:underline"
                          onClick={() => {
                            setShowNotifications(undefined);
                            notification.url && navigate(notification.url);
                          }}
                        >
                          {notification.title}
                        </p>
                        <RenderHtml className="text-gray-500">
                          {convert(notification.body || "")}
                        </RenderHtml>
                        <div className="flex justify-between items-center">
                          <p className="text-gray-500">
                            {dayjs().to(dayjs(notification.createdAt))}
                          </p>
                          <div className="flex">
                            {!!notification.url && (
                              <Button
                                size="sm"
                                variant="link"
                                onClick={() => {
                                  setShowNotifications(undefined);
                                  notification.url &&
                                    navigate(notification.url);
                                }}
                              >
                                Open
                              </Button>
                            )}
                            {notificationType === "unread" && (
                              <Button
                                size="sm"
                                variant="link"
                                icon={CheckCheck}
                                loading={markLoading === notification.id}
                                onClick={() => {
                                  setMarkLoading(notification.id);
                                  markAsReadMutation.mutate({
                                    id: [notification.id],
                                  });
                                }}
                              >
                                Mark read
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </ScrollArea>
              </PopoverContent>
            </Popover>
            <Popover
              onOpenChange={() => {
                setShowNotifications(
                  showNotifications === "notification"
                    ? undefined
                    : "notification"
                );
                setNotificationType("unread");
              }}
              open={showNotifications === "notification"}
            >
              <PopoverTrigger asChild>
                <div className="relative mt-0.5">
                  <Button size="icon" className="w-8 h-8 rounded-full">
                    {notifications &&
                      notificationType === "unread" &&
                      notifications?.length > 0 && (
                        <span className="flex absolute -top-1 -right-1 justify-center items-center p-2 w-3 h-3 text-xs text-white rounded-full bg-primary">
                          {notifications?.length}
                        </span>
                      )}
                    <Bell className="w-5 h-5" />
                  </Button>
                </div>
              </PopoverTrigger>
              <PopoverContent className="sm:w-[400px] w-screen p-0 ">
                <ScrollArea className="flex relative flex-col p-1 pt-3 h-96">
                  <div className="flex sticky top-0 justify-between px-2 pb-2 bg-white">
                    <TabsInput
                      value={notificationType}
                      onChange={(value) => setNotificationType(value)}
                      options={[
                        {
                          label: "Unread",
                          value: "unread",
                        },
                        {
                          label: "Read",
                          value: "read",
                        },
                      ]}
                    />
                    {notificationType === "unread" && (
                      <Button
                        loading={markLoading === "ALL"}
                        variant="link"
                        onClick={() => {
                          setMarkLoading("ALL");
                          markAsReadMutation.mutate({
                            id: notifications?.map((n) => n.id) || [],
                          });
                        }}
                      >
                        Mark all as read
                      </Button>
                    )}
                  </div>
                  {notifications?.length === 0 && (
                    <Empty
                      icon={Bell}
                      title="No notifications"
                      description="You don't have any notifications"
                    />
                  )}
                  {notifications?.map((notification) => (
                    <div
                      key={notification.id}
                      className="flex gap-4 p-3 pb-2 w-full rounded border-b cursor-pointer hover:bg-gray-100"
                    >
                      <div className="flex justify-center items-center p-2 w-10 h-10">
                        <Bell className="w-5 h-5" />
                      </div>
                      <div className="w-full">
                        <p
                          className="font-medium hover:text-primary hover:underline"
                          onClick={() => {
                            setShowNotifications(undefined);
                            notification.url && navigate(notification.url);
                          }}
                        >
                          {notification.title}
                        </p>
                        <RenderHtml className="text-gray-500">
                          {convert(notification.body || "")}
                        </RenderHtml>
                        <div className="flex justify-between items-center">
                          <p className="text-gray-500">
                            {dayjs().to(dayjs(notification.createdAt))}
                          </p>
                          <div className="flex">
                            {!!notification.url && (
                              <Button
                                size="sm"
                                variant="link"
                                onClick={() => {
                                  setShowNotifications(undefined);
                                  notification.url &&
                                    navigate(notification.url);
                                }}
                              >
                                Open
                              </Button>
                            )}
                            {notificationType === "unread" && (
                              <Button
                                size="sm"
                                variant="link"
                                icon={CheckCheck}
                                loading={markLoading === notification.id}
                                onClick={() => {
                                  setMarkLoading(notification.id);
                                  markAsReadMutation.mutate({
                                    id: [notification.id],
                                  });
                                }}
                              >
                                Mark read
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </ScrollArea>
              </PopoverContent>
            </Popover>
          </div>
        )}
        {currentUser && !showBack && showAccount && (
          <DropMenu
            className=""
            items={[
              {
                onClick: () => navigate("/settings/profile"),
                label: "Profile",
                icon: User,
              },
              {
                label: "Logout",
                onClick: async () => {
                  doLogout();
                  navigate("/login");
                },
                icon: LogOut,
              },
            ]}
          >
            {currentUser.teamId === SHOPDOC_TEAM_ID ? (
              <div className="flex gap-2 items-center p-2 rounded-xl border transition-colors hover:border-primary-500">
                <img
                  src="https://shopdoc.heffl.site/shopdoc.png"
                  className="object-contain w-24"
                  alt="ShopDoc Logo"
                />
                <p className="flex gap-1 items-center text-xs text-gray-600">
                  <UserCircleIcon className="w-4 h-4" />
                  {heffl.format.name(currentUser)}
                </p>
              </div>
            ) : (
              <UserAvatar user={currentUser} size="sm" hideToast />
            )}
          </DropMenu>
        )}
      </div>
    </div>
  );
};

export default PageTitle;
