import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Button } from "@heffl/ui/components/primitives/button";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Icon as Iconify } from "@iconify/react";
import { cn } from "@heffl/ui/lib/utils";
export type ConfirmModalProps = {
  title?: string;
  description?: string;
  confirmIconify?: string;
  mainIconify?: string;
  onClose?: () => void;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  variant?: "primary" | "destructive";
};

export const ConfirmModal = NiceModal.create(
  ({
    title = "Confirm Action",
    description = "Are you sure you want to continue?",
    onClose,
    onConfirm,
    confirmText = "Confirm",
    cancelText = "Cancel",
    variant = "primary",
    confirmIconify,
    mainIconify,
  }: ConfirmModalProps) => {
    const modal = useModal();

    const onModalClose = () => {
      onClose?.();
      modal.hide();
    };

    const handleConfirm = () => {
      onConfirm();
      onModalClose();
    };

    return (
      <ModalDrawer
        open={modal.visible}
        modalClassName="max-w-sm"
        onClose={() => modal.hide()}
        footer={
          <div className="flex gap-3 w-full">
            <Button
              variant="outline"
              className="w-full hover:bg-gray-50"
              onClick={onModalClose}
            >
              {cancelText}
            </Button>
            <Button
              className="w-full"
              variant={variant}
              onClick={handleConfirm}
              iconify={confirmIconify}
            >
              {confirmText}
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2 items-center px-8 text-center">
          {mainIconify && (
            <div className="flex justify-center items-center w-16 h-16 bg-gray-50 rounded-full">
              <Iconify
                icon={mainIconify}
                className={cn("w-12 h-12", {
                  "text-red-500": variant === "destructive",
                  "text-primary": variant !== "destructive",
                })}
              />
            </div>
          )}
          <h3 className="text-lg font-medium">{title}</h3>
          <p className="max-w-sm text-sm text-center text-gray-600">
            {description}
          </p>
        </div>
      </ModalDrawer>
    );
  }
);
