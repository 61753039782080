import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Plus } from "lucide-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import BillForm from "./bill-form";
import dayjs from "dayjs";
import heffl from "@/helpers/hefflHelpers/heffl";
import useQueryParams from "@/helpers/useQuery";
import { useEffect } from "react";

const BillAdd = () => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const invoiceId = queryParams.get("invoiceId");

  const form = useForm<z.infer<typeof Schemas.purchases.bill>, unknown>({
    resolver: zodResolver(Schemas.purchases.bill),
    defaultValues: {
      date: new Date(),
      dueDate: dayjs().add(30, "day").toDate(),
    },
  });

  const { data: convertInvoice } = trpc.invoices.details.useQuery(
    Number(invoiceId),
    {
      enabled: !!invoiceId,
    }
  );

  const billAddMutation = trpc.purchases.bills.add.useMutation({
    onSuccess: (response) => {
      heffl.toast.success("Bill added successfully");
      navigate(`/purchases/bills/details/${response.id}`);
    },
    onError: (error) => {
      heffl.toast.error(error.message);
    },
  });

  const onSubmit = (values: z.infer<typeof Schemas.purchases.bill>) => {
    billAddMutation.mutate(values);
  };

  useEffect(() => {
    if (convertInvoice) {
      form.setValue(
        "billItems",
        convertInvoice.invoiceProducts
          .filter((item) => item.viewType === "LINE_ITEM")
          .map((item) => ({
            productId: item.productId,
            quantity: item.quantity,
            price: item.buyPrice,
            name: item.name ?? "",
            tax: item.tax,
            viewType: "LINE_ITEM" as const,
          }))
      );
    }
  }, [convertInvoice]);

  return (
    <Page
      title="Add Bill"
      className="max-w-screen-lg"
      showBack
      breadcrumbs={[
        { label: "Bills", path: "/purchases/bills" },
        { label: "Add", path: "/purchases/bills/add" },
      ]}
      suffix={
        <>
          <Button
            onClick={() => form.handleSubmit(onSubmit)()}
            loading={billAddMutation.isLoading}
            variant="primary"
            icon={Plus}
          >
            Add bill
          </Button>
        </>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <div className="flex justify-center px-3 py-5 w-full sm:px-0">
          <BillForm form={form} />
        </div>
      </Form>
    </Page>
  );
};

export default BillAdd;
