import { z } from "zod";
import { reminder, selectableDatePicker } from "./crm";
import enums from "./enums";
import { filesInput } from "./files";
import { lineItemProduct } from "./sales";

const project = z.object({
  projectTemplateId: z.number().nullish(),
  title: z.string().min(1, "Please add a title to project"),
  startDate: z.date(),
  endDate: z.date(),
  pipelineId: z.number(),
  archived: z.boolean().default(false).optional(),
  projectStageId: z.number(),
  dealId: z.number().nullish(),
  contactId: z.number().nullish(),
  clientId: z.number().nullish(),
  projectProfileId: z.number().nullish(),
  description: z.string().nullish(),
  status: z
    .enum(["ACTIVE", "COMPLETED", "CANCELLED"])
    .default("ACTIVE")
    .optional(),
  projectAssignees: z.coerce.number().array().nullish(),
  projectTags: z.coerce.number().array().nullish(),
  customFields: z.any().nullish(),
  cancelReason: z.string().nullish(),
  projectQuotations: z.coerce.number().array().nullish(),
  projectItems: lineItemProduct
    .extend({
      buyPrice: z.coerce.number().default(0),
    })
    .array(),
  salesPersonId: z.number().nullish(),
  discount: z.coerce.number().default(0),
  type: enums.projectTypes,
  frequencyRule: z.string().nullish(),
});

const timesheet = z.object({
  userId: z.number().optional(),
  durationType: enums.timeSheetDurationTypes.default("MINUTES"),
  minutesWorked: z.coerce.number().optional().default(0),
  projectTaskId: z.number(),
  startTime: z.date(),
  endTime: z.date().nullish(),
  timerRunning: z.boolean().default(false),
  notes: z.string().default(""),
  approvalStatus: enums.timeSheetStatusTypes.default("OPEN"),
  billable: z.boolean().default(false),
});

const section = z.object({
  title: z.string(),
  projectId: z.number(),
});

const projectTemplateSubTask = z.object({
  title: z.string().min(1, "Please add a title to task"),
  parentProjectTemplateTaskId: z.number(),
});
const projectTemplateSection = z.object({
  title: z.string(),
  projectTemplateId: z.number(),
});

const projectTemplateTask = z.object({
  position: z.number().default(1),
  title: z.string(),
  description: z.string().nullish(),
  dueDateDays: z.coerce.number().nullish(),
  projectTemplateSectionId: z.number().nullish(),
  projectTemplateTaskAssignees: z.coerce.number().array().optional(),
  projectTemplateId: z.number(),
  projectTemplateSubTasks: projectTemplateSubTask
    .omit({
      parentProjectTemplateTaskId: true,
    })
    .array()
    .optional(),
});

const projectTemplate = z.object({
  name: z.string(),
  description: z.string().nullish(),
  projectPipelineId: z.number(),
});

const projectNote = z.object({
  projectId: z.number(),
  note: z.string(),
});

const projectPipelineStage = z.object({
  stageType: z.enum(["OPEN", "CLOSED"]),
  closedStageType: z.enum(["WON", "LOST"]).nullish(),
  name: z.string(),
  position: z.coerce.number(),
});

const projectPipeline = z.object({
  name: z.string(),
  singularName: z.string(),
  pluralName: z.string(),
  isActive: z.boolean().default(true),
  projectPipelineStages: projectPipelineStage.array(),
});

const projectSubTask = z.object({
  title: z.string().min(1, "Please add a title to task"),
  status: enums.projectTaskStatusTypes.default("OPEN"),
  parentTaskId: z.number(),
});

const task = z.object({
  title: z.string().min(1, "Please add a title to task"),
  description: z.string().nullish(),
  status: enums.projectTaskStatusTypes.default("OPEN"),
  date: z.date().nullish(),
  priority: enums.taskPriority.default("MEDIUM"),
  projectTaskAssignees: z.array(z.coerce.number()).optional(),
  projectId: z.number(),
  parentTaskId: z.number().nullish(),
  cancelReason: z.string().nullish(),
  projectSectionId: z.number().nullish(),
  reminders: reminder.nullish(),
  projectTaskTags: z.coerce.number().array().nullish(),
  files: filesInput.nullish(),
  durationMinutes: z.coerce.number().default(0),
  approvalStatus: enums.projectApprovalStatus.default("OPEN"),
  approvalRequired: z.boolean().default(false),
  projectTaskProfileId: z.number().nullish(),
});

const projectTask = task.extend({
  subTasks: task
    .extend({
      taskProfile: z
        .object({
          recurringRule: z
            .string()
            .min(1, "Please select a frequency")
            .nullish(),
        })
        .nullish(),
    })
    .array()
    .nullish(),
  taskProfile: z
    .object({
      recurringRule: z.string().min(1, "Please select a frequency").nullish(),
    })
    .nullish(),
});

const projectActivity = z.object({
  type: enums.activityTypes,
  priority: z.enum(["LOW", "MEDIUM", "HIGH"]).nullish(),
  title: z.string(),
  description: z.string().nullish(),
  projectActivityAssignees: z.coerce.number().array().nullish(),
  startDate: z.date(),
  endDate: selectableDatePicker,
  projectActivityAttendees: z.coerce.number().array().nullish(),
  pipelineStageId: z.number().nullish(),
  projectId: z.number(),
  completed: z.boolean().default(false),
  completedOn: z.date().nullish(),
  reminders: reminder.nullish(),
});

const projectTemplateActivity = z.object({
  type: enums.activityTypes,
  priority: z.enum(["LOW", "MEDIUM", "HIGH"]).nullish(),
  title: z.string(),
  description: z.string().nullish(),
  projectTemplateActivityAssignees: z.coerce.number().array().optional(),
  startDateDays: z.coerce.number(),
  projectPipelineStageId: z.number().nullish(),
  projectTemplateId: z.number(),
  duration: z.string(),
});

export default {
  timesheet,
  project,
  projectPipeline,
  projectPipelineStage,
  projectTask,
  projectActivity,
  projectSubTask,
  projectNote,
  projectTemplate,
  projectTemplateTask,
  projectTemplateActivity,
  section,
  projectTemplateSection,
};
