import {
  ClientInput,
  ContactInput,
  UserInput,
} from "@/components/FormComponents";

import LineItemSelectorNew from "@/components/line-item-selector-new";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import useTeam from "@/lib/hooks/useTeam";
import FrequencySelector from "@/pages/field-service/jobs/components/job-frequency-selector";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import Schemas from "@heffl/server/src/schemas";
import { Button } from "@heffl/ui/components/primitives/button";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import Select from "@heffl/ui/components/primitives/select";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import NumberFlow from "@number-flow/react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

export const RecurringInvoiceForm = ({
  form,
  edit = false,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.sales.recurringInvoice>, unknown>;
  edit?: boolean;
}) => {
  const templateId = form.watch("templateId");
  const clientId = form.watch("clientId");
  const lineItems = form.watch("recurringInvoiceProducts");
  const discount = form.watch("discount");
  const startDate = form.watch("startDate");

  const team = useTeam();
  const qp = useQueryParams().all();

  const [projectSearch, setProjectSearch] = useState("");

  const { data: templates } = trpc.templates.list.useQuery({
    type: "INVOICE",
  });

  const { data: projects, isLoading } = trpc.projects.list.useQuery(
    {
      search: projectSearch,
      clients: clientId ? [clientId] : undefined,
      pageSize: 20,
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: paymentMethods } =
    trpc.invoices.payments.methods.list.useQuery();
  const { data: depositToAccounts } = trpc.books.chartOfAccounts.list.useQuery({
    type: "PAID_THROUGH",
  });

  useEffect(() => {
    if (templates?.length && !edit && !templateId) {
      form.setValue("templateId", templates[0].id);
    }
  }, [templates]);

  useEffect(() => {
    if (qp.projectId) {
      form.setValue("projectId", Number(qp.projectId));
    }
    if (qp.clientId) {
      form.setValue("clientId", Number(qp.clientId));
    }
  }, [qp]);

  const lineItemsValues = calculateLineItems({ lineItems, discount });

  const includeItemsVat = () => {
    // decrease vat of each items
    const updatedLineItems = lineItems.map((item) => {
      const vatDeductedAmount: number = (item.price * 100) / (100 + item.tax);
      return {
        ...item,
        price: Number(vatDeductedAmount.toFixed(2)),
      };
    });
    form.setValue("recurringInvoiceProducts", updatedLineItems, {
      shouldDirty: true,
    });
  };

  if (!paymentMethods || !depositToAccounts || !team || !templates)
    return <FullScreenSpinner />;

  return (
    <div className="space-y-3">
      <div className="grid grid-cols-4 gap-2">
        {!edit && (
          <FormField name="templateId" label="Template">
            <Select
              placeholder="Select invoice template"
              options={templates.map((template) => ({
                label: template.name,
                value: template.id,
              }))}
            />
          </FormField>
        )}
        <FormField name="name" label="Profile name" className="col-span-2">
          <Input placeholder="Enter Invoice Profile Name" />
        </FormField>
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <FormField name="frequencyRule" label="Frequency">
          <FrequencySelector placeholder="Recurring frequency" />
        </FormField>
        <FormField name="startDate" label="Start date">
          <DatePicker />
        </FormField>
        <FormField name="endDate" label="End date">
          <DatePicker
            allowClear
            presets={[
              {
                label: "1 year",
                value: dayjs(startDate).add(364, "day").toDate(),
              },
              {
                label: "6 months",
                value: dayjs(startDate).add(6, "months").toDate(),
              },
              {
                label: "3 months",
                value: dayjs(startDate).add(3, "months").toDate(),
              },
            ]}
          />
        </FormField>
        <FormField name="dueDays" label="Due days">
          <Input type="number" placeholder="Enter due days" />
        </FormField>
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-4">
        <ClientInput
          className="w-full"
          name="clientId"
          label="Client"
          onChange={(id) => {
            if (id) form.setValue("contactId", null);
          }}
          onAddModalClose={(id) =>
            id &&
            form.setValue("clientId", id, {
              shouldDirty: true,
            })
          }
        />
        <ContactInput
          name="contactId"
          label="Contact"
          clientId={clientId}
          fetchEnabled={!!clientId}
          onAddModalClose={(id) => id && form.setValue("contactId", id)}
          disabled={!clientId || !team.user.permissions.CREATE_INVOICES.allowed}
          setDefault={!edit && !!clientId}
          allowClear
        />
        <UserInput
          name="salesPersonId"
          label="Sales person"
          type={["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"]}
          allowClear
        />
        {team.apps.installed("PROJECTS") && (
          <FormField name="projectId" label="Project">
            <Select
              onSearch={setProjectSearch}
              shouldFilter={false}
              allowClear
              isLoading={isLoading}
              placeholder="Select project"
              iconify="tabler:layout-kanban"
              options={
                projects?.projects.map((project) => ({
                  clientName: project.clients?.name,
                  number: project.number,
                  label: project.title,
                  value: project.id,
                })) || []
              }
              render={(project) => (
                <div className="flex flex-col gap-1 pb-1 w-full">
                  <p className="text-sm line-clamp-1">
                    {project.number} - {project.label}
                  </p>
                  <div className="flex gap-1 items-center text-xs text-gray-500">
                    <Icon
                      icon="tabler:building"
                      className="w-3.5 h-3.5 shrink-0"
                    />
                    <p className="line-clamp-1">{project.clientName}</p>
                  </div>
                </div>
              )}
            />
          </FormField>
        )}
      </div>
      <div className="mb-6 !w-full">
        <FormField name="recurringInvoiceProducts" label="" className="!w-full">
          <LineItemSelectorNew type="INVOICE" required={true} isEdit={edit} />
        </FormField>
      </div>
      <div className="flex justify-end mt-3">
        <table className="gap-8 mt-3 text-base border-separate border-spacing-y-2 sm:mt-0">
          <tr>
            <td className="w-52">Subtotal</td>
            <td className="text-right">
              {heffl.format.currency(lineItemsValues.subTotal, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Discount</td>
            <td className="text-right">
              <FormField name="discount">
                <Input
                  type="number"
                  placeholder="0.00"
                  suffix="AED"
                  className="sm:w-[100px]"
                />
              </FormField>
            </td>
          </tr>
          <tr>
            <td className="flex gap-2 items-center w-52">
              VAT
              <Button
                onClick={includeItemsVat}
                size="sm"
                variant="primaryOutline"
                iconify="tabler:plus"
              >
                Include
              </Button>
            </td>
            <td className="text-right">
              {heffl.format.currency(lineItemsValues.totalTax, "AED")}
            </td>
          </tr>
          <tr>
            <td className="w-52">Total</td>
            <td className="font-semibold text-right">
              <NumberFlow
                value={lineItemsValues.total}
                format={{
                  currency: "AED",
                  style: "currency",
                }}
              />
            </td>
          </tr>
        </table>
      </div>

      <FormField name="notes" label="Notes" className="sm:w-1/2">
        <Textarea placeholder="Write notes here..." />
      </FormField>
    </div>
  );
};

const AddRecurringInvoice = () => {
  const team = useTeam();

  const navigate = useNavigate();
  const form = useForm<z.infer<typeof Schemas.sales.recurringInvoice>>({
    resolver: zodResolver(Schemas.sales.recurringInvoice),
    defaultValues: {
      startDate: dayjs().toDate(),
      recurringInvoiceProducts: [],
    },
  });

  const recurringInvoiceAddMutation =
    trpc.sales.recurringInvoices.add.useMutation({
      onSuccess: () => {
        form.reset();
        navigate("/sales/recurring-invoices");
      },
      onError: (error) => {
        heffl.toast.error(error.message);
      },
    });

  console.log(form.formState.errors);
  const onSubmit = (data: z.infer<typeof Schemas.sales.recurringInvoice>) => {
    recurringInvoiceAddMutation.mutate(data);
  };

  useEffect(() => {
    if (!team) return;
    let dueDays = 30;
    if (team && team.settings.invoice.dueDateDays !== undefined) {
      dueDays = team.settings.invoice.dueDateDays;
    }
    form.setValue("dueDays", dueDays);
  }, [team]);

  return (
    <Page
      title="Add Recurring Invoice"
      className="max-w-screen-lg"
      showBack
      breadcrumbs={[
        {
          label: "Recurring Invoices",
          path: "/sales/recurring-invoices",
        },
        {
          label: "Add recurring invoice",
          path: "/sales/recurring-invoices/add",
        },
      ]}
      suffix={
        <div className="flex gap-2 items-center">
          <ResponsivePrimaryButton
            onClick={() => form.handleSubmit(onSubmit)()}
            type="submit"
            variant="primary"
            loading={recurringInvoiceAddMutation.isLoading}
            iconify="tabler:plus"
          >
            Add Recurring Invoice
          </ResponsivePrimaryButton>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <RecurringInvoiceForm form={form} />
      </Form>
    </Page>
  );
};

export default AddRecurringInvoice;
