import DetailsPage from "@/components/details-page";
import Empty from "@/components/Empty";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import SimpleTable from "@heffl/ui/components/simple-table";

import { rruleSchedulesGenerator } from "@/pages/field-service/jobs/components/job-form";
import RenderHtml from "@heffl/ui/components/render-html";
import dayjs from "dayjs";
import {
  Calendar,
  Clock,
  FileText,
  Folder,
  List,
  MoreHorizontal,
  Pencil,
  Plus,
  Power,
  RefreshCcw,
  Trash,
  Type,
  Users,
} from "lucide-react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { rrulestr } from "rrule";
import { EditProjectProfileDrawer } from "./components/EditProjectProfileDrawer";
import {
  AddProjectTaskProfileModal,
  EditProjectTaskProfileModal,
} from "./components/project-task-profile-modals";
import AddProjectDrawer from "../list/components/AddProjectDrawer";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import appIcons from "@heffl/ui/components/appIcons";
import useTeam from "@/lib/hooks/useTeam";
import { ActionsToolbar } from "@heffl/ui/components/actions-toolbar";
const getNextProjectDate = ({
  recurringRule,
  recurringEndDate,
  recurringStartDate,
}: {
  recurringStartDate: Date;
  recurringRule: string;
  recurringEndDate?: Date | null;
}) => {
  const dates = rruleSchedulesGenerator({
    startDate: recurringStartDate,
    endDate: recurringEndDate || dayjs().add(1, "year").toDate(),
    frequencyRule: recurringRule,
  }).filter((date) => dayjs(date).isAfter(dayjs().endOf("day")));
  return dates[0];
};

const ProjectProfileDetails = () => {
  const params = useParams();
  const navigate = useNavigate();

  const profileId = Number(params.id);

  const [editProfile, setEditProfile] = useState(false);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
  const [editTaskId, setEditTaskId] = useState<number | null>(null);
  const [addProjectModal, setAddProjectModal] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);

  const team = useTeam();

  const { data: profile } = trpc.projects.profiles.details.useQuery(profileId);

  const deleteProfileMutation = trpc.projects.profiles.delete.useMutation({
    onSuccess() {
      heffl.toast.success("Recurring project deleted successfully");
      navigate("/projects/recurring-projects/list");
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const deleteProfileTasksMutation =
    trpc.projects.profiles.tasks.delete.useMutation({
      onError(error) {
        heffl.toast.error(error.message);
      },
    });

  const updateProfileMutation = trpc.projects.profiles.update.useMutation({
    onSuccess() {
      heffl.toast.success("Recurring project updated successfully");
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  if (!profile || !team) return <FullScreenSpinner />;

  return (
    <>
      {addTaskModalOpen && (
        <AddProjectTaskProfileModal
          open={addTaskModalOpen}
          onClose={() => setAddTaskModalOpen(false)}
          defaultValues={{
            projectProfileId: profileId,
          }}
        />
      )}

      {editTaskId && (
        <EditProjectTaskProfileModal
          open={true}
          onClose={() => {
            setEditTaskId(null);
          }}
          id={editTaskId}
        />
      )}

      <EditProjectProfileDrawer
        open={editProfile}
        onClose={() => setEditProfile(false)}
        id={profileId}
      />

      {addProjectModal && (
        <AddProjectDrawer
          open={addProjectModal}
          onClose={() => setAddProjectModal(false)}
          pipelineId={profile.projectPipelineId}
          defaultValues={{
            projectProfileId: profileId,
          }}
        />
      )}

      {selectedTasks.length > 0 && (
        <ActionsToolbar
          selectedCount={selectedTasks.length}
          onClear={() => setSelectedTasks([])}
          actions={[
            {
              key: "delete-tasks",
              icon: Trash,
              label: "Delete",
              loading: deleteProfileMutation.isLoading,
              onClick: () => {
                console.log(selectedTasks);
                heffl.modal.confirm({
                  title: "Delete tasks",
                  description:
                    "Are you sure you want to delete the selected tasks?",
                  onConfirm: () => {
                    selectedTasks.forEach((taskId) => {
                      deleteProfileTasksMutation.mutate(Number(taskId));
                    });
                  },
                });
              },
            },
          ]}
        />
      )}

      <DetailsPage
        title={`${profile.number} - ${profile.projectTitle}`}
        description={`Created on ${dayjs(profile.createdAt).format(
          "DD MMM YYYY hh:mm a"
        )}`}
        widgets={[
          <Badge key="frequency" variant="neutral" small icon={RefreshCcw}>
            {heffl.format.capitalize(rrulestr(profile.recurringRule).toText())}
          </Badge>,
          <Badge
            key="status"
            variant={profile.isActive ? "success" : "error"}
            small
          >
            {profile.isActive ? "Active" : "Inactive"}
          </Badge>,
        ]}
        attributes={[
          {
            section: "Details",
            icon: Pencil,
            onClick: () => setEditProfile(true),
            items: [
              {
                label: "Name",
                value: profile.name,
              },
              {
                label: "Status",
                value: (
                  <Badge
                    key="status"
                    variant={profile.isActive ? "success" : "error"}
                    small
                  >
                    {profile.isActive ? "Active" : "Inactive"}
                  </Badge>
                ),
              },
              {
                label: "Frequency",
                value: heffl.format.capitalize(
                  rrulestr(profile.recurringRule).toText()
                ),
              },
              {
                label: "Project Title",
                value: profile.projectTitle,
              },
              {
                label: "Next Date",
                value: getNextProjectDate({
                  recurringStartDate: profile.recurringStartDate,
                  recurringRule: profile.recurringRule,
                  recurringEndDate: profile.recurringEndDate,
                })
                  ? heffl.format.date(
                      getNextProjectDate({
                        recurringStartDate: profile.recurringStartDate,
                        recurringRule: profile.recurringRule,
                        recurringEndDate: profile.recurringEndDate,
                      })
                    )
                  : "No next project date",
              },
              {
                label: "Quotations",
                value: (
                  <div className="flex flex-wrap gap-2">
                    {profile.projectProfileQuotations.length > 0 ? (
                      profile.projectProfileQuotations.map((quotation) => (
                        <Badge
                          key={quotation.quotations.id}
                          variant="unique"
                          small
                          icon={heffl.icons.sales.quotes.icon}
                          className="cursor-pointer"
                          onClick={() => {
                            navigate(
                              `/sales/quotations/details/${quotation.quotations.id}`
                            );
                          }}
                        >
                          #{quotation.quotations.number}
                        </Badge>
                      ))
                    ) : (
                      <p>No quotations linked</p>
                    )}
                  </div>
                ),
              },
              {
                label: "Assignees",
                value: (
                  <div className="flex flex-wrap gap-2">
                    {profile.projectProfileAssignees?.map((assignee) => (
                      <Badge
                        key={
                          assignee.users_projectProfileAssignees_userIdTousers
                            .id
                        }
                        avatar
                        variant="outline"
                      >
                        {heffl.format.name(
                          assignee.users_projectProfileAssignees_userIdTousers
                        )}
                      </Badge>
                    ))}
                  </div>
                ),
              },
              {
                label: "Description",
                value: profile.description || "No description",
              },
              {
                label: "Start Date",
                value: dayjs(profile.recurringStartDate).format("DD/MM/YYYY"),
              },
              {
                label: "End Date",
                value: profile.recurringEndDate
                  ? dayjs(profile.recurringEndDate).format("DD/MM/YYYY")
                  : "No end date",
              },
              {
                label: "Client",
                value: profile.clients?.name || "No client",
              },
              {
                label: "Contact",
                value: profile.contacts
                  ? heffl.format.name(profile.contacts)
                  : "No contact",
              },
            ],
          },
        ]}
        tabs={{
          items: [
            {
              key: "tasks",
              label: "Tasks",
              icon: List,
              count: profile.projectTaskProfiles?.length,
              children: (
                <div className="flex flex-col gap-4">
                  <div className="flex justify-end">
                    <Button
                      variant="primary"
                      size="sm"
                      icon={Plus}
                      onClick={() => setAddTaskModalOpen(true)}
                    >
                      Add task
                    </Button>
                  </div>
                  {!profile.projectTaskProfiles?.length ? (
                    <Empty
                      title="No tasks"
                      description="Add tasks to this project profile"
                      icon={List}
                    />
                  ) : (
                    <SimpleTable
                      rows={profile.projectTaskProfiles}
                      rowSelection={{
                        type: "checkbox",
                        selectedRowKeys: selectedTasks.map((task) =>
                          task.toString()
                        ),
                        onChange: (selectedRowKeys) => {
                          setSelectedTasks(selectedRowKeys.map(Number));
                        },
                      }}
                      idKey="id"
                      height={800}
                      columns={[
                        {
                          label: "Title",
                          key: "title",
                          icon: Type,
                          render: (row) => (
                            <p
                              onClick={() => setEditTaskId(row.id)}
                              className="cursor-pointer hover:underline"
                            >
                              {row.title}
                            </p>
                          ),
                        },
                        {
                          label: "Due days",
                          key: "dueDays",
                          width: 100,
                          icon: Calendar,
                        },
                        {
                          label: "Assignees",
                          key: "projectTaskProfileAssignees",
                          icon: Users,
                          render: (row) => (
                            <div className="flex flex-wrap gap-2">
                              {row.projectTaskProfileAssignees.map(
                                (assignee) => (
                                  <Badge
                                    key={
                                      assignee
                                        .users_projectTaskProfileAssignees_userIdTousers
                                        .id
                                    }
                                    avatar
                                    variant="outline"
                                  >
                                    {heffl.format.name(
                                      assignee.users_projectTaskProfileAssignees_userIdTousers
                                    )}
                                  </Badge>
                                )
                              )}
                            </div>
                          ),
                        },
                        {
                          label: "Description",
                          key: "description",
                          icon: FileText,
                          render: (row) =>
                            row.description ? (
                              <RenderHtml className="line-clamp-1">
                                {row?.description || ""}
                              </RenderHtml>
                            ) : (
                              <span className="text-gray-500">
                                No description
                              </span>
                            ),
                        },
                      ]}
                    />
                  )}
                </div>
              ),
            },
            {
              key: "lineitems",
              label: "Line items",
              icon: appIcons.common.lineItems.icon,
              children: (
                <div>
                  <SimpleTable
                    fixedHeader={false}
                    borderless
                    idKey="id"
                    columns={
                      team.user.permissions.VIEW_PROJECTS_FINANCIALS.allowed
                        ? [
                            {
                              label: "No",
                              render: (_, index) => index + 1,
                              className: "w-10",
                            },
                            {
                              label: "Item",
                              key: "name",
                              className: "w-1/2",
                              render: (row) => (
                                <div className="flex flex-col">
                                  <div className="text-sm font-medium">
                                    {row.name}
                                  </div>
                                  <div className="text-xs text-gray-500">
                                    <RenderHtml>{row.description}</RenderHtml>
                                  </div>
                                </div>
                              ),
                            },
                            {
                              label: "Qty",
                              key: "quantity",
                              className: "w-10",
                            },
                            {
                              label: "Price",
                              render: (row) =>
                                heffl.format.currency(row.price, "AED"),
                              className: "w-10",
                            },
                            {
                              label: "Total",
                              render: (row) =>
                                heffl.format.currency(
                                  row.quantity * row.price,
                                  "AED"
                                ),
                              className: "w-10",
                            },
                          ]
                        : [
                            {
                              label: "No",
                              render: (_, index) => index + 1,
                              className: "w-10",
                            },
                            {
                              label: "Item",
                              key: "name",
                              render: (row) => (
                                <div className="flex flex-col">
                                  <div className="text-sm font-medium">
                                    {row.name}
                                  </div>
                                  <div className="text-xs text-gray-500">
                                    <RenderHtml>{row.description}</RenderHtml>
                                  </div>
                                </div>
                              ),
                              className: "w-1/2",
                            },
                            {
                              label: "Qty",
                              key: "quantity",
                            },
                          ]
                    }
                    rows={profile.projectProfileItems}
                  />
                  {team.user.permissions.VIEW_PROJECTS_FINANCIALS.allowed && (
                    <div className="flex justify-end w-full">
                      <table className="gap-8 border-separate border-spacing-y-2">
                        <tr>
                          <td className="w-52">Subtotal</td>
                          <td className="text-right">
                            {heffl.format.currency(
                              calculateLineItems({
                                lineItems: profile.projectProfileItems,
                                discount: profile.discount,
                              }).subTotal,
                              "AED"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-52">Discount</td>
                          <td className="text-right">
                            {heffl.format.currency(profile.discount, "AED")}
                          </td>
                        </tr>
                        <tr>
                          <td className="flex gap-2 items-center w-52">VAT</td>
                          <td className="text-right">
                            {heffl.format.currency(
                              calculateLineItems({
                                lineItems: profile.projectProfileItems,
                                discount: profile.discount,
                              }).totalTax,
                              "AED"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="w-52">Total</td>
                          <td className="font-semibold text-right">
                            {heffl.format.currency(
                              calculateLineItems({
                                lineItems: profile.projectProfileItems,
                                discount: profile.discount,
                              }).total,
                              "AED"
                            )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  )}
                </div>
              ),
            },
            {
              key: "projects",
              label: "Projects",
              icon: Folder,
              count: profile.projects?.length,
              children: (
                <div className="flex flex-col gap-4">
                  {!profile.projects?.length ? (
                    <Empty
                      title="No projects"
                      description="No projects created from this profile yet"
                      icon={Folder}
                    />
                  ) : (
                    <SimpleTable
                      rows={profile.projects}
                      idKey="id"
                      columns={[
                        {
                          label: "No",
                          key: "number",
                          className: "w-30",
                        },
                        {
                          label: "Title",
                          key: "title",
                          icon: Type,
                          render: (row) => (
                            <Link
                              to={`/projects/details/${row.id}`}
                              className="text-primary hover:underline"
                            >
                              {row.title}
                            </Link>
                          ),
                        },
                        {
                          label: "Start Date",
                          key: "startDate",
                          icon: Calendar,
                          render: (row) => heffl.format.date(row.startDate),
                        },
                        {
                          label: "End Date",
                          key: "endDate",
                          icon: Calendar,
                          render: (row) =>
                            row.endDate
                              ? heffl.format.date(row.endDate)
                              : "No end date",
                        },
                        {
                          label: "Created At",
                          key: "createdAt",
                          icon: Clock,
                          render: (row) =>
                            heffl.format.dynamicDate(row.createdAt, true),
                        },
                      ]}
                    />
                  )}
                </div>
              ),
            },
          ],
        }}
        actions={[
          {
            icon: Plus,
            buttonVariant: "primary",
            label: "Create Project",
            onClick: () => {
              setAddProjectModal(true);
            },
          },
          {
            icon: MoreHorizontal,
            label: "More",
            onClick: () => {},
            dropdown: (
              <>
                <button
                  className="flex items-center gap-2 w-full px-2 py-1.5 text-sm text-left hover:bg-gray-100"
                  onClick={() => setEditProfile(true)}
                >
                  <Pencil className="w-4 h-4" />
                  Edit
                </button>
                <button
                  className="flex items-center gap-2 w-full px-2 py-1.5 text-sm text-left hover:bg-gray-100"
                  onClick={() => {
                    heffl.modal.confirm({
                      title: profile.isActive
                        ? "Stop Recurring Project"
                        : "Resume Recurring Project",
                      description: profile.isActive
                        ? "Are you sure you want to stop this recurring project?"
                        : "Are you sure you want to resume this recurring project?",
                      onConfirm: () => {
                        updateProfileMutation.mutate({
                          id: profileId,
                          projectProfile: {
                            isActive: !profile.isActive,
                          },
                        });
                      },
                    });
                  }}
                >
                  <Power className="w-4 h-4" />
                  {profile.isActive ? "Stop" : "Resume"}
                </button>
                <button
                  className="flex items-center gap-2 w-full px-2 py-1.5 text-sm text-left text-red-500 hover:bg-gray-100"
                  onClick={() => {
                    heffl.modal.confirm({
                      title: "Delete Project Profile",
                      description:
                        "Are you sure you want to delete this project profile?",
                      onConfirm: () => {
                        deleteProfileMutation.mutate(profileId);
                      },
                    });
                  }}
                >
                  <Trash className="w-4 h-4" />
                  Delete
                </button>
              </>
            ),
          },
        ]}
      />
    </>
  );
};

export default ProjectProfileDetails;
