import { pluspointDigitalId } from "@/pages/sales/reports";
import { NavCollapsible, type SidebarData } from "./types";

type AppNav = {
  pathId: string;
  navGroups: SidebarData["navGroups"];
};

type Props = {
  projectDashboards: NavCollapsible["items"];
  teamId: number;
};

export const appNavs = ({ projectDashboards, teamId }: Props): AppNav[] => [
  {
    pathId: "purchases",
    navGroups: [
      {
        items: [
          {
            icon: "tabler:clipboard-list",
            title: "Purchase orders",
            url: "/purchases/purchase-orders",
          },
          {
            icon: "tabler:cash-banknote",
            title: "Expenses",
            url: "/purchases/expenses",
          },
          {
            icon: "tabler:briefcase",
            title: "Vendors",
            url: "/purchases/vendors",
          },
          { icon: "tabler:receipt", title: "Bills", url: "/purchases/bills" },
          {
            icon: "tabler:cash",
            title: "Payments Made",
            url: "/purchases/payments-made",
          },
        ],
      },
    ],
  },
  {
    pathId: "sales",
    navGroups: [
      {
        title: "",
        items: [
          {
            icon: "tabler:chart-pie",
            title: "Dashboard",
            url: "/sales/dashboard",
          },
          {
            icon: "tabler:file-text",
            title: "Quotations",
            url: "/sales/quotations",
          },
          {
            icon: "tabler:currency-dollar",
            title: "Invoices",
            url: "/sales/invoices",
          },
          {
            icon: "tabler:shopping-cart",
            title: "Sales Orders",
            url: "/sales/sales-orders",
          },
          {
            icon: "tabler:currency-dollar",
            title: "Proforma",
            url: "/sales/proforma-invoices",
          },
          {
            icon: "tabler:cash-banknote",
            title: "Payments",
            url: "/sales/payments",
          },
          { icon: "tabler:building", title: "Clients", url: "/sales/clients" },
          { icon: "tabler:box", title: "Products", url: "/sales/products" },
          {
            icon: "tabler:coin",
            title: "Commissions",
            url: "/sales/commissions",
          },
          {
            icon: "tabler:repeat",
            title: "Recurring Invoices",
            url: "/sales/recurring-invoices",
          },
          { icon: "tabler:chart-bar", title: "Reports", url: "/sales/reports" },
        ],
      },
    ],
  },
  {
    pathId: "books",
    navGroups: [
      {
        items: [
          {
            icon: "tabler:chart-pie",
            title: "Dashboard",
            url: "/books/dashboard",
          },
          {
            icon: "tabler:box",
            title: "Products",
            url: "/books/products",
          },
          {
            icon: "tabler:building-bank",
            title: "Banking",
            url: "/books/banking",
          },
          {
            icon: "tabler:shopping-cart",
            title: "Sales",
            items: [
              {
                icon: "tabler:users",
                title: "Clients",
                url: "/books/sales/clients",
              },
              {
                icon: "tabler:file-text",
                title: "Quotes",
                url: "/books/sales/quotations",
              },
              {
                icon: "tabler:currency-dollar",
                title: "Invoices",
                url: "/books/sales/invoices",
              },
              {
                icon: "tabler:shopping-cart",
                title: "Sales Orders",
                url: "/books/sales/sales-orders",
              },
              {
                icon: "tabler:cash",
                title: "Payments",
                url: "/books/sales/payments",
              },
              {
                icon: "tabler:coin",
                title: "Commissions",
                url: "/books/sales/commissions",
              },
            ],
          },
          {
            icon: "tabler:shopping-bag",
            title: "Purchases",
            items: [
              {
                icon: "tabler:users-group",
                title: "Vendors",
                url: "/books/purchases/vendors",
              },
              {
                icon: "tabler:receipt",
                title: "Expenses",
                url: "/books/purchases/expenses",
              },
              {
                icon: "tabler:cash",
                title: "Payments Made",
                url: "/books/purchases/payments-made",
              },
              {
                icon: "tabler:file-invoice",
                title: "Bills",
                url: "/books/purchases/bills",
              },
              {
                icon: "tabler:clipboard-list",
                title: "Purchase Orders",
                url: "/books/purchases/purchase-orders",
              },
            ],
          },
          {
            icon: "tabler:chart-dots",
            title: "Chart of Accounts",
            url: "/books/chart-of-accounts",
          },
          { icon: "tabler:chart-bar", title: "Reports", url: "/books/reports" },
        ],
      },
    ],
  },
  {
    pathId: "crm",
    navGroups: [
      {
        title: "",
        items: [
          {
            icon: "tabler:chart-pie",
            title: "Dashboard",
            url: "/crm/dashboard",
          },
          {
            icon: "tabler:target",
            title: "Leads",
            url: "/crm/leads/table",
            activeUrl: "/crm/leads",
          },
          {
            icon: "tabler:heart-handshake",
            title: "Deals",
            url: "/crm/deals/board",
            activeUrl: "/crm/deals",
          },
          { icon: "tabler:target-arrow", title: "Goals", url: "/crm/goals" },
          { icon: "tabler:building", title: "Clients", url: "/crm/clients" },
          {
            icon: "tabler:checkbox",
            title: "Tasks",
            url: "/crm/tasks/board",
            activeUrl: "/crm/tasks",
          },
          {
            icon: "tabler:activity",
            title: "Activities",
            url: "/crm/activities/table",
            activeUrl: "/crm/activities",
          },
          {
            icon: "tabler:user-square",
            title: "Contacts",
            url: "/crm/contacts",
          },
          {
            icon: "tabler:chart-bar",
            title: "Reports",
            url: "/crm/reports",
          },
        ],
      },
    ],
  },
  {
    pathId: "projects",
    navGroups: [
      {
        title: "",
        items: [
          // @ts-ignore
          {
            icon: "tabler:chart-pie",
            title: "Dashboard",
            url: "/projects/dashboard",
            onPlusUrl: "/projects/dashboard?addDashboard=true",
            // if other than personal dashboard, show the dashboards
            items: projectDashboards.length > 1 ? projectDashboards : undefined,
          },
          {
            icon: "tabler:checkbox",
            title: "Tasks",
            url: "/projects/tasks/board",
          },
          {
            icon: "tabler:clipboard-list",
            title: "Projects",
            url: "/projects/board",
          },
          ...(teamId !== pluspointDigitalId
            ? [
                {
                  icon: "tabler:folder",
                  title: "Recurring Projects",
                  url: "/projects/recurring-projects/list",
                },
              ]
            : []),
          {
            icon: "tabler:files",
            title: "Templates",
            url: "/projects/templates",
          },
          {
            icon: "tabler:clock",
            title: "Timesheets",
            url: "/projects/timesheets",
          },
          {
            icon: "tabler:chart-bar",
            title: "Reports",
            url: "/projects/reports",
          },
        ],
      },
    ],
  },
  {
    pathId: "field-service",
    navGroups: [
      {
        title: "",
        items: [
          {
            icon: "tabler:chart-pie",
            title: "Dashboard",
            url: "/field-service/dashboard",
          },
          {
            icon: "tabler:calendar",
            title: "Schedules",
            url: "/field-service/schedules/calendar",
            activeUrl: "/field-service/schedules",
          },
          {
            icon: "tabler:tool",
            title: "Jobs",
            url: "/field-service/jobs",
          },
          {
            icon: "tabler:chart-bar",
            title: "Reports",
            url: "/field-service/reports",
          },
        ],
      },
    ],
  },
  {
    pathId: "property-management",
    navGroups: [
      {
        title: "",
        items: [
          {
            icon: "tabler:chart-pie",
            title: "Dashboard",
            url: "/property-management/dashboard",
          },
          {
            icon: "tabler:building",
            title: "Properties",
            url: "/property-management/properties",
          },
          {
            icon: "tabler:contract",
            title: "Leases",
            url: "/property-management/leases",
          },
          {
            icon: "tabler:chart-bar",
            title: "Reports",
            url: "/property-management/reports",
          },
        ],
      },
    ],
  },
  {
    pathId: "settings",
    navGroups: [
      {
        title: "",
        items: [
          {
            icon: "tabler:building",
            title: "Client & Vendor",
            url: "/settings/client-vendor",
          },
          {
            icon: "tabler:file-text",
            title: "Quotation ",
            url: "/settings/quotation",
          },
          {
            icon: "tabler:currency-dollar",
            title: "Invoices",
            url: "/settings/invoices",
          },
          {
            icon: "tabler:coin",
            title: "Commission",
            url: "/settings/commission",
          },
          {
            icon: "tabler:target",
            title: "Leads ",
            url: "/settings/leads",
          },
          {
            icon: "tabler:layout-kanban",
            title: "Projects",
            url: "/settings/projects",
          },
          {
            icon: "tabler:tool",
            title: "Field service",
            url: "/settings/field-service",
          },
          {
            icon: "tabler:shopping-cart",
            title: "Sales orders",
            url: "/settings/sales-orders",
          },
          {
            icon: "tabler:tag",
            title: "Tags",
            url: "/settings/tags",
          },
          {
            icon: "tabler:box",
            title: "Objects",
            url: "/settings/objects",
          },
          {
            icon: "tabler:forms",
            title: "Custom fields",
            url: "/settings/custom-fields",
          },
          {
            icon: "tabler:map-pin",
            title: "Zones",
            url: "/settings/zones",
          },
          {
            icon: "tabler:user-search",
            title: "Sources",
            url: "/settings/sources",
          },
          {
            icon: "tabler:file-x",
            title: "Lost reasons",
            url: "/settings/lost-reasons",
          },
          {
            icon: "tabler:credit-card",
            title: "Payment methods",
            url: "/settings/payment-methods",
          },
          {
            icon: "tabler:file-digit",
            title: "Module numbers",
            url: "/settings/module-numbers",
          },
          {
            icon: "tabler:plug",
            title: "Integrations",
            url: "/settings/integrations",
          },
          {
            icon: "tabler:users",
            title: "Team",
            url: "/settings/team",
          },
        ],
      },
    ],
  },
  {
    pathId: "employees",
    navGroups: [
      {
        title: "",
        items: [
          {
            icon: "tabler:users",
            title: "All",
            url: "/employees/list",
          },
          {
            icon: "tabler:key",
            title: "Permission sets",
            url: "/employees/permission-sets",
          },
        ],
      },
    ],
  },
  {
    pathId: "files",
    navGroups: [
      {
        items: [
          { icon: "tabler:file", title: "Files", url: "/files/list" },
          { icon: "tabler:trash", title: "Trash", url: "/files/trash" },
        ],
      },
    ],
  },
];

export default appNavs;
