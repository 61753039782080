import heffl from "@/helpers/hefflHelpers/heffl";

import dayjs from "dayjs";

import { ManipulateType } from "dayjs";

const parseRelativeDate = (value: {
  value: number;
  timeUnit: "DAYS" | "WEEKS" | "MONTHS" | "YEARS";
  direction: "LAST" | "NEXT";
  includeCurrent: boolean;
}) => {
  if (heffl.is.empty(value)) return undefined;

  const today = dayjs();
  let start = today;
  let end = today;

  if (value.direction === "LAST") {
    start = today.subtract(
      value.value,
      value.timeUnit.toLowerCase() as ManipulateType
    );
    if (!value.includeCurrent) {
      end = end.subtract(1, "day");
    }
  } else if (value.direction === "NEXT") {
    end = today.add(
      value.value,
      value.timeUnit.toLowerCase() as ManipulateType
    );
    if (!value.includeCurrent) {
      start = start.add(1, "day");
    }
  } else if (value.direction === "THIS") {
    start = today.startOf(value.timeUnit.toLowerCase() as ManipulateType);
    end = today.endOf(value.timeUnit.toLowerCase() as ManipulateType);
  }

  return [start.startOf("day").toDate(), end.endOf("day").toDate()];
};

export default parseRelativeDate;
