/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import { cn } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Icon } from "@iconify/react";
import { Columns2, Grid2X2, Square } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import {
  DashboardChartItem,
  dashboardChartItems,
} from "../charts-item-helpers";

type AddChartModalProps = {
  open: boolean;
  onClose: () => void;
  dashboardId: number;
};

const DashBoardItemForm = ({
  form,
  name,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.dashboards.dashboardItem>>;
  name: string;
}) => {
  const [search, setSearch] = useState<Record<string, string>>({});

  const filtersValues = form.watch("filtersValues");

  const selectedChart = dashboardChartItems.find(
    (item) => item.name === name
  ) as DashboardChartItem;

  // Query all the options data based on optionsMeta
  const optionsQueries = selectedChart.filters.reduce<Record<string, any>>(
    (acc, filter) => {
      const queryInput = {
        ...filter.optionsMeta?.queryInput,
        ...(filter.optionsMeta?.hasAsyncSearch && {
          search: search[filter.key],
        }),
      };

      if (filter.optionsMeta?.query) {
        const queryPath = filter.optionsMeta.query;
        // @ts-ignore
        const query = trpc[queryPath].useQuery(queryInput, {
          keepPreviousData: true,
        });
        acc[filter.key] = query;
      }
      return acc;
    },
    {}
  );

  if (Object.values(optionsQueries).some((query) => query.isLoading)) {
    return <FullScreenSpinner />;
  }

  const filters = selectedChart.filters.map((filter) => {
    const optionsQuery = filter.optionsMeta?.query
      ? optionsQueries[filter.key]
      : null;

    const options = optionsQuery?.data
      ? filter.optionsMeta?.parseResult(optionsQuery.data)
      : undefined;

    return {
      ...filter,
      value: filtersValues[filter.key] || undefined,
      onChange: (value: any) => {
        form.setValue("filtersValues", {
          ...filtersValues,
          [filter.key]: value,
        });
      },
      ...(filter.type === "date-range" && {
        type: "relative-date-range" as const,
      }),
      ...(filter.optionsMeta?.hasAsyncSearch && {
        onSearch: (value: string) =>
          setSearch({ ...search, [filter.key]: value }),
      }),
      ...(options && { options }),
    };
  });

  return (
    <>
      <FormField name="title" label="Title">
        <Input placeholder="Enter a title" />
      </FormField>
      <FormField name="width" label="Width">
        <TabsInput
          options={[
            { label: "Quarter width", value: 25, icon: Grid2X2 },
            { label: "Half width", value: 50, icon: Columns2 },
            { label: "Full width", value: 100, icon: Square },
          ]}
        />
      </FormField>
      <FilterBar filters={filters} />
    </>
  );
};

export const AddDashboardItemModal = ({
  open,
  onClose: onCloseRaw,
  dashboardId,
}: AddChartModalProps) => {
  const form = useForm<z.infer<typeof Schemas.dashboards.dashboardItem>>({
    resolver: zodResolver(Schemas.dashboards.dashboardItem),
    defaultValues: {
      dashboardId,
      name: "",
      title: "",
      width: 1,
      filtersValues: {},
    },
  });

  const [selectedChart, setSelectedChart] = useState<DashboardChartItem | null>(
    null
  );

  const onClose = () => {
    form.reset();
    onCloseRaw();
    setTimeout(() => {
      setSelectedChart(null);
    }, 200);
  };

  const addDashboardItemMutation = trpc.dashboards.items.add.useMutation({
    onSuccess: () => {
      heffl.toast.success("Chart added to dashboard");
      onClose();
    },
  });

  const onSubmit = (data: z.infer<typeof Schemas.dashboards.dashboardItem>) => {
    addDashboardItemMutation.mutate(data);
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Add Chart"
      description="Add a chart to your dashboard"
      modalClassName={cn("max-w-4xl", selectedChart && "max-w-lg")}
      footer={
        selectedChart && (
          <Button
            variant="primary"
            onClick={() => form.handleSubmit(onSubmit)()}
            className="w-full"
          >
            Add Chart
          </Button>
        )
      }
    >
      {!selectedChart && (
        <div className="flex flex-col gap-4">
          {/* <SearchInput
            autoFocus
            placeholder="Search charts"
            className="!w-full !max-w-none"
            value={chartSearch}
            onChange={(value) => setChartSearch(value)}
          /> */}
          <div className="flex flex-col gap-2">
            <div className="grid grid-cols-3 gap-3">
              {dashboardChartItems.map((item) => (
                <div
                  key={item.name}
                  className="flex flex-col gap-2 p-3 rounded-lg border border-gray-200 cursor-pointer"
                  onClick={() => {
                    setSelectedChart(item);
                    form.setValue("title", item.title);
                    form.setValue("name", item.name);
                    form.setValue("width", item.defaultWidth);
                  }}
                >
                  <div className="mb-4 text-base font-medium">{item.title}</div>
                  {item.mainChartType === "pie" && (
                    <div className="flex gap-4 items-center">
                      <Icon
                        icon="uim:chart-pie"
                        className="text-8xl text-green-500"
                      />
                      <Icon
                        icon="uim:list-ul"
                        className="ml-3 text-5xl text-orange-500"
                      />
                    </div>
                  )}
                  {item.mainChartType === "number" && (
                    <Icon
                      icon="uim:ruler"
                      className="ml-4 text-8xl text-blue-500"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {selectedChart && (
        <Form {...form} onSubmit={onSubmit}>
          <DashBoardItemForm form={form} name={selectedChart.name} />
        </Form>
      )}
    </ModalDrawer>
  );
};

export const EditDashboardItemModal = ({
  open,
  onClose: onCloseRaw,
  id,
}: {
  open: boolean;
  onClose: () => void;
  id: number;
}) => {
  const { data: item } = trpc.dashboards.items.details.useQuery(id);
  const updateDashboardItemMutation = trpc.dashboards.items.update.useMutation({
    onSuccess: () => {
      heffl.toast.success("Chart updated");
      onClose();
    },
    onError: (error) => {
      heffl.toast.error(error.message);
    },
  });

  const deleteDashboardItemMutation = trpc.dashboards.items.delete.useMutation({
    onSuccess: () => {
      heffl.toast.success("Chart deleted");
      onClose();
    },
  });

  const form = useForm<z.infer<typeof Schemas.dashboards.dashboardItem>>({
    resolver: zodResolver(Schemas.dashboards.dashboardItem),
    defaultValues: {
      filtersValues: {},
    },
  });

  const onClose = () => {
    form.reset();
    onCloseRaw();
  };

  const onSubmit = (data: z.infer<typeof Schemas.dashboards.dashboardItem>) => {
    updateDashboardItemMutation.mutate({
      id,
      dashboardItem: data,
    });
  };

  useEffect(() => {
    if (item) {
      form.reset(item);
    }
  }, [item]);

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      title="Edit Chart"
      footer={
        <div className="flex gap-3 items-center">
          <Button
            variant="destructiveOutline"
            iconify="tabler:trash"
            onClick={() => {
              heffl.modal.confirm({
                title: "Delete chart",
                description: "Are you sure you want to delete this chart?",
                onConfirm: () => deleteDashboardItemMutation.mutate(id),
              });
            }}
          >
            Delete
          </Button>
          <Button
            iconify="tabler:device-floppy"
            variant="primary"
            onClick={form.handleSubmit(onSubmit)}
          >
            Update chart
          </Button>
        </div>
      }
    >
      {item && (
        <Form {...form} onSubmit={onSubmit}>
          <DashBoardItemForm form={form} name={item?.name} />
        </Form>
      )}
    </ModalDrawer>
  );
};
