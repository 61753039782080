import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterProcedureOptions, trpc } from "@/helpers/trpc";

const useUpdateTask = (
  options?: RouterProcedureOptions["projects"]["tasks"]["update"]
) => {
  return trpc.projects.tasks.update.useMutation({
    ...options,
    async onMutate(variables) {
      if (variables.task.status && variables.task.status === "CANCELLED") {
        await heffl.modal.reason({
          title: "What is the reason for cancelling the task?",
          showSkip: true,
          onSubmit: (reason) => {
            variables.task.cancelReason = reason.description;
          },
        });
      }
    },
  });
};

export default useUpdateTask;
