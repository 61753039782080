import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";

import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { AddProductDrawer } from "../products/components/addProductDrawer";
import ProformaInvoiceForm from "./components/proforma-invoice-form";
import { Save } from "lucide-react";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";

const ProformaInvoiceEdit = () => {
  const params = useParams();
  const invoiceId = Number(params.id);
  const navigate = useNavigate();

  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const form = useForm<z.infer<typeof Schemas.sales.proformaInvoice>, unknown>({
    resolver: zodResolver(Schemas.sales.proformaInvoice),
  });

  const { data: invoiceDetails } = trpc.proformaInvoice.details.useQuery(
    invoiceId,
    {
      refetchOnWindowFocus: false,
    }
  );

  const invoiceUpdateMutation = trpc.proformaInvoice.update.useMutation({
    onSuccess: () => {
      form.reset();
      toast.success("Invoice updated successfully");
      navigate(-1);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    // check if default values is already set
    if (!form.getValues("clientId") && invoiceDetails) {
      form.reset(invoiceDetails);
    }
  }, [invoiceDetails, form]);

  if (!invoiceDetails) return <FullScreenSpinner />;

  return (
    <Page
      showBack
      title="Edit Proforma Invoice"
      description="Update proforma invoice details"
      suffix={
        <>
          <ResponsivePrimaryButton
            onClick={() =>
              form.handleSubmit((values) =>
                invoiceUpdateMutation.mutate({
                  id: invoiceId,
                  proformaInvoice: values,
                })
              )()
            }
            loading={invoiceUpdateMutation.isLoading}
            variant="primary"
            icon={Save}
          >
            Update proforma invoice
          </ResponsivePrimaryButton>
        </>
      }
    >
      {showAddProductDialog && (
        <AddProductDrawer
          open={showAddProductDialog}
          onClose={() => setShowAddProductDialog(false)}
        />
      )}
      <Form
        {...form}
        onSubmit={(values) => {
          invoiceUpdateMutation.mutate({
            id: invoiceId,
            proformaInvoice: values,
          });
        }}
      >
        <div className="flex justify-center px-3 w-full sm:px-0">
          <ProformaInvoiceForm form={form} edit />
        </div>
      </Form>
    </Page>
  );
};

export default ProformaInvoiceEdit;
