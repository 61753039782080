import { UserInput, VendorInput } from "@/components/FormComponents";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import { Textarea } from "@heffl/ui/components/primitives/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { CircleDollarSign } from "lucide-react";
import { useEffect } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { DatePicker } from "@heffl/ui/components/primitives/datepicker";
import dayjs from "dayjs";
import { Switch } from "@heffl/ui/components/primitives/switch";
import useTeam from "@/lib/hooks/useTeam";

const CommissionForm = ({
  form,
}: {
  form: UseFormReturn<z.infer<typeof Schemas.sales.commission>, unknown>;
}) => {
  const paidTo = form.watch("paidTo");
  const salesOrderId = form.watch("salesOrderId");

  const { data: salesOrder } = trpc.sales.salesOrders.details.useQuery(
    salesOrderId!,
    { enabled: !!salesOrderId }
  );

  return (
    <>
      <div className="grid gap-4 sm:grid-cols-2">
        <FormField name="date" label="Date">
          <DatePicker />
        </FormField>
        <FormField name="paidTo" label="Paid to">
          <Select
            onChange={() => {
              form.setValue("payableToUserId", null);
              form.setValue("payableToVendorId", null);
            }}
            options={[
              {
                label: "Employee",
                value: "USER",
                icon: heffl.icons.common.user.icon,
              },
              {
                label: "Vendor",
                value: "VENDOR",
                icon: heffl.icons.purchases.vendors.icon,
              },
            ]}
            placeholder="Select paid to"
          />
        </FormField>
      </div>
      {paidTo === "USER" && (
        <UserInput name="payableToUserId" placeholder="Payable to employee" />
      )}
      {paidTo === "VENDOR" && (
        <VendorInput
          name="payableToVendorId"
          placeholder="Payable to vendor"
          className="mt-4"
        />
      )}
      {salesOrder && (
        <FormField name="salesOrderItemId" label="Sales Order Item">
          <Select
            placeholder="Link to sales order item"
            allowClear
            options={salesOrder.salesOrderItems.map((item) => ({
              label: `#${item.position} ${item.name} x ${item.quantity}`,
              value: item.id,
            }))}
          />
        </FormField>
      )}
      <div className="flex gap-4">
        <FormField name="amount" label="Amount" className="w-full">
          <Input
            placeholder="Amount"
            type="number"
            prefix={<CircleDollarSign className="w-4 h-4" />}
          />
        </FormField>
        <FormField name="createInstantBill" label="Create instant bill">
          <Switch />
        </FormField>
      </div>
      <FormField name="notes" label="Notes">
        <Textarea placeholder="Notes" />
      </FormField>
    </>
  );
};

type AddCommissionModalProps = {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.sales.commission>>;
};

export const AddCommissionModal = ({
  open,
  onClose,
  defaultValues,
}: AddCommissionModalProps) => {
  const team = useTeam();

  const form = useForm<z.infer<typeof Schemas.sales.commission>>({
    resolver: zodResolver(Schemas.sales.commission),
    defaultValues: {
      date: dayjs().toDate(),
      paidTo: "VENDOR",
    },
  });

  const addCommissionMutation = trpc.sales.commissions.add.useMutation({
    onSuccess() {
      heffl.toast.success("Commission added successfully");
      form.reset();
      onClose();
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const onSubmit = (data: z.infer<typeof Schemas.sales.commission>) => {
    addCommissionMutation.mutate(data);
  };

  useEffect(() => {
    form.reset({ ...form.getValues(), ...defaultValues });
  }, [defaultValues]);

  useEffect(() => {
    if (team) {
      form.setValue(
        "createInstantBill",
        team.settings.commission.createInstantBillDefault
      );
    }
  }, [team]);

  return (
    <ModalDrawer
      title="Add Commission"
      open={open}
      onClose={onClose}
      footer={
        <Button
          className="w-full"
          variant="primary"
          onClick={form.handleSubmit(onSubmit)}
          loading={addCommissionMutation.isLoading}
        >
          Add Commission
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <CommissionForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

export const EditCommissionModal: React.FC<{
  open: boolean;
  onClose: () => void;
  id: number;
}> = ({ open, onClose, id }) => {
  const form = useForm<z.infer<typeof Schemas.sales.commission>>({
    resolver: zodResolver(Schemas.sales.commission),
  });

  const { data: commission } = trpc.sales.commissions.details.useQuery(id, {
    enabled: !!id,
  });

  const updateCommissionMutation = trpc.sales.commissions.update.useMutation({
    onSuccess() {
      heffl.toast.success("Commission updated successfully");
      onClose();
    },
    onError(error) {
      heffl.toast.error(error.message);
    },
  });

  const onSubmit = (data: z.infer<typeof Schemas.sales.commission>) => {
    if (!open) return;
    updateCommissionMutation.mutate({
      id,
      commission: data,
    });
  };

  useEffect(() => {
    if (commission) {
      form.reset(commission);
    }
  }, [commission]);

  return (
    <ModalDrawer
      title="Edit Commission"
      open={open}
      onClose={onClose}
      footer={
        <Button
          className="w-full"
          variant="primary"
          onClick={form.handleSubmit(onSubmit)}
          loading={updateCommissionMutation.isLoading}
        >
          Update Commission
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <CommissionForm form={form} />
      </Form>
    </ModalDrawer>
  );
};
