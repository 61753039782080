import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Badge } from "@heffl/ui/components/primitives/badge";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { Box, Package } from "lucide-react";
import { useState } from "react";
import { AddProductDrawer } from "./components/addProductDrawer";
import { EditProductDrawer } from "./components/editProductDrawer";
import RenderHtml from "@heffl/ui/components/render-html";

const Products = () => {
  const [addProduct, setAddProduct] = useState(false);
  const [editProduct, setEditProduct] = useState<undefined | number>(undefined);
  const [filters, setFilters] = useParamsState<
    RouterInputs["products"]["list"]
  >({
    pageNo: 1,
    pageSize: 50,
    search: "",
    createdById: [],
  });

  const permissions = usePermissions();

  const { data: users } = trpc.users.list.useQuery();
  const { data, isLoading: isLoadingProducts } = trpc.products.list.useQuery(
    {
      ...filters,
      includeInactive: true,
    },
    {
      keepPreviousData: true,
    }
  );

  if (!permissions) return <FullScreenSpinner />;

  if (!permissions.VIEW_PRODUCTS.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page title="Products" fullWidth className="sm:!p-0">
      <div className="flex flex-row justify-between border-gray-200 sm:p-3 sm:border-b">
        <SearchInput
          value={filters.search || ""}
          onChange={(v) =>
            setFilters({
              search: v,
              pageNo: 1,
            })
          }
          placeholder="Search products..."
        />
        <ResponsiveActionButton
          onClick={() => setAddProduct(true)}
          text="Product"
        />
      </div>

      <FilterBar
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        className="py-3 sm:p-3"
        filters={[
          {
            key: "createdById",
            label: "Created By",
            type: "checkbox",
            value: filters.createdById || [],
            options:
              users?.map((user) => ({
                label: heffl.format.name(user),
                value: user.id,
              })) || [],
            onChange: (value) => setFilters({ createdById: value as number[] }),
          },
        ]}
      />

      {addProduct && (
        <AddProductDrawer open onClose={() => setAddProduct(false)} />
      )}
      {!!editProduct && (
        <EditProductDrawer
          productId={editProduct}
          onClose={() => setEditProduct(undefined)}
        />
      )}

      <DataGrid
        name="productsListMain"
        label="Products"
        loading={isLoadingProducts}
        className="h-[calc(100vh-120px-var(--header-height))]"
        rows={data?.products || []}
        rowKey="id"
        onCellClick={({ row }) => setEditProduct(row.id)}
        columns={[
          {
            key: "name",
            name: "Name",
            width: 250,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <Package className="w-4 h-4" />
                <span>{row.name}</span>
              </div>
            ),
          },
          {
            key: "status",
            name: "Status",
            width: 100,
            renderCell: ({ row }) => (
              <Badge variant={row.isActive ? "success" : "error"}>
                {row.isActive ? "Active" : "Inactive"}
              </Badge>
            ),
          },
          {
            key: "description",
            name: "Description",
            width: 300,
            renderCell: ({ row }) => (
              <RenderHtml className="text-sm text-gray-600 line-clamp-2">
                {row.description}
              </RenderHtml>
            ),
          },
          {
            key: "buyPrice",
            name: "Buy Price",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.currency(row.buyPrice || 0, "AED")}</div>
            ),
          },
          {
            key: "price",
            name: "Sell Price",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.currency(row.price, "AED")}</div>
            ),
          },
          {
            key: "createdAt",
            name: "Created At",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.date(row.createdAt)}</div>
            ),
          },
          {
            key: "createdBy",
            name: "Created By",
            width: 150,
            renderCell: ({ row }) => (
              <div>{heffl.format.name(row.createdBy)}</div>
            ),
          },
        ]}
        pagination={{
          pageNo: filters.pageNo || 1,
          pageSize: filters.pageSize || 30,
          setPageNo: (pageNo) => setFilters({ pageNo }),
          setPageSize: (pageSize) => setFilters({ pageSize }),
          count: data?.meta?.count || 0,
        }}
        empty={{
          title: "No products added",
          icon: Box,
          description: "Add products and quote, invoice etc..",
          actionText: permissions.CREATE_PRODUCTS.allowed
            ? "Add product"
            : undefined,
          onAction: () => setAddProduct(true),
        }}
      />
    </Page>
  );
};

export default Products;
