import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { FC, useState } from "react";
import { Cropper, CropperRef } from "react-advanced-cropper";
import "react-advanced-cropper/dist/style.css";

interface ImageCropperProps {
  imageUrl: string;
  onCrop: (croppedImage: string) => void;
  onClose: () => void;
  open: boolean;
  aspectRatio?: number;
}

const ImageCropper: FC<ImageCropperProps> = ({
  imageUrl,
  onCrop,
  onClose,
  open,
}) => {
  const [cropper, setCropper] = useState<CropperRef>();

  const handleCrop = () => {
    if (!cropper) return;
    const canvas = cropper.getCanvas();
    if (canvas) {
      const croppedImage = canvas.toDataURL("image/jpeg", 0.9);
      onCrop(croppedImage);
      onClose();
    }
  };

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      footer={
        <div className="flex gap-2 justify-end">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCrop}>
            Crop Image
          </Button>
        </div>
      }
    >
      <div className="flex flex-col gap-4">
        <Cropper
          src={imageUrl}
          className="max-h-[70vh]"
          onChange={(cropper) => setCropper(cropper)}
          stencilProps={{
            aspectRatio: undefined,
          }}
          defaultSize={{
            width: 2000,
            height: 2000,
          }}
          transitions={false}
          backgroundWrapperProps={{
            scaleImage: false,
            moveImage: false,
            touchMove: false,
            wheelMove: false,
          }}
        />
      </div>
    </ModalDrawer>
  );
};

export default ImageCropper;
