import { Badge } from "@heffl/ui/components/primitives/badge";
import { z } from "zod";
import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import enums from "@heffl/server/src/schemas/enums";
import { useState } from "react";
import { commissionStatus } from "../salesOrders/details";
import { EditCommissionModal } from "./commission-modals";
import useTeam from "@/lib/hooks/useTeam";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";

type Filters = {
  search: string;
  statuses?: z.infer<typeof enums.commissionStatus>[];
  vendorIds?: number[];
  pageNo: number;
  pageSize: number;
  dates?: [Date, Date];
};

const CommissionsList: React.FC = () => {
  const team = useTeam();

  const [editCommission, setEditCommission] = useState<number | null>(null);

  const [filters, setFilters] = useParamsState<Filters>({
    search: "",
    statuses: [],
    vendorIds: [],
    pageNo: 1,
    pageSize: 50,
    dates: undefined,
  });

  const { data: vendors } = trpc.purchases.vendors.list.useQuery();

  const { data: commissions, isLoading } = trpc.sales.commissions.list.useQuery(
    {
      ...filters,
    }
  );

  if (!team || !commissions) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_COMMISSIONS.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page title="Commissions" fullWidth className="sm:!p-0">
      <div className="flex flex-row justify-between border-gray-200 sm:p-3 sm:border-b">
        <SearchInput
          value={filters.search}
          onChange={(v) =>
            setFilters({
              search: v,
              pageNo: 1,
            })
          }
          placeholder="Search commissions..."
        />
      </div>

      <FilterBar
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        suffix={
          <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
            Total:{" "}
            {heffl.format.currency(commissions?.meta.totalAmount || 0, "AED")}
          </div>
        }
        // onChange={() => {
        //   setFilters({
        //     ...filters,
        //     pageNo: 1,
        //   });
        // }}
        className="py-3 sm:p-3"
        filters={[
          {
            key: "dates",
            type: "date-range",
            label: "Dates",
            value: filters.dates,
            onChange: (value) => setFilters({ dates: value as [Date, Date] }),
          },
          {
            key: "statuses",
            type: "checkbox",
            label: "Status",
            value: filters.statuses || [],
            onChange: (value) =>
              setFilters({
                statuses: value as z.infer<typeof enums.commissionStatus>[],
              }),
            options: Object.entries(commissionStatus).map(([key, value]) => ({
              label: value.label,
              value: key,
            })),
          },
          {
            key: "vendorIds",
            type: "checkbox",
            label: "Vendors",
            value: filters.vendorIds || [],
            onChange: (value) =>
              setFilters({
                vendorIds: value as number[],
              }),
            options:
              vendors?.vendors?.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })) || [],
          },
        ]}
      />

      {!!editCommission && (
        <EditCommissionModal
          id={editCommission}
          open={!!editCommission}
          onClose={() => setEditCommission(null)}
        />
      )}

      <DataGrid
        name="commissionsListMain"
        label="Commissions"
        loading={isLoading}
        className="h-[calc(100vh-120px-var(--header-height))]"
        rowKey="id"
        rows={commissions?.commissions || []}
        pagination={{
          pageNo: filters.pageNo,
          pageSize: filters.pageSize,
          setPageNo: (pageNo) => setFilters({ pageNo }),
          setPageSize: (pageSize) => setFilters({ pageSize }),
          count: commissions?.meta.count || 0,
        }}
        empty={{
          icon: heffl.icons.sales.commissions.icon,
          title: "No commissions found",
          description: "Create a commission to get started",
        }}
        onCellClick={({ row }) => setEditCommission(row.id)}
        columns={[
          {
            key: "createdAt",
            name: "Created At",
            renderCell: ({ row }) => heffl.format.dateTime(row.createdAt),
            width: 150,
          },
          {
            key: "amount",
            name: "Amount",
            renderCell: ({ row }) => heffl.format.currency(row.amount, "AED"),
            width: 150,
          },
          {
            key: "paidTo",
            name: "Paid To",
            width: 150,
          },
          {
            key: "payableTo",
            name: "Payable To",
            width: 200,
            renderCell: ({ row }) => {
              if (row.paidTo === "USER" && row.payableToUser) {
                return heffl.format.name(row.payableToUser);
              }
              if (row.paidTo === "VENDOR" && row.payableToVendor) {
                return row.payableToVendor.name;
              }
              return "-";
            },
          },
          {
            key: "status",
            name: "Status",
            width: 150,
            renderCell: ({ row }) => (
              <Badge variant={commissionStatus[row.status].variant}>
                {commissionStatus[row.status].label}
              </Badge>
            ),
          },
        ]}
      />
    </Page>
  );
};

export default CommissionsList;
