import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatName, isMobile } from "@heffl/ui/lib/utils";
import { Calendar, KanbanIcon, List, Pencil, Plus } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AddProjectDrawer from "./components/AddProjectDrawer";

import FilterBar from "@/components/filter-bar";
import { SearchInput } from "@/components/FormComponents";
import KanbanBoard from "@/components/kanbanBoard/kanban";
import SortBar from "@/components/sort-bar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { getCustomFieldFilters } from "@/pages/crm/leads/list";
import Schemas from "@heffl/server/src/schemas";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { Switch } from "@heffl/ui/components/primitives/switch";
import dayjs from "dayjs";
import { match } from "ts-pattern";
import { z } from "zod";
import ProjectCard from "./components/projectCard";
import MobileList from "./mobileList";
import ProjectTable from "./table";

export type ProjectFilter = {
  pipelineId: number | null;
  clients: number[];
  tags: number[];
  assignees: number[];
  statuses: ("COMPLETED" | "CANCELLED" | "ACTIVE")[];
  invoiced: ("YES" | "NO")[];
  search: string;
  pageNo?: number;
  pageSize?: number;
  archived: boolean;
  type: z.infer<typeof enums.projectTypes>[];
  createdAt?: [Date, Date];
  startDate?: [Date, Date];
  endDate?: [Date, Date];
  customFields: {
    [key: string]: z.infer<typeof Schemas.customFields.customFieldFilters>;
  };
  sortBy: {
    createdAt?: "asc" | "desc";
    startDate?: "asc" | "desc";
    endDate?: "asc" | "desc";
  };
};

const ProjectsListPage = () => {
  const trpcUtils = trpc.useUtils();
  const navigate = useNavigate();

  const { view } = useParams<{ view?: "table" | "board" | "gantt" }>();

  const [addProjectDrawer, setAddProjectDrawer] = useState(false);
  const [clientSearch, setClientSearch] = useState("");
  const [showPipelinesDrawer, setShowPipelinesDrawer] = useState(false);

  const [filters, setFilters] = useParamsState<ProjectFilter>({
    pipelineId: null,
    clients: [],
    tags: [],
    assignees: [],
    statuses: ["ACTIVE"],
    search: "",
    pageNo: 1,
    pageSize: 30,
    invoiced: [],
    archived: false,
    sortBy: {
      createdAt: "desc",
    },
    customFields: {},
    type: [],
  });

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
    include: filters.clients.length > 0 ? filters.clients : undefined,
  });
  const { data: tags } = trpc.tags.list.useQuery({
    type: "PROJECT",
  });
  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const [searchParams] = useSearchParams();

  const bulkUpdateProjectMutation =
    trpc.projects.bulkPositionUpdate.useMutation();

  const { data: pipelines } = trpc.projects.pipelines.list.useQuery({
    includeInactive: true,
  });

  const { data: pipelineDetails } = trpc.projects.pipelines.details.useQuery(
    filters.pipelineId || 0,
    {
      enabled: !!filters.pipelineId,
    }
  );

  const projectsFilters = useMemo(
    () => ({
      pipelines: filters.pipelineId ? [filters.pipelineId] : [],
      clients: filters.clients,
      createdAt: filters.createdAt,
      startDate: filters.startDate,
      search: filters.search,
      endDate: filters.endDate,
      tags: filters.tags,
      assignees: filters.assignees,
      statuses: filters.statuses,
      archived: filters.archived,
      sortBy: filters.sortBy,
      invoiced:
        filters.invoiced.length > 0 ? filters.invoiced[0] === "YES" : undefined,
      pageNo: view === "table" && !isMobile() ? filters.pageNo : undefined,
      pageSize: view === "table" && !isMobile() ? filters.pageSize : undefined,
      customFields: filters.customFields,
      type: filters.type,
    }),
    [filters]
  );

  const { data: projects } = trpc.projects.list.useQuery(projectsFilters);

  const { data: pipelineCustomFields } =
    trpc.customizations.customFields.list.useQuery(
      {
        section: "PROJECT",
        projectPipelineId: filters.pipelineId,
      },
      {
        enabled: !!filters.pipelineId,
      }
    );

  const { data: projectCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "PROJECT",
      projectPipelineId: null,
    });

  useEffect(() => {
    if (pipelines?.length && !filters.pipelineId && !searchParams.toString()) {
      const activePipeline = pipelines.find((pipeline) => pipeline.isActive);
      setFilters({
        pipelineId: activePipeline?.id,
      });
    }
  }, [pipelines, searchParams]);

  const pipelineStages =
    filters.pipelineId && pipelineDetails?.projectPipelineStages
      ? pipelineDetails.projectPipelineStages.map((stage) => ({
          id: stage.id.toString(),
          label: stage.name,
          value: stage.id.toString(),
          color: stage.stageType === "CLOSED" ? "bg-gray-100" : "bg-white",
        }))
      : [
          {
            id: "OPEN",
            label: "Open",
            value: "OPEN",
            color: "bg-white",
          },
          {
            id: "CLOSED",
            label: "Closed",
            value: "CLOSED",
            color: "bg-gray-100",
          },
        ];

  return (
    <Page title="Projects" fullWidth className="!p-0">
      {!!filters.pipelineId && (
        <AddProjectDrawer
          open={addProjectDrawer}
          onClose={() => setAddProjectDrawer(false)}
          pipelineId={filters.pipelineId}
        />
      )}

      <ModalDrawer
        open={showPipelinesDrawer}
        onClose={() => setShowPipelinesDrawer(false)}
        title="Project Pipelines"
        type="sheet"
        sheetClassName="w-[500px]"
      >
        <div className="flex flex-col gap-2 mt-4">
          {pipelines?.map((pipeline) => (
            <div
              key={pipeline.id}
              onClick={() => {
                navigate(`/projects/pipelines/edit/${pipeline.id}`);
                setShowPipelinesDrawer(false);
              }}
              className="flex flex-col gap-2 p-2 rounded-lg border transition-colors cursor-pointer hover:border-primary"
            >
              <div className="flex justify-between items-center">
                <h3 className="text-sm font-semibold">{pipeline.name}</h3>
                <div className="text-xs text-muted-foreground">
                  {pipeline._count.projects} projects
                </div>
              </div>
              <div className="flex gap-4 text-xs text-muted-foreground">
                <Badge variant={pipeline.isActive ? "success" : "error"} small>
                  {pipeline.isActive ? "Active" : "Inactive"}
                </Badge>
                <div>Created {dayjs(pipeline.createdAt).fromNow()}</div>
                <div>•</div>
                <div>{pipeline.projectPipelineStages.length} stages</div>
              </div>
            </div>
          ))}
        </div>
      </ModalDrawer>

      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-2 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search || ""}
          onChange={(v) =>
            setFilters({
              search: v,
            })
          }
        />
        {!!filters.pipelineId && (
          <ResponsiveActionButton
            onClick={() => setAddProjectDrawer(true)}
            text={pipelineDetails?.singularName || ""}
          />
        )}
      </div>
      <StripeTabs
        className="pt-2 w-full"
        tabParentClassName="pl-4"
        value={view}
        onChange={(tab) => navigate(`/projects/${tab}`)}
        items={[
          { label: "Board", key: "board", icon: KanbanIcon },
          { label: "Table", key: "table", icon: List },
          // { label: "Gantt", key: "gantt", icon: Calendar },
        ]}
      />

      <div className="flex flex-wrap gap-2 p-3 mt-0 mb-3">
        {filters.sortBy && (
          <SortBar
            options={[
              { label: "Created at", value: "createdAt", icon: Calendar },
              { label: "Start date", value: "startDate", icon: Calendar },
              { label: "End date", value: "endDate", icon: Calendar },
            ]}
            value={filters.sortBy}
            onChange={(value) => setFilters({ sortBy: value })}
          />
        )}
        <FilterBar
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          defaultFilters={["pipelineId"]}
          filters={[
            {
              key: "createdAt",
              type: "date-range",
              label: "Created at",
              value: filters.createdAt,
              onChange: (value) => setFilters({ createdAt: value }),
              presetType: "past",
            },
            {
              key: "startDate",
              type: "date-range",
              label: "Start date",
              value: filters.startDate,
              onChange: (value) => setFilters({ startDate: value }),
            },
            {
              key: "endDate",
              type: "date-range",
              label: "End date",
              value: filters.endDate,
              onChange: (value) => setFilters({ endDate: value }),
            },
            {
              key: "type",
              type: "checkbox",
              label: "Type",
              value: filters.type,
              onChange: (value) =>
                setFilters({
                  type: value,
                }),
              options: [
                {
                  label: "Flat rate",
                  value: "flat_rate",
                },
                {
                  label: "Retainer",
                  value: "retainer",
                },
              ],
            },
            {
              key: "pipelineId",
              type: "checkbox",
              multiple: false,
              label: "Pipeline",
              value: filters.pipelineId ? [filters.pipelineId] : [],
              onChange: (value) =>
                setFilters({
                  pipelineId: value.length > 0 ? Number(value[0]) : null,
                  customFields: {},
                }),
              options: pipelines
                ?.filter((pipeline) => pipeline.isActive)
                .map((pipeline) => ({
                  label: pipeline.name,
                  value: pipeline.id,
                })),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients",
              showSearch: true,
              value: filters.clients,
              onChange: (value) => setFilters({ clients: value as number[] }),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
              onSearch: (e) => setClientSearch(e),
            },
            {
              key: "assignees",
              type: "checkbox",
              label: "Assignees",
              value: filters.assignees,
              onChange: (value) => setFilters({ assignees: value as number[] }),
              options: [
                { label: "Unassigned", value: 0 },
                ...(users?.map((user) => ({
                  label: formatName(user),
                  value: user.id,
                })) || []),
              ],
            },
            {
              key: "statuses",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) => setFilters({ statuses: value }),
              options: [
                { label: "Active", value: "ACTIVE" },
                { label: "Completed", value: "COMPLETED" },
                { label: "Cancelled", value: "CANCELLED" },
              ],
            },
            {
              key: "invoiced",
              label: "Invoiced",
              type: "checkbox",
              multiple: false,
              value: filters.invoiced,
              options: [
                { label: "Yes", value: "YES" },
                { label: "No", value: "NO" },
              ],
              onChange: (e) => {
                setFilters({
                  invoiced: e as ("YES" | "NO")[],
                });
              },
            },
            {
              key: "tags",
              type: "checkbox",
              label: "Tags",
              value: filters.tags,
              onChange: (value) => setFilters({ tags: value as number[] }),
              options: tags?.map((tag) => ({
                label: tag.name,
                value: tag.id,
              })),
            },
            ...(filters.pipelineId
              ? getCustomFieldFilters({
                  customFields: pipelineCustomFields || [],
                  setFilters,
                  filters,
                })
              : []),
            ...getCustomFieldFilters({
              customFields: projectCustomFields || [],
              setFilters,
              filters,
            }),
          ]}
        />
        <div className="flex gap-2 items-center pl-1 mt-3 sm:mt-0">
          <div className="px-2 py-1 rounded-lg border shadow-sm">
            Total:{" "}
            {heffl.format.currency(projects?.meta?.totalValue || 0, "AED")}
          </div>
          <Switch
            value={filters.archived}
            onChange={(value) => setFilters({ archived: value })}
            checkedChildren="Archived"
            unCheckedChildren="Archived"
          />
          <Button
            onClick={() => setShowPipelinesDrawer(true)}
            variant="primaryOutline"
            icon={Pencil}
            size="xs"
          >
            Edit pipelines
          </Button>
          <Button
            onClick={() => navigate("/projects/pipelines/add")}
            variant="primaryOutline"
            icon={Plus}
            size="xs"
          >
            Add pipeline
          </Button>
        </div>
      </div>

      {match({ view, isMobile: isMobile() })
        .with({ view: "board" }, () => (
          <KanbanBoard
            columns={pipelineStages}
            items={projects?.projects.map((project) => ({
              ...project,
              projectStageId: filters.pipelineId
                ? project.projectStageId.toString()
                : project.projectPipelineStages.stageType,
            }))}
            statusKey="projectStageId"
            renderItem={(project) => (
              <ProjectCard
                project={project}
                onProjectClick={(id) => navigate(`/projects/details/${id}`)}
              />
            )}
            onDragging={(updated) => {
              if (!filters.pipelineId) return;
              trpcUtils.projects.list.setData(projectsFilters, {
                projects: updated.map((item) => ({
                  ...item,
                  projectStageId: Number(item.projectStageId),
                })),
                meta: projects?.meta || { count: 0, totalValue: 0 },
              });
            }}
            onChange={(updated) => {
              if (!filters.pipelineId) return;
              bulkUpdateProjectMutation.mutate(
                updated.map((item) => ({
                  id: Number(item.id),
                  position: item.position,
                  projectStageId: Number(item.status),
                }))
              );
            }}
            className="px-2 h-[calc(100vh-120px)] sm:h-[calc(100vh-220px)]"
          />
        ))
        .with({ isMobile: true }, () => (
          <MobileList projects={projects?.projects || []} />
        ))
        .with({ view: "table", isMobile: false }, () => (
          <ProjectTable
            projects={projects}
            pageNo={filters.pageNo || 1}
            pageSize={filters.pageSize || 30}
            setPageNo={(pageNo) => setFilters({ pageNo })}
          />
        ))
        // .with({ view: "gantt" }, () => <GanttExample projects={projects} />)
        .otherwise(() => null)}
    </Page>
  );
};

export default ProjectsListPage;
