import {
  ActivityDetailsModal,
  activityStatuses,
} from "@/components/actionsModals/activities-modals";
import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { ActivityLinkBadge } from "@/pages/personal-dashboard";
import { taskDateColor } from "@/pages/projects/details/components/kanban/TaskCard";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import SimpleTable from "@heffl/ui/components/simple-table";
import { cn, dynamicDateFormatting, makeEllipsis } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  Briefcase,
  Calendar,
  DollarSign,
  Equal,
  FileText,
  ListTodo,
  LucideIcon,
  Target,
} from "lucide-react";
import { useState } from "react";
import GaugeComponent from "react-gauge-component";

const MetricCard = ({
  title,
  value,
  onClick,
  icon: Icon,
}: {
  title: string;
  value: string | number;
  onClick?: () => void;
  icon: LucideIcon;
}) => {
  return (
    <Card parentClassName="cursor-pointer" onClick={onClick}>
      <div className="flex gap-2 items-center">
        <Icon className="w-4 text-primary" />
        <p className="text-sm font-medium text-gray-500">{title}</p>
      </div>
      <p className="text-xl font-semibold sm:text-xl">{value}</p>
    </Card>
  );
};

const CrmPersonalDashboard = () => {
  const [filters, setFilters] = useState<{
    dates: [Date, Date];
  }>({
    dates: [dayjs().startOf("day").toDate(), dayjs().endOf("day").toDate()],
  });

  const [activitiesTab, setActivitiesTab] = useState<
    "pending" | "completed" | "upcoming"
  >("pending");
  const [assignedActivitiesTab, setAssignedActivitiesTab] = useState<
    "pending" | "completed" | "upcoming"
  >("pending");
  const [editActivityId, setEditActivityId] = useState<number | undefined>();

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const { data: dashboardData } = trpc.crm.dashboard.personal.useQuery(
    {
      dates: filters.dates,
    },
    {
      keepPreviousData: true,
    }
  );

  const { data: salesGoals } = trpc.salesGoals.detailsUser.useQuery();

  const { data: assignedActivities, isLoading: assignedActivitiesLoading } =
    trpc.crm.dashboard.activitiesIAssigned.useQuery(
      {
        status: assignedActivitiesTab,
      },
      {
        keepPreviousData: true,
      }
    );
  const { data: myActivities, isLoading: myActivitiesLoading } =
    trpc.crm.dashboard.myActivities.useQuery(
      {
        status: activitiesTab,
      },
      {
        keepPreviousData: true,
      }
    );

  const updateNotesMutation = trpc.users.update.useMutation({
    onSuccess: () => {
      return { invalidate: false };
    },
  });

  const debouncedUpdateNotesMutation = debounce(
    updateNotesMutation.mutate,
    500
  );

  if (!dashboardData || !currentUser || !salesGoals)
    return <FullScreenSpinner />;

  return (
    <>
      {!!editActivityId && (
        <ActivityDetailsModal
          open={true}
          id={editActivityId}
          onClose={() => setEditActivityId(undefined)}
        />
      )}
      <div className="space-y-4">
        <div className="flex flex-col gap-3 justify-center items-center w-full">
          <p className="font-normal opacity-80 lg:text-lg">
            {dayjs().format("dddd, MMMM D")}
          </p>
          <h5 className="text-2xl font-medium lg:text-3xl">
            {dayjs().hour() < 12
              ? "Good morning"
              : dayjs().hour() < 17
              ? "Good afternoon"
              : "Good evening"}
            , {currentUser.firstName}
          </h5>

          <div className="w-full">
            <FilterBar
              onChange={() => {}}
              className=""
              filters={[
                {
                  key: "date",
                  type: "date-range",
                  label: "Date",
                  value: filters.dates,
                  onChange: (value) =>
                    setFilters({
                      dates: value as [Date, Date],
                    }),
                },
              ]}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 w-full sm:grid-cols-4">
            <MetricCard
              title="Leads created"
              value={dashboardData.leadsCreated}
              icon={Target}
            />
            <MetricCard
              title="Deals created"
              value={dashboardData.dealsCreated}
              icon={Briefcase}
            />
            <MetricCard
              title="Deals won count"
              value={dashboardData.noOfDealsWon}
              icon={ListTodo}
            />
            <MetricCard
              title="Deals won amount"
              value={heffl.format.currency(dashboardData.dealsAmountWon, "AED")}
              icon={DollarSign}
            />
          </div>

          <div className="grid grid-cols-1 gap-4 w-full sm:grid-cols-4">
            {salesGoals.map((salesGoal) => (
              <Card
                key={salesGoal.id}
                title={salesGoal.title}
                className="flex flex-col items-center w-full text-center"
              >
                <GaugeComponent
                  className="!w-full sm:w-auto"
                  value={salesGoal.goalMetrics.achievedPercentage}
                  labels={{
                    valueLabel: {
                      style: {
                        fill: "black",
                        textShadow: "none",
                        fontSize: "22px",
                      },
                    },
                  }}
                  arc={{
                    subArcs: [
                      {
                        limit: 20,
                        color: "#EA4228",
                        showTick: true,
                      },
                      {
                        limit: 40,
                        color: "#F58B19",
                        showTick: true,
                      },
                      {
                        limit: 60,
                        color: "#F5CD19",
                        showTick: true,
                      },
                      {
                        limit: 100,
                        color: "#5BE12C",
                        showTick: true,
                      },
                    ],
                  }}
                />
                <p className="text-xs font-medium text-gray-500">
                  {heffl.format.currency(
                    salesGoal.goalMetrics.achievedAmount,
                    "AED"
                  )}{" "}
                  /{" "}
                  {heffl.format.currency(
                    salesGoal.goalMetrics.targetAmount,
                    "AED"
                  )}
                </p>
              </Card>
            ))}
          </div>
          <div className="grid grid-cols-1 gap-4 w-full sm:grid-cols-2">
            <Card title="My Activities" className="pt-0">
              <StripeTabs
                className="w-full"
                value={activitiesTab}
                onChange={setActivitiesTab}
                items={[
                  {
                    key: "pending",
                    label: "Pending",
                    count: myActivities?.stats.pending,
                  },
                  {
                    key: "upcoming",
                    label: "Upcoming",
                    count: myActivities?.stats.upcoming,
                  },
                  {
                    key: "completed",
                    label: "Completed",
                  },
                ]}
              />
              {myActivities && !myActivitiesLoading ? (
                <SimpleTable
                  className="mt-4"
                  idKey="id"
                  borderless
                  rows={
                    activitiesTab === "completed"
                      ? [...myActivities.activities].sort((a, b) => {
                          // First sort by completedOn date
                          const completedOnA = a.completedOn?.getTime() || 0;
                          const completedOnB = b.completedOn?.getTime() || 0;
                          if (completedOnA !== completedOnB) {
                            return completedOnB - completedOnA; // Descending order
                          }
                          // Then sort by activity date
                          return (
                            (b.startDate?.getTime() || 0) -
                            (a.startDate?.getTime() || 0)
                          );
                        })
                      : myActivities.activities
                  }
                  onRowClick={(row) => setEditActivityId(row.id)}
                  height={240}
                  columns={[
                    {
                      label: "Title",
                      icon: FileText,
                      render: (row) => (
                        <Tooltip content={row.title} delayDuration={0.5}>
                          <div className="flex flex-col gap-1">
                            <span>{makeEllipsis(row.title, 30)}</span>
                            <ActivityLinkBadge
                              activity={row}
                              className="w-fit"
                              small
                            />
                          </div>
                        </Tooltip>
                      ),
                      width: 200,
                    },
                    {
                      label: "Status",
                      icon: Target,
                      width: 100,
                      render: (row) => (
                        <div>
                          {row.completed ? (
                            <Badge variant="success">Completed</Badge>
                          ) : (
                            <Badge variant="warning">Pending</Badge>
                          )}
                          {row.completed && row.completedOn && (
                            <p className="text-[10px] text-gray-500">
                              on{" "}
                              {heffl.format.dynamicDate(row.completedOn, true)}
                            </p>
                          )}
                        </div>
                      ),
                    },
                    {
                      label: "Due Date",
                      icon: Calendar,
                      width: 120,
                      render: (row) =>
                        row.startDate && (
                          <p
                            className={cn(
                              "",
                              taskDateColor(row.startDate, row.completed)
                            )}
                          >
                            {dynamicDateFormatting(row.startDate, true)}
                          </p>
                        ),
                    },
                    {
                      label: "Outcome",
                      icon: Equal,
                      width: 140,
                      render(row) {
                        if (
                          !["meeting", "call"].includes(row.type) ||
                          !row.completed ||
                          row.status === "TODO"
                        ) {
                          return "";
                        }

                        const statusDetails = activityStatuses.find(
                          (status) => status.value === row.status
                        );
                        const Icon = statusDetails?.icon;

                        return (
                          <Badge
                            small
                            variant={statusDetails?.variant}
                            icon={Icon}
                            className="flex gap-1 items-center whitespace-nowrap w-fit"
                          >
                            {row.status}
                          </Badge>
                        );
                      },
                    },
                  ]}
                />
              ) : (
                <FullScreenSpinner />
              )}
            </Card>

            <Card title="Private Notes">
              <MiniRichTextEditor
                placeholder="Write your personal notes here..."
                height={270}
                value={currentUser.personalNotes || ""}
                onChange={(value) =>
                  debouncedUpdateNotesMutation({
                    id: currentUser.id,
                    user: { personalNotes: value },
                  })
                }
              />
            </Card>

            {/* ACTIVITIES I ASSIGNED👇 */}
            <Card title="Activities I Assigned">
              <StripeTabs
                className="w-full"
                value={assignedActivitiesTab}
                onChange={setAssignedActivitiesTab}
                items={[
                  {
                    key: "pending",
                    label: "Pending",
                    count: assignedActivities?.stats.pending,
                  },
                  {
                    key: "upcoming",
                    label: "Upcoming",
                    count: assignedActivities?.stats.upcoming,
                  },
                  {
                    key: "completed",
                    label: "Completed",
                  },
                ]}
              />
              {assignedActivities && !assignedActivitiesLoading ? (
                <SimpleTable
                  idKey="id"
                  className="mt-4"
                  borderless
                  rows={
                    assignedActivitiesTab === "completed"
                      ? [...assignedActivities.activities].sort((a, b) => {
                          // First sort by completedOn date
                          const completedOnA = a.completedOn?.getTime() || 0;
                          const completedOnB = b.completedOn?.getTime() || 0;
                          if (completedOnA !== completedOnB) {
                            return completedOnB - completedOnA; // Descending order
                          }
                          // Then sort by activity date
                          return (
                            (b.startDate?.getTime() || 0) -
                            (a.startDate?.getTime() || 0)
                          );
                        })
                      : assignedActivities.activities
                  }
                  onRowClick={(row) => setEditActivityId(row.id)}
                  height={240}
                  columns={[
                    {
                      label: "Title",
                      icon: FileText,
                      render: (row) => (
                        <Tooltip content={row.title} delayDuration={0.5}>
                          <div>
                            <span>{makeEllipsis(row.title, 30)}</span>
                            <div className="flex flex-wrap gap-1 items-center mt-1">
                              <ActivityLinkBadge
                                activity={row}
                                className="w-fit"
                                small
                              />
                              {row.activityAssignees.map((assignee) => (
                                <Badge
                                  key={assignee.users.id}
                                  small
                                  variant="outline"
                                  avatar
                                >
                                  {heffl.format.name(assignee.users)}
                                </Badge>
                              ))}
                            </div>
                          </div>
                        </Tooltip>
                      ),
                    },
                    {
                      label: "Status",
                      icon: Target,
                      width: 140,
                      render: (row) => (
                        <div>
                          {row.completed ? (
                            <Badge variant="success">Completed</Badge>
                          ) : (
                            <Badge variant="warning">Pending</Badge>
                          )}
                          {row.completed && row.completedOn && (
                            <p className="text-[10px] text-gray-500">
                              on{" "}
                              {heffl.format.dynamicDate(row.completedOn, true)}
                            </p>
                          )}
                        </div>
                      ),
                    },
                    {
                      label: "Due Date",
                      icon: Calendar,
                      width: 130,
                      render: (row) =>
                        row.startDate && (
                          <p className={cn("", taskDateColor(row.startDate))}>
                            {dynamicDateFormatting(row.startDate, true)}
                          </p>
                        ),
                    },
                    {
                      label: "Outcome",
                      icon: Equal,
                      width: 140,
                      render(row) {
                        if (
                          !["meeting", "call"].includes(row.type) ||
                          !row.completed ||
                          row.status === "TODO"
                        ) {
                          return "";
                        }

                        const statusDetails = activityStatuses.find(
                          (status) => status.value === row.status
                        );
                        const Icon = statusDetails?.icon;

                        return (
                          <Badge
                            small
                            variant={statusDetails?.variant}
                            icon={Icon}
                            className="flex gap-1 items-center whitespace-nowrap"
                          >
                            {row.status}
                          </Badge>
                        );
                      },
                    },
                  ]}
                />
              ) : (
                <FullScreenSpinner />
              )}
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrmPersonalDashboard;
