import React from "react";
import {
  Cascader as AntdCascader,
  CascaderProps as AntdCascaderProps,
} from "antd";

type CascaderProps = {
  options: AntdCascaderProps["options"];
  // Instead of passing value as array-of-objects, we expect an array-of-objects,
  // so that the parent can use keys defined by optionsName and childrenName.
  value?: Array<Record<string, string | number | null>> | undefined;
  onChange?: (value: Array<Record<string, string | number | null>>) => void;
  optionsName: string;
  childrenName: string;
  placeholder?: string;
  disabled?: boolean;
};

const InvoiceLinkCascader: React.FC<CascaderProps> = ({
  options,
  value = [],
  onChange,
  optionsName,
  childrenName,
  placeholder,
  disabled,
}) => {
  // Convert the value from the parent (an array of objects) to the
  // format expected by AntdCascader (an array-of-arrays). If the child value
  // is not provided, don't include it so that the full project selection reflects.

  const cascaderValue = value?.map((item) => {
    const primary = item[optionsName];
    const child = item[childrenName];
    return child !== undefined && child !== null ? [primary, child] : [primary];
  });

  const handleChange = (selectedValues: (string | number | null)[][]) => {
    const mapped = selectedValues.map((path) => {
      const entry: Record<string, string | number | null> = {
        [optionsName]: path[0],
      };
      if (path.length > 1 && path[1] !== null && path[1] !== undefined) {
        entry[childrenName] = path[1];
      }
      return entry;
    });
    onChange?.(mapped);
  };

  return (
    <div className="w-full min-h-9">
      <AntdCascader
        className="w-full h-full [&_.ant-select-selector]:min-h-[36px] [&_.ant-select-selection-overflow]:flex [&_.ant-select-selection-overflow]:flex-wrap [&_.ant-select-selection-overflow]:gap-1 [&_.ant-select-selection-overflow]:p-1"
        options={options}
        placeholder={placeholder}
        multiple
        value={cascaderValue}
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
};

export default InvoiceLinkCascader;
