import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@heffl/ui/components/primitives/dropdown-menu";
import { cn } from "@heffl/ui/lib/utils";
import { LucideIcon } from "lucide-react";
import { Icon as Iconify } from "@iconify/react";

type MenuItem = {
  label: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
  icon?: LucideIcon;
  iconClassName?: string;
  hidden?: boolean;
  iconify?: string;
};

const DropMenu = ({
  children,
  items,
  className,
}: {
  children: React.ReactNode;
  items: MenuItem[];
  className?: string;
}) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger
        className={cn(className)}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" sideOffset={4}>
        {items.map((item) => {
          if (item.hidden) return null;
          const Icon = item.icon;
          return (
            <DropdownMenuItem
              className={cn(
                "cursor-pointer flex items-center gap-2",
                item.className
              )}
              key={item.label}
              onClick={(e) => {
                e.stopPropagation();
                item.onClick(e);
              }}
            >
              {item.iconify && (
                <Iconify icon={item.iconify} className="w-4 h-4" />
              )}
              {Icon && <Icon className={cn("w-4 h-4", item.iconClassName)} />}
              {item.label}
            </DropdownMenuItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default DropMenu;
