import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Banknote, Landmark } from "lucide-react";
import { useNavigate } from "react-router-dom";

const Banking = () => {
  const navigate = useNavigate();

  const { data: chartOfAccounts } = trpc.books.cashflowAccounts.list.useQuery();

  if (!chartOfAccounts) return <FullScreenSpinner className="h-96" />;

  return (
    <Page title="Banking">
      <p className="text-base font-medium">Active Accounts</p>
      <div className="grid grid-cols-5 gap-4 mt-3">
        {chartOfAccounts.map((account) => (
          <Card
            key={account.id}
            className="h-28"
            parentClassName="cursor-pointer hover:border hover:border-primary-700"
            onClick={() =>
              navigate(`/books/cashflow-accounts/transactions/${account.id}`)
            }
          >
            <div className="font-medium">
              <div className="flex justify-between">
                <p className="text-sm">{account.name}</p>
                {account.type === "BANK" && (
                  <Landmark className="w-5 h-5 text-gray-500" />
                )}
                {account.type === "CASH" && (
                  <Banknote className="w-5 h-5 text-gray-500" />
                )}
              </div>
              <p className="text-[10px] text-gray-500">{account.type}</p>
            </div>
            <div className="pt-2 mt-8 border-t border-gray-200">
              <p className="text-base font-medium text-gray-700">
                {heffl.format.currency(account.balance, "AED")}
              </p>
              <p className="-mt-1 text-[10px] text-gray-500">BALANCE</p>
            </div>
          </Card>
        ))}
      </div>
    </Page>
  );
};

export default Banking;
