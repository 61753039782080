import { RouterInputs, RouterOutputs, trpc } from "@/helpers/trpc";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { objectToParamsJSON } from "@heffl/ui/lib/utils";
import { useNavigate } from "react-router-dom";
import { TaskStatusDistributionChart } from "../../reports/task-reports/task-status-pie";
import { DashboardChartItem } from "../charts-item-helpers";
import heffl from "@/helpers/hefflHelpers/heffl";

const COLORS = {
  OPEN: "#F59E0B", // yellow
  IN_PROGRESS: "#60A5FA", // blue
  ON_HOLD: "#F97316", // orange
  COMPLETED: "#34D399", // green
};

const ProjectTaskStatusDistribution = ({
  filterValues,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterValues: Record<string, any>;
  width: number;
}) => {
  const navigate = useNavigate();

  const { data: taskData } = trpc.projects.reports.taskStats.useQuery({
    ...filterValues,
  });

  const onChartClick = (status: string) => {
    const params = objectToParamsJSON({
      ...filterValues,
      statuses: [status],
    });
    navigate(`/projects/tasks/table?${params}`);
  };

  if (!taskData) return <FullScreenSpinner />;

  const pieData = [
    {
      name: "Open",
      value: taskData?.byStatus.OPEN || 0,
      color: COLORS.OPEN,
      onClick: () => onChartClick("OPEN"),
    },
    {
      name: "In Progress",
      value: taskData?.byStatus.IN_PROGRESS || 0,
      color: COLORS.IN_PROGRESS,
      onClick: () => onChartClick("IN_PROGRESS"),
    },
    {
      name: "On Hold",
      value: taskData?.byStatus.ON_HOLD || 0,
      color: COLORS.ON_HOLD,
      onClick: () => onChartClick("ON_HOLD"),
    },
    {
      name: "Completed",
      value: taskData?.byStatus.COMPLETED || 0,
      color: COLORS.COMPLETED,
      onClick: () => onChartClick("COMPLETED"),
    },
  ];

  return <TaskStatusDistributionChart data={pieData} width={50} />;
};

export const taskStatusDistributionChart: DashboardChartItem = {
  name: "task-status-distribution",
  title: "Task Status Distribution",
  description: "Task Status Distribution",
  entity: "PROJECT",
  filters: [
    {
      label: "Date",
      type: "date-range",
      key: "dates",
    },
    {
      label: "User",
      type: "checkbox",
      key: "assignees",
      optionsMeta: {
        query: "users.list",
        hasAsyncSearch: false,
        queryInput: {
          excludeType: ["FIELD_STAFF"],
        } as RouterInputs["users"]["list"],
        parseResult: (data: RouterOutputs["users"]["list"]) =>
          data.map((user) => ({
            label: heffl.format.name(user),
            value: user.id,
          })),
      },
    },
  ],
  defaultWidth: 50,
  mainChartType: "pie",
  components: {
    stat: null,
    chart: ProjectTaskStatusDistribution,
    table: null,
  },
};
