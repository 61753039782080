import { z } from "zod";
import enums from "./enums";
import { filesInput } from "./files";

export const client = z.object({
  crmclientId: z.number().nullish(),
  crmContactId: z.number().nullish(),
});

export const fsProperty = z.object({
  name: z.string().nullish(),
  city: z.string().min(1, "Please add a city"),
  address: z.string(),
  latitude: z.coerce.number().nullish(),
  longitude: z.coerce.number().nullish(),
  stateId: z.number(),
  countryId: z.number(),
  clientId: z.number(),
  googleMapsPlaceId: z.string().nullish(),
  googleMapsLink: z.string().nullish(),
  fsPropertyZoneId: z.number().nullish(),
});

export const fsService = z.object({
  id: z.coerce.number().optional(),
  productId: z.number().nullish(),
  quantity: z.coerce.number(),
  price: z.coerce.number(),
  description: z.string().nullish(),
  tax: z.coerce.number().default(0),
  viewType: enums.lineItemViewType,
  type: enums.fsJobServiceType.default("SERVICE"),
  name: z.string().min(1, { message: "Please add a service or title" }),
});

export const fsSchedule = z.object({
  fsJobId: z.number(),
  startDate: z.date(),
  timeDisabled: z.boolean().default(false),
  endDate: z.date(),
  status: enums.scheduleStatus.default("SCHEDULED"),
  notes: z.string().nullish(),
  cancelReason: z.string().nullish(),
  invoiceRequired: z.boolean().optional().default(false),
  fsScheduleAssignees: z.coerce.number().array().default([]),
  fsScheduleTags: z.coerce.number().array().default([]),
  fsScheduleParts: fsService.array().optional().default([]),
  fsScheduleServices: fsService
    .extend({
      fsJobServiceId: z.number(),
    })
    .array()
    .optional()
    .default([]),
  files: z
    .object({
      fs_schedule_service_report: filesInput,
    })
    .nullish(),
});

export const fsScheduleStatusTimeline = z.object({
  fsScheduleId: z.number(),
  oldStatus: enums.scheduleStatus,
  newStatus: enums.scheduleStatus,
  date: z.date(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});

export const fsJob = z.object({
  paymentMethodId: z.number().nullish(),
  contractDate: z.date().nullish(),
  paymentDueDays: z.coerce.number().nullish(),
  salesPersonId: z.number().nullish(),
  startDate: z.date(),
  endDate: z.date().nullish(),
  startTime: z.date().nullish(),
  endTime: z.date().nullish(),
  type: enums.jobType,
  status: enums.jobStatus,
  cancelReason: z.string().nullish(),
  internalNotes: z.string().nullish(),
  instructions: z.string().nullish(),
  fsPropertyId: z.number(),
  fsJobServices: fsService.omit({ type: true }).array(),
  fsJobTags: z.coerce.number().array().default([]),
  fsSchedules: fsSchedule.omit({ fsJobId: true }).array(),
  paymentsCount: z.coerce.number().default(0),
  contactPersonId: z.number().nullish(),
  siteContactPersonId: z.number().nullish(),
  frequencyRule: z.string().nullish(),
  lpoNumber: z.string().nullish(),
  vendorId: z.number().nullish(),
  vendorCharge: z.coerce.number().default(0),
  markedCompletedOn: z.date().nullish(),
  fsJobQuotations: z.coerce.number().array().default([]),
  discount: z.coerce.number().default(0),
  fsSchedulePricingType: enums.jobSchedulePricingType.default("SCHEDULE"),
  files: z
    .object({
      fs_job_lpo: filesInput,
    })
    .optional(),
});

export const fsAggregator = z.object({
  name: z.string(),
});

export const jobAddSchema = fsJob.extend({
  fsScheduleAssignees: z.coerce.number().array().default([]),
  fsJobServices: fsService
    .omit({
      type: true,
      id: true,
    })
    .extend({
      uuid: z.string().optional(),
    })
    .array()
    .optional()
    .default([]),
  fsSchedules: fsSchedule
    .omit({
      fsJobId: true,
    })
    .extend({
      uuid: z.string().optional(),
      fsScheduleServices: fsService
        .extend({
          fsJobServiceIdUuid: z.string().optional(),
          uuid: z.string().optional(),
        })
        .array()
        .optional()
        .default([]),
    })
    .array()
    .optional()
    .default([]),
});
