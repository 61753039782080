import { MultipleUserInput } from "@/components/FormComponents";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import FrequencySelector from "@/pages/field-service/jobs/components/job-frequency-selector";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import { zodResolver } from "@hookform/resolvers/zod";
import { Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const ProjectTaskProfileForm = () => {
  return (
    <div className="flex flex-col gap-2">
      <FormField name="title">
        <Input placeholder="Task title" />
      </FormField>
      <div className="grid grid-cols-2 gap-2">
        <FormField
          name="dueDays"
          label="Due days"
          info="Days from project start date"
        >
          <Input type="number" placeholder="Due days" />
        </FormField>
        <MultipleUserInput
          name="projectTaskProfileAssignees"
          label="Assignees"
        />
      </div>
      <FormField name="description">
        <MiniRichTextEditor
          height={100}
          placeholder="Describe the task, note down points etc...."
        />
      </FormField>
      <FormField name="recurringRule">
        <FrequencySelector allowClear={true} />
      </FormField>
    </div>
  );
};

export const AddProjectTaskProfileModal = ({
  open,
  onClose,
  defaultValues,
}: {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<
    z.infer<typeof Schemas.projectProfile.projectProfileTask>
  >;
}) => {
  const form = useForm<
    z.infer<typeof Schemas.projectProfile.projectProfileTask>
  >({
    resolver: zodResolver(Schemas.projectProfile.projectProfileTask),
    defaultValues: {
      ...defaultValues,
      dueDays: 0,
    },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
      });
    }
  }, [defaultValues, form]);

  const addTaskMutation = trpc.projects.profiles.tasks.add.useMutation({
    onSuccess() {
      toast.success("Successfully added task profile");
      onClose();
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const onSubmit = (
    values: z.infer<typeof Schemas.projectProfile.projectProfileTask>
  ) => {
    addTaskMutation.mutate({
      ...values,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="Add Task Profile"
      footer={
        <Button
          loading={addTaskMutation.isLoading}
          onClick={() => form.handleSubmit(onSubmit)()}
          variant="primary"
          className="w-full"
        >
          Add Task Profile
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <ProjectTaskProfileForm />
      </Form>
    </ModalDrawer>
  );
};

export const EditProjectTaskProfileModal = ({
  open,
  onClose,
  id,
}: {
  open: boolean;
  onClose: ({ deleted }: { deleted: boolean }) => void;
  id: number;
}) => {
  const form = useForm<
    z.infer<typeof Schemas.projectProfile.projectProfileTask>
  >({
    resolver: zodResolver(Schemas.projectProfile.projectProfileTask),
  });

  const { data: taskDetails } =
    trpc.projects.profiles.tasks.details.useQuery(id);
  const taskDeleteMutation = trpc.projects.profiles.tasks.delete.useMutation({
    onSuccess() {
      toast.success("Successfully deleted task profile");
      onClose({ deleted: true });
      form.reset();
    },
  });

  useEffect(() => {
    if (taskDetails) {
      form.reset({
        ...taskDetails,
        projectTaskProfileAssignees:
          taskDetails.projectTaskProfileAssignees.map(
            (assignee) =>
              assignee.users_projectTaskProfileAssignees_userIdTousers.id
          ),
      });
    }
  }, [taskDetails, form]);

  const taskUpdateMutation = trpc.projects.profiles.tasks.update.useMutation({
    onSuccess() {
      toast.success("Successfully updated task profile");
      onClose({ deleted: false });
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const onSubmit = (
    values: z.infer<typeof Schemas.projectProfile.projectProfileTask>
  ) => {
    taskUpdateMutation.mutate({
      id,
      task: values,
    });
  };

  return (
    <ModalDrawer
      open={open}
      onClose={() => onClose({ deleted: false })}
      title="Edit Task Profile"
      footer={
        <div className="flex gap-2 w-full">
          <Button
            size="md"
            loading={taskDeleteMutation.isLoading}
            icon={Trash2}
            variant="destructiveOutline"
            onClick={async () => {
              heffl.modal.confirm({
                title: "Are you sure you want to delete this task profile?",
                onConfirm() {
                  taskDeleteMutation.mutate(id);
                },
              });
            }}
          />
          <Button
            className="w-full"
            size="md"
            loading={taskUpdateMutation.isLoading}
            variant="primary"
            onClick={() => form.handleSubmit(onSubmit)()}
          >
            Update Task Profile
          </Button>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        {taskDetails ? <ProjectTaskProfileForm /> : <FullScreenSpinner />}
      </Form>
    </ModalDrawer>
  );
};
