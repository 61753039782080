import { LucideIcon } from "lucide-react";
import {
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuSeparator,
  ContextMenuTrigger,
} from "../components/primitives/context-menu";
import { cn } from "../lib/utils";
import { Icon } from "@iconify/react";

type RightClickMenuProps = {
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  items: (
    | {
        type: "separator";
      }
    | {
        type?: "item";
        iconify?: string;
        icon?: LucideIcon;
        label: string;
        onClick: () => void;
        hidden?: boolean;
        className?: string;
      }
  )[];
  contentClassName?: string;
};

export const RightClickMenu: React.FC<RightClickMenuProps> = ({
  children,
  items,
  className,
  disabled,
  contentClassName,
}) => {
  return (
    <ContextMenu modal={false}>
      <ContextMenuTrigger disabled={disabled} className={className}>
        {children}
      </ContextMenuTrigger>
      <ContextMenuContent className={cn("w-32", contentClassName)}>
        {items
          .filter((item) => !("hidden" in item) || !item.hidden)
          .map((item) => (
            <>
              {item.type === "separator" ? (
                <ContextMenuSeparator />
              ) : (
                <ContextMenuItem
                  onClick={() => {
                    item.onClick();
                  }}
                  className={item.className}
                >
                  {item.icon && <item.icon className="mr-2 w-4 h-4" />}
                  {item.iconify && (
                    <Icon icon={item.iconify} className="mr-2 w-4 h-4" />
                  )}
                  {item.label}
                </ContextMenuItem>
              )}
            </>
          ))}
      </ContextMenuContent>
    </ContextMenu>
  );
};

export default RightClickMenu;
