import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import PrintPreview from "@/components/print-preview";
import { trpc } from "@/helpers/trpc";
import useQueryParams from "@/helpers/useQuery";
import { useParamsState } from "@/lib/hooks/useParamsState";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { formatCurrency } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Banknote, Download, Pencil, Plus, Receipt } from "lucide-react";
import { useEffect, useState } from "react";
import {
  AddPaymentReceivedModal,
  EditPaymentReceivedModal,
} from "../invoices/components/payment-modals";
import { downloadPdf } from "../invoices/details";
import { useNavigate } from "react-router-dom";
import usePermissions from "@/lib/hooks/usePermissions";
import PaymentReceivedDetailsModal from "./details-modal";
import useTeam from "@/lib/hooks/useTeam";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import heffl from "@/helpers/hefflHelpers/heffl";

type PageFilters = {
  dates: [Date, Date] | undefined;
  clients: number[];
  paymentMethodId: number[];
};

type PaymentsRecievedListProps = {
  filters: Partial<PageFilters>;
  pagination?: {
    setPageNo: (pageNo: number) => void;
  };
  classes?: {
    dataGrid?: string;
  };
};

export const PrintPreviewModal = ({
  paymentUuid,
  invoiceUuid,
  number,
  open,
  onClose,
}: {
  paymentUuid?: string;
  invoiceUuid?: string;
  number: string;
  open: boolean;
  onClose: () => void;
}) => {
  const [paymentDownloadLoading, setPaymentDownloadLoading] = useState(false);

  const type = paymentUuid ? "payment" : "invoice";

  return (
    <ModalDrawer
      open={open}
      onClose={onClose}
      actions={[
        {
          label: "Download",
          icon: Download,
          onClick: async () => {
            setPaymentDownloadLoading(true);
            await downloadPdf({
              name: `${heffl.format.capitalize(type)} ${number}`,
              url: `print?${type}=${paymentUuid || invoiceUuid}`,
            });
            setPaymentDownloadLoading(false);
          },
          loading: paymentDownloadLoading,
        },
      ]}
      title={`${heffl.format.capitalize(type)} - ${number}`}
      modalClassName="max-w-screen-sm"
    >
      <div className="overflow-auto w-full h-full">
        <div className="w-[21cm] h-[29.7cm] transform scale-[0.75] origin-top-left mx-auto">
          <PrintPreview paymentUuid={paymentUuid} invoiceUuid={invoiceUuid} />
        </div>
      </div>
    </ModalDrawer>
  );
};

export const PaymentsRecievedList = ({
  filters,
  classes,
}: PaymentsRecievedListProps) => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const editPaymentId = params.get("editPaymentId");
  const permissions = usePermissions();

  const [showPaymentEditModal, setShowPaymentEditModal] = useState<
    number | undefined
  >(undefined);
  const [showDetailsModal, setShowDetailsModal] = useState<number | undefined>(
    undefined
  );
  const [showPaymentReceipt, setShowPaymentReceipt] = useState<
    | {
        uuid: string;
        number: string;
      }
    | undefined
  >(undefined);

  const { data, isLoading } = trpc.invoices.payments.list.useQuery({
    clientId: filters.clients,
    paymentMethodId: filters.paymentMethodId,
    dates: filters.dates,
  });

  useEffect(() => {
    if (editPaymentId) {
      setShowPaymentEditModal(Number(editPaymentId));
    }
  }, [editPaymentId]);

  if (!data) return <FullScreenSpinner />;

  return (
    <>
      {!!showPaymentEditModal && (
        <EditPaymentReceivedModal
          open={!!showPaymentEditModal}
          id={showPaymentEditModal}
          onClose={() => setShowPaymentEditModal(undefined)}
        />
      )}
      {!!showDetailsModal && (
        <PaymentReceivedDetailsModal
          open={!!showDetailsModal}
          id={showDetailsModal}
          onClose={() => setShowDetailsModal(undefined)}
        />
      )}
      {showPaymentReceipt && (
        <PrintPreviewModal
          paymentUuid={showPaymentReceipt.uuid}
          number={showPaymentReceipt.number}
          open={!!showPaymentReceipt}
          onClose={() => setShowPaymentReceipt(undefined)}
        />
      )}
      <DataGrid
        rowKey="id"
        name="invoicesPaymentsListMain"
        label="Payments"
        allowExport={permissions?.EXPORT_PAYMENTS.allowed}
        className={classes?.dataGrid}
        loading={isLoading}
        onCellClick={({ row }) => setShowDetailsModal(row.id)}
        empty={{
          icon: Banknote,
          title: "No Payments",
          description: "Please add some payments to get started.",
          buttonSize: "sm",
        }}
        rows={data?.payments || []}
        columns={[
          {
            key: "date",
            name: "Date",
            width: 150,
            renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
          },
          {
            key: "number",
            name: "Number",
            width: 100,
            renderCell: ({ row }) => (
              <p
                className="cursor-pointer hover:underline hover:text-primary-800"
                onClick={() => setShowDetailsModal(row.id)}
              >
                {row.number}
              </p>
            ),
          },
          {
            key: "client",
            name: "Client",
            width: 140,
            renderCell: ({ row }) => (
              <p
                className="cursor-pointer hover:underline hover:text-primary-800"
                onClick={() => navigate(`/crm/clients/details/${row.clientId}`)}
              >
                {row.clients?.name}
              </p>
            ),
          },
          {
            key: "paymentMethod",
            name: "Mode",
            width: 100,
            renderCell: ({ row }) => row.paymentMethods.name,
          },
          {
            key: "amount",
            name: "Amount",
            width: 140,
            renderCell: ({ row }) => formatCurrency(row.amount, "AED"),
          },
          {
            key: "unusedAmount",
            name: "Unused Amount",
            width: 140,
            renderCell: ({ row }) => {
              const totalAllocated = heffl.math.sum(
                row.paymentReceivedAllocations,
                (p) => p.amount
              );
              const unusedAmount = row.amount - totalAllocated;
              return heffl.format.currency(unusedAmount, "AED");
            },
          },
          {
            key: "referenceNumber",
            name: "Reference Number",
            width: 130,
            renderCell: ({ row }) => row.refNo,
          },
          {
            key: "depositedTo",
            name: "Deposited To",
            width: 140,
            renderCell: ({ row }) => row.depositToAccount.name,
          },
          {
            key: "actions",
            name: "Actions",
            width: 100,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <Button
                  size="xs"
                  onClick={() =>
                    setShowPaymentReceipt({
                      uuid: row.uuid,
                      number: row.number,
                    })
                  }
                  variant="primaryOutline"
                  icon={Receipt}
                />
                <Button
                  onClick={() => setShowPaymentEditModal(row.id)}
                  size="xs"
                  variant="primaryOutline"
                  icon={Pencil}
                />
              </div>
            ),
          },
        ]}
      />
    </>
  );
};

const PaymentsReceived = () => {
  const team = useTeam();

  const [filters, setFilters] = useParamsState<PageFilters>({
    dates: undefined,
    clients: [],
    paymentMethodId: [],
  });

  const [clientSearch, setClientSearch] = useState("");
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);

  const { data } = trpc.invoices.payments.list.useQuery({
    clientId: filters.clients,
    paymentMethodId: filters.paymentMethodId,
    dates: filters.dates,
  });
  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });
  const { data: paymentMethods } =
    trpc.invoices.payments.methods.list.useQuery();

  if (!team) return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_PAYMENTS.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page title="Payments Received" className="!p-0" fullWidth>
      <div className="flex justify-between items-center p-3">
        <FilterBar
          suffix={
            <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
              Total: {formatCurrency(data?.total || 0, "AED")}
            </div>
          }
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "clients",
              type: "checkbox",
              label: "Clients", // Added clients filter
              value: filters.clients,
              showSearch: true,
              onChange: (value) => setFilters({ clients: value as number[] }),
              onSearch: (value) => setClientSearch(value),
              options: clients?.clients.map((client) => ({
                label: client.name,
                value: client.id,
              })),
            },
            {
              key: "paymentMethod",
              type: "checkbox",
              label: "Payment Method",
              value: filters.paymentMethodId,
              onChange: (value) =>
                setFilters({ paymentMethodId: value as number[] }),
              options: paymentMethods?.map((method) => ({
                label: method.name,
                value: method.id,
              })),
            },
          ]}
        />
        <Button
          size="md"
          variant="primary"
          icon={Plus}
          onClick={() => setShowAddPaymentModal(true)}
        >
          Payment
        </Button>
      </div>
      <AddPaymentReceivedModal
        open={showAddPaymentModal}
        onClose={() => setShowAddPaymentModal(false)}
      />

      <PaymentsRecievedList
        filters={filters}
        classes={{
          dataGrid: "h-[calc(100vh-160px)] mt-12",
        }}
      />
    </Page>
  );
};

export default PaymentsReceived;
