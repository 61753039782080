import Empty from "@/components/Empty";
import InfoItemsVT from "@/components/InfoItemVT";
import Page from "@/components/page";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import useIntegrations from "@/lib/hooks/useIntegrations";
import useTeam from "@/lib/hooks/useTeam";
import {
  EditPropertyModal,
  generateGoogleMapsLink,
  openGoogleMaps,
} from "@/pages/field-service/components/property-selector";
import { JobList } from "@/pages/field-service/jobs/list";
import StatementOfAccounts from "@/pages/crm/clients/StatementOfAccounts";
import {
  AddPaymentReceivedModal,
  EditPaymentReceivedModal,
} from "@/pages/sales/invoices/components/payment-modals";
import { AddPaymentLinkModal } from "@/pages/sales/invoices/details";
import { InvoiceList } from "@/pages/sales/invoices/list";
import { PaymentsRecievedList } from "@/pages/sales/paymetsReceived/list";
import { QuotationList } from "@/pages/sales/quotations/list";
import { renderCustomFieldValue } from "@heffl/server/src/helpers/customFields";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { ScrollArea } from "@heffl/ui/components/primitives/scroll-area";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import TabsInput from "@heffl/ui/components/primitives/TabsInput";
import ResponsiveActions from "@heffl/ui/components/responsive-actions";
import SimpleTable from "@heffl/ui/components/simple-table";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { UAE_CURRENCY_ID } from "@heffl/ui/lib/constants";
import { calculatePaymentReceived } from "@heffl/server/src/helpers/lineItems/calculatePaymentRecieved";
import {
  cn,
  dynamicDateFormatting,
  formatAddress,
  formatCurrency,
  formatValue,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  BadgeDollarSign,
  CircleDollarSign,
  Copy,
  CreditCard,
  FileText,
  Handshake,
  LinkIcon,
  List,
  MapPin,
  Navigation,
  Pencil,
  Plus,
  ScrollText,
  ScrollTextIcon,
  Trash2,
  Users,
  Wrench,
} from "lucide-react";
import { sum } from "radash";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { match } from "ts-pattern";
import DealCard from "../deals/components/dealCard";
import { EditClientDrawer } from "./list";
import { useQueryState } from "nuqs";

type Tab = "sales" | "crm" | "service";

const StatsCard = ({
  label,
  value,
  onClick,
  link,
  classes,
}: {
  label: string;
  value: number | string;
  link?: string;
  onClick?: () => void;
  classes?: {
    card?: string;
    label?: string;
    value?: string;
  };
}) => {
  const navigate = useNavigate();
  return (
    <Card
      parentClassName={cn("cursor-pointer", classes?.card)}
      onClick={() => {
        if (link) {
          navigate(link);
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      <p className={cn("text-sm font-medium text-gray-500", classes?.label)}>
        {label}
      </p>
      <p className={cn("text-xl font-semibold sm:text-xl", classes?.value)}>
        {value}
      </p>
    </Card>
  );
};

const PropertyCard = ({
  property,
  onClick,
}: {
  property: RouterOutputs["fieldService"]["properties"]["list"][0];
  onClick: () => void;
}) => {
  return (
    <Card
      onClick={onClick}
      className="flex gap-3 cursor-pointer"
      parentClassName="hover:bg-green-50 "
    >
      <div className="flex gap-2 items-center w-full">
        <div className="grid place-items-center px-4 h-full rounded-lg border border-gray-200">
          <MapPin className="h-12 text-primary" />
        </div>
        <div className="flex flex-col gap-1 w-full">
          <div className="flex justify-between w-full">
            <p className="text-sm">{formatValue.propertyName(property)}</p>
            <Button size="xs" variant="linkOutline" icon={Pencil}>
              Edit
            </Button>
          </div>
          <p className="text-xs text-gray-500">
            {formatValue.address(property)}
          </p>
          <div className="flex gap-2 items-center">
            <Button
              onClick={(e) => {
                e.stopPropagation();
                const link = generateGoogleMapsLink(property);
                copyToClipboard(link);
              }}
              variant="primaryOutline"
              size="xs"
              icon={Copy}
            >
              Copy link
            </Button>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                openGoogleMaps(property);
              }}
              variant="primaryOutline"
              size="xs"
              icon={Navigation}
            >
              Open map
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
};

export const ApplyUnusedCreditsModal = ({
  clientId,
  open,
  onClose,
}: {
  clientId: number;
  open: boolean;
  onClose: () => void;
}) => {
  const [showEditPaymentModal, setShowEditPaymentModal] = useState<
    number | null
  >(null);

  const { data: client } = trpc.clients.details.useQuery(clientId);

  const paymentsWithCredits = client?.paymentsRecieved.filter((payment) => {
    const paymentReceived = calculatePaymentReceived({
      amount: payment.amount,
      paymentReceivedAllocations: payment.paymentReceivedAllocations,
    });
    return paymentReceived.excess > 0;
  });

  return (
    <>
      {!!showEditPaymentModal && (
        <EditPaymentReceivedModal
          id={showEditPaymentModal}
          open={!!showEditPaymentModal}
          onClose={() => setShowEditPaymentModal(null)}
        />
      )}
      <ModalDrawer
        open={open}
        onClose={onClose}
        title="Unused Credits"
        modalClassName="min-w-[600px]"
      >
        {!paymentsWithCredits && <FullScreenSpinner />}
        {paymentsWithCredits && (
          <SimpleTable
            columns={[
              { label: "Info", key: "info" },
              { label: "Date", key: "date" },
              { label: "Amount", key: "amount" },
              { label: "Action", key: "action" },
            ]}
            idKey="id"
            rows={paymentsWithCredits.map((payment) => ({
              id: payment.id,
              info: "Excess payment",
              date: dayjs(payment.date).format("DD/MM/YYYY"),
              amount: formatCurrency(
                payment.amount -
                  sum(payment.paymentReceivedAllocations, (a) => a.amount),
                "AED"
              ),
              action: (
                <Button
                  onClick={() => {
                    setShowEditPaymentModal(payment.id);
                    onClose();
                  }}
                  variant="primaryOutline"
                  size="xs"
                >
                  Apply
                </Button>
              ),
            }))}
          />
        )}
      </ModalDrawer>
    </>
  );
};

type SalesTab = "invoices" | "payments" | "quotations" | "statement";

const ClientDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const integrations = useIntegrations();

  const clientId = Number(params.id);

  const team = useTeam();
  const { data: client } = trpc.clients.details.useQuery(clientId);
  const { data: deals } = trpc.deals.list.useQuery({
    clients: [clientId],
  });
  const { data: properties } = trpc.fieldService.properties.list.useQuery({
    clientId,
  });

  const [showAddPaymentLinkModal, setShowAddPaymentLinkModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState<Tab>("sales");
  const [showCredits, setShowCredits] = useState(false);
  const [showEditPaymentModal, setShowEditPaymentModal] = useState<
    number | null
  >(null);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [salesTab, setSalesTab] = useQueryState("salesTab", {
    defaultValue: "invoices" as SalesTab,
  });
  const [editPropertyModal, setEditPropertyModal] = useState<number | null>(
    null
  );
  const [showClientEdit, setShowClientEdit] = useState(false);

  const clientDeleteMutation = trpc.clients.delete.useMutation({
    onSuccess() {
      toast.success("Client deleted successfully");
      navigate("/sales/clients");
    },
    onError(er) {
      toast.error(er.message);
    },
  });

  if (!client || !clientId || !properties || !team)
    return <FullScreenSpinner />;

  if (!team.user.permissions.VIEW_CLIENTS.allowed) {
    return <NoPermissionScreen />;
  }

  const billingAddress = client.clientAddresses.length
    ? client.clientAddresses.find((address) => address.type === "BILLING")
    : client.clientAddresses[0];

  return (
    <Page
      showBack
      title={client.name}
      description={`Added ${dayjs(client.createdAt).fromNow()}`}
      fullWidth
      className="!p-0"
      suffix={
        <div className="flex flex-col gap-2 sm:flex-row">
          <ResponsiveActions
            actions={[
              {
                label: "Delete",
                buttonVariant: "destructive",
                loading: clientDeleteMutation.isLoading,
                icon: Trash2,
                onClick: async () => {
                  const confirmed = await confirm({
                    title: "Are you sure to delete this client?",
                    body: `This will delete the client "${client.name}" and its history from the system.`,
                    actionButton: "Delete",
                  });
                  if (confirmed) {
                    clientDeleteMutation.mutate(clientId);
                  }
                },
              },
              {
                label: "Edit",
                icon: Pencil,
                onClick: () => setShowClientEdit(true),
              },
            ]}
          />
        </div>
      }
    >
      <ApplyUnusedCreditsModal
        clientId={clientId}
        open={showCredits}
        onClose={() => setShowCredits(false)}
      />
      {showClientEdit && (
        <EditClientDrawer
          id={clientId}
          onClose={() => setShowClientEdit(false)}
        />
      )}
      {!!showEditPaymentModal && (
        <EditPaymentReceivedModal
          id={showEditPaymentModal}
          open={!!showEditPaymentModal}
          onClose={() => setShowEditPaymentModal(null)}
        />
      )}
      {showAddPaymentModal && (
        <AddPaymentReceivedModal
          defaultValues={{
            clientId,
          }}
          open={showAddPaymentModal}
          onClose={() => setShowAddPaymentModal(false)}
        />
      )}

      <div className="flex sm:h-[calc(100vh-64px)]  h-full">
        <div className="sm:w-3/4">
          <StripeTabs
            tabParentClassName="px-4"
            className="mt-2"
            value={selectedTab}
            onChange={(value) => setSelectedTab(value)}
            items={[
              { label: "Sales", key: "sales", icon: BadgeDollarSign },
              { label: "Service", key: "service", icon: Wrench },
              { label: "CRM", key: "crm", icon: Users },
            ]}
          />
          <div className="p-3">
            {match(selectedTab)
              .with("sales", () => (
                <div className="space-y-3">
                  <div className="grid grid-cols-6 gap-3 items-center">
                    <StatsCard
                      label="Opening Balance"
                      value={formatCurrency(client.openingBalance, "AED", true)}
                    />
                    <StatsCard
                      label="Invoiced"
                      value={formatCurrency(
                        client.values.invoicedAmount,
                        "AED",
                        true
                      )}
                    />
                    <StatsCard
                      label="Pending"
                      value={formatCurrency(
                        client.values.pendingAmount,
                        "AED",
                        true
                      )}
                      classes={{
                        value: "text-red-500",
                        card: "bg-red-50",
                      }}
                      onClick={() => setShowAddPaymentModal(true)}
                    />
                    <StatsCard
                      label="Unused Credits"
                      value={formatCurrency(client.unUsedCredits, "AED", true)}
                      classes={{
                        value: "text-green-500 underline cursor-pointer",
                      }}
                      onClick={() => setShowCredits(true)}
                    />
                    <StatsCard
                      label="Invoices"
                      value={client.values.invoicesCount}
                    />
                    <StatsCard
                      label="Quotations"
                      value={client.values.quotationsCount}
                    />
                  </div>

                  <TabsInput
                    value={salesTab}
                    onChange={(value) => setSalesTab(value)}
                    options={[
                      {
                        label: "Invoices",
                        icon: CircleDollarSign,
                        value: "invoices",
                        count: client.values.count.invoices,
                      },
                      {
                        label: "Payments",
                        icon: CreditCard,
                        value: "payments",
                        count: client.values.count.payments,
                      },
                      {
                        label: "Quotations",
                        icon: ScrollText,
                        value: "quotations",
                        count: client.values.count.quotations,
                      },
                      {
                        label: "Statement of Accounts",
                        icon: FileText,
                        value: "statement",
                      },
                    ]}
                  />
                  {match(salesTab)
                    .with("invoices", () => (
                      <Card
                        title="Invoices"
                        icon={<CircleDollarSign />}
                        suffix={
                          <Button
                            icon={Plus}
                            variant="primary"
                            onClick={() => {
                              navigate(
                                `/sales/invoices/add?clientId=${clientId}`
                              );
                            }}
                          >
                            Invoice
                          </Button>
                        }
                      >
                        <InvoiceList
                          name="invoicesListClient"
                          classes={{
                            dataGrid: "h-full mt-10",
                          }}
                          filters={{
                            clients: [clientId],
                            pageNo: 1,
                          }}
                          setInvoiceIds={() => {}}
                        />
                      </Card>
                    ))
                    .with("payments", () => (
                      <div className="space-y-3">
                        <Card
                          title="Payments"
                          icon={<CreditCard />}
                          suffix={
                            <Button
                              icon={Plus}
                              variant="primary"
                              onClick={() => {
                                setShowAddPaymentModal(true);
                              }}
                            >
                              Payment
                            </Button>
                          }
                        >
                          {team.user.permissions.VIEW_PAYMENTS.allowed ? (
                            <PaymentsRecievedList
                              classes={{
                                dataGrid: "h-fit mt-10",
                              }}
                              filters={{
                                clients: [clientId],
                              }}
                            />
                          ) : (
                            <NoPermissionScreen />
                          )}
                        </Card>
                        <AddPaymentLinkModal
                          open={showAddPaymentLinkModal}
                          onClose={() => setShowAddPaymentLinkModal(false)}
                          defaultValues={{
                            clientId: clientId,
                            description: "Advance payment",
                            paymentGateway: "telr",
                            currencyId: UAE_CURRENCY_ID,
                          }}
                        />
                        {integrations.get("telr").integrated && (
                          <Card
                            title="Payment Links"
                            icon={<LinkIcon className="h-5 text-blue-500" />}
                            suffix={
                              <Button
                                onClick={() => setShowAddPaymentLinkModal(true)}
                                variant="primary"
                                icon={Plus}
                              >
                                Payment link
                              </Button>
                            }
                          >
                            {client.paymentLinks &&
                            client.paymentLinks.length > 0 ? (
                              <div className="space-y-4">
                                {client.paymentLinks.map((link) => (
                                  <div
                                    key={link.id}
                                    className="flex justify-between items-center"
                                  >
                                    <div>
                                      <p className="text-sm font-medium">
                                        #{link.id} - {link.description}
                                      </p>
                                      <div className="flex gap-1 items-center">
                                        <Badge
                                          small
                                          variant={
                                            link.paymentStatus === "PAID"
                                              ? "success"
                                              : link.paymentStatus === "PENDING"
                                              ? "warning"
                                              : "error"
                                          }
                                        >
                                          {link.paymentStatus}
                                        </Badge>
                                      </div>
                                      <p className="text-xs text-gray-500">
                                        {dynamicDateFormatting(link.createdAt)}
                                      </p>
                                    </div>
                                    <div className="flex flex-col items-end space-x-2">
                                      <p className="text-sm font-medium text-gray-600">
                                        {formatCurrency(link.amount, "AED")}
                                      </p>
                                      <Button
                                        disabled={link.paymentStatus === "PAID"}
                                        size="sm"
                                        onClick={() => {
                                          const paymentLink = `https://clienthub.heffl.com/payment-links/${link.uuid}`;
                                          copyToClipboard(paymentLink);
                                        }}
                                      >
                                        <Copy className="w-4 h-4" />
                                      </Button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <Empty
                                icon={LinkIcon}
                                iconSize={30}
                                title="No payment links"
                                description="Create payment links to share with your clients."
                              />
                            )}
                          </Card>
                        )}
                      </div>
                    ))
                    .with("statement", () => (
                      <Card title="Statement of Accounts">
                        <StatementOfAccounts clientId={clientId} />
                      </Card>
                    ))
                    .with("quotations", () => (
                      <Card
                        title="Quotations"
                        icon={<ScrollTextIcon />}
                        suffix={
                          <Button
                            icon={Plus}
                            variant="primary"
                            onClick={() => {
                              navigate(
                                `/sales/quotations/add?clientId=${clientId}`
                              );
                            }}
                          >
                            Quotation
                          </Button>
                        }
                      >
                        <QuotationList
                          classes={{
                            dataGrid: "h-fit mt-10",
                          }}
                          filters={{
                            clients: [clientId],
                            pageNo: 1,
                          }}
                        />
                      </Card>
                    ))
                    .otherwise(() => null)}
                </div>
              ))
              .with("crm", () => (
                <div>
                  <Card
                    title="Deals"
                    icon={<Handshake />}
                    suffix={
                      <Button
                        icon={Plus}
                        variant="primary"
                        onClick={() => {
                          navigate(`/crm/deals/board`);
                        }}
                      >
                        Deal
                      </Button>
                    }
                  >
                    {!deals?.length && (
                      <Empty
                        icon={Handshake}
                        title="No deals found"
                        description="This client has no deals"
                        actionText="Add deal"
                        onAction={() => {
                          navigate(`/crm/deals/add?clientId=${clientId}`);
                        }}
                      />
                    )}
                    <div className="grid grid-cols-2 gap-3">
                      {deals?.map((deal) => (
                        <DealCard isListingCard key={deal.id} deal={deal} />
                      ))}
                    </div>
                  </Card>
                </div>
              ))
              .with("service", () => (
                <div className="space-y-3">
                  {!!editPropertyModal && (
                    <EditPropertyModal
                      open={!!editPropertyModal}
                      onClose={() => setEditPropertyModal(null)}
                      propertyId={editPropertyModal}
                    />
                  )}
                  <Card title="Properties" icon={<MapPin />}>
                    {!properties.length && (
                      <Empty
                        icon={MapPin}
                        title="No properties found"
                        description="This client has no properties"
                      />
                    )}
                    <div className="grid grid-cols-2 gap-3">
                      {properties.map((property) => (
                        <PropertyCard
                          key={property.id}
                          property={property}
                          onClick={() => {
                            setEditPropertyModal(property.id);
                          }}
                        />
                      ))}
                    </div>
                  </Card>
                  <Card title="Jobs" icon={<Wrench />}>
                    <JobList
                      classes={{
                        dataGrid: "mt-8",
                      }}
                      filters={{
                        clients: [clientId],
                      }}
                      setMeta={() => {}}
                    />
                  </Card>
                </div>
              ))
              .exhaustive()}
          </div>
        </div>
        <ScrollArea className="block overflow-auto w-full h-full border-l border-gray-200 sm:w-1/4 sm:block">
          <StripeTabs
            tabParentClassName="px-4"
            className="mt-2"
            items={[{ label: "Details", key: "details", icon: List }]}
          />
          <div className="p-3">
            <InfoItemsVT
              items={[
                {
                  label: "Number",
                  value: client.number,
                },
                {
                  label: "Name",
                  value: client.name,
                },
                {
                  label: "Tax No",
                  value: client.taxNumber ?? "No tax number added",
                  allowCopy: true,
                },
                {
                  label: "Billing Address",
                  value: billingAddress
                    ? formatAddress(billingAddress)
                    : "No address added",
                },
                {
                  label: "Website",
                  value: client.website ?? "No website added",
                },
                ...(client.customFieldFields &&
                  client.customFieldFields.map((field) => ({
                    label: field.label,
                    value: renderCustomFieldValue(
                      field,
                      client.customFields[field.name]
                    ),
                  }))),
              ]}
            />
          </div>
        </ScrollArea>
      </div>
    </Page>
  );
};

export default ClientDetails;
