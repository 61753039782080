import heffl from "@/helpers/hefflHelpers/heffl";
import { getS3URL } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import { convertFileSize } from "@/lib/utils";
import enums from "@heffl/server/src/schemas/enums";
import { Button } from "@heffl/ui/components/primitives/button";
import { copyToClipboard } from "@heffl/ui/components/primitives/copy-to-clipboard";
import { cn } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import { Paperclip } from "lucide-react";
import { z } from "zod";
import DropMenu from "./DropMenu";
import Empty from "./Empty";
import FileIcon from "./file-icon";

type FileListProps = {
  files: {
    id: number;
    name: string;
    createdAt: Date;
    link: string;
    section: string;
    size: number;
  }[];
  fileClassName?: string;
  className?: string;
};

const FilesList = ({ files, className, fileClassName }: FileListProps) => {
  const deleteFileMutation = trpc.files.delete.useMutation({
    onSuccess: () => {
      heffl.toast.success("File deleted successfully");
    },
    onError: () => {
      heffl.toast.error("Failed to delete file");
    },
  });

  return (
    <div className={cn("flex flex-col gap-2", className)}>
      {!files.length && (
        <Empty
          icon={Paperclip}
          title="No files uploaded"
          description="Upload a file to get started"
        />
      )}
      {files.map((file) => (
        <div
          className={cn("flex justify-between items-center", fileClassName)}
          key={file.id}
        >
          <div className="flex gap-2 items-center">
            <FileIcon
              ext={file.name.split(".").pop() ?? ""}
              className="w-10 h-10"
            />
            <div className="flex flex-col gap-0.5 justify-between h-full">
              <span className="text-sm font-medium">{file.name}</span>
              <div className="flex gap-2 items-center text-xs text-gray-500">
                <span>{dayjs(file.createdAt).fromNow()}</span> •
                <span>{convertFileSize(file.size)}</span>
              </div>
            </div>
          </div>
          <DropMenu
            items={[
              {
                label: "View",
                onClick: () => window.open(getS3URL(file), "_blank"),
                iconify: "tabler:eye",
              },
              {
                label: "Copy Link",
                onClick: () => copyToClipboard(getS3URL(file)),
                iconify: "tabler:copy",
              },
              // {
              //   label: "Rename",
              //   onClick: () => {}, // TODO: Implement rename functionality
              //   iconify: "tabler:edit",
              // },
              {
                label: "Delete",
                onClick: () => {
                  heffl.modal.confirm({
                    title: `Are you sure you want to delete this file?`,
                    description: `"${file.name}"`,
                    confirmIconify: "tabler:trash",
                    mainIconify: "tabler:trash",
                    variant: "destructive",
                    onConfirm: () =>
                      deleteFileMutation.mutate({
                        id: file.id,
                        section: file.section as z.infer<
                          typeof enums.S3Folders
                        >,
                      }),
                  });
                },
                iconify: "tabler:trash",
                className: "text-red-500",
              },
            ]}
          >
            <Button iconify="tabler:dots-vertical" variant="ghost" size="sm" />
          </DropMenu>
        </div>
      ))}
    </div>
  );
};

export default FilesList;
