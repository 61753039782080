import Page from "@/components/page";
import { trpc } from "@/helpers/trpc";
import { teamSettingsSchema } from "@heffl/server/src/schemas/teamSettings";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Switch } from "@heffl/ui/components/primitives/switch";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

const FieldServiceSettings: React.FC = () => {
  const form = useForm<z.infer<typeof teamSettingsSchema>>({
    resolver: zodResolver(teamSettingsSchema),
  });

  const { data: teamDetails } = trpc.teams.currentTeam.useQuery();

  const updateTeamSettingsMutation = trpc.teams.updateTeamSettings.useMutation({
    onSuccess: () => {
      toast.success("Settings updated");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const onSubmit = (data: z.infer<typeof teamSettingsSchema>) => {
    updateTeamSettingsMutation.mutate({
      fsJobs: data.fsJobs,
    });
  };

  useEffect(() => {
    if (teamDetails?.teamSettings) {
      form.reset({
        ...teamDetails.teamSettings,
      });
    }
  }, [teamDetails, form]);

  return (
    <Page title="Field Service">
      <Form {...form} onSubmit={onSubmit}>
        <p className="text-sm text-gray-500">Job settings</p>
        <FormField
          name="fsJobs.requiredFields.paymentMethodId"
          label="Payment method required"
        >
          <Switch />
        </FormField>
        <FormField
          name="fsJobs.requiredFields.lpoNumber"
          label="LPO number required"
        >
          <Switch />
        </FormField>
        <FormField
          name="fsJobs.allowScheduleCompletionWithoutAssignees"
          label="Allow schedule completion without assignees"
        >
          <Switch />
        </FormField>
        <FormField
          name="fsJobs.allowConversionAboveQuotationValue"
          label="Allow conversion above quotation value"
        >
          <Switch />
        </FormField>
        <FormField
          name="fsJobs.oneoffDefaultConfirmed"
          label="One off default confirmed"
        >
          <Switch />
        </FormField>
        <FormField
          name="fsJobs.showJobTagsInSchedules"
          label="Show job tags in schedules"
        >
          <Switch />
        </FormField>
        {/* <FormField
          name="fsJobs.showScheduleRevenue"
          label="Show schedule revenue"
        >
          <Switch />
        </FormField> */}
        <FormField
          name="fsJobs.allowJobEditingAfterClosed"
          label="Allow job editing after completion"
        >
          <Switch />
        </FormField>
        <FormField
          name="fsJobs.showJobTypeModalOnAdd"
          label="Show job type modal on add"
        >
          <Switch />
        </FormField>
        <div className="flex justify-end">
          <Button
            loading={updateTeamSettingsMutation.isLoading}
            type="submit"
            icon={Save}
            variant="primary"
          >
            Update settings
          </Button>
        </div>
      </Form>
    </Page>
  );
};

export default FieldServiceSettings;
