import FilterBar from "@/components/filter-bar";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterInputs, trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { BarChart } from "@heffl/ui/components/charts/barchart";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import EmptyScreen from "@heffl/ui/components/primitives/empty-screen";
import SimpleTable from "@heffl/ui/components/simple-table";
import { cn } from "@heffl/ui/lib/utils";
import { Link, useNavigate } from "react-router-dom";
import { match } from "ts-pattern";
import { leadStageTypeMeta } from "@/lib/constants";
import dayjs from "dayjs";

const LeadsBySource = () => {
  const navigate = useNavigate();

  const [filters, setFilters] = useParamsState<RouterInputs["leads"]["list"]>({
    stages: [],
    search: "",
    dates: [heffl.date.monthStart(), heffl.date.monthEnd()],
    assignedTo: [],
    owners: [],
    sources: [],
    customFields: {},
  });

  const { data: leads, isLoading: leadsLoading } = trpc.leads.list.useQuery({
    dates: filters.dates,
    stages: filters.stages,
    assignedTo: filters.assignedTo,
    owners: filters.owners,
    sources: filters.sources,
    search: filters.search,
  });
  const { data: leadSources } = trpc.sources.list.useQuery();
  const { data: leadStages } = trpc.leads.stages.list.useQuery();
  const { data: users } = trpc.users.list.useQuery({
    type: ["STAFF", "ADMIN", "OWNER", "SUPER_ADMIN"],
  });

  return (
    <div>
      <FilterBar
        suffix={
          <div className="p-1 px-2 rounded-lg border">
            Count: {leads?.count}
          </div>
        }
        onChange={() => {}}
        className="pb-3"
        filters={[
          {
            key: "date",
            type: "date-range",
            label: "Created at",
            value: filters.dates,
            presets: [
              {
                label: "This week",
                value: [
                  dayjs().startOf("week").toDate(),
                  dayjs().endOf("week").toDate(),
                ],
              },
              {
                label: "Last week",
                value: [
                  dayjs().subtract(1, "week").startOf("week").toDate(),
                  dayjs().subtract(1, "week").endOf("week").toDate(),
                ],
              },
              {
                label: "This month",
                value: [
                  dayjs().startOf("month").toDate(),
                  dayjs().endOf("month").toDate(),
                ],
              },
              {
                label: "Last month",
                value: [
                  dayjs().subtract(1, "month").startOf("month").toDate(),
                  dayjs().subtract(1, "month").endOf("month").toDate(),
                ],
              },
              {
                label: "Last 3 months",
                value: [
                  dayjs().subtract(3, "month").startOf("month").toDate(),
                  dayjs().endOf("month").toDate(),
                ],
              },
            ],
            onChange: (value) =>
              setFilters({
                dates: value,
              }),
          },
          {
            key: "stages",
            type: "checkbox",
            label: "Stage",
            value: filters.stages,
            onChange: (value) =>
              setFilters({
                stages: value,
              }),
            options: leadStages?.map((stage) => ({
              label: stage.label,
              value: stage.id,
            })),
          },
          {
            key: "assignedTo",
            type: "checkbox",
            label: "Assigned to",
            value: filters.assignedTo,
            onChange: (value) =>
              setFilters({
                assignedTo: value as number[],
              }),
            options: [
              { label: "Unassigned", value: 0 },
              ...(users?.map((user) => ({
                label: user.firstName,
                value: user.id,
              })) || []),
            ],
          },
          {
            key: "owners",
            type: "checkbox",
            label: "Owners",
            value: filters.owners,
            onChange: (value) =>
              setFilters({
                owners: value as number[],
              }),
            options:
              users?.map((user) => ({
                label: user.firstName,
                value: user.id,
              })) || [],
          },
          {
            key: "sources",
            type: "checkbox",
            label: "Sources",
            value: filters.sources,
            onChange: (value) =>
              setFilters({
                sources: value as number[],
              }),
            options:
              leadSources?.map((source) => ({
                label: source.name,
                value: source.id,
              })) || [],
          },
        ]}
      />

      {match({
        isLoading: leadsLoading,
        leads,
      })
        .with({ isLoading: false, leads: { leads: [] } }, () => (
          <EmptyScreen title="No leads found" />
        ))
        .with({ isLoading: false }, () => (
          <div className="mb-4">
            <BarChart
              data={[
                ...(leadSources?.map((source) => ({
                  id: source.id,
                  name: source.name,
                  "Leads count":
                    leads?.leads?.filter(
                      (lead) => lead.crmSourceId === source.id
                    )?.length || 0,
                })) || []),
                {
                  id: 0,
                  name: "No source",
                  "Leads count":
                    leads?.leads?.filter((lead) => !lead.crmSourceId)?.length ||
                    0,
                },
              ].filter((item) => item["Leads count"] > 0)}
              index="name"
              categories={["Leads count"]}
              showLegend={false}
              yAxisWidth={48}
              className="h-64"
              yAxisLabel="Leads count"
              xAxisLabel="Lead source"
              onValueChange={(value) => {
                if (value) {
                  const params = heffl.url.objToParams({
                    ...filters,
                    sources: [value.id],
                    archived: [],
                  });

                  navigate(`/crm/leads/table?${params}`);
                }
              }}
            />

            <SimpleTable
              height={500}
              borderless
              groupBy={{
                rowKey: "crmSourceId",
                groups: leadSources?.map((source) => ({
                  label: source.name,
                  key: source.id.toString(),
                })),
              }}
              size="small"
              columns={[
                {
                  label: "No",
                  key: "number",
                  width: 60,
                },
                {
                  label: "Name",
                  key: "name",
                  width: 200,
                  render: (row) => (
                    <Link
                      to={`/crm/leads/details/${row.id}`}
                      className="truncate rounded-md cursor-pointer hover:text-primary-600"
                    >
                      {row.name}
                    </Link>
                  ),
                },
                {
                  label: "Title",
                  key: "title",
                  width: 300,
                  fixed: "left",
                  render: (row) => (
                    <div className="truncate rounded-md cursor-pointer hover:text-primary-600">
                      {row.title}
                    </div>
                  ),
                },
                {
                  label: "Stage",
                  key: "stageId",
                  width: 100,
                  render: (row) => (
                    <div className="flex gap-1 items-center capitalize">
                      <div
                        className={cn(
                          "h-2 w-2 rounded-full",
                          row.leadStages &&
                            leadStageTypeMeta[row.leadStages?.type].color
                        )}
                      />
                      {row.leadStages && row.leadStages.label}
                    </div>
                  ),
                },
                {
                  label: "Mobile",
                  key: "mobile",
                  width: 120,
                },
                {
                  label: "Source",
                  width: 120,
                  render: (row) => row.crmSources?.name || "No source",
                },
                {
                  label: "Owner",
                  width: 120,
                  render: (row) => row.ownedBy?.firstName || "No owner",
                },
                {
                  label: "Assignees",
                  width: 120,
                  render: (row) =>
                    row.leadAssignees?.map((a) => a.users.firstName).join(", "),
                },
                {
                  label: "Email",
                  key: "email",
                  width: 120,
                },
                {
                  label: "Mobile (secondary)",
                  key: "secondaryMobile",
                  width: 120,
                },

                {
                  label: "Lead Created",
                  key: "createdAt",
                  width: 170,
                  render: (row) => heffl.format.date(row.createdAt),
                },
              ]}
              rows={leads?.leads || []}
              idKey="id"
            />
          </div>
        ))
        .otherwise(() => (
          <FullScreenSpinner />
        ))}
    </div>
  );
};

export default LeadsBySource;
