import { AvatarsGroup } from "@/components/UserAvatar";
import enums from "@heffl/server/src/schemas/enums";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { ProgressLabel } from "@heffl/ui/components/primitives/progress-label";
import { Tooltip } from "@heffl/ui/components/primitives/tooltip";
import { cn } from "@heffl/ui/lib/utils";
import { Icon } from "@iconify/react";
import dayjs from "dayjs";
import { formatDateRange } from "little-date";
import { z } from "zod";

type TProject = {
  id: number;
  number: string | null;
  title: string;
  type: z.infer<typeof enums.projectTypes>;
  status: z.infer<typeof enums.projectStatsuses>;
  taskCompletedPercentage: number;
  startDate: Date;
  endDate: Date | null;
  projectAssignees: {
    userId: number;
    users: { id: number; firstName: string; lastName: string };
  }[];
  clients?: {
    id: number;
    name: string;
  } | null;
  projectTags: { tags: { id: number; name: string } }[];
  _count: {
    projectNotes: number;
    projectFiles: number;
    projectActivities: number;
    projectTasks: number;
  };
};

interface Props {
  project: TProject;
  dragOverlay?: boolean;
  onProjectClick?: (id: number) => void;
  className?: string;
}

const ProjectCard: React.FC<Props> = ({
  project,
  dragOverlay,
  onProjectClick,
  className,
}) => {
  return (
    <div
      className={cn(
        "px-2.5 py-2 bg-white rounded-md border border-gray-200 cursor-pointer !max-w-[260px] !overflow-hidden",
        className,
        dragOverlay && "cursor-grabbing"
      )}
      onClick={() => onProjectClick && onProjectClick(project.id)}
    >
      <div className="flex gap-2 justify-between">
        <p className="w-4/5 text-sm font-medium hover:text-primary-600">
          {project.title}
        </p>
        {project.status !== "ACTIVE" && (
          <Badge
            small
            className="h-fit"
            variant={project.status === "COMPLETED" ? "success" : "error"}
          >
            {project.status}
          </Badge>
        )}
      </div>
      <div className="flex flex-col gap-1 mt-1">
        <div className="flex gap-2 items-center">
          <p className="text-xs font-medium text-gray-600">
            #{project?.number}
          </p>

          <Tooltip
            content={`${dayjs(project.endDate).diff(
              dayjs(project.startDate),
              "month"
            )} months`}
            showArrow={false}
          >
            <div className="flex gap-1 items-center">
              <Icon
                icon="tabler:calendar-week-filled"
                className="w-3.5 h-3.5 text-gray-600"
              />
              <p className="text-xs text-gray-600">
                {project.startDate && project.endDate
                  ? formatDateRange(project.startDate, project.endDate, {
                      includeTime: false,
                    })
                  : "No date"}
              </p>
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-wrap gap-1 items-center">
          {project.clients && (
            <Badge
              small
              iconify="ph:buildings-fill"
              variant="outline"
              iconClassName="text-purple-500"
              ellipsis={13}
            >
              {project.clients.name}
            </Badge>
          )}
          {project.type === "flat_rate" && (
            <Badge
              small
              iconify="ph:currency-dollar"
              variant="outline"
              iconClassName="text-yellow-500"
            >
              Flat Rate
            </Badge>
          )}
          {project.type === "retainer" && (
            <Badge
              small
              iconify="ph:arrow-clockwise"
              variant="outline"
              iconClassName="text-green-500"
            >
              Retainer
            </Badge>
          )}
          {project.projectTags.map((tag) => (
            <Badge
              key={tag.tags.id}
              small
              iconify="ph:tag"
              variant="outline"
              iconClassName="text-blue-500"
            >
              {tag.tags.name}
            </Badge>
          ))}
        </div>
      </div>
      <hr className="-mx-3 my-2 !w-[280px] border-dashed" />
      <div className="flex flex-wrap justify-between items-center w-full">
        <div className="flex flex-row flex-wrap gap-1">
          {project._count.projectTasks > 0 && (
            <div className="flex flex-row gap-0.5 items-center">
              <Icon icon="ph:check-square" className="w-3.5 h-3.5" />
              <p className="text-xs text-gray-500">
                {project._count.projectTasks}
              </p>
            </div>
          )}
          {project._count.projectFiles > 0 && (
            <div className="flex flex-row gap-0.5 items-center">
              <Icon icon="ph:paperclip" className="w-3.5 h-3.5" />
              <p className="text-xs text-gray-500">
                {project._count.projectFiles}
              </p>
            </div>
          )}
          {project._count.projectNotes > 0 && (
            <div className="flex flex-row gap-0.5 items-center">
              <Icon icon="ph:note" className="w-3.5 h-3.5" />
              <p className="text-xs text-gray-500">
                {project._count.projectNotes}
              </p>
            </div>
          )}
          <ProgressLabel
            progress={project.taskCompletedPercentage}
            className="ring-0"
          />
        </div>

        <AvatarsGroup
          size="xxs"
          users={project.projectAssignees.map((assignee) => assignee.users)}
        />
      </div>
    </div>
  );
};

export default ProjectCard;
