import DataGrid from "@/components/dataGrid/DataGrid";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import Select from "@heffl/ui/components/primitives/select";
import { Form, FormField } from "@heffl/ui/components/primitives/form";
import { Input } from "@heffl/ui/components/primitives/input";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { dynamicDateFormatting } from "@heffl/ui/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { FileText } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

type Filters = {
  search?: string;
  pageNo: number;
  pageSize: number;
};

const FormsForm = ({ edit = false }: { edit?: boolean }) => {
  return (
    <>
      <FormField name="title" label="Form Title">
        <Input placeholder="Form title" />
      </FormField>

      <FormField name="description" label="Description">
        <MiniRichTextEditor placeholder="Form description" />
      </FormField>

      {!edit && (
        <FormField name="entity" label="Entity">
          <Select
            allowClear
            placeholder="Select entity"
            options={[
              {
                label: "Lead",
                value: "LEAD",
              },
            ]}
          />
        </FormField>
      )}
    </>
  );
};

export const AddFormModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const formAddMutation = trpc.forms.add.useMutation({
    onSuccess(response) {
      heffl.toast.success("Successfully added form.");
      form.reset();
      onClose();
      navigate(`/forms/details/${response.id}`);
    },
    onError(err) {
      heffl.toast.error(err.message);
    },
  });

  const form = useForm<z.infer<typeof Schemas.forms.form>>({
    resolver: zodResolver(Schemas.forms.form),
    defaultValues: {
      title: "",
      description: "",
      entity: null,
    },
  });

  const onAddForm = async (values: z.infer<typeof Schemas.forms.form>) => {
    formAddMutation.mutate({ ...values });
  };

  return (
    <ModalDrawer
      className="w-full sm:max-w-sm"
      open={open}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title="Add Form"
      footer={
        <Button
          loading={formAddMutation.isLoading}
          className="w-full"
          type="submit"
          variant="primary"
          onClick={() => {
            form.handleSubmit(onAddForm)();
          }}
          size="md"
        >
          Add form
        </Button>
      }
    >
      <Form {...form} onSubmit={onAddForm}>
        <FormsForm />
      </Form>
    </ModalDrawer>
  );
};

export const EditFormModal = ({
  open,
  onClose,
  formId,
}: {
  open: boolean;
  onClose: () => void;
  formId: number;
}) => {
  const { data: formDetails } = trpc.forms.details.useQuery(formId, {
    enabled: open,
  });

  const formEditMutation = trpc.forms.update.useMutation({
    onSuccess() {
      heffl.toast.success("Form updated successfully");
      onClose();
    },
    onError(err) {
      heffl.toast.error(err.message);
    },
  });

  const form = useForm<z.infer<typeof Schemas.forms.form>>({
    resolver: zodResolver(Schemas.forms.form),
  });

  useEffect(() => {
    if (formDetails) {
      form.reset(formDetails);
    }
  }, [formDetails, form]);

  const onEditForm = async (values: z.infer<typeof Schemas.forms.form>) => {
    formEditMutation.mutate({ id: formId, form: values });
  };

  return (
    <ModalDrawer
      className="w-full sm:max-w-sm"
      open={open}
      onClose={() => {
        form.reset();
        onClose();
      }}
      title="Edit Form"
      footer={
        <Button
          loading={formEditMutation.isLoading}
          className="w-full"
          type="submit"
          variant="primary"
          onClick={() => {
            form.handleSubmit(onEditForm)();
          }}
          size="md"
        >
          Save changes
        </Button>
      }
    >
      <Form {...form} onSubmit={onEditForm}>
        <FormsForm edit />
      </Form>
    </ModalDrawer>
  );
};

const FormsList = () => {
  const navigate = useNavigate();

  const [addForm, setAddForm] = useState(false);
  const [filters, setFilters] = useParamsState<Filters>({
    search: "",
    pageNo: 1,
    pageSize: 30,
  });

  const { data: forms, isLoading } = trpc.forms.list.useQuery({
    search: filters.search,
  });

  return (
    <Page title="Forms" fullWidth className="sm:p-0">
      <AddFormModal open={addForm} onClose={() => setAddForm(false)} />
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-2 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search || ""}
          onChange={(v) =>
            setFilters({
              search: v,
            })
          }
        />
        <ResponsiveActionButton onClick={() => setAddForm(true)} text="Form" />
      </div>
      <DataGrid
        className="h-[calc(100vh-162px-var(--header-height))] mt-14"
        name="formsListMain"
        label="Forms"
        onCellClick={({ row }) => navigate(`details/${row.id}`)}
        columns={[
          {
            key: "name",
            name: "Name",
            width: 300,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center cursor-pointer hover:underline hover:text-primary">
                <FileText className="w-4 h-4" />
                <div className="truncate">{row.title}</div>
              </div>
            ),
          },
          {
            key: "createdAt",
            name: "Created At",
            width: 150,
            renderCell: ({ row }) =>
              dynamicDateFormatting(row.createdAt, false, ","),
          },
          {
            key: "createdBy",
            name: "Created By",
            width: 200,
            renderCell: ({ row }) => (
              <div className="flex gap-2 items-center">
                <span>{heffl.format.name(row.createdBy)}</span>
              </div>
            ),
          },
          {
            key: "entity",
            name: "Entity",
            width: 150,
            renderCell: ({ row }) => (
              <div>
                {row.entity ? (
                  <Badge variant="unique">{row.entity}</Badge>
                ) : (
                  <Badge variant="neutral">No entity linked</Badge>
                )}
              </div>
            ),
          },
        ]}
        rows={forms?.forms || []}
        rowKey="id"
        loading={isLoading}
        empty={{
          title: "No forms found",
          description: "Get started by creating a new form",
          icon: FileText,
          actionText: "Add your first form",
          onAction: () => setAddForm(true),
          buttonSize: "sm",
        }}
      />
    </Page>
  );
};

export default FormsList;
