import { ConfirmModal, ConfirmModalProps } from "@/lib/confirm-modal";
import { ReasonModal, ReasonModalProps } from "@/lib/reason-modal";
import NiceModal from "@ebay/nice-modal-react";
import appIcons from "@heffl/ui/components/appIcons";
import {
  dynamicDateFormatting,
  formatCurrency,
  formatName,
  makeEllipsis,
  objectToParamsJSON,
} from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { capitalize, sum } from "radash";
import toast from "react-hot-toast";

import { PromptModal, PromptModalProps } from "@/lib/prompt-modal";
import { convert } from "html-to-text";
import calculateInvoice from "@heffl/server/src/helpers/lineItems/calculateInvoice";
import money from "./money";
import date from "./date";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";

// rules - only nest 3 levels deep
dayjs.extend(duration);

const modal = {
  reason: (props: ReasonModalProps) =>
    NiceModal.show(ReasonModal, { ...props }),
  confirm: (props: ConfirmModalProps) =>
    NiceModal.show(ConfirmModal, { ...props }),
  prompt: (props: PromptModalProps) =>
    NiceModal.show(PromptModal, { ...props }),
};

const math = {
  sum,
};

const icons = appIcons;

const dateFormat = {
  now: () => dayjs().toDate(),
  dynamicDate: (date: Date, showTime: boolean = false) =>
    dynamicDateFormatting(date, !showTime),
  date: (date: Date) => dayjs(date).format("DD/MM/YYYY"),
  dateTime: (date: Date) => dayjs(date).format("DD/MM/YYYY hh:mm A"),
  duration: (minutes: number, format: "xs" | "sm" = "xs") => {
    const duration = dayjs.duration(minutes, "minutes");
    const hours = Math.floor(duration.asHours());
    const mins = duration.minutes();

    if (hours === 0) return `${mins}${format === "xs" ? "m" : " mins"}`;
    if (mins === 0) return `${hours}${format === "xs" ? "h" : " hrs"}`;
    return `${hours}${format === "xs" ? "h" : " hrs"} ${mins}${
      format === "xs" ? "m" : "mins"
    }`;
  },
};

const url = {
  objToParams: (obj: Record<string, unknown>) => {
    return objectToParamsJSON(obj);
  },
};

const calculate = {
  invoice: calculateInvoice,
  lineItems: calculateLineItems,
};

const array = {
  includes: <T>(array: T[], value: T): boolean => {
    return array.includes(value);
  },
};

const is = {
  empty: (value?: unknown | null) => {
    if (typeof value === "object") {
      return value === null || Object.keys(value).length === 0;
    }
    return !value;
  },
};

const format = {
  name: formatName,
  currency: formatCurrency,
  capitalize: capitalize,
  ellipsis: makeEllipsis,
  html: (html: string) => {
    return convert(html);
  },
  ...dateFormat,
};

const heffl = {
  format,
  modal,
  toast,
  icons,
  math,
  array,
  date,
  money,
  url,
  is,
  calculate,
};

export default heffl;
