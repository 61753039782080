import heffl from "@/helpers/hefflHelpers/heffl";
import Select from "@heffl/ui/components/primitives/select";
import { generateUniqueColor } from "@heffl/ui/lib/utils";
import { sum } from "radash";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import { periodOptions } from "../dashboard";

const ExpensePieChart: React.FC<{
  data: { name: string; amount: number }[];
  expenses: { name: string; amount: number }[];
  onChange: (value: keyof typeof periodOptions) => void;
  value: keyof typeof periodOptions;
}> = ({ data, expenses, onChange, value }) => {
  return (
    <div className="space-y-6 h-fit">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-gray-800">Expenses</h2>
        <div className="w-36">
          <Select
            hideSearch
            value={value}
            onChange={(value) => onChange(value as keyof typeof periodOptions)}
            options={[
              {
                label: "This month",
                value: "this_month",
              },
              {
                label: "Last month",
                value: "last_month",
              },
              {
                label: "Last 6 months",
                value: "last_6_months",
              },
              {
                label: "Last 12 months",
                value: "last_12_months",
              },
            ]}
            placeholder="Select period"
          />
        </div>
      </div>

      <div className="relative h-[340px]">
        <div className="flex absolute inset-0 flex-col justify-center items-center text-center">
          <div className="text-sm font-medium text-gray-500">Total Expense</div>
          <div className="text-2xl font-bold text-gray-800">
            {heffl.format.currency(
              sum(data, (d) => d.amount),
              "AED"
            )}
          </div>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              innerRadius={103}
              outerRadius={133}
              paddingAngle={2}
              dataKey="percentage"
              startAngle={90}
              endAngle={450}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={generateUniqueColor(entry.name, 500)}
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: "#fff",
                border: "1px solid #e5e7eb",
                borderRadius: "6px",
              }}
              formatter={(value: number) => `${value}%`}
              isAnimationActive={true}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div>
        <div className="mb-4 text-sm font-medium text-gray-800">
          TOP EXPENSE
        </div>
        <div className="grid grid-cols-2 gap-4">
          {expenses.map((expense) => (
            <div key={expense.name} className="p-3 bg-gray-50 rounded-lg">
              <div className="flex gap-2 items-center mb-1 text-sm">
                <div
                  className="w-2 h-2 rounded-full"
                  style={{
                    backgroundColor: generateUniqueColor(expense.name, 500),
                  }}
                />
                <span className="text-gray-600">{expense.name}</span>
              </div>
              <div className="font-medium text-gray-800">
                {heffl.format.currency(expense.amount, "AED")}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ExpensePieChart;
