import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button } from "@heffl/ui/components/primitives/button";
import { cn } from "@heffl/ui/lib/utils";
import { GripHorizontal, Plus } from "lucide-react";
import React from "react";
import { Icon } from "@iconify/react";
import { VList } from "virtua";
import { Item } from "./item";

interface ColumnProps<T extends { id: number | string }> {
  id: string;
  title: string;
  className?: string;
  items: T[];
  renderItem: (item: T) => React.ReactNode;
  color?: string;
  summary?: string | number;
  disabled?: boolean;
  iconify?: string;
  iconClassName?: string;
}

export const Column = <T extends { id: string }>({
  id,
  title,
  className,
  items = [],
  renderItem,
  color = "gray",
  summary,
  disabled = false,
  iconify,
  iconClassName,
}: ColumnProps<T>): React.ReactElement => {
  const { setNodeRef } = useDroppable({ id, disabled });

  return (
    <SortableContext items={items} strategy={verticalListSortingStrategy}>
      <div
        className={cn(
          "flex flex-col px-2 pt-0 rounded-sm min-w-[300px] max-w-[300px]",
          className
        )}
        ref={setNodeRef}
      >
        <div className="flex flex-col pb-2 w-full border-b border-gray-200">
          <div className="flex gap-2 justify-between items-center px-2.5 w-full bg-zinc-100 rounded-md">
            <div className="flex gap-2 items-center">
              {color && !iconify && (
                <div
                  className={cn("w-2 h-2 rounded-full")}
                  style={{ backgroundColor: color }}
                />
              )}
              {iconify && (
                <Icon icon={iconify} className={cn("w-4 h-4", iconClassName)} />
              )}
              <p className="text-sm font-medium">{title}</p>
              <div className="p-1 py-0.5 text-xs bg-white rounded-md shadow-sm w-[24px] h-[20px] text-center">
                {items.length}
              </div>
            </div>
            <div className="flex">
              <Button variant="ghost" className="!p-0">
                <Plus className="h-4 text-gray-400" />
              </Button>
              <Button variant="ghost" className="!p-0">
                <GripHorizontal className="h-4 text-gray-400" />
              </Button>
            </div>
          </div>
          <div className="pr-1 mt-1 w-full text-sm text-right text-gray-500">
            {summary}
          </div>
        </div>
        <VList className="flex flex-col py-2 pr-1 h-full">
          {items.map((item) => (
            <Item
              className="mt-2"
              key={item.id}
              id={item.id}
              disabled={disabled}
            >
              {renderItem(item)}
            </Item>
          ))}
        </VList>
      </div>
    </SortableContext>
  );
};
