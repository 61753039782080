import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import { SearchInput } from "@/components/FormComponents";
import Page from "@/components/page";
import Pagination from "@/components/Pagination";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import DropMenu from "@heffl/ui/components/DropMenu";
import { Badge, BadgeProps } from "@heffl/ui/components/primitives/badge";
import { Card } from "@heffl/ui/components/primitives/card";
import { Button } from "@heffl/ui/components/primitives/button";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatCurrency, isMobile } from "@heffl/ui/lib/utils";
import dayjs from "dayjs";
import {
  ArrowDownToLine,
  Copy,
  MoreHorizontal,
  ReceiptText,
  Trash,
} from "lucide-react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import calculateBill from "@heffl/server/src/helpers/lineItems/calculateBill";

type PageFilters = {
  search: string;
  pageNo: number;
  statuses: z.infer<typeof enums.billStatuses>[];
  dates?: [Date, Date];
  vendors: number[];
  productIds: number[];
};

const BILL_STATUS_CONFIG: Record<
  z.infer<typeof enums.billStatuses>,
  {
    label: string;
    value: z.infer<typeof enums.billStatuses>;
    variant: BadgeProps["variant"];
  }
> = {
  DRAFT: {
    label: "Draft",
    value: "DRAFT",
    variant: "neutral",
  },
  OPEN: {
    label: "Open",
    value: "OPEN",
    variant: "default",
  },
  PARTIALLY_PAID: {
    label: "Partially Paid",
    value: "PARTIALLY_PAID",
    variant: "warning",
  },
  PAID: {
    label: "Paid",
    value: "PAID",
    variant: "success",
  },
  CANCELLED: {
    label: "Cancelled",
    value: "CANCELLED",
    variant: "error",
  },
} as const;

export const BillStatusBadge: React.FC<{
  status: z.infer<typeof enums.billStatuses>;
  small?: boolean;
}> = ({ status, small }) => {
  const config = BILL_STATUS_CONFIG[status];
  return (
    <Badge small={small} variant={config.variant as BadgeProps["variant"]}>
      {config.label}
    </Badge>
  );
};

const BillList: React.FC = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    dates: undefined,
    vendors: [],
    productIds: [],
  });

  const [vendorSearch, setVendorSearch] = useState("");
  const [productSearch, setProductSearch] = useState("");

  const { data: vendors } = trpc.purchases.vendors.list.useQuery({
    search: vendorSearch,
  });
  const { data: products } = trpc.products.list.useQuery({
    search: productSearch,
    pageSize: 10,
  });
  const { data: bills, isLoading } = trpc.purchases.bills.list.useQuery({
    statuses: filters.statuses,
    dates: filters.dates,
    pageNo: filters.pageNo,
    pageSize: 30,
    vendorId: filters.vendors[0],
    productIds: filters.productIds,
    search: filters.search,
  });

  const deleteBillMutation = trpc.purchases.bills.delete.useMutation({
    onSuccess() {
      toast.success("Bill deleted successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <Page fullWidth title="Bills" className="sm:p-0">
      <div className="flex flex-col gap-3 justify-between w-full border-gray-200 sm:border-b sm:p-3 sm:flex-row sm:gap-0">
        <SearchInput
          value={filters.search}
          onChange={(e: string) => setFilters({ search: e })}
        />

        <ResponsiveActionButton
          onClick={() => navigate("/purchases/bills/add")}
          text="Bill"
        />
      </div>

      <div className="sm:px-3">
        <FilterBar
          suffix={
            <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
              Total: {heffl.format.currency(bills?.meta.total || 0, "AED")}
            </div>
          }
          onChange={() => {
            setFilters({
              pageNo: 1,
            });
          }}
          className="py-3"
          filters={[
            {
              key: "date",
              type: "date-range",
              label: "Date",
              value: filters.dates,
              onChange: (value) => setFilters({ dates: value }),
            },
            {
              key: "status",
              type: "checkbox",
              label: "Status",
              value: filters.statuses,
              onChange: (value) =>
                setFilters({
                  statuses: value as z.infer<typeof enums.billStatuses>[],
                }),
              options: [
                { label: "Draft", value: "DRAFT" },
                { label: "Open", value: "OPEN" },
                { label: "Partially Paid", value: "PARTIALLY_PAID" },
                { label: "Paid", value: "PAID" },
                { label: "Cancelled", value: "CANCELLED" },
              ],
            },
            {
              key: "vendors",
              type: "checkbox",
              label: "Vendors",
              showSearch: true,
              value: filters.vendors,
              onChange: (value) => {
                setFilters({ vendors: value as number[] });
              },
              options: vendors?.vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
              onSearch: (e: string) => setVendorSearch(e),
            },
            {
              key: "products",
              type: "checkbox",
              label: "Products",
              value: filters.productIds || [],
              onChange: (value) =>
                setFilters({ productIds: value as number[] }),
              showSearch: true,
              onSearch: (value) => setProductSearch(value),
              options: products?.products.map((product) => ({
                label: product.name,
                value: product.id,
              })),
            },
          ]}
        />
      </div>
      {isMobile() ? (
        <div className="flex flex-col gap-2 mt-4 mb-[100px]">
          {bills?.data.map((bill) => (
            <Card
              key={bill.id}
              onClick={() => navigate(`details/${bill.id}`)}
              className="cursor-pointer"
            >
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <p className="text-sm font-medium">{bill.vendors.name}</p>
                  <div className="flex flex-row gap-2">
                    <div
                      className={`flex items-center px-3 py-1 text-xs font-medium text-white rounded-full hover:text-black w-fit`}
                    >
                      {BILL_STATUS_CONFIG[bill.status].label}
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-xs">Bill number</p>
                  <p className="text-sm font-medium text-gray-700">
                    #{bill.billNumber}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-xs">Created on</p>
                  <p className="text-sm text-gray-500">
                    {dayjs(bill.date).format("DD/MM/YYYY")}
                  </p>
                </div>
                <div className="flex flex-row justify-between">
                  <p className="text-xs">Total</p>
                  <p className="text-sm font-medium text-primary">
                    {formatCurrency(
                      calculateBill(bill).billTotal,
                      bill.currencies?.symbol || "AED"
                    )}
                  </p>
                </div>
              </div>
            </Card>
          ))}
          <Pagination
            pageNo={filters.pageNo}
            count={bills?.meta.count}
            setPageNo={(pageNo) => setFilters({ pageNo })}
            className="justify-start"
          />
        </div>
      ) : (
        <DataGrid
          rowKey="id"
          name="billsListMain"
          className="h-[calc(100vh-117px-var(--header-height))]"
          label="Bills"
          loading={isLoading}
          onCellClick={({ row }) => {
            navigate(`details/${row.id}`);
          }}
          empty={{
            icon: ReceiptText,
            title: "No bills added",
            description: "Please add a bill",
            actionText: "Add bill",
            onAction: () => navigate("/purchases/bills/add"),
            buttonSize: "sm",
          }}
          rows={bills?.data || []}
          pagination={{
            pageNo: filters.pageNo,
            pageSize: 30,
            count: bills?.meta.count || 0,
            setPageNo(pageNo) {
              setFilters({ pageNo });
            },
          }}
          columns={[
            {
              key: "date",
              name: "Date",
              width: 140,
              renderCell: ({ row }) => dayjs(row.date).format("DD/MM/YYYY"),
            },
            {
              key: "number",
              name: "Number",
              width: 120,
              renderCell: ({ row }) => (
                <p className="cursor-pointer hover:underline hover:text-primary-600">
                  {row.billNumber}
                </p>
              ),
            },
            {
              key: "vendor",
              name: "Vendor",
              width: 300,
              renderCell: ({ row }) => <p>{row.vendors.name}</p>,
            },
            {
              key: "status",
              name: "Status",
              width: 100,
              renderCell: ({ row }) => <BillStatusBadge status={row.status} />,
            },
            {
              key: "dueDate",
              name: "Due Date",
              width: 140,
              renderCell: ({ row }) => dayjs(row.dueDate).format("DD/MM/YYYY"),
            },
            {
              key: "amount",
              name: "Amount",
              width: 130,
              renderCell: ({ row }) =>
                formatCurrency(
                  calculateLineItems({
                    lineItems: row.billItems,
                    discount: row.discount,
                  }).total,
                  row.currencies.symbol
                ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      icon: Trash,
                      className: "text-red-500",
                      label: "Delete",
                      onClick: async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this bill?"
                        );
                        if (confirmed) {
                          deleteBillMutation.mutate(row.id);
                        }
                      },
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () =>
                        navigate(`/purchases/bills/add?billId=${row.id}`),
                    },
                    {
                      label: "Download",
                      icon: ArrowDownToLine,
                      onClick: async () => {
                        // Implement download functionality
                      },
                    },
                  ]}
                >
                  <Button variant="ghost">
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
          ]}
        />
      )}
    </Page>
  );
};

export default BillList;
