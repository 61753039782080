import DataGrid from "@/components/dataGrid/DataGrid";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { FileText } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";

const ClientUnusedCreditsReport: React.FC = () => {
  const { data: unusedCredits } = trpc.books.reports.unusedCredits.useQuery();
  const navigate = useNavigate();

  if (!unusedCredits) return <FullScreenSpinner />;

  return (
    <Page
      title="Client Unused Credits Report"
      fullWidth
      className="sm:!p-0"
      showBack
    >
      <div className="grid grid-cols-2 gap-4 p-3 sm:grid-cols-3 lg:grid-cols-6">
        <Card>
          <h3 className="text-xs font-medium text-gray-500">
            Total Unused Credits
          </h3>
          <p className="mt-1 text-xl font-semibold text-gray-900">
            {heffl.format.currency(
              unusedCredits.summary.totalUnusedCredits,
              "AED"
            )}
          </p>
        </Card>
      </div>

      <DataGrid
        rowKey="id"
        name="clientUnusedCredits"
        className="h-[calc(100vh-155px-var(--header-height))]"
        label="Client Unused Credits"
        loading={!unusedCredits}
        empty={{
          icon: FileText,
          title: "No unused credits",
          description: "There are no clients with unused credits to display.",
        }}
        rows={unusedCredits.clients}
        columns={[
          {
            key: "name",
            name: "Client Name",
            width: 300,
            renderCell: ({ row }) => (
              <div
                className="truncate cursor-pointer hover:text-primary-600 hover:underline text-primary-800"
                onClick={() => navigate(`/crm/clients/details/${row.id}`)}
              >
                {row.name}
              </div>
            ),
          },
          {
            key: "unusedCredits",
            name: "Unused Credits",
            width: 150,
            renderCell: ({ row }) =>
              heffl.format.currency(row.unusedCredits, "AED", true),
          },
        ]}
      />
    </Page>
  );
};

export default ClientUnusedCreditsReport;
