import InfoItemsHZ from "@/components/InfoItemHZ";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import ActivityTimeline from "@/pages/crm/components/activity-timeline";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import ResponsiveActions from "@heffl/ui/components/responsive-actions";
import TemplateRender from "@heffl/ui/components/template-render";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import { formatName, isMobile } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  ArrowDownToLine,
  ArrowLeft,
  Ban,
  CalendarDays,
  Copy,
  Info,
  Loader,
  MailCheck,
  MoreHorizontal,
  PencilIcon,
  Printer,
  ReceiptText,
  StickyNote,
  Trash,
} from "lucide-react";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { downloadPdf } from "../../sales/invoices/details";
import { PurchaseOrderStatusBadge } from "./list";

export const generatePurchaseOrderName = (purchaseOrder: {
  date: Date;
  number: string;
  vendors: {
    name: string;
  };
}) => {
  if (!purchaseOrder) return "";
  return `PurchaseOrder ${purchaseOrder.number}-${
    purchaseOrder.vendors.name
  }-${dayjs(purchaseOrder.date).format("DD-MM-YYYY")}`;
};

const PurchaseOrderDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const purchaseOrderPrintRef = useRef<HTMLDivElement>(null);
  const confirm = useConfirm();

  const purchaseOrderId = Number(params.id);

  const [previewMode, setPreviewMode] = useState(true);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { data: purchaseOrder } =
    trpc.purchases.purchaseOrders.details.useQuery(purchaseOrderId);

  const printPurchaseOrder = useReactToPrint({
    content: () => purchaseOrderPrintRef.current,
    documentTitle: purchaseOrder
      ? generatePurchaseOrderName(purchaseOrder)
      : "",
  });

  const updatePurchaseOrderMutation =
    trpc.purchases.purchaseOrders.update.useMutation({
      onSuccess() {
        toast.success("Purchase Order updated successfully");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const updateNotesMutation = trpc.purchases.purchaseOrders.update.useMutation({
    onSuccess() {
      return {
        invalidate: false,
      };
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const debouncedUpdateNotes = debounce(updateNotesMutation.mutate, 500);

  const deletePurchaseOrderMutation =
    trpc.purchases.purchaseOrders.delete.useMutation({
      onSuccess() {
        toast.success("Purchase Order deleted successfully");
        navigate("/purchases/purchase-orders");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  if (!purchaseOrder) return <FullScreenSpinner />;

  return (
    <Page
      fullWidth
      title={`Purchase Order #${purchaseOrder.number}`}
      tags={
        <div className="flex flex-wrap gap-2">
          <PurchaseOrderStatusBadge
            status={purchaseOrder.status}
            small={isMobile()}
          />
          {purchaseOrder.purchaseOrderTags.map((tag) => (
            <Badge small={isMobile()} key={tag.id} background={tag.tags.color}>
              {tag.tags.name}
            </Badge>
          ))}
        </div>
      }
      description={`Created on ${format(
        purchaseOrder.createdAt,
        "dd MMM yyyy hh:mm a"
      )}`}
      showBack
      suffix={
        <div className="flex gap-2">
          <ResponsiveActions
            actions={[
              {
                icon: Printer,
                buttonVariant: "outline",
                iconClassName: "text-purple-500",
                buttonClassName: "sm:rounded-r-none",
                label: "Print",
                onClick: () => {
                  setPreviewMode(false);
                  setTimeout(() => {
                    printPurchaseOrder();
                    setPreviewMode(true);
                  }, 100);
                },
              },
              {
                buttonClassName: "sm:rounded-l-none sm:rounded-r-none sm:-ml-2",
                buttonVariant: "outline",
                icon: ArrowDownToLine,
                label: "Download",
                onClick: async () => {
                  setDownloadLoading(true);
                  await downloadPdf({
                    name: generatePurchaseOrderName(purchaseOrder),
                    url: `print?purchase-order=${purchaseOrder.uuid}`,
                  });
                  setTimeout(() => {
                    setDownloadLoading(false);
                  }, 3000);
                },
                loading: downloadLoading,
                hideLabelOnDesktop: true,
              },
              {
                buttonClassName: "sm:rounded-l-none sm:-ml-2",
                buttonVariant: "outline",
                label: "Duplicate",
                icon: Copy,
                hideLabelOnDesktop: true,
                onClick: () =>
                  navigate(
                    `/purchases/purchase-orders/add?purchaseOrderId=${purchaseOrder.id}`
                  ),
              },
              {
                hide: purchaseOrder.status !== "DRAFT",
                icon: MailCheck,
                label: "Mark as sent",
                iconClassName: "text-green-500",
                loading: updatePurchaseOrderMutation.isLoading,
                onClick: () => {
                  updatePurchaseOrderMutation.mutate({
                    id: purchaseOrder.id,
                    purchaseOrder: {
                      status: "SENT",
                    },
                  });
                },
              },
              {
                hide: purchaseOrder.status !== "SENT",
                icon: ReceiptText,
                label: "Close purchase order",
                iconClassName: "text-green-500",
                onClick: () => {
                  updatePurchaseOrderMutation.mutate({
                    id: purchaseOrder.id,
                    purchaseOrder: {
                      status: "CLOSED",
                    },
                  });
                },
              },
              {
                hide: purchaseOrder.status !== "CLOSED",
                icon: ReceiptText,
                label: "Convert to bill",
                iconClassName: "text-red-500",
                onClick: () => {},
              },
              {
                icon: MoreHorizontal,
                label: "More",
                onClick: () => {},
                dropdown: (
                  <>
                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      onClick={() =>
                        navigate(
                          `/purchases/purchase-orders/edit/${purchaseOrder.id}`
                        )
                      }
                    >
                      <PencilIcon className="mr-2 w-5" />
                      Edit
                    </Button>
                    {purchaseOrder.status !== "DRAFT" && (
                      <Button
                        variant="ghost"
                        className="flex justify-start"
                        icon={ArrowLeft}
                        onClick={() => {
                          heffl.modal.confirm({
                            title: "Revert to draft",
                            description:
                              "Are you sure you want to revert to draft?",
                            onConfirm: () => {
                              updatePurchaseOrderMutation.mutate({
                                id: purchaseOrder.id,
                                purchaseOrder: {
                                  status: "DRAFT",
                                },
                              });
                            },
                          });
                        }}
                      >
                        Revert to draft
                      </Button>
                    )}

                    <Button
                      variant="ghost"
                      className="flex justify-start text-red-500"
                      onClick={async () => {
                        heffl.modal.reason({
                          title: "Cancel purchase order",
                          submitText: "Cancel purchase order",
                          onSubmit: (data) => {
                            updatePurchaseOrderMutation.mutate({
                              id: purchaseOrder.id,
                              purchaseOrder: {
                                status: "CANCELLED",
                                cancelReason: data.description,
                              },
                            });
                          },
                        });
                      }}
                      icon={Ban}
                    >
                      Cancel purchase order
                    </Button>
                    <Button
                      variant="ghost"
                      className="flex justify-start"
                      onClick={async () => {
                        const confirmed = await confirm(
                          "Are you sure to delete this purchase order?"
                        );
                        if (confirmed) {
                          deletePurchaseOrderMutation.mutate(purchaseOrder.id);
                        }
                      }}
                    >
                      <Trash className="mr-2 w-5 text-red-500" />
                      Delete
                    </Button>
                  </>
                ),
              },
            ]}
          />
        </div>
      }
    >
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-12">
        <div className="flex justify-center w-full sm:col-span-8">
          <div className="w-full rounded-lg border border-gray-200 shadow-lg sm:w-fit">
            <div ref={purchaseOrderPrintRef}>
              <TemplateRender
                contentHtml={purchaseOrder.contentInjected}
                template={purchaseOrder.documentTemplates}
                previewMode={previewMode}
              />
            </div>
          </div>
        </div>
        <div className="space-y-4 w-full sm:col-span-4">
          <Card
            title="Details"
            icon={<Info className="h-5 text-blue-500" />}
            className="space-y-3"
          >
            <InfoItemsHZ
              items={[
                {
                  label: "Date",
                  value: dayjs(purchaseOrder.date).format("DD/MM/YYYY"),
                },
                {
                  label: "Status",
                  value: (
                    <PurchaseOrderStatusBadge status={purchaseOrder.status} />
                  ),
                },
                {
                  hidden: purchaseOrder.status !== "CANCELLED",
                  label: "Cancel Reason",
                  value: purchaseOrder.cancelReason || "No reason provided",
                  valueClassName: "text-red-500",
                },
                {
                  label: "Contact",
                  value: purchaseOrder.contacts?.firstName,
                },
                {
                  label: "Vendor",
                  value: purchaseOrder.vendors.name,
                  onClick: () => {
                    navigate(
                      `/purchases/vendors/details/${purchaseOrder.vendors.id}`
                    );
                  },
                },
                {
                  label: "Created by",
                  value: formatName(purchaseOrder.createdBy),
                },
                {
                  label: "Expected Delivery",
                  value: purchaseOrder.expectedDeliveryDate
                    ? dayjs(purchaseOrder.expectedDeliveryDate).format(
                        "DD/MM/YYYY"
                      )
                    : "Not specified",
                },
              ]}
            />
          </Card>
          <Card
            title="Notes"
            icon={<StickyNote className="text-yellow-500" />}
            className="pb-4"
          >
            <MiniRichTextEditor
              placeholder="Add notes about purchase order..."
              value={purchaseOrder.notes || ""}
              onChange={(v) => {
                debouncedUpdateNotes({
                  id: purchaseOrder.id,
                  purchaseOrder: {
                    notes: v,
                  },
                });
              }}
              height={170}
            />
            {updateNotesMutation.isLoading && (
              <div className="flex gap-2 items-center mt-1 float-end">
                <p>Updating</p>
                <Loader className="h-5 animate-twSpin text-primary" />
              </div>
            )}
          </Card>
          <Card
            title="Timeline"
            icon={<CalendarDays className="h-5 text-green-500" />}
          >
            <ActivityTimeline activities={purchaseOrder.activities} />
          </Card>
        </div>
      </div>
    </Page>
  );
};

export default PurchaseOrderDetails;
