import Empty from "@/components/Empty";
import FileIcon from "@/components/file-icon";
import { downloadS3File, getS3URL } from "@/helpers/s3Helpers";
import { trpc } from "@/helpers/trpc";
import { Button } from "@heffl/ui/components/primitives/button";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { useConfirm } from "@heffl/ui/components/use-confirm-dialog-provider";
import {
  convertFileSize,
  dynamicDateFormatting,
  formatName,
} from "@heffl/ui/lib/utils";
import { Accept, useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";

import { UserAvatar } from "@/components/UserAvatar";
import enums from "@heffl/server/src/schemas/enums";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import axios from "axios";
import { Download, FilePlus, Trash2, Upload, User } from "lucide-react";
import mime from "mime-types";
import { useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { z } from "zod";

type FileSection = z.infer<typeof enums.S3Folders>;

type FileCardProps = {
  file: {
    id: number;
    name: string;
    format: string;
    section: string;
    size: number;
    link: string;
    createdAt: Date;
    createdBy: {
      firstName: string;
      lastName: string;
      id: number;
    };
  };
};

export const FileCardNew = ({ file }: FileCardProps) => {
  const confirm = useConfirm();

  const deleteFileMutation = trpc.files.delete.useMutation({
    onSuccess() {
      toast.success("File deleted successfully");
    },
  });

  return (
    <Card
      className="flex flex-col gap-2 px-2 w-full h-12 sm:flex-row"
      parentClassName="py-2"
    >
      <FileIcon ext={mime.extension(file.format || "") || ""} />
      <div className="flex flex-col gap-0.5 w-full">
        <div className="flex justify-between w-full">
          <p
            className="w-4/5 text-xs cursor-pointer hover:underline line-clamp-2"
            onClick={() => window.open(getS3URL(file), "_blank")}
          >
            {file.name}
          </p>

          <div className="flex gap-2 items-start">
            <Button
              onClick={async () => {
                const confirmed = await confirm({
                  title: "Are you sure to delete this file?",
                  body: `This will delete the file "${file.name}" and its history.`,
                  actionButton: "Delete",
                });
                if (confirmed) {
                  deleteFileMutation.mutate({
                    id: file.id,
                    section: file.section as z.infer<typeof enums.S3Folders>,
                  });
                }
              }}
              size="xs"
              variant="destructiveOutline"
              icon={Trash2}
            />
            <Button
              onClick={() => downloadS3File(file.section as FileSection, file)}
              size="xs"
              variant="primaryOutline"
              icon={Download}
            />
          </div>
        </div>
        <div className="flex gap-1 text-[11px]">
          <p className="text-gray-400">
            {dynamicDateFormatting(file.createdAt)}
          </p>{" "}
          ·<p className="text-gray-400 font-xs">{convertFileSize(file.size)}</p>
          ·
          <p className="flex gap-0.5 items-center text-gray-400 font-xs">
            <UserAvatar user={file.createdBy} size="xxs" />
            {file.createdBy.firstName}
          </p>
        </div>
      </div>
    </Card>
  );
};

export const FileCard = ({ file }: FileCardProps) => {
  const confirm = useConfirm();

  const deleteFileMutation = trpc.files.delete.useMutation({
    onSuccess() {
      toast.success("File deleted successfully");
    },
  });

  return (
    <Card className="flex flex-col gap-2 px-2 w-full text-gray-400 sm:flex-row">
      <FileIcon ext={mime.extension(file.format || "") || ""} />
      <div className="flex flex-col gap-2 w-full">
        <div className="flex justify-between w-full">
          <p
            className="w-3/5 text-sm font-medium cursor-pointer font-md text-primary hover:underline"
            onClick={() => window.open(getS3URL(file), "_blank")}
          >
            {file.name}
          </p>

          <div className="flex gap-2 items-center">
            <Button
              onClick={async () => {
                const confirmed = await confirm({
                  title: "Are you sure to delete this file?",
                  body: `This will delete the file "${file.name}" and its history.`,
                  actionButton: "Delete",
                });
                if (confirmed) {
                  deleteFileMutation.mutate({
                    id: file.id,
                    section: file.section as z.infer<typeof enums.S3Folders>,
                  });
                }
              }}
              size="xs"
              variant="destructiveOutline"
              icon={Trash2}
            />
            <Button
              onClick={() => downloadS3File(file.section as FileSection, file)}
              size="xs"
              variant="primaryOutline"
              icon={Download}
            />
          </div>
        </div>
        <div className="flex gap-1 text-xs">
          <p className="text-gray-400">
            {dynamicDateFormatting(file.createdAt)}
          </p>{" "}
          ·<p className="text-gray-400 font-xs">{convertFileSize(file.size)}</p>
          ·
          <p className="flex items-center text-gray-400 font-xs">
            <User className="h-4" />
            {formatName(file.createdBy)}
          </p>
        </div>
      </div>
    </Card>
  );
};

type FileUploadModalProps = {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  section: z.infer<typeof enums.S3Folders>;
  entity: z.infer<typeof enums.entities>;
  entityId: number | null;
  multiple?: boolean;
  accept?: Accept;
  className?: string;
  parentFolderId?: number | null;
};

export const UploadFileModalNew = (props: FileUploadModalProps) => {
  const [uploading, setUploading] = useState(false);

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess(data) {
        console.log(data);
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const addFileMutation = trpc.files.addFiles.useMutation({
    onSuccess() {
      toast.success("File uploaded successfully");
      props.onSuccess?.();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const multiple = props.multiple || true;

  const doUpload = async (files: File[]) => {
    const filesToUpload = Array.from(files);
    setUploading(true);
    const presignedUrls = await getPresignedUrls({
      files: filesToUpload.map((file) => ({
        name: file.name,
        format: file.type,
        size: file.size,
      })),
      section: props.section,
    });
    const addFilesResponse = await addFileMutation.mutateAsync(
      presignedUrls.map((file) => ({
        name: file.name,
        format: file.format,
        size: file.size,
        link: file.link,
        entity: props.entity,
        entityId: props.entityId,
        section: props.section,
        parentFolderId: props.parentFolderId || null,
      }))
    );
    if (addFilesResponse) {
      await Promise.all(
        presignedUrls.map(async (presignedUrl, index) => {
          await axios.put(presignedUrl.presignedUrl, filesToUpload[index], {
            headers: {
              "Content-Type": presignedUrl.format,
            },
          });
        })
      );
    }
    setUploading(false);
    props.onClose();
  };

  const onPickFile = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      doUpload(multiple ? acceptedFiles : [acceptedFiles[0]]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onPickFile,
    accept: props.accept,
    multiple: multiple,
  });

  return (
    <ModalDrawer open={props.open} onClose={props.onClose} title="Upload file">
      {uploading && (
        <div className="flex flex-col gap-1 justify-center items-center w-full h-36">
          <FullScreenSpinner className="pt-0" />
          <p className="text-base font-medium text-gray-600 animate-pulse">
            Uploading files...
          </p>
        </div>
      )}

      {!uploading && (
        <div {...getRootProps()} className="cursor-pointer">
          <input {...getInputProps()} />
          <div className="flex flex-col gap-2 justify-center items-center py-4 mt-4 w-full h-full bg-white rounded-lg border border-dashed border-primary">
            <Upload className="h-7 text-primary" />
            <p className="text-base font-medium text-center text-gray-600">
              {isDragActive
                ? "Drop the files here ..."
                : "Drag and Drop files to upload"}
            </p>
            <p className="text-sm text-gray-800">or</p>
            <button className="px-6 py-2 text-white rounded-full cursor-pointer bg-primary">
              Browse
            </button>
          </div>
        </div>
      )}
    </ModalDrawer>
  );
};

export const InstantFileUploadCard = (props: FileUploadModalProps) => {
  const [uploading, setUploading] = useState(false);

  const { mutateAsync: getPresignedUrls } =
    trpc.files.presignedUrls.useMutation({
      onSuccess(data) {
        console.log(data);
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const addFileMutation = trpc.files.addFiles.useMutation({
    onSuccess() {
      toast.success("File uploaded successfully");
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const multiple = props.multiple || true;

  const doUpload = async (files: File[]) => {
    const filesToUpload = Array.from(files);
    setUploading(true);
    const presignedUrls = await getPresignedUrls({
      files: filesToUpload.map((file) => ({
        name: file.name,
        format: file.type,
        size: file.size,
      })),
      section: props.section,
    });
    const addFilesResponse = await addFileMutation.mutateAsync(
      presignedUrls.map((file) => ({
        name: file.name,
        format: file.format,
        size: file.size,
        link: file.link,
        entity: props.entity,
        entityId: props.entityId,
        section: props.section,
        parentFolderId: null,
      }))
    );
    if (addFilesResponse) {
      await Promise.all(
        presignedUrls.map(async (presignedUrl, index) => {
          await axios.put(presignedUrl.presignedUrl, filesToUpload[index], {
            headers: {
              "Content-Type": presignedUrl.format,
            },
          });
        })
      );
    }
    setUploading(false);
    props.onClose();
  };

  const onPickFile = (acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      doUpload(multiple ? acceptedFiles : [acceptedFiles[0]]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onPickFile,
    accept: props.accept,
    multiple: multiple,
  });

  return (
    <div className={props.className}>
      <div {...getRootProps()} className="h-8 cursor-pointer">
        <input {...getInputProps()} />
        <div className="flex gap-2 justify-center items-center p-2 w-full rounded-lg border border-dashed">
          {uploading ? (
            <>
              <Icon
                icon="tabler:cloud-upload"
                className="w-4 h-4 motion-preset-oscillate"
              />
              <p className="text-xs text-gray-600">Uploading ...</p>
            </>
          ) : (
            <>
              <p className="text-xs text-center text-gray-600">
                Drop your files here to
              </p>
              <Button size="xs" variant="primary">
                Upload
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Files = () => {
  const params = useParams();

  const projectId = Number(params.projectId);

  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const { data: files } = trpc.files.listNew.useQuery({
    entity: "PROJECT",
    entityId: projectId,
    parentFolderId: null,
  });

  if (!files) return <FullScreenSpinner />;

  return (
    <div className="flex flex-col mt-2">
      <div className="flex justify-end">
        <Button
          onClick={() => setShowFileUploadModal(true)}
          icon={Upload}
          size="sm"
          variant="primary"
        >
          Upload file
        </Button>
      </div>

      <UploadFileModalNew
        open={showFileUploadModal}
        onClose={() => setShowFileUploadModal(false)}
        section="projects"
        entity="PROJECT"
        entityId={projectId}
      />
      <div className="mt-3">
        {files.length ? (
          <div className="grid grid-cols-2 gap-4">
            {files.map((file) => (
              <FileCard key={file.id} file={file} />
            ))}
          </div>
        ) : (
          <Empty
            className="mt-8"
            title="No files added yet"
            icon={FilePlus}
            description="Please add a file to the project"
          />
        )}
      </div>
    </div>
  );
};

export default Files;
