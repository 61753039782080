import { zodResolver } from "@hookform/resolvers/zod";

import { useForm } from "react-hook-form";

import Schemas from "@heffl/server/src/schemas";
import { Form } from "@heffl/ui/components/primitives/form";
import { z } from "zod";
import { RecurringInvoiceForm } from "./add";
import { useNavigate, useParams } from "react-router-dom";
import { trpc } from "@/helpers/trpc";
import heffl from "@/helpers/hefflHelpers/heffl";
import Page from "@/components/page";
import ResponsivePrimaryButton from "@heffl/ui/components/ResponsivePrimaryButton";
import { useEffect } from "react";

const EditRecurringInvoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof Schemas.sales.recurringInvoice>>({
    resolver: zodResolver(Schemas.sales.recurringInvoice),
  });

  const { data: recurringInvoice } =
    trpc.sales.recurringInvoices.details.useQuery(Number(id));

  const updateRecurringInvoice =
    trpc.sales.recurringInvoices.update.useMutation({
      onSuccess: () => {
        heffl.toast.success("Recurring invoice updated");
        navigate("/sales/recurring-invoices");
      },
      onError: (error) => {
        heffl.toast.error(error.message);
      },
    });

  const onSubmit = (data: z.infer<typeof Schemas.sales.recurringInvoice>) => {
    updateRecurringInvoice.mutate({
      id: Number(id),
      recurringInvoice: data,
    });
  };

  useEffect(() => {
    if (recurringInvoice) {
      form.reset(recurringInvoice);
    }
  }, [recurringInvoice]);

  return (
    <Page
      title="Edit Recurring Invoice"
      className="max-w-screen-lg"
      showBack
      breadcrumbs={[
        {
          label: "Recurring Invoices",
          path: "/sales/recurring-invoices",
        },
        {
          label: "Edit recurring invoice",
          path: `/sales/recurring-invoices/edit/${id}`,
        },
      ]}
      suffix={
        <div className="flex gap-2 items-center">
          <ResponsivePrimaryButton
            onClick={() => form.handleSubmit(onSubmit)()}
            type="submit"
            variant="primary"
            loading={updateRecurringInvoice.isLoading}
            iconify="tabler:plus"
          >
            Update Recurring Invoice
          </ResponsivePrimaryButton>
        </div>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <RecurringInvoiceForm form={form} edit={true} />
      </Form>
    </Page>
  );
};

export default EditRecurringInvoice;
