// import FilterBar from "@/components/filters";
import EmptyState from "@/components/empty-state";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { RouterOutputs, trpc } from "@/helpers/trpc";
import appIcons from "@heffl/ui/components/appIcons";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Card } from "@heffl/ui/components/primitives/card";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import MiniRichTextEditor from "@heffl/ui/components/primitives/mini-rich-text-editor";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import StripeTabs from "@heffl/ui/components/primitives/stripe-tabs";
import SimpleTable from "@heffl/ui/components/simple-table";
import StatsBar from "@heffl/ui/components/stats-cards";
import { cn, dynamicDateFormatting, makeEllipsis } from "@heffl/ui/lib/utils";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import { debounce } from "lodash";
import {
  Briefcase,
  Calendar,
  Check,
  CheckCircle,
  Clock,
  FileText,
  ListTodo,
  Pause,
  Pencil,
  PlayCircle,
  Target,
} from "lucide-react";
import { parseAsBoolean, useQueryState } from "nuqs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { taskDateColor } from "../details/components/kanban/TaskCard";
import { ProjectTaskDetailsModal } from "../tasks/components/project-task-modals";
import {
  AddTimesheetDrawer,
  convertToHoursAndMinutes,
  EditTimesheetDrawer,
} from "../timesheets/list";
import AddCustomDashboardModal from "./components/add-custom-dashboard-modal";

interface TaskTableProps {
  tasks: RouterOutputs["projects"]["dashboard"]["myTasks"]["tasks"];
  onTaskClick: (taskId: number) => void;
  emptyStateTitle: string;
  emptyStateDescription: string;
  showAssignees?: boolean;
}

const TaskTable = ({
  tasks,
  onTaskClick,
  emptyStateTitle,
  emptyStateDescription,
  showAssignees = false,
}: TaskTableProps) => {
  if (!tasks?.length) {
    return (
      <div className="flex justify-center items-center !w-full">
        <EmptyState
          type="tasks"
          title={emptyStateTitle}
          description={emptyStateDescription}
        />
      </div>
    );
  }

  return (
    <SimpleTable
      className="mt-2"
      height={240}
      rows={tasks}
      idKey="id"
      onRowClick={(row) => onTaskClick(row.id)}
      columns={[
        {
          label: "Title",
          icon: FileText,
          render: (row) => (
            <Tooltip title={row.title} mouseEnterDelay={0.5}>
              <div>
                <p className="text-sm !line-clamp-1">
                  {makeEllipsis(row.title, 30)}
                </p>
                <span className="flex flex-wrap gap-1 items-center mt-1">
                  {showAssignees &&
                    row.projectTaskAssignees.map((assignee) => (
                      <Badge
                        key={assignee.users.id}
                        small
                        variant="outline"
                        avatar
                      >
                        {heffl.format.name(assignee.users)}
                      </Badge>
                    ))}
                  {!!row.projects?.clients && (
                    <Badge
                      small
                      variant="outline"
                      icon={appIcons.sales.clients.icon}
                    >
                      {makeEllipsis(row.projects.clients.name, 12)}
                    </Badge>
                  )}
                </span>
              </div>
            </Tooltip>
          ),
        },
        {
          label: "Status",
          icon: Target,
          width: 140,
          render: (row) => (
            <div>
              <span
                className={cn(
                  "text-xs rounded tracking-wide px-2 py-1 flex items-center gap-1 ",
                  {
                    "bg-yellow-200 bg-opacity-40 text-yellow-500":
                      row.status === "IN_PROGRESS",
                    "bg-red-200 bg-opacity-40 text-red-500":
                      row.status === "ON_HOLD",
                    "bg-blue-200 bg-opacity-40 text-blue-500":
                      row.status === "OPEN",
                    "bg-green-200 bg-opacity-40 text-green-600":
                      row.status === "COMPLETED",
                  }
                )}
              >
                {row.status === "IN_PROGRESS" ? (
                  <>
                    <PlayCircle size={14} /> In Progress
                  </>
                ) : row.status === "ON_HOLD" ? (
                  <>
                    <Pause size={14} /> On Hold
                  </>
                ) : row.status === "OPEN" ? (
                  <>
                    <Clock size={14} /> Open
                  </>
                ) : row.status === "COMPLETED" ? (
                  <>
                    <CheckCircle size={14} /> Completed
                  </>
                ) : (
                  row.status
                )}
              </span>
              {row.status === "COMPLETED" && row.completedOn && (
                <p className="text-[10px] text-gray-500">
                  on {heffl.format.dynamicDate(row.completedOn, true)}
                </p>
              )}
            </div>
          ),
        },
        {
          label: "Due Date",
          icon: Calendar,
          width: 130,
          render: (row) =>
            row.date && (
              <p
                className={cn(
                  "",
                  taskDateColor(row.date, row.status === "COMPLETED")
                )}
              >
                {dynamicDateFormatting(row.date, true)}
              </p>
            ),
        },
      ]}
    />
  );
};

const ProjectsDashboard = () => {
  const navigate = useNavigate();

  const [addDashboard, setAddDashboard] = useQueryState(
    "addDashboard",
    parseAsBoolean.withDefault(false)
  );

  const [myTasksTab, setMyTasksTab] = useState<
    "pending" | "completed" | "inProgress" | "upcoming"
  >("pending");
  const [assignedTasksTab, setAssignedTasksTab] = useState<
    "pending" | "completed" | "inProgress" | "upcoming"
  >("pending");
  const [editTaskId, setEditTaskId] = useState<number | undefined>();

  const [addTimesheetDrawerOpen, setAddTimesheetDrawerOpen] = useState(false);
  const [editTimesheetId, setEditTimesheetId] = useState<number | undefined>();

  const { data: currentUser } = trpc.users.currentUser.useQuery();

  const { data: dashboardData } =
    trpc.projects.dashboard.personalNew.useQuery();

  const { data: assignedTasks, isLoading: assignedTasksLoading } =
    trpc.projects.dashboard.tasksIAssigned.useQuery(
      {
        status: assignedTasksTab,
      },
      {
        keepPreviousData: true,
      }
    );
  const { data: myTasks, isLoading: myTasksLoading } =
    trpc.projects.dashboard.myTasks.useQuery(
      {
        status: myTasksTab,
      },
      {
        keepPreviousData: true,
      }
    );

  const updateNotesMutation = trpc.users.update.useMutation({
    onSuccess: () => {
      return { invalidate: false };
    },
  });

  const debouncedUpdateNotesMutation = debounce(
    updateNotesMutation.mutate,
    500
  );

  if (!dashboardData || !currentUser) return <FullScreenSpinner />;

  return (
    <Page fullWidth className="bg-[#F7F8FA]">
      <AddTimesheetDrawer
        open={addTimesheetDrawerOpen}
        onClose={() => setAddTimesheetDrawerOpen(false)}
      />
      <AddCustomDashboardModal
        open={
          addDashboard &&
          (currentUser.type === "ADMIN" || currentUser.type === "SUPER_ADMIN")
        }
        onClose={() => setAddDashboard(false)}
      />
      {!!editTimesheetId && (
        <EditTimesheetDrawer
          open={!!editTimesheetId}
          onClose={() => setEditTimesheetId(undefined)}
          timesheetId={editTimesheetId}
        />
      )}
      {!!editTaskId && (
        <ProjectTaskDetailsModal
          open={!!editTaskId}
          onClose={() => setEditTaskId(undefined)}
          taskId={editTaskId}
          switchToTask={(taskId) => {
            setEditTaskId(taskId);
          }}
        />
      )}
      <div className="space-y-4">
        <div className="flex flex-col gap-3 justify-center items-center w-full">
          <p className="font-normal opacity-80 lg:text-lg">
            {dayjs().format("dddd, MMMM D")}
          </p>
          <div className="flex gap-2 items-center text-2xl font-medium lg:text-3xl">
            <div className="motion-preset-shake">👋</div>
            {dayjs().hour() < 12
              ? "Good morning"
              : dayjs().hour() < 17
              ? "Good afternoon"
              : "Good evening"}
            , {currentUser.firstName}
          </div>

          <div className="grid grid-cols-1 gap-6 mt-4 w-full lg:grid-cols-2">
            <Card title="My Tasks" className="pt-0">
              <StripeTabs
                className="mt-1 w-full"
                value={myTasksTab}
                onChange={setMyTasksTab}
                items={[
                  {
                    key: "pending",
                    label: "Pending",
                    count: myTasks?.stats.pending,
                  },
                  {
                    key: "inProgress",
                    label: "In Progress",
                    count: myTasks?.stats.inProgress,
                  },
                  {
                    key: "upcoming",
                    label: "Upcoming",
                    count: myTasks?.stats.upcoming,
                  },
                  {
                    key: "completed",
                    label: "Completed",
                  },
                ]}
              />
              {myTasks && !myTasksLoading ? (
                <TaskTable
                  tasks={
                    myTasksTab === "completed"
                      ? [...myTasks.tasks].sort((a, b) => {
                          // First sort by completedOn date
                          const completedOnA = a.completedOn?.getTime() || 0;
                          const completedOnB = b.completedOn?.getTime() || 0;
                          if (completedOnA !== completedOnB) {
                            return completedOnB - completedOnA; // Descending order
                          }
                          // Then sort by task date
                          return (
                            (b.date?.getTime() || 0) - (a.date?.getTime() || 0)
                          );
                        })
                      : myTasks.tasks
                  }
                  onTaskClick={setEditTaskId}
                  emptyStateTitle="No pending tasks"
                  emptyStateDescription="Your pending tasks will appear here"
                />
              ) : (
                <FullScreenSpinner />
              )}
            </Card>

            <Card title="Private Notes">
              <div className="w-full">
                <MiniRichTextEditor
                  placeholder="Write your personal notes here..."
                  height={314}
                  value={currentUser.personalNotes || ""}
                  onChange={(value) =>
                    debouncedUpdateNotesMutation({
                      id: currentUser.id,
                      user: { personalNotes: value },
                    })
                  }
                />
              </div>
            </Card>

            <Card title="Tasks I Assigned" className="pt-0">
              <StripeTabs
                className="mt-1 w-full"
                value={assignedTasksTab}
                onChange={setAssignedTasksTab}
                items={[
                  {
                    key: "pending",
                    label: "Pending",
                    count: assignedTasks?.stats.pending,
                  },
                  {
                    key: "inProgress",
                    label: "In Progress",
                    count: assignedTasks?.stats.inProgress,
                  },
                  {
                    key: "upcoming",
                    label: "Upcoming",
                    count: assignedTasks?.stats.upcoming,
                  },
                  {
                    key: "completed",
                    label: "Completed",
                  },
                ]}
              />
              {assignedTasks && !assignedTasksLoading ? (
                <TaskTable
                  tasks={
                    assignedTasksTab === "completed"
                      ? [...assignedTasks.tasks].sort((a, b) => {
                          // First sort by completedOn date if available
                          if (a.completedOn && b.completedOn) {
                            return (
                              dayjs(b.completedOn).valueOf() -
                              dayjs(a.completedOn).valueOf()
                            );
                          }
                          // Then sort by task date
                          if (a.date && b.date) {
                            return (
                              dayjs(b.date).valueOf() - dayjs(a.date).valueOf()
                            );
                          }
                          return 0;
                        })
                      : assignedTasks.tasks
                  }
                  onTaskClick={setEditTaskId}
                  emptyStateTitle="No pending tasks"
                  showAssignees
                  emptyStateDescription="Your pending tasks will appear here"
                />
              ) : (
                <FullScreenSpinner />
              )}
            </Card>
            <Card
              title="My Projects"
              suffix={
                (dashboardData?.projects?.length ?? 0) > 0 && (
                  <span className="flex justify-center items-center w-6 h-6 text-xs font-medium text-gray-700 bg-gray-100 rounded-full">
                    {dashboardData?.projects?.length ?? 0}
                  </span>
                )
              }
            >
              {!dashboardData?.projects?.length ? (
                <EmptyState
                  type="projects"
                  title="No projects"
                  description="Your projects will appear here"
                />
              ) : (
                <SimpleTable
                  height={280}
                  rows={dashboardData.projects}
                  idKey="id"
                  onRowClick={(row) => navigate(`/projects/details/${row.id}`)}
                  columns={[
                    {
                      label: "Project",
                      icon: Briefcase,
                      render: (row) => row.title,
                      width: 150,
                      fixed: "left",
                    },
                    {
                      label: "Status",
                      width: 100,
                      icon: Target,
                      render: (row) => (
                        <span
                          className={`text-xs rounded px-2 py-1 ${
                            row.status === "ACTIVE"
                              ? "bg-green-100 text-green-600"
                              : row.status === "COMPLETED" ||
                                row.status === "CANCELLED"
                              ? "bg-red-100 text-red-600"
                              : "bg-yellow-100 text-yellow-600"
                          }`}
                        >
                          {row.status === "ACTIVE"
                            ? "Active"
                            : row.status === "COMPLETED"
                            ? "Completed"
                            : row.status === "CANCELLED"
                            ? "Cancelled"
                            : "Inactive"}
                        </span>
                      ),
                    },
                    {
                      label: "Tasks",
                      icon: ListTodo,
                      render: (row) => (
                        <div className="flex gap-2 items-center whitespace-nowrap">
                          <span className="px-2 py-1 text-xs text-blue-600 bg-blue-50 rounded border border-blue-100">
                            Open:{" "}
                            <span className="font-semibold">
                              {row.projectTasks?.filter(
                                (task) => task.status === "OPEN"
                              ).length || 0}
                            </span>
                          </span>
                          <span className="px-2 py-1 text-xs text-red-600 bg-red-50 rounded border border-red-100">
                            On Hold:{" "}
                            <span className="font-semibold">
                              {row.projectTasks?.filter(
                                (task) => task.status === "ON_HOLD"
                              ).length || 0}
                            </span>
                          </span>
                          <span className="px-2 py-1 text-xs text-yellow-600 bg-yellow-50 rounded border border-yellow-100">
                            In Progress:{" "}
                            <span className="font-semibold">
                              {row.projectTasks?.filter(
                                (task) => task.status === "IN_PROGRESS"
                              ).length || 0}
                            </span>
                          </span>
                          <span className="px-2 py-1 text-xs text-green-600 bg-green-50 rounded border border-green-100">
                            Completed:{" "}
                            <span className="font-semibold">
                              {row.projectTasks?.filter(
                                (task) => task.status === "COMPLETED"
                              ).length || 0}
                            </span>
                          </span>
                        </div>
                      ),
                    },
                  ]}
                />
              )}
            </Card>

            <Card
              title="My Timesheets"
              suffix={
                <ResponsiveActionButton
                  onClick={() => setAddTimesheetDrawerOpen(true)}
                  text="Timesheet"
                />
              }
              parentClassName="sm:col-span-2 w-full"
            >
              <StatsBar
                className="mb-4 sm:w-fit"
                items={[
                  {
                    title: "Billable",
                    value: `${convertToHoursAndMinutes(
                      dashboardData?.timesheets?.stats.billableMinutes || 0
                    )} hours`,
                    icon: Clock,
                  },
                  {
                    title: "Non-billable",
                    value: `${convertToHoursAndMinutes(
                      dashboardData?.timesheets?.stats.nonBillableMinutes || 0
                    )} hours`,
                    icon: Clock,
                  },
                  {
                    title: "Total",
                    value: `${convertToHoursAndMinutes(
                      dashboardData?.timesheets?.stats.totalMinutes || 0
                    )} hours`,
                    icon: Clock,
                  },
                ]}
              />

              {!dashboardData?.timesheets?.list?.length ? (
                <EmptyState
                  type="timesheets"
                  title="No timesheets"
                  description="Your timesheets will appear here"
                />
              ) : (
                <SimpleTable
                  idKey="id"
                  borderless
                  height={300}
                  rows={dashboardData?.timesheets?.list}
                  actions={(row) => [
                    {
                      type: "item",
                      label: "Edit",
                      icon: Pencil,
                      onClick: () => setEditTimesheetId(row.id),
                    },
                  ]}
                  columns={[
                    {
                      label: "Project",
                      icon: Briefcase,
                      render: (row) => (
                        <p
                          className="cursor-pointer hover:text-primary-600"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/projects/details/${row.projectTasks?.projects?.id}`
                            );
                          }}
                        >
                          {row.projectTasks?.projects?.title}
                        </p>
                      ),
                      responsive: ["sm"],
                    },
                    {
                      label: "Task",
                      icon: ListTodo,
                      render: (row) => (
                        <p
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditTaskId(row.projectTasks?.id);
                          }}
                          className="cursor-pointer hover:text-primary-600"
                        >
                          {row.projectTasks?.title}
                        </p>
                      ),
                      responsive: ["sm"],
                    },
                    {
                      label: "Hours",
                      icon: Clock,
                      render: (row) => (
                        <p>{convertToHoursAndMinutes(row.minutesWorked)} Hrs</p>
                      ),
                    },
                    {
                      label: "Status",
                      icon: Target,
                      render: (row) => (
                        <span
                          className={cn(
                            "text-xs rounded tracking-wide px-2 py-1 flex items-center gap-1 w-fit",
                            {
                              "bg-blue-200 bg-opacity-40 text-blue-500":
                                row.approvalStatus === "OPEN",
                              "bg-yellow-200 bg-opacity-40 text-yellow-600":
                                row.approvalStatus === "APPROVED",
                              "bg-red-200 bg-opacity-40 text-red-500":
                                row.approvalStatus === "REJECTED",
                            }
                          )}
                        >
                          {row.approvalStatus === "OPEN" ? (
                            <>
                              <Clock size={14} /> Open
                            </>
                          ) : row.approvalStatus === "APPROVED" ? (
                            <>
                              <Check size={14} /> Approved
                            </>
                          ) : row.approvalStatus === "REJECTED" ? (
                            <>
                              <Pause size={14} /> Rejected
                            </>
                          ) : (
                            row.approvalStatus
                          )}
                        </span>
                      ),
                    },
                    {
                      label: "Date",
                      icon: Calendar,
                      render: (row) =>
                        dayjs(row.createdAt).format("DD MMM YYYY"),
                      responsive: ["sm"],
                    },
                  ]}
                />
              )}
            </Card>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ProjectsDashboard;
