import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CashflowForm from "./cashflow-form";
import { Form } from "@heffl/ui/components/primitives/form";
import { Button } from "@heffl/ui/components/primitives/button";
import { Plus } from "lucide-react";
import { capitalize } from "radash";
import { toast } from "react-hot-toast";
import { trpc } from "@/helpers/trpc";
import heffl from "@/helpers/hefflHelpers/heffl";

type AddCashflowModalProps = {
  open: boolean;
  onClose: () => void;
  defaultValues?: Partial<z.infer<typeof Schemas.books.cashflowTransaction>>;
};

const AddCashflowModal = ({
  open,
  onClose,
  defaultValues,
}: AddCashflowModalProps) => {
  const form = useForm<z.infer<typeof Schemas.books.cashflowTransaction>>({
    resolver: zodResolver(Schemas.books.cashflowTransaction),
    defaultValues: {
      date: heffl.date.now(),
      ...defaultValues,
    },
  });
  const type = form.watch("type");

  const addCashflowTransaction =
    trpc.books.cashflowAccounts.transactions.add.useMutation({
      onSuccess: () => {
        toast.success("Transaction added");
        onClose();
        form.reset();
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

  const onSubmit = (
    data: z.infer<typeof Schemas.books.cashflowTransaction>
  ) => {
    addCashflowTransaction.mutate(data);
  };

  return (
    <ModalDrawer
      title={`Add ${type.split("_").map(capitalize).join(" ")}`}
      open={open}
      onClose={onClose}
      footer={
        <Button
          onClick={form.handleSubmit(onSubmit)}
          type="submit"
          className="w-full"
          variant="primary"
          icon={Plus}
        >
          Add Transaction
        </Button>
      }
    >
      <Form {...form} onSubmit={onSubmit}>
        <CashflowForm form={form} />
      </Form>
    </ModalDrawer>
  );
};

export default AddCashflowModal;
