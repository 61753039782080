import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarRail,
  useSidebar,
} from "@heffl/ui/components/primitives/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { NavGroup } from "./nav-group";
import { appNavs } from "./sidebar-data";
import LogoSmall from "@/assets/images/logo_small.png";
import { trpc } from "@/helpers/trpc";
import { useMemo } from "react";

export function NewSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const navigate = useNavigate();
  const { state } = useSidebar();
  const isCollapsed = state === "collapsed";

  const { data: team } = trpc.teams.currentTeam.useQuery();

  const { pathname } = useLocation();
  const selectedApp = useMemo(() => pathname.split("/")[1], [pathname]);

  const { data } = trpc.dashboards.list.useQuery();

  const projectDashboards = useMemo(
    () => [
      {
        icon: "tabler:user-circle",
        title: "Personal Dashboard",
        url: "/projects/dashboard",
      },
      ...(data?.dashboards.map((dashboard) => ({
        icon: "tabler:chart-pie",
        title: dashboard.title,
        url: `/projects/dashboard/${dashboard.id}`,
      })) || []),
    ],
    [data?.dashboards]
  );

  const sidebarData = useMemo(
    () => appNavs({ projectDashboards, teamId: team?.id || 0 }),
    [projectDashboards, team?.id]
  );

  return (
    <Sidebar
      collapsible="icon"
      variant="floating"
      {...props}
      className="border-r"
    >
      <SidebarHeader className="!p-0 border-b h-[41px] flex justify-center">
        <div
          className="flex gap-2 items-center pl-2 cursor-pointer"
          onClick={() => navigate("/")}
        >
          <img src={LogoSmall} alt="Heffl" className="w-4" />
          {!isCollapsed && (
            <span className="text-xl font-medium peer-data-[state=expanded]:block peer-data-[state=collapsed]:hidden">
              Heffl
            </span>
          )}
        </div>
      </SidebarHeader>
      <SidebarContent>
        {/* {appNavs
          .find((app) => app.pathId === selectedApp)
          ?.navGroups.map((props) => (
            <div key={props.title} className="flex flex-col pt-2">
              {props.items.map((item) => {
                return (
                  <div
                    key={item.title}
                    className="flex gap-2 items-center px-0 py-2 pl-2 rounded hover:bg-gray-100"
                  >
                    {item.icon && <item.icon className="w-4 h-4" />}
                    {item.title}
                  </div>
                );
              })}
            </div>
          ))} */}
        {sidebarData
          .find((app) => app.pathId === selectedApp)
          ?.navGroups.map((props) => (
            <NavGroup key={props.title} {...props} />
          ))}
      </SidebarContent>
      <SidebarRail />
    </Sidebar>
  );

  return (
    <div className="flex overflow-hidden fixed h-screen bg-gray-100 !z-50">
      {/* <div className="flex flex-col ml-0.5 bg-gray-100 h-full relative">
        <div
          className="absolute left-0 w-0.5 h-10 bg-green-500 transition-transform duration-200 ease-in-out"
          style={{
            transform: `translateY(${
              apps.findIndex((app) => app.name === selectedApp) * 40
            }px)`,
          }}
        />
        {apps.slice(0, 5).map((app) => (
          <div
            className={cn(
              "flex p-2 h-10 text-gray-700 cursor-pointer sm:gap-3"
            )}
            key={app.name}
            onClick={() => setSelectedApp(app.name)}
          >
            <app.icon className={cn("w-6 h-6", `text-${app.color}`)} />
          </div>
        ))}
      </div> */}
    </div>
  );
}
