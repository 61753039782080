import { dinero, greaterThan, add, subtract, toSnapshot } from "dinero.js";
import { AED } from "@dinero.js/currencies";

const money = {
  gt: (a: number, b: number) => {
    // this only works for AED or currency with 100 subunits, oman rial has 1000 subunits
    return greaterThan(
      dinero({ amount: Math.round(a * 100), currency: AED }),
      dinero({ amount: Math.round(b * 100), currency: AED })
    );
  },
  sum: <T>(arr: T[], fn: (item: T) => number) => {
    // Convert each amount to dinero, sum them, then convert back to number
    return arr.reduce((acc, item) => {
      const amount = fn(item);
      const dineroAmount = dinero({
        amount: Math.round(amount * 100),
        currency: AED,
      });
      const accDinero = dinero({
        amount: Math.round(acc * 100),
        currency: AED,
      });
      const total = add(accDinero, dineroAmount);
      return total.toJSON().amount / 100;
    }, 0);
  },
  add: (a: number, b: number) => {
    const aDinero = dinero({ amount: Math.round(a * 100), currency: AED });
    const bDinero = dinero({ amount: Math.round(b * 100), currency: AED });
    return toSnapshot(add(aDinero, bDinero)).amount / 100;
  },
  subtract: (a: number, b: number) => {
    const aDinero = dinero({ amount: Math.round(a * 100), currency: AED });
    const bDinero = dinero({ amount: Math.round(b * 100), currency: AED });
    return toSnapshot(subtract(aDinero, bDinero)).amount / 100;
  },
};

export default money;
