import DropMenu from "@/components/DropMenu";
import { SearchInput } from "@/components/FormComponents";
import DataGrid from "@/components/dataGrid/DataGrid";
import FilterBar from "@/components/filter-bar";
import Page from "@/components/page";
import heffl from "@/helpers/hefflHelpers/heffl";
import { trpc } from "@/helpers/trpc";
import { useParamsState } from "@/lib/hooks/useParamsState";
import usePermissions from "@/lib/hooks/usePermissions";
import {
  formatCustomFields,
  renderCustomFieldValue,
} from "@heffl/server/src/helpers/customFields";
import { calculateLineItems } from "@heffl/server/src/helpers/lineItems/calculateLineItems";
import enums from "@heffl/server/src/schemas/enums";
import NoPermissionScreen from "@heffl/ui/components/no-permissions";
import { Badge } from "@heffl/ui/components/primitives/badge";
import { Button } from "@heffl/ui/components/primitives/button";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import ResponsiveActionButton from "@heffl/ui/components/primitives/responsive-action-button";
import { formatName } from "@heffl/ui/lib/utils";
import { format } from "date-fns";
import {
  Ban,
  Building,
  Calendar,
  Check,
  Circle,
  Copy,
  DollarSign,
  Eye,
  MoreHorizontal,
  Pencil,
  User,
  Zap,
} from "lucide-react";
import { sum } from "radash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

type SalesOrderStatus = z.infer<typeof enums.salesOrderStatus>;

export const SalesOrderStatusBadge = ({
  status,
  small,
}: {
  status: SalesOrderStatus;
  small?: boolean;
}) => {
  switch (status) {
    case "DRAFT":
      return (
        <Badge variant="neutral" small={small} icon={Circle}>
          Draft
        </Badge>
      );
    case "CONFIRMED":
      return (
        <Badge variant="success" small={small} icon={Check}>
          Confirmed
        </Badge>
      );
    case "CANCELLED":
      return (
        <Badge variant="error" small={small} icon={Ban}>
          Cancelled
        </Badge>
      );
    default:
      return (
        <Badge variant="neutral" small={small}>
          {status}
        </Badge>
      );
  }
};

type PageFilters = {
  search: string;
  pageNo: number;
  statuses: SalesOrderStatus[];
  clients: number[];
  salesPersons: number[];
  pageSize: number;
};

const SalesOrders = () => {
  const navigate = useNavigate();
  const permissions = usePermissions();

  const [filters, setFilters] = useParamsState<PageFilters>({
    search: "",
    pageNo: 1,
    statuses: [],
    clients: [],
    salesPersons: [],
    pageSize: 30,
  });

  const [clientSearch, setClientSearch] = useState("");

  const { data: salesOrders, isLoading } = trpc.sales.salesOrders.list.useQuery(
    {
      ...filters,
    }
  );
  const { data: salesOrdersCustomFields } =
    trpc.customizations.customFields.list.useQuery({
      section: "SALES_ORDER",
    });

  const { data: users } = trpc.users.list.useQuery({
    excludeType: ["FIELD_STAFF"],
  });

  const { data: clients } = trpc.clients.list.useQuery({
    search: clientSearch,
    pageNo: 1,
    pageSize: 10,
  });

  if (!permissions || !salesOrders) return <FullScreenSpinner />;
  if (!permissions.VIEW_SALES_ORDERS.allowed) {
    return <NoPermissionScreen />;
  }

  return (
    <Page fullWidth title="Sales Orders" className="!p-0">
      <div className="flex flex-col gap-2 justify-between p-3 border-b sm:flex-row">
        <SearchInput
          placeholder="Search sales orders..."
          value={filters.search}
          onChange={(e) => setFilters({ search: e })}
        />
        <div className="flex gap-2 items-center">
          {permissions.CREATE_SALES_ORDERS.allowed && (
            <ResponsiveActionButton
              onClick={() => navigate("add")}
              text="Sales Order"
            />
          )}
        </div>
      </div>

      <FilterBar
        suffix={
          <div className="p-1 px-2 text-sm rounded-lg border shadow-sm">
            Total Orders: {salesOrders?.meta.count || 0}
          </div>
        }
        onChange={() => {
          setFilters({
            pageNo: 1,
          });
        }}
        className="p-3"
        filters={[
          {
            key: "status",
            type: "checkbox",
            label: "Status",
            value: filters.statuses,
            onChange: (value) =>
              setFilters({ statuses: value as SalesOrderStatus[] }),
            options: [
              { label: "Draft", value: "DRAFT" },
              { label: "Confirmed", value: "CONFIRMED" },
              { label: "Cancelled", value: "CANCELLED" },
            ],
          },
          {
            key: "clients",
            type: "checkbox",
            label: "Clients",
            value: filters.clients,
            showSearch: true,
            onChange: (value) => setFilters({ clients: value as number[] }),
            onSearch: (value) => setClientSearch(value),
            options: clients?.clients.map((client) => ({
              label: client.name,
              value: client.id,
            })),
          },
          {
            key: "salesPersons",
            label: "Sales Persons",
            type: "checkbox",
            value: filters.salesPersons,
            options: users?.map((user) => ({
              label: formatName(user),
              value: user.id,
            })),
            onChange: (e) => {
              setFilters({ salesPersons: e as number[] });
            },
          },
        ]}
      />

      {isLoading || !salesOrders ? (
        <FullScreenSpinner />
      ) : (
        <DataGrid
          rowKey="id"
          className="h-[calc(100vh-var(--header-height)-117px)]"
          onCellClick={(salesOrder) => {
            navigate(`/sales/sales-orders/details/${salesOrder.row.id}`);
          }}
          loading={isLoading}
          name="salesOrdersListMain"
          label="Sales Orders"
          empty={{
            icon: DollarSign,
            title: "No sales orders found",
            description: "No sales orders available to display.",
          }}
          pagination={{
            pageNo: filters.pageNo || 1,
            count: salesOrders?.meta.count,
            pageSize: filters.pageSize || 30,
            setPageNo: (pageNo) => setFilters({ pageNo }),
            setPageSize: (pageSize) => setFilters({ pageSize }),
          }}
          rows={salesOrders?.salesOrders || []}
          columns={[
            {
              key: "number",
              name: "#",
              width: 100,
              renderCell: ({ row }) => row.number,
            },
            {
              key: "client",
              name: "Client",
              width: 300,
              icon: Building,
              renderCell: ({ row }) => <p>{row.clients.name}</p>,
            },
            {
              key: "status",
              name: "Status",
              width: 130,
              icon: Eye,
              renderCell: ({ row }) => (
                <SalesOrderStatusBadge status={row.status} />
              ),
            },
            {
              key: "date",
              name: "Date",
              width: 160,
              icon: Calendar,
              renderCell: ({ row }) => format(row.date, "dd/MM/yyyy"),
            },
            {
              key: "salesPerson",
              name: "Sales Person",
              width: 160,
              icon: User,
              renderCell: ({ row }) => (
                <p>{row.salesPerson ? formatName(row.salesPerson) : "-"}</p>
              ),
            },
            {
              key: "amount",
              name: "Amount",
              width: 160,
              icon: DollarSign,
              renderCell: ({ row }) => (
                <p>
                  {heffl.format.currency(
                    calculateLineItems({
                      discount: row.discount,
                      lineItems: row.salesOrderItems,
                    }).total,
                    "AED",
                    true
                  )}
                </p>
              ),
            },
            {
              key: "commission",
              name: "Commission",
              width: 160,
              icon: DollarSign,
              renderCell: ({ row }) => (
                <p>
                  {heffl.format.currency(
                    sum(row.commissions, (c) => c.amount),
                    "AED",
                    true
                  )}
                </p>
              ),
            },
            {
              key: "actions",
              name: "Actions",
              width: 100,
              icon: Zap,
              renderCell: ({ row }) => (
                <DropMenu
                  items={[
                    {
                      label: "Edit",
                      icon: Pencil,
                      onClick: () => navigate(`edit/${row.id}`),
                    },
                    {
                      label: "Duplicate",
                      icon: Copy,
                      onClick: () => navigate(`add?salesOrderId=${row.id}`),
                    },
                  ]}
                >
                  <Button
                    onClick={(e) => e.stopPropagation()}
                    variant={"ghost"}
                  >
                    <MoreHorizontal className="h-4 text-gray-700" />
                  </Button>
                </DropMenu>
              ),
            },
            ...(salesOrdersCustomFields ?? []).map((field) => ({
              key: field.name,
              name: field.label,
              // @ts-ignore
              renderCell: ({ row }) =>
                renderCustomFieldValue(
                  field,
                  formatCustomFields({
                    customFields: row.customFields,
                    customFieldFields: salesOrdersCustomFields || [],
                  })[field.name]
                ),
            })),
          ]}
        />
      )}
    </Page>
  );
};

export default SalesOrders;
