import { trpc } from "@/helpers/trpc";
import Schemas from "@heffl/server/src/schemas";
import ModalDrawer from "@heffl/ui/components/modal-drawer";
import { Button } from "@heffl/ui/components/primitives/button";
import { Form } from "@heffl/ui/components/primitives/form";
import FullScreenSpinner from "@heffl/ui/components/primitives/full-screen-spinner";
import { zodResolver } from "@hookform/resolvers/zod";
import { Save, Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";
import FormQuestionForm from "./add-question-form";
import heffl from "@/helpers/hefflHelpers/heffl";

export const AddFormQuestionModal = ({
  defaultValues,
  open,
  onClose,
}: {
  defaultValues?: Partial<z.infer<typeof customFieldsAddSchema>>;
  open: boolean;
  onClose: () => void;
}) => {
  const form = useForm<z.infer<typeof customFieldsAddSchema>>({
    resolver: zodResolver(customFieldsAddSchema),
    defaultValues: { dataType: "TEXT" },
  });

  useEffect(() => {
    if (defaultValues) {
      form.reset({ ...form.getValues(), ...defaultValues });
    }
  }, [defaultValues, form]);

  const customFieldAddMutation =
    trpc.customizations.customFields.add.useMutation({
      onSuccess() {
        form.reset();
        toast.success("Added custom field.");
        onClose();
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  return (
    <ModalDrawer
      className="max-w-sm"
      open={open}
      title="Add question"
      onClose={() => {
        form.reset({});
        onClose();
      }}
    >
      <Form
        {...form}
        onSubmit={(values) =>
          customFieldAddMutation.mutate({
            ...values,
            customObjectId: defaultValues?.customObjectId,
          })
        }
      >
        <FormQuestionForm form={form} />
        <Button
          className="mt-3"
          type="submit"
          variant="primary"
          loading={customFieldAddMutation.isLoading}
        >
          Add field
        </Button>
      </Form>
    </ModalDrawer>
  );
};

const customFieldsAddSchema = Schemas.customFields.customFields.superRefine(
  (
    {
      section,
      dealPipelineId,
      // projectPipelineId,
      documentTemplateId,
      customObjectId,
      dataType,
      customFieldLink,
    },
    ctx
  ) => {
    if (section === "DEAL" && !dealPipelineId) {
      ctx.addIssue({
        path: ["dealPipelineId"],
        code: z.ZodIssueCode.custom,
        message: "dealPipelineId is required for DEAL section",
      });
    }

    if (section === "CUSTOM_OBJECT" && !customObjectId) {
      ctx.addIssue({
        path: ["customObjectId"],
        code: z.ZodIssueCode.custom,
        message: "customObjectId is required for CUSTOM_OBJECT section",
      });
    }
    // if (section === "PROJECT" && !projectPipelineId) {
    //   ctx.addIssue({
    //     path: ["projectPipelineId"],
    //     code: z.ZodIssueCode.custom,
    //     message: "projectPipelineId is required for PROJECT section",
    //   });
    // }
    if (section === "QUOTATION_LINE_ITEM" && !documentTemplateId) {
      ctx.addIssue({
        path: ["documentTemplateId"],
        code: z.ZodIssueCode.custom,
        message:
          "documentTemplateId is required for QUOTATION_LINE_ITEM section",
      });
    }

    if (section === "QUOTATION" && !documentTemplateId) {
      ctx.addIssue({
        path: ["documentTemplateId"],
        code: z.ZodIssueCode.custom,
        message: "documentTemplateId is required for QUOTATION section",
      });
    }

    if (dataType === "LINK_ENTITY" && !customFieldLink) {
      ctx.addIssue({
        path: ["customFieldLink"],
        code: z.ZodIssueCode.custom,
        message: "customFieldLink is required for LINK_ENTITY section",
      });
    }
  }
);

export const EditFormQuestionModal = ({
  open,
  onClose,
  customFieldId,
}: {
  open: boolean;
  onClose: () => void;
  customFieldId: number;
}) => {
  const form = useForm<z.infer<typeof customFieldsAddSchema>>({
    resolver: zodResolver(customFieldsAddSchema),
  });

  const { data: customField, isLoading } =
    trpc.customizations.customFields.details.useQuery(customFieldId);

  const customFieldDeleteMutation =
    trpc.customizations.customFields.delete.useMutation({
      onSuccess() {
        onClose();
        form.reset();
        toast.success("Deleted custom field successfully");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const customFieldUpdateMutation =
    trpc.customizations.customFields.update.useMutation({
      onSuccess() {
        onClose();
        form.reset();
        toast.success("Updated custom field successfully");
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  useEffect(() => {
    if (customField) {
      form.reset({
        ...customField,
        values:
          customField?.values?.map((value) => ({
            ...value,
            isNew: false,
            customFieldLink: customField?.customFieldLink
              ? {
                  ...customField.customFieldLink,
                }
              : undefined,
          })) || [],
      });
    }
  }, [customField, form]);

  const onSubmit = (
    values: z.infer<typeof Schemas.customFields.customFields>
  ) => {
    customFieldUpdateMutation.mutate({
      id: customFieldId,
      customField: values,
    });
  };

  return (
    <ModalDrawer
      className="max-w-sm"
      open={open}
      title="Edit question"
      onClose={onClose}
      footer={
        <div className="flex gap-2 w-full">
          <Button
            onClick={() => {
              heffl.modal.confirm({
                title: "Delete custom field",
                description:
                  "Are you sure you want to delete this custom field? This will remove all data associated with this field.",
                onConfirm: () =>
                  customFieldDeleteMutation.mutate(customFieldId),
              });
            }}
            icon={Trash2}
            variant="destructive"
            className="w-1/3"
          >
            Delete
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={customFieldUpdateMutation.isLoading}
            className="w-full"
            onClick={() => form.handleSubmit(onSubmit)()}
            icon={Save}
          >
            Update question
          </Button>
        </div>
      }
    >
      {isLoading ? (
        <FullScreenSpinner className="h-[100px]" />
      ) : (
        <Form {...form} onSubmit={onSubmit}>
          <FormQuestionForm form={form} edit />
        </Form>
      )}
    </ModalDrawer>
  );
};
